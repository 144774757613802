<template>
  <modal :bigModal="true" :show="showCreateAccount" :showClose="true" v-on:close="$emit('close')">
    <auto-compleate-info-modal 
      :idElement="accountselectedId"
      :showDialogAutoCompleate="showDialogAutoCompleate"
      v-on:close="closeAutoCompleteModal()"
      v-on:accept="getDataAccount"
      ></auto-compleate-info-modal>
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="type"
      :isMultipleSelection="false"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="itemsSelected"
      v-on:error="handleError"
    ></assign-item-modal>
    <div slot="header">
      <div class="container-fluid">
        <div class="row">Nueva empresa</div>
      </div>
    </div>
    
    <card shadow type="secondary">
        <b-tabs pills card no-nav-style v-model="tabIndex" no-key-nav class="local-card">
            <!-- General data -->
            <b-tab title-item-class="disabledTab">
              <div class="col-xl-11">
                <h3 class="mb-3">Datos generales</h3>
              </div>
              <b-container style="width:100%" >

                <b-row>
                  <b-col>
                    <label class="form-control-label">
                        Asignar empresa a la cuenta: 
                      </label>
                      <input
                        alternative
                        @click="showAssignItem = true" 
                        style="cursor: pointer; background-color: white" 
                        class="form-control" 
                        v-model="accountName"
                        readonly="true"
                        required>
                      <slot name="helpBlock">
                        <div class="text-danger invalid-feedback" style="display: block;" >
                            {{validateField('idAccount')}}
                        </div>
                      </slot>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de la empresa: *"
                      v-model="item.name"
                      :error = "validateField('name')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Clave interna:"
                      v-model="item.internalKey"
                      :error = "validateField('internalKey')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de contacto: *"
                      v-model="item.contactName"
                      :error = "validateField('contactName')"
                      :capitalize='true'
                      :maxlength="128"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número Telefonico: *"
                      v-model="item.contactPhoneNumber"
                      :error = "validateField('contactPhoneNumber')"
                      :maxlength="16"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Extención:"
                      v-model="item.contactPhoneExt"
                      :error = "validateField('contactPhoneExt')"
                      :maxlength="8"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Celular: *"
                      v-model="item.contactMobileNumber"
                      :error = "validateField('contactMobileNumber')"
                      :capitalize='true'
                      :maxlength="16"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Email: *"
                      v-model="item.contactMail"
                      :error = "validateField('contactMail')"
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

              </b-container>
            </b-tab>

            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Datos de ubicación</h3>
              </div>
              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Código postal: * "
                      v-model="item.zipcode"
                      :error = "validateField('zipcode')"
                      :capitalize='true'
                      :maxlength="6"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Calle: * "
                      v-model="item.street"
                      :error = "validateField('street')"
                      :capitalize='true'
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número ext: * "
                      v-model="item.extNumber"
                      :error = "validateField('extNumber')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número int: "
                      v-model="item.intNumber"
                      :error = "validateField('intNumber')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Colonia: *"
                      v-model="item.suburb"
                      :error = "validateField('suburb')"
                      :capitalize='true'
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Municipio: *"
                      v-model="item.county"
                      :error = "validateField('county')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Ciudad: * "
                      v-model="item.city"
                      :error = "validateField('city')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Estado: * "
                      v-model="item.state"
                      :error = "validateField('state')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Tipo Cliente (Marketing): * "
                      v-model="item.country"
                      :error = "validateField('country')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>
              </b-container>

            </b-tab>
            
            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Datos de la empresa</h3>
              </div>
              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre del negocio:"
                      v-model="item.businessName"
                      :error = "validateField('businessName')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo de ventas por día:"
                      v-model="item.maxByDay"
                      :error = "validateField('maxByDay')"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo de ventas por mes:"
                      v-model="item.maxByMonth"
                      :error = "validateField('maxByMonth')"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número de cuenta CLABE:"
                      v-model="item.bankTransferNumber"
                      :error = "validateField('bankTransferNumber')"
                      :maxlength="18"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="RFC:"
                      v-model="item.tin"
                      :error = "validateField('tin')"
                      :maxlength="13"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="mt-1" style="background: white" >
                      <v-select 
                        label="label"
                        :value="bussinesSelected"
                        :reduce="label => label.id"
                        v-model="idBussines"
                        placeholder = 'Seleccione una opción'
                        :options="businessactivitiesList"
                        @input = "businessSelected"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('idBusinessActivity')}}
                      </div>
                    </slot>
                  </b-col>                  
                </b-row>
              </b-container>
            </b-tab>
        </b-tabs>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goPreviousPage" v-if="tabIndex > 0" >Atras</base-button>
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goNextPage" >{{textButton}}</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import flatpickr from "flatpickr";
import Spanish from "flatpickr/dist/l10n/es.js";
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import AssignItemModal from './AssignItemModal.vue';
import { getAccountInfo, getBusinessActivities, getItemInfoByType, saveEnterprise } from '../../utils/api';
import { catalogs } from '../../utils/catalog';
import { isEmptyOrNull, validateMail } from '../../utils/utilMethods';
import enums from '../../enums/enums';
import "vue-select/dist/vue-select.css";
import "vue-select/dist/vue-select.css";
import AutoCompleateInfoModal from './AutoCompleateInfoModal.vue';
import { getFocus } from '../../utils/generalMethods';

export default {
  name: "new-enterprise-modal",
  components: {
    AssignItemModal,
    AutoCompleateInfoModal
  },
  mixins: {axiosResponseMixin},
  props: {
    ownerId: Number,
    showCreateAccount: {
      type: Boolean
    },
    itemId: {},
    clean: {}
  },
  data() {
    return {
      jsonData: {
        
      },
      type: enums.ENTERPRISE,
      item: {
        idAccount: '',
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: 'N',
        internalKey: '',
        maxByDay: 0,
        maxByMonth: 0,
        maxByDayNoSwipped: 0,
        maxByMonthNoSwipped: 0,
        bankData: '',
        businessName: '',
        idBusinessActivity: '',
        tin: '',
        bankTransferNumber: '',
        zipcode: ''
      },
      emptyItem: {
        idAccount: '',
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        internalKey: '',
        inheritable: 'N',
        maxByDay: 0,
        maxByMonth: 0,
        maxByDayNoSwipped: 0,
        maxByMonthNoSwipped: 0,
        bankData: '',
        businessName: '',
        idBusinessActivity: '',
        tin: '',
        bankTransferNumber: '',
        zipcode: ''
      },
      bussinesSelected: {label: '', id: null},
      businessactivitiesList: [{label: '', id: null}],
      idBussines: 0,
      selected: { name: null, id: null, externalKey: null },
      textButton: 'Siguiente',
      catalogs: catalogs,
      tabIndex: 0,
      tabNumber: 2,
      searchTerm: '',
      showErrorMessages: false,
      showAssignItem: false,
      selectedItems:{},
      accountName: '',
      showDialogAutoCompleate: false,
      accountselectedId: 0
    };
  },
  methods: {
    handleError(error) {
      this.$emit('error', error)
    },
    itemsSelected(event) {
      this.accountName = event[0].fullName;
      this.item.idAccount = event[0].id
      this.showAssignItem = false
      this.accountselectedId = this.item.idAccount
      this.showDialogAutoCompleate = true
      getFocus()
    },
    goPreviousPage(){
      this.tabIndex --
    },
    goNextPage() {
      this.showErrorMessages = true                
      if (!this.canContinue()) {
        this.showErrorMessages = true
        return
      } else{
        this.showErrorMessages = false
      }
      if (this.tabIndex === this.tabNumber ) {
          this.saveEnterprise()
      } else {
          this.tabIndex++;
          this.textButton = this.tabIndex === this.tabNumber ? "Continuar" : "Siguiente"
      }
    },
    canContinue() {
      var keys = Object.keys(this.item)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    validateField(key) {
      if (this.showErrorMessages) {
        if (this.tabIndex === 0) {
          switch (key) {
            case 'name':
              return this.item.name === '' ? 'Ingresa el nombre de la empresa' : ''
            case 'contactName':
              return this.item.contactName === '' ? 'Ingresa el nombre de contacto' : ''
            case 'contactPhoneNumber':
              return this.item.contactPhoneNumber === '' 
              || this.item.contactPhoneNumber.length < 10 ? 'El número telefónico no es valido' : ''
            case 'contactMobileNumber':
              return this.item.contactMobileNumber === ''
              || this.item.contactMobileNumber.length < 10 ? 'El número de celular no es valido' : ''
            case 'contactMail':
              return !validateMail(this.item.contactMail) ? 'El número de celular no es valido' : ''
            case 'idAccount':
              return this.item.idAccount <= 0 ? 'Selecciona una cuenta' : '' 
            case 'internalKey':
              return isEmptyOrNull(this.item.internalKey) ? 'Ingresa la clave interna' : '' 
          }
        }else if (this.tabIndex === 1) {
          switch (key) {
            case 'street':
              return this.item.street === '' ? 'Ingresa la calle' : ''
            case 'extNumber':
              return this.item.extNumber === '' ? 'Ingresa el número del domicilio' : ''
            case 'city':
              return this.item.city === '' ? 'Ingresa la ciudad' : ''
            case 'state':
              return this.item.state === '' ? 'Ingresa el estado' : ''
            case 'country':
              return this.item.country === '' ? 'Ingresa el tipo de cliente' : ''
            case 'suburb':
              return this.item.suburb === '' ? 'Ingresa la colonia' : ''
            case 'county':
              return this.item.county === '' ? 'Ingresa el municipio' : ''
            case 'zipcode':
              return isEmptyOrNull(this.item.zipcode) ? 'Ingresar el código postal' : ''
          }
        }else {
          switch (key) {
            case 'maxByDay':
              return this.item.maxByDay === '' ? 'Ingresa la cantidad maxima por día' : ''
            case 'maxByMonth':
              return this.item.maxByMonth === '' ? 'Ingresa la cantidad maxima por mes' : ''
            case 'businessName':
              return isEmptyOrNull(this.item.businessName) ? 'Ingresa el nombre del negocio' : ''
            case 'idBusinessActivity':
              return isEmptyOrNull(this.item.idBusinessActivity) ? 'Selecciona un giro' : ''
            case 'tin':
              return isEmptyOrNull(this.item.tin) ? 'Ingresa el RFC' : ''
            case 'bankTransferNumber':
              return isEmptyOrNull(this.item.bankTransferNumber) ? 'Ingresa el número de transferencia bancaria' : ''
            default:
              break;
          }
        }
        return '';
      }            
    },
    saveEnterprise() {
      let loaded = this.$loading.show({loader: 'dots'})
      this.item.maxByDay = Number(this.item.maxByDay)
      this.item.maxByMonth = Number(this.item.maxByMonth)
      this.item.maxByDayNoSwipped = Number(this.item.maxByDayNoSwipped)
      this.item.maxByMonthNoSwipped = Number(this.item.maxByMonthNoSwipped)
      saveEnterprise(this.item)
      .then(response => {
        loaded.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show('Empresa creada correctamente')
          this.$emit('enterprise-created')
        }else{
          processErrorResponse(response.data, this.$toasted)
        }

      })
      .catch(error => {
       loaded.hide()
       this.$emit('error', error)
      })
    },
    isEmptyOrNull(data) {
      return data === '' || data === null
    },
    cleanData() {
      this.showErrorMessages = false
      this.item = Object.assign({}, this.emptyItem)
      this.tabIndex = 0
      this.accountName = ''
      this.accountselectedId = 0
      },
    businessSelected() {
      this.item.idBusinessActivity = this.idBussines
    },
    getDropdownValues() {
    },
    getBusinessActivity() {
      getBusinessActivities()
      .then(response => {
        if (response.data.statusCode === 200){
          this.businessactivitiesList = []
          response.data.result.list.forEach(element => {
            this.businessactivitiesList.push({id: element.idBusinessActivities, name: `${element.externalKey} - ${element.name}`})
            this.createLabelSelectorBusiness()
          });
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    createLabelSelectorBusiness() {
      this.businessactivitiesList.map(function (x){
        return x.label = x.name;
      });
    },
    getDataAccount(event){
      getFocus()
      this.showDialogAutoCompleate = false
      let loader = this.$loading.show({loader: 'dots'})
      getAccountInfo(event)
      .then(response=>{
        loader.hide()
        this.accountselectedId = 0
        if (response.data.statusCode === 200) {
          //actualmente el eddpoint dde get account no regresa estos campos por lo que se 
          //   setean en cero debido a que para empresa no son modificables
          let resp = Object.assign({}, response.data.result)
          resp.maxByDayNoSwipped  = "0";
          resp.maxByMonthNoSwipped = "0";
          this.item = Object.assign({}, resp)
          this.item.name = ''
          this.item.internalKey = ''
          this.item.inheritable = 'N'
          


        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    closeAutoCompleteModal(){
      this.showDialogAutoCompleate=false;
      getFocus()
    }
  },
  watch: {
    'jsonData': function () {
			let c = this.formatJson(JSON.stringify(this.jsonData))
			this.drawResCode(c)
		},
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    },
    itemId: {
      handler(newValue) {
        if (newValue) {
          getItemInfoByType(this.type, newValue)
          .then(response => {
            if (response.data.statusCode === 200) {
              this.item = response.data.result
            }
          })
          .catch(error => {
            this.$emit('error', error);
          })
        }
      }
    },
  },
  mounted() {
    this.getBusinessActivity()
  }
};
</script>
<style >

.dropdown-input{
   background: white !important;
   min-width: 100% !important;
   height: 45px !important;
   border-radius: 8px !important;
   box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.dropdown-content {
   min-width: 100% !important;
   background: white !important;

}

.dropdown-item {
  height: 35px !important;
  font-size: .9em !important;
  color: #525f7f;
  font-weight: 600;
}

.disabledTab {
  pointer-events: none;
}

.local-card .card-header {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}

.body {
  font-size: 14px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.t {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.editor-w {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.w-2 {
  float: left;
  width: 100%;
}

.editor {
  padding: 20px 60px;
}

.pure-form select{
  min-height: 2.75em;
}

/* .big-modal-dialog {
  max-width: 90% ;
} */
</style>