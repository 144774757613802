var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-xl-2" },
          [
            _c("base-select", {
              attrs: { label: "Año", options: _vm.years },
              model: {
                value: _vm.period.year,
                callback: function($$v) {
                  _vm.$set(_vm.period, "year", $$v)
                },
                expression: "period.year"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xl-2" },
          [
            _c("base-select", {
              attrs: { label: "Mes", options: _vm.months },
              model: {
                value: _vm.period.month,
                callback: function($$v) {
                  _vm.$set(_vm.period, "month", $$v)
                },
                expression: "period.month"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xl-2 text-right" },
          [
            _c("download-button", {
              attrs: {
                endpoint: "/sales/download",
                payload: _vm.downloadPayload,
                reportName: "Estado_cuenta"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "table-responsive" },
      [
        _c(
          "base-table",
          {
            attrs: { "thead-classes": "thead-light", data: _vm.tableData },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.fecha)))
                    ]),
                    _c("td", [_vm._v(_vm._s(row.noOperacion))]),
                    _c("td", [_vm._v(_vm._s(row.noAutorizacion))]),
                    _c("td", [_vm._v(_vm._s(row.nomTarjeta))]),
                    _c("td", [_vm._v("**" + _vm._s(row.noTarjeta))]),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm._f("currency")(row.monto)))
                    ]),
                    _vm.detail
                      ? _c("td", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(_vm._f("currency")(row.monto * 0.035)))
                        ])
                      : _vm._e(),
                    _vm.detail
                      ? _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(_vm._f("currency")(row.monto * 0.035 * 0.16))
                          )
                        ])
                      : _vm._e(),
                    _vm.detail
                      ? _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                row.monto -
                                  row.monto * 0.035 -
                                  row.monto * 0.035 * 0.16
                              )
                            )
                          )
                        ])
                      : _vm._e(),
                    _c("td", [
                      row.estatusVenta === "canceled"
                        ? _c("span", [_vm._v("Cancelada")])
                        : _vm._e(),
                      row.estatusVenta === "returned"
                        ? _c("span", [_vm._v("Devolución")])
                        : _vm._e(),
                      row.estatusVenta === "approved"
                        ? _c("span", [_vm._v("Aprobada")])
                        : _vm._e(),
                      row.estatusVenta === "rejected"
                        ? _c("span", [_vm._v("Rechazada")])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          },
          [
            _c("template", { slot: "columns" }, [
              _c("th", [_vm._v("Fecha")]),
              _c("th", [_vm._v("Num. Operacion")]),
              _c("th", [_vm._v("Num. Autorizacion")]),
              _c("th", [_vm._v("Tarjetahabiente")]),
              _c("th", [_vm._v("Tarjeta")]),
              _c("th", [_vm._v("Monto")]),
              _vm.detail ? _c("th", [_vm._v("Comision")]) : _vm._e(),
              _vm.detail ? _c("th", [_vm._v("IVA")]) : _vm._e(),
              _vm.detail ? _c("th", [_vm._v("Deposito")]) : _vm._e(),
              _c("th", [_vm._v("Estado")])
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-4" }, [
      _c("h3", { staticClass: "mb-0" }, [_vm._v("Operaciones")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }