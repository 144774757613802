var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("ticket-info-modal", {
        attrs: { idTicket: _vm.idTicket },
        on: {
          close: function($event) {
            _vm.idTicket = 0
          },
          error: _vm.handleError
        }
      }),
      _c("div", { staticClass: "card-header border-0" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "col-xl-3 mt-2 align-self-center" },
            [
              _c("v-select", {
                attrs: {
                  placeholder: "Todos",
                  options: ["Revisados", "Pendientes"]
                },
                on: { input: _vm.changeTableData }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xl-3 mt-2 align-self-center" },
            [
              _c("v-select", {
                attrs: {
                  placeholder: "Todos",
                  options: [
                    "Rollos",
                    "Reportes",
                    "Comentarios",
                    "Limite de ventas"
                  ]
                },
                on: { input: _vm.changeTableDataTypes }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xl-3 mt-2 text-right align-self-center",
              staticStyle: { "{background-color": "#0A0" }
            },
            [
              _c("base-input", {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { placeholder: "Búsqueda" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.loadData($event)
                  }
                },
                model: {
                  value: _vm.paginationDTO.searchTerm,
                  callback: function($$v) {
                    _vm.$set(_vm.paginationDTO, "searchTerm", $$v)
                  },
                  expression: "paginationDTO.searchTerm"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "card-body border-0" }, [
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c(
              "base-table",
              {
                attrs: { "thead-classes": "thead-light", data: _vm.tableData },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "th",
                          {
                            staticClass: "text-left",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.ticketClicket(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getType(row)) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.ticketClicket(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.enterpriseName) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.ticketClicket(row)
                              }
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.description1) +
                                  "\n            "
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.description2) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _c("td", { staticClass: "text-left" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.createdOn) +
                              "\n          "
                          )
                        ]),
                        _c("td", [
                          row.status === "A"
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-pill badge-danger",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.check(row)
                                    }
                                  }
                                },
                                [_vm._v("Pendiente")]
                              )
                            : _vm._e(),
                          row.status === "I"
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-pill badge-success",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.check(row)
                                    }
                                  }
                                },
                                [_vm._v("Revisado")]
                              )
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              },
              [
                _c("template", { slot: "columns" }, [
                  _c("th", [_vm._v("Tipo")]),
                  _c("th", [_vm._v("Empresa")]),
                  _c("th", { ref: "prev" }, [_vm._v("Título/Descripción")]),
                  _c("th", [_vm._v("F. Alta")]),
                  _c("th", [_vm._v("Estatus")])
                ])
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "card-footer d-flex justify-content-end" },
              [
                _c("base-pagination", {
                  attrs: {
                    total: _vm.totalElements,
                    perPage: _vm.pagination.size,
                    value: _vm.pagination.page
                  },
                  on: { input: _vm.updatePagination }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col mt-2 align-self-center" }, [
      _c("div", [
        _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
          _vm._v("\n            Tickets\n         ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }