
<script>
import { Bar } from 'vue-chartjs'
import { isEmptyOrNull } from '../utils/generalMethods'
  export default {
    extends: Bar,
    name: 'survey-chart',
    props: {
      accountId: Number,
      data: {}
    },
    data() {
      return {
        
      }
    },
    methods: {
      getData(){
        var dataChart = []
        var values =  Object.values(this.data.answers)
        values.forEach(value => {
          dataChart.push(value)
        })
        return dataChart
      },
      setupChart(){
        if (isEmptyOrNull(this.data) ) {
          return
        }
        this.renderChart({
          labels: ['Mal', 'No tan mal', 'Bien', 'Excelente'],
          datasets: [
            {
              label: this.data.surveyCode,
              backgroundColor: '#0084DA',
              data: this.getData()
            }
          ]
          }, 
          {
            responsive: true, 
            maintainAspectRatio: false, 
            scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
            }}
        )}
    },
    watch: {
      data: {
        handler(){
          this.setupChart()
        }
      }
    },
    mounted(){
      this.setupChart()
    }
  }
</script>
<style>

.chartjs-render-monitor {
  height: 200px !important;
}
</style>
