var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { bigModal: true, show: _vm.showDetails, showClose: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [_vm._v("Detalles de la sucursal")])
        ])
      ]),
      _c(
        "card",
        { attrs: { shadow: "", type: "secondary" } },
        [
          _c(
            "b-tabs",
            {
              attrs: { pills: "", "content-class": "mt-3", "no-key-nav": "" },
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "flex-fill text-center ",
                    title: "Generales"
                  }
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Generales")
                  ]),
                  _c(
                    "b-container",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Nombre de la sucursal: *",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "name", $$v)
                                  },
                                  expression: "item.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Nombre de contacto: *",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.contactName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "contactName", $$v)
                                  },
                                  expression: "item.contactName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Número Telefonico: *",
                                  disabled: "",
                                  maxlength: 10,
                                  validation: "number"
                                },
                                model: {
                                  value: _vm.item.contactPhoneNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "contactPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "item.contactPhoneNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Extención:",
                                  disabled: "",
                                  maxlength: 10,
                                  validation: "number"
                                },
                                model: {
                                  value: _vm.item.contactPhoneExt,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "contactPhoneExt", $$v)
                                  },
                                  expression: "item.contactPhoneExt"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Celular: *",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 10,
                                  validation: "number"
                                },
                                model: {
                                  value: _vm.item.contactMobileNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "contactMobileNumber",
                                      $$v
                                    )
                                  },
                                  expression: "item.contactMobileNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Email: *",
                                  disabled: "",
                                  maxlength: 64
                                },
                                model: {
                                  value: _vm.item.contactMail,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "contactMail", $$v)
                                  },
                                  expression: "item.contactMail"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "flex-fill text-center ",
                    title: "Direccion"
                  }
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Dirección")
                  ]),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Calle: * ",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.street,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "street", $$v)
                                  },
                                  expression: "item.street"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Número ext: * ",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 10
                                },
                                model: {
                                  value: _vm.item.extNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "extNumber", $$v)
                                  },
                                  expression: "item.extNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Número int: ",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 10
                                },
                                model: {
                                  value: _vm.item.intNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "intNumber", $$v)
                                  },
                                  expression: "item.intNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Colonia: *",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.suburb,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "suburb", $$v)
                                  },
                                  expression: "item.suburb"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Municipio: *",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.county,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "county", $$v)
                                  },
                                  expression: "item.county"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Ciudad: * ",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "city", $$v)
                                  },
                                  expression: "item.city"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Estado: * ",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.state,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "state", $$v)
                                  },
                                  expression: "item.state"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Tipo Cliente (Marketing): * ",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.item.country,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "country", $$v)
                                  },
                                  expression: "item.country"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "flex-fill text-center ",
                    title: "Cuenta"
                  }
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Cuenta")
                  ]),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Clave interna:",
                                  disabled: "",
                                  capitalize: true,
                                  maxlength: 16
                                },
                                model: {
                                  value: _vm.item.internalKey,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "internalKey", $$v)
                                  },
                                  expression: "item.internalKey"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Máximo de ventas por día:",
                                  disabled: "",
                                  validation: "number"
                                },
                                model: {
                                  value: _vm.item.maxByDay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "maxByDay", $$v)
                                  },
                                  expression: "item.maxByDay"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("base-input", {
                                attrs: {
                                  "input-classes": "form-control-alternative",
                                  label: "Máximo de ventas por mes:",
                                  disabled: "",
                                  validation: "number"
                                },
                                model: {
                                  value: _vm.item.maxByMonth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "maxByMonth", $$v)
                                  },
                                  expression: "item.maxByMonth"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "bg-white border-0",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-right" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "my-4 ml-3 mr-3",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_vm._v("Cerrar")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }