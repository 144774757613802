var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.top-center",
          value: "Descargar reporte",
          expression: "'Descargar reporte'",
          modifiers: { "top-center": true }
        }
      ],
      staticStyle: { cursor: "pointer" },
      on: { click: _vm.downloadReport }
    },
    [_c("i", { staticClass: "ni ni-cloud-download-95 download-icon-size" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }