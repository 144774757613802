<template>
    <div>
        <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
          <card-stats v-if="isRegularUser" :period=period></card-stats>
        </base-header>

        <div class="container-fluid mt--7">
            <!--Tables-->
            <div class="row mt-5">
                <div class="col-xl-12">
                    <transactions-table 
                      v-on:error="handleError"
                      :showDownloadButton="true"
                      :retrieveCompany="$route.meta.retrieveCompany"
                      :retrieveClientInfo="false">
                    </transactions-table>
                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>

  // Tables
  import TransactionsTable from './Tables/TransactionsTable';
  import CardStats from './Dashboard/CardStats'

  import handleErrorMixin from '../mixins/handleErrorMixin';
  import periodSearchMixin from '../mixins/periodSearchMixin';  
  

  export default {
    components: {
      TransactionsTable,
      CardStats,
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        period:{},
      };
    },
    computed: {
      isRegularUser(){
        const REGULAR_USER_ROLES = ['USER', 'ADMIN_USER'];
        return REGULAR_USER_ROLES.includes(this.$store.getters.jwtRole);
      }
    },
    methods: {
    },
    mounted() {
      this.initializePeriod();
    }
  };
</script>
<style></style>
