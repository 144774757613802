<template >
  <div class="row justify-content-center">
     <template>
          <modal :show="showModal" >
            <div>
              <div class="text-center">
                <img src="img/signup/success.png" style="width: 100px; height: 100px" />
                <div class="p-3" style="color: green; font-size: large ">Felicidades</div>
                <div>
                  Tu solicitud ha sido registrada de manera exitosa en el sistema Zuma Pagos.
                  En breve recibirás la información de acceso a su cuenta.
                </div>
                 <base-button type="primary" class="my-4" @click="closeModal" >Entendido</base-button>
              </div>
            </div>
          </modal>
      </template>      
    <div class="col-lg-9">
      <div class="card bg-secondary shadow border-0">
        <div>
          <!-- Tabs with card integration -->
          <b-tabs pills card no-nav-style v-model="tabIndex" no-key-nav class="local-card">
            <!-- General data -->
            <b-tab title-item-class="disabledTab">
              <div class="col-xl-11">
                <h3 class="mb-3">Datos generales del titular</h3>
              </div>
              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre(s):"
                      v-model="model.generalData.strFirstName"
                      :error = "validateField('strFirstName')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Apellido paterno:"
                      v-model="model.generalData.strLastName"
                      :error = "validateField('strLastName')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Apellido materno:"
                      v-model="model.generalData.strLastName2"
                      :error = "validateField('strLastName2')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h4>Tipo de persona</h4>
                    <div class="" style="background: white" >
                      <v-select 
                        v-model="model.generalData.strTaxPayerType"  
                        placeholder = 'Selecciona una opción' 
                        :options="typePersonCatalog"
                        @input = "resetIneData"></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strTaxPayerType')}}
                      </div>
                    </slot>
                  </b-col>
                </b-row>
              </b-container>
              <b-container v-show="this.model.generalData.strTaxPayerType === 'Persona física'">
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número de INE:"
                      v-model="model.generalData.strPPDocNumber"
                      :error = "validateField('strPPDocNumber')"
                      :uppercase='true'
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h4>INE de la persona física</h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="text-center" >
                      <label class="file-select" >
                      <div class="select-button">
                        <span v-if="ineDocumentPersonalAdverseText">{{ineDocumentPersonalAdverseText}}</span>
                        <span v-else>Adjuntar Anverso INE</span>
                      </div>
                      <input id="anverso" type="file" @change="onChangeFileUpload"/>
                    </label>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strPPDocFile')}}
                      </div>
                    </slot>
                    </div>
                  </b-col>
                  <b-col >
                    <div class="text-center" >
                      <label class="file-select" >
                      <div class="select-button">
                        <span v-if="ineDocumentPersonalBackText">{{ineDocumentPersonalBackText}}</span>
                        <span v-else>Adjuntar reverso INE</span>
                      </div>
                      <input type="file" @change="onChangeFileUpload"/>
                    </label>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strPPDocFileBack')}}
                      </div>
                    </slot>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <label class="form-control-label">
                      Fecha de expedición
                    </label>
                    <div class="mt-3 " style="background: white" >
                      <v-select 
                        v-model="model.generalData.dtmPPDocIssueDate"
                        placeholder = 'Seleccione una opción'
                        :options="yearsBefore"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('dtmPPDocIssueDate')}}
                      </div>
                    </slot>
                  </b-col>
                  <b-col>
                    <label class="form-control-label">
                      Vigencia
                    </label>
                    <div class="mt-3 " style="background: white" >
                      <v-select 
                        v-model="model.generalData.dtmPPDocExpire"
                        placeholder = 'Seleccione una opción'
                        :options="yearsAfter"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('dtmPPDocExpire')}}
                      </div>
                    </slot>
                  </b-col>
                </b-row>
              </b-container>

              <b-container>
                <b-row>
                  <b-col class="mt-3">
                    <base-input
                      input-classes="form-control-alternative"
                      label="Email:"
                      v-model="model.generalData.strEmail"
                      :maxlength="64"
                      :error = "validateField('strEmail')"
                    ></base-input>
                  </b-col>
                  <b-col class="mt-3">
                    <base-input
                      input-classes="form-control-alternative"
                      label="Confirmar email:"
                      v-model="confirmEmail"
                      :maxlength="64"
                      :error = "validateField('confirmEmail')"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Teléfono"
                      v-model="model.generalData.strPhoneNumber"
                      :error = "validateField('strPhoneNumber')"
                      :maxlength="10"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                   <base-input
                     input-classes="form-control-alternative"
                     label="RFC:"
                     v-model="model.generalData.strTaxPayerID"
                     :error = "validateField('strTaxPayerID')"
                   ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                   <b-col>
                     <base-input
                      input-classes="form-control-alternative"
                      label="Calle y número exterior:"
                      v-model="model.generalData.strAddress"
                      :error = "validateField('strAddress')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                   </b-col>
                    <b-col>
                     <base-input
                      input-classes="form-control-alternative"
                      label="Número interior:"
                      v-model="model.generalData.strIntNumber"
                      :error = "validateField('strIntNumber')"
                    ></base-input>
                   </b-col>
                </b-row>
                <b-row>
                   <b-col>
                     <base-input
                      input-classes="form-control-alternative"
                      label="Colonia:"
                      v-model="model.generalData.strSuburb"
                      :error = "validateField('strSuburb')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                   </b-col>
                    <b-col>
                      <base-input
                        input-classes="form-control-alternative"
                        label="Delegación o municipio:"
                        v-model="model.generalData.strCounty"
                        :error = "validateField('strCounty')"
                        :capitalize='true'
                        :maxlength="40"
                      ></base-input>
                   </b-col>
                </b-row>
                <b-row>
                   <b-col>
                      <base-input
                        input-classes="form-control-alternative"
                        label="Ciudad:"
                        v-model="model.generalData.strCity"
                        :error = "validateField('strCity')"
                        :capitalize='true'
                        :maxlength="40"
                      ></base-input>
                   </b-col>
                    <b-col>
                      <base-input
                        input-classes="form-control-alternative"
                        label="Estado:"
                        v-model="model.generalData.strState"
                        :error = "validateField('strState')"
                        :capitalize='true'
                        :maxlength="40"
                      ></base-input>
                   </b-col>
                </b-row>
                <b-row>
                   <!-- <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="País:"
                      v-model="model.generalData.strCountry"
                      :error = "validateField('strCountry')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                   </b-col> -->
                   <b-col>
                      <base-input
                        input-classes="form-control-alternative"
                        label="Código postal:"
                        v-model="model.generalData.strZipCode"
                        :error = "validateField('strZipCode')"
                        :maxlength='5'
                        validation="number"
                      ></base-input>
                   </b-col>
                </b-row>
                <b-row>
                  <b-col>
                     <h4>Actividad o giro </h4>
                    <div class="mt-3 " style="background: white" >
                      <v-select 
                        v-model="model.generalData.strFiscalRegimen"
                        placeholder = 'Seleccione una opción'
                        :options="catalogs"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                      <slot name="helpBlock">
                        <div class="text-danger invalid-feedback" style="display: block;" >
                            {{validateField('strFiscalRegimen')}}
                        </div>
                      </slot>
                  </b-col>
                </b-row>
                <b-row v-show="model.generalData.strFiscalRegimen === 'Otros'">
                   <b-col class="mt-3">
                    <base-input
                      input-classes="form-control-alternative"
                      label="Especifique el Giro:"
                      v-model="model.generalData.strFiscalRegimenCustom"
                      :error = "validateField('strFiscalRegimenCustom')"
                    ></base-input>
                   </b-col>
                </b-row>
              </b-container>
            </b-tab>

            <!-- Enterprise data -->
            <b-tab title-item-class="disabledTab">
              <div class="col-xl-11">
                <h3 class="mb-3">Datos generales de la empresa</h3>
              </div>
              <b-container>
                <b-row v-show="isMoralType()">
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Razón social"
                      v-model="model.enterpriseData.strLegalName"
                      :error = "validateField('strLegalName')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre comercial"
                      v-model="model.enterpriseData.strBrandName"
                      :error = "validateField('strBrandName')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                </b-row>
                 <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de la Sucursal (Solo en caso de que sea distinto al Nombre Comercial)"
                      v-model="model.enterpriseData.strBranchName"
                      :error = "validateField('strBranchName')"
                      :capitalize='true'
                      :maxlength="40"
                    ></base-input>
                  </b-col>
                </b-row>
              </b-container>
              <b-container v-show="this.isMoralType()">
                <b-row>
                  <div class="col-xl-11">
                    <h4 class="mb-3">Datos del representante</h4>
                  </div>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre(s)"
                      v-model="model.enterpriseData.strLRFirstName"
                      :error = "validateField('strLRFirstName')"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Apellido paterno"
                      v-model="model.enterpriseData.strLRLastName"
                      :error = "validateField('strLRLastName')"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Apellido materno"
                      v-model="model.enterpriseData.strLRLastName2"
                      :error = "validateField('strLRLastName2')"
                    ></base-input>
                  </b-col>
                </b-row>
                 <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="No. de escritura"
                      v-model="model.enterpriseData.strLegalInfoNum"
                      :error = "validateField('strLegalInfoNum')"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <label class="form-control-label">
                      Fecha de escritura
                    </label>
                    <base-input-no-info>
                      <flat-pickr
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true, dateFormat: 'Y/m/d'}"
                        class="form-control datepicker"
                        v-model="model.enterpriseData.strLegalInfoDate"
                      ></flat-pickr>
                    </base-input-no-info>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strLegalInfoDate')}}
                      </div>
                    </slot>                    
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número de INE"
                      v-model="model.enterpriseData.strLRDocNumber"
                      :error = "validateField('strLRDocNumber')"
                      :uppercase='true'
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h4>INE del representante legal</h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col >
                    <div class="text-center" >
                      <label class="file-select" >
                      <div class="select-button">
                        <span v-if="ineDocumentText">{{ineDocumentText}}</span>
                        <span v-else>Adjuntar anverso INE</span>
                      </div>
                      <input id="anverso"  type="file" @change="onChangeFileUpload"/>
                    </label>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strLRDocFile')}}
                      </div>
                    </slot>
                    </div>
                  </b-col>
                  <b-col >
                    <div class="text-center" >
                      <label class="file-select" >
                      <div class="select-button">
                        <span v-if="ineDocumentBackText">{{ineDocumentBackText}}</span>
                        <span v-else>Adjuntar reverso INE</span>
                      </div>
                      <input type="file" @change="onChangeFileUpload"/>
                    </label>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strLRDocFileBack')}}
                      </div>
                    </slot>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <label class="form-control-label">
                      Fecha de expedición
                    </label>
                    <div class="mt-3 " style="background: white" >
                      <v-select 
                        v-model="model.enterpriseData.dtmLRDocIssueDate"
                        placeholder = 'Seleccione una opción'
                        :options="yearsBefore"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('dtmLRDocIssueDate')}}
                      </div>
                    </slot>
                  </b-col>
                  <b-col>
                    <label class="form-control-label">
                      Vigencia
                    </label>
                    <div class="mt-3 " style="background: white" >
                      <v-select 
                        v-model="model.enterpriseData.dtmLRDocExpire"
                        placeholder = 'Seleccione una opción'
                        :options="yearsAfter"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('dtmLRDocExpire')}}
                      </div>
                    </slot>
                  </b-col>
                </b-row>
              </b-container>
              
            </b-tab>

            <!-- Bank data -->
            <b-tab title-item-class="disabledTab">
              <div class="col-xl-11">
                <h3 class="mb-3">Datos bancarios</h3>
              </div>
              <b-container>
                <b-row>
                  <b-col>
                    <label class="form-control-label">
                      Banco
                    </label>
                    <div class="mt-2" style="background: white" >
                      <v-select 
                        v-model="model.bankData.strBankName"
                        placeholder = 'Seleccione una opción'
                        :options="banks"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('strBankName')}}
                      </div>
                    </slot>
                  </b-col>
                  <b-col>
                    <base-input
                        input-classes="form-control-alternative"
                        label="Cuenta de cheques"
                        validation="number"
                        :maxlength="11"
                        v-model="model.bankData.strAccountNumber"
                        :error = "validateField('strAccountNumber')"
                     />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                     <base-input
                        input-classes="form-control-alternative"
                        label="CLABE"
                        placeholder = 'La cuenta para depósito debe estar al mismo nombre del titular del servicio'
                        v-model="model.bankData.strCLABE"
                        :maxlength="18"
                        validation='number'
                        :error = "validateField('strCLABE')"
                      ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                     <label class="file-select col-12" >
                      <div class="select-button-bank">
                        <span v-if="bankDocumentText">{{bankDocumentText}}</span>
                        <span v-else>Adjuntar documento bancario</span>
                      </div>
                      <input type="file" @change="onChangeFileUpload"/>
                    </label>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strBankDocFile')}}
                      </div>
                    </slot>
                  </b-col>
                </b-row>
              </b-container>
             
              <div class="col-xl-11 mt-4">
                <h3 class="mb-3">Referencias personales</h3>
              </div>

              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                        input-classes="form-control-alternative"
                        label="Nombre(s)"
                        v-model="model.referenceData.strPRFirstName"
                        :error = "validateField('strPRFirstName')"
                        :capitalize='true'
                        :maxlength="40"
                      ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                 <b-col>
                    <base-input
                        input-classes="form-control-alternative"
                        label="Apellido paterno"
                        v-model="model.referenceData.strPRLastName"
                        :error = "validateField('strPRLastName')"
                        :capitalize='true'
                        :maxlength="40"
                      ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                        input-classes="form-control-alternative"
                        label="Apellido materno"
                        v-model="model.referenceData.strPRLastName2"
                        :error = "validateField('strPRLastName2')"
                        :capitalize='true'
                        :maxlength="40"
                      ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                        input-classes="form-control-alternative"
                        label="Teléfono"
                        validation="number"
                        v-model="model.referenceData.strPRPhoneNumber"
                        :error = "validateField('strPRPhoneNumber')"
                        :maxlength="10"
                      ></base-input>
                  </b-col>
                   <b-col>
                    <base-input
                        input-classes="form-control-alternative"
                        label="Correo electrónico"
                        v-model="model.referenceData.strPREmail"
                        :error = "validateField('strPREmail')"
                        :maxlength="64"
                      ></base-input>
                  </b-col>
                </b-row>
              </b-container>

            </b-tab>
            
            <!-- Contrac data -->
            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Contrato</h3>
              </div>
              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                        alternative
                        input-classes="form-control-alternative"
                        label = "Nombre del ejecutivo que te atendió"
                        v-model="model.contractData.strExecutiveContact"
                        :error = "validateField('strExecutiveContact')"
                        :capitalize='true'
                        :maxlength="40"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <label class="form-control-label">
                      Cómo te enteraste de Zuma Pagos
                    </label>
                    <div class="mt-2" style="background: white" >
                      <v-select 
                        v-model="model.contractData.strChannelContact"
                        placeholder = 'Seleccione una opción'
                        :options="optionsFindOut"
                        @input = "checkOtherValue"
                        ></v-select>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;" >
                          {{validateField('strChannelContact')}}
                      </div>
                    </slot>
                    <!-- <base-input
                        alternative
                        input-classes="form-control-alternative"
                        label = "Cómo te enteraste de Zuma Pagos"
                        v-model="model.contractData.strChannelContact"
                        :error = "validateField('strChannelContact')"
                        :capitalize='true'
                        :maxlength="40"
                    /> -->
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div >
                      <h3 class="mb-3 mt-2">Productos que deseas contratar</h3>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                   <b-col>
                    <b-form-checkbox-group v-model="model.contractData.strProductRequired" name="flavour-2">
                      <b-form-checkbox 
                        value="LINK">Zuma Link (Cobra a distancia generando ligas de pago desde tu dispositivo móvil)</b-form-checkbox>
                      <b-form-checkbox 
                        value="MINI" >Zuma MINI (Requieres una forma rápida para cobrar con un lector móvil, esta es la opción)</b-form-checkbox>
                      <b-form-checkbox 
                        value="SMART">Zuma Smart (Terminal Inteligente con opción de voucher digital o impreso)</b-form-checkbox>
                    </b-form-checkbox-group>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('strProductRequired')}}
                      </div>
                    </slot>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <base-input
                        alternative
                        input-classes="form-control-alternative "
                        label = "Comentarios"
                        v-model="model.contractData.strComments"
                        :error = "validateField('strComments')"
                        :capitalize='true'
                        :maxlength="512"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div >
                      <h4 class="mt-2">Condiciones comerciales</h4>
                    </div>
                    <div >
                      <div v-html="zumaPagosTextHTML"></div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div style=" display: flex" class="mt-3">
                      <b-form-checkbox  v-model="model.contractData.isAgreementAccepted"  value="true" unchecked-value="false"/>
                      <router-link :to="{ name: 'terms-conditions'}" target="_blank">
                        Acepto términos y condiciones
                      </router-link>
                    </div>
                    <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;">
                          {{validateField('isAgreementAccepted')}}
                      </div>
                    </slot>
                  </b-col>
                </b-row>
                
              </b-container>
            </b-tab>

          </b-tabs>
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="goPreviousPage" v-if="tabIndex > 0">Anterior</base-button>
          <base-button type="primary" class="my-4" @click="goNextPage">{{textButton}}</base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { catalogs, banks, optionsFindOut } from '../utils/catalog'
  import "vue-select/dist/vue-select.css";
  import flatpickr from "flatpickr";
  import { Spanish } from "flatpickr/dist/l10n/es.js";
  flatpickr.localize(Spanish);
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  
  export default {
    name: 'register',
    components: {
      flatPickr
    },
    data() {
      return {
        showErrorMessages: false,
        showModal: false,
        headerModal: '',
        bodyModal: '',
        zumaPagosTextHTML: "Los costos y comisiones del producto pueden ser consultadas en <a target='_blank' rel='noopener noreferrer' href='http://www.zumapagos.com'>www.zumapagos.com</a>",
        catalogs: catalogs,
        banks: banks,
        optionsFindOut: optionsFindOut,
        yearsBefore: [],
        yearsAfter: [],
        typePersonCatalog: ['Persona física', 'Persona moral'],
        ineDocumentPersonalAdverseText: '',
        ineDocumentPersonalBackText: '',
        ineDocumentsPersonal: [],
        ineDocumentText: '',
        ineDocumentBackText: '',
        ineDocuments: [],
        documents: [],
        bankDocumentText: '',
        bankDocuments: [],
        model: {
            generalData: {
                strFirstName: '',
                strLastName: '',
                strLastName2: '',
                strEmail: '',
                strPhoneNumber: '',
                strTaxPayerType: '',
                strFiscalRegimen: '',
                strFiscalRegimenCustom: '',
                strTaxPayerID: '',
                strAddress: '',
                strIntNumber: '',
                strSuburb: '',
                strCounty: '',
                strZipCode: '',
                strCity: '',
                strState: '',
                strCountry: 'México',
                strPPDocNumber: '',
                dtmPPDocIssueDate: '',
                dtmPPDocExpire: '',
                strPPDocFile: '',
                strPPDocFileBack: ''
            },
            enterpriseData: {
                strLegalName: '',
                strBrandName: '',
                strBranchName: '',
                strLRFirstName: '',
                strLRLastName: '',
                strLRLastName2: '',
                strLegalInfoNum: '',
                strLegalInfoDate: '',
                strLRDocNumber: '',
                dtmLRDocIssueDate: '',
                dtmLRDocExpire: '',
                strLRDocFile: '',
                strLRDocFileBack: '',
            },
            bankData: {
                strBankName: '',
                strAccountNumber: '',
                strCLABE: '',
                strBankDocFile: '',
            },
            referenceData: {
              strPRFirstName: '',
              strPRLastName: '',
              strPRLastName2: '',
              strPRPhoneNumber: '',
              strPREmail: ''              
            },
            contractData: {
              strComments: '',
              strExecutiveContact: '',
              strChannelContact: '',
              strProductRequired: [],
              isAgreementAccepted: "false"
            }
        },
        tabIndex: 0,
        textButton: "Siguiente",
        confirmEmail: ''
      }
    },
    methods: {
        checkOtherValue() {
          if (this.model.generalData.strFiscalRegimen !== 'Otros'){
            this.model.generalData.strFiscalRegimenCustom = ''
          }          
        },
        resetIneData(e) {    
          if (e === null) {
            this.model.generalData.strTaxPayerType = ''
          }
          this.ineDocumentsPersonal = []                
          this.model.generalData.strPPDocNumber = ''
          this.model.generalData.dtmPPDocIssueDate = ''
          this.model.generalData.dtmPPDocExpire = ''
          this.model.generalData.strPPDocFile = ''
          this.model.generalData.strPPDocFileBack = ''
          this.ineDocumentPersonalAdverseText = '';
          this.ineDocumentPersonalBackText = '';

          if (!this.isMoralType()) {
            this.ineDocuments = []
            this.model.enterpriseData.strLegalName = ''
            this.model.enterpriseData.strLRFirstName = ''
            this.model.enterpriseData.strLRLastName = ''
            this.model.enterpriseData.strLRLastName2 = ''
            this.model.enterpriseData.strLegalInfoNum = ''
            this.model.enterpriseData.strLegalInfoDate = ''
            this.model.enterpriseData.strLRDocNumber = ''
            this.model.enterpriseData.dtmLRDocIssueDate = ''
            this.model.enterpriseData.dtmLRDocExpire = ''
            this.model.enterpriseData.strLRDocFile = ''
            this.model.enterpriseData.strLRDocFileBack = ''
            this.ineDocumentText = ''
            this.ineDocumentBackText = ''
          }
        },
        closeModal() {
          this.showModal = false
          window.location = "https://www.zumapagos.com"

        },
        goPreviousPage(){
          this.tabIndex--
        },
        goNextPage() {
          this.showErrorMessages = true                
          if (!this.canContinue()) {
            this.showErrorMessages = true
            return
          } else{
            this.showErrorMessages = false
          }
          if (this.tabIndex === 3 ) {
              this.sendDataToBackEnd()            
          } else {
              this.tabIndex++;
              this.textButton = this.tabIndex === 3 ? "Continuar" : "Siguiente"
          }
        },
        sendDataToBackEnd() {
            let loader = this.$loading.show({loader: 'dots'})
            const json = JSON.stringify(this.model);
            let formData = new FormData();
            for(let i=0; i<this.ineDocumentsPersonal.length;i++){
                formData.append('inePersonal'+i,this.ineDocumentsPersonal[i]);
            }
            for(let i=0; i<this.ineDocuments.length;i++){
                formData.append('ine',this.ineDocuments[i]);
            }
            for(let i=0; i<this.bankDocuments.length;i++){
                formData.append('bank',this.bankDocuments[i]);
            }
            formData.append("data", json);

            this.axios.post('/signup/newSignUpUser',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
              loader.hide()
              if (response.data.statusCode === 200) {
                this.showModal = true
              }else {
                this.$toasted.show("No se pudo completar el registro correctamente, contacte con el administrador del sistema");
              }
              
            })
            .catch(() => {
              loader.hide()
                this.$toasted.show("No se pudo completar el registro correctamente, contacte con el administrador del sistema");
            });
        },
        canContinue() {
          var canContinue = '';
          var keys
          switch (this.tabIndex) {
            case 0:
              keys = Object.keys(this.model.generalData)
              break;
            case 1:
              keys = Object.keys(this.model.enterpriseData)
              break;
            case 2:
              keys = Object.keys(this.model.bankData)
              Object.keys(this.model.referenceData).forEach(key =>{
                keys.push(key)
              })
              break;
            case 3:
              keys = Object.keys(this.model.contractData)
              break;
          }
          for (let i = 0; i < keys.length; i++) {
            canContinue = this.validateField(keys[i]) ;
            if (canContinue !== '')
              return false;
          }
          return true;
        },
        validEmail(email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
        },
        validRfc(rfc) {
          var patter = '';
          if (this.model.generalData.strTaxPayerType === 'Persona física') {
            patter = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/;
          }else {
            patter = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
          }
          return patter.test(rfc);
        },
        onChangeFileUpload(e){
            let selectedFiles = e.target.files;
            if(!selectedFiles.length){
                return false;
            }
            var size = selectedFiles[0].size / Math.pow(1024,2)
            if (size > 5 ) {
              this.$toasted.show("El archivo seleccionado excede los 5MB permitidos, intente con otro.");
              e.target.value = null;
              return;
            }
            let namesFile = '';

            switch (this.tabIndex) {
                case 0:
                  namesFile = selectedFiles[0].name
                  if (selectedFiles[0].name.length > 20) {
                    namesFile = selectedFiles[0].name.substring(0,20) + '...' 
                  }        
                  if (e.target.id === 'anverso') {
                    this.ineDocumentsPersonal.splice(0, 1, selectedFiles[0])
                    this.model.generalData.strPPDocFile = selectedFiles[0].name
                    this.ineDocumentPersonalAdverseText = namesFile
                  } else {
                    this.ineDocumentsPersonal.splice(1, 1, selectedFiles[0])
                    this.model.generalData.strPPDocFileBack = selectedFiles[0].name
                    this.ineDocumentPersonalBackText = namesFile
                  }   
                  break;
                case 1:
                  namesFile = selectedFiles[0].name
                  if (selectedFiles[0].name.length > 20) {
                    namesFile = selectedFiles[0].name.substring(0,20) + '...' 
                  }        
                  if (e.target.id === 'anverso') {
                    this.ineDocuments.splice(0, 1, selectedFiles[0])
                    this.model.enterpriseData.strLRDocFile = selectedFiles[0].name
                    this.ineDocumentText = namesFile
                  } else {
                    this.ineDocuments.splice(1, 1, selectedFiles[0])
                    this.model.enterpriseData.strLRDocFileBack = selectedFiles[0].name
                    this.ineDocumentBackText = namesFile
                  }
                  break;
                case 2:
                   this.bankDocuments = []
                    for(let i=0;i<selectedFiles.length;i++){
                        this.bankDocuments.push(selectedFiles[i]);
                        let nameFile = selectedFiles[i].name
                        this.model.bankData.strBankDocFile = nameFile
                        namesFile = nameFile
                        if (nameFile.length > 30) {
                          namesFile = nameFile.substring(0,30) + '...' 
                        }
                    }
                    this.bankDocumentText = namesFile
                  break;
            }
            e.target.value = null;
        },
        isMoralType() {
          return this.model.generalData.strTaxPayerType === 'Persona moral';
        },
        validateField(key) {
          if (this.showErrorMessages)
            switch (key) {
              case 'strFirstName':            
                return this.model.generalData.strFirstName === '' ? 'El nombre no es valido' : ''
              case 'strLastName':
                return this.model.generalData.strLastName === '' ? 'El apellido paterno no es valido' : ''
              case 'strLastName2':
                return this.model.generalData.strLastName2 === '' ? 'El apellido materno no es valido' : ''
              case 'strEmail':
                return this.model.generalData.strEmail === ''
                        || !this.validEmail(this.model.generalData.strEmail) ? 'El correo electrónico no es valido': ''
              case 'strPhoneNumber':
                return this.model.generalData.strPhoneNumber === '' 
                        || this.model.generalData.strPhoneNumber.length !== 10 ? 'El teléfono no es valido' : ''
              case 'strTaxPayerType':              
                return this.model.generalData.strTaxPayerType === '' ? 'Es necesario seleccionar un tipo de persona' : ''
              case 'strFiscalRegimen':
                return this.model.generalData.strFiscalRegimen === '' 
                        ||  this.model.generalData.strFiscalRegimen ===  null ? 'Es necesario seleccionar una actividad o giro' : ''
              case 'strFiscalRegimenCustom':
                return this.model.generalData.strFiscalRegimenCustom === '' 
                        &&  this.model.generalData.strFiscalRegimen ===  'Otros' ? 'Es necesario especificar el giro' : ''
              case 'strTaxPayerID':
                return this.model.generalData.strTaxPayerID === '' || !this.validRfc(this.model.generalData.strTaxPayerID) ? 'El RFC no es valido' : ''
              case 'strAddress':
                return this.model.generalData.strAddress === '' ? 'La dirección es obligatoria' : ''
              case 'strSuburb':
                return this.model.generalData.strSuburb === '' ? 'La colonia es obligatoria' : ''
              case 'strCounty':
                return this.model.generalData.strCounty === '' ? 'El municipio es obligatorio' : ''
              case 'strZipCode':
                return this.model.generalData.strZipCode === '' ? 'El código postal no es valido' : ''
              case 'strCity':
                return this.model.generalData.strCity === '' ? 'La ciudad es obligatoria' : ''
              case 'strState':
                return this.model.generalData.strState === '' ? 'El estado es obligatorio' : ''
              case 'strCountry':
                return this.model.generalData.strCountry === '' ? 'El país es obligatorio' : ''
              case 'strPPDocNumber':
                return this.model.generalData.strPPDocNumber === '' 
                        && this.model.generalData.strTaxPayerType === 'Persona física' ? 'El número de INE es obligatorio' : ''
              case 'dtmPPDocIssueDate':              
                return this.model.generalData.dtmPPDocIssueDate === ''
                        && this.model.generalData.strTaxPayerType === 'Persona física' ? 'La fecha de expedición es obligatoria' : ''
              case 'dtmPPDocExpire':
                return this.model.generalData.dtmPPDocExpire === ''
                        && this.model.generalData.strTaxPayerType === 'Persona física' ? 'La fecha de vigencia es obligatoria'  : ''
              case 'strPPDocFile':
                return this.model.generalData.strPPDocFile === ''
                        && this.model.generalData.strTaxPayerType === 'Persona física' ? 'Es necesario adjuntar el anverso del INE' : ''
              case 'strPPDocFileBack':
                return this.model.generalData.strPPDocFileBack === ''
                        && this.model.generalData.strTaxPayerType === 'Persona física' ? 'Es necesario adjuntar el reverso del INE' : ''
              case 'strLegalName':
                return this.model.enterpriseData.strLegalName === ''
                        && this.isMoralType() ? 'La razón social es obligatoria' : ''
              case 'strBrandName':
                return this.model.enterpriseData.strBrandName === '' ? 'El nombre comercial es obligatorio' : ''
              case 'strLRFirstName':
                return this.model.enterpriseData.strLRFirstName === '' 
                        && this.isMoralType() ? 'El nombre es obligatorio' : ''
              case 'strLRLastName':
              return this.model.enterpriseData.strLRLastName === '' 
                      && this.isMoralType() ? 'El apellido paterno es obligatorio' : ''
              case 'strLRLastName2':
              return this.model.enterpriseData.strLRLastName2 === '' 
                      && this.isMoralType() ? 'El apellido materno es obligatorio' : ''
              case 'strLegalInfoNum':
                return this.model.enterpriseData.strLegalInfoNum === ''
                        && this.isMoralType() ? 'El No. de Escritura es obligatorio' : ''
              case 'strLegalInfoDate':
                return this.model.enterpriseData.strLegalInfoDate === ''
                        && this.isMoralType() ? 'La Fecha de escritura es obligatorio' : ''
              case 'strLRDocNumber':
                return this.model.enterpriseData.strLRDocNumber === ''
                        && this.isMoralType() ? 'El número de INE es obligatorio' : ''
              case 'dtmLRDocIssueDate':
                return this.model.enterpriseData.dtmLRDocIssueDate === ''
                    && this.isMoralType() ? 'La fecha de expedición es obligatoria' : ''
              case 'dtmLRDocExpire':
                return this.model.enterpriseData.dtmLRDocExpire === ''
                        && this.isMoralType() ? 'La fecha de vigencia es obligatoria' : ''
              case 'strLRDocFile':
                return this.model.enterpriseData.strLRDocFile === ''
                        && this.isMoralType() ? 'Es necesario adjuntar el anverso del INE' : ''
              case 'strLRDocFileBack':
                return this.model.enterpriseData.strLRDocFileBack === ''
                        && this.isMoralType() ? 'Es necesario adjuntar el reverso del INE' : ''
              case 'strBankName':
                return this.model.bankData.strBankName === '' ? 'El nombre del banco es obligatorio' : ''
              case 'strAccountNumber':
                return this.model.bankData.strAccountNumber === '' ? 'La cuenta de cheques es obligatoria' : ''
              case 'strCLABE':
                return this.model.bankData.strCLABE === ''
                || this.model.bankData.strCLABE.length !== 18 ? 'La CLABE ingresada no es correcta' : ''
              case 'strBankDocFile':
                return this.model.bankData.strBankDocFile === '' ? 'Es necesario adjuntar el documento bancario' : ''
              case 'strPRFirstName':
                return this.model.referenceData.strPRFirstName === '' ? 'El nombre es obligatorio' : '';
              case 'strPRLastName':
                return this.model.referenceData.strPRLastName === '' ? 'El apellido paterno es obligatorio' : '';
              case 'strPRLastName2':
                return this.model.referenceData.strPRLastName2 === '' ? 'El apellido materno es obligatorio' : '';
              case 'strPRPhoneNumber':
                return this.model.referenceData.strPRPhoneNumber === ''
                        || this.model.referenceData.strPRPhoneNumber.length !== 10 ? 'El teléfono no es valido' : '';
              case 'strPREmail':
                return this.model.referenceData.strPREmail === '' 
                        || !this.validEmail(this.model.referenceData.strPREmail) ? 'El correo electrónico no es valido' : '';
              case 'strExecutiveContact':
                return this.model.contractData.strExecutiveContact === '' ? 'El nombre del ejecutivo es obligatoria' : ''
              case 'strChannelContact':
                return this.model.contractData.strChannelContact === '' ? 'Esta información es necesaria' : ''
              case 'strProductRequired':
                return this.model.contractData.strProductRequired.length === 0 ? 'Es necesario seleccionar por lo menos un producto' : ''
              case 'isAgreementAccepted':
                return this.model.contractData.isAgreementAccepted === 'false' ? 'Es necesario aceptar los términos y condiciones' : ''
              case 'confirmEmail':
                if (this.confirmEmail === '' || !this.validEmail(this.confirmEmail)) {
                  return 'El correo electrónico no es valido';
                }else if (this.confirmEmail !== this.model.generalData.strEmail) {
                  return 'Los correos electrónicos no coinciden'
                }else {
                  return '';
                }
            }
            return '';
        },
        loadYears() {
          const actualYear = new Date().getFullYear();
          for (let index = 0; index < 20; index++) {
            this.yearsBefore.push(actualYear-index);
            this.yearsAfter.push(actualYear+index);
          }
        }        
    },
    mounted() {
      this.loadYears();
    }
  }
</script>
<style>
.disabledTab {
  pointer-events: none;
}

.local-card .card-header {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}

.file-select > .select-button {
  padding: 10px;
  color: white;
  background-color: #302277;
  border-radius: .3rem;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  width: 300px;
}
.file-select > .select-button-bank {
  padding: 10px;
  color: white;
  background-color: #302277;
  border-radius: .3rem;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

/* Don't forget to hide the original file input! */
.file-select > input[type="file"] {
  display: none;
}

.hiperlink {
  cursor:pointer;
  color:blue;
  text-decoration:underline;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

</style>
