<template>
  <div class="card shadow">
    <branch-details :showDetails="showDetails" :idBranch="idBranch" v-on:close="closeModal()"></branch-details>
    <div class="card-header border-0">
      <div class="row">
        <div class="col mt-2 align-self-center">
          <div v-if="idEnterprise > 0">
            <h3 h3 class="mb-0">
              Empresa
              <span> {{enterprise.idEnterprise}} - {{enterprise.name}}</span>
            </h3>
            <i class="ni ni-email-83 icon-size-sm align-middle"></i>
              <span :class="detailClass">{{" "}}{{enterprise.contactMail}}</span>
            &nbsp;
            <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
              <span :class="detailClass">{{" "}}{{enterprise.contactPhoneNumber}}</span>
          </div>

          <div v-else>
           <h3 h3 class="mb-0">
              Sucursales
           </h3>
          </div>
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <v-select 
            v-if="userRol !== 'UsersAccounts'"
            placeholder = 'Todas'
            :options="['Habilitadas', 'Desabilitadas']"
            @input = "changeTableData"

          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center"  style="{background-color: #0A0;}">
          <base-input
            style="margin-bottom: 0px"
            placeholder="Búsqueda"
            v-model="paginationDTO.searchTerm"
            @keyup.enter="loadData"
          >
          </base-input>
        </div>
        <div v-if="userRol === 'UsersAdmins'" class="col-md-2 mt-2  align-self-center">
          <div class="text-right">
            <a @click="$emit('new-item')" class="btn btn-primary text-white">Agregar sucursal</a>
          </div>
        </div>
        <div class="col-md-1 text-center align-self-center mt-2"  style="{background-color: #00A;}">
           <download-button
            endpoint="/branches/download"
            :payload="downloadPayload"
            reportName="Reporte_sucursales"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th v-if="idEnterprise <= 0">Empresa</th>
          <th>Sucursal</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
          <th v-if="userRol !== 'UsersAccounts'" >Estatus</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td v-if="idEnterprise <= 0">
            <div>
                {{row.data.enterpriseName}}
            </div>
          </td>
         
          <td>
            <div 
              @click="goToDetailsItem(row)" 
              style="cursor: pointer; background-color: white" 
              class="h4">
                {{row.data.name}}
            </div>
            <div >
              {{row.data.contactPhone}}
              <br>
              {{row.data.contactMail}}
            </div>
          </td>
      
          <td
            class="text-left"
            v-for="n in monthsToDisplay" :key="n">
            {{ (row.totals[monthNamesEnglish[monthsToDisplay - n]] || "0.00") | currency}}
          </td>
 
          <td v-if="userRol !== 'UsersAccounts'">
            <span v-if="row.data.status === 'I'" class="badge badge-pill badge-danger">Deshabilitada</span>
            <span v-if="row.data.status === 'A'" class="badge badge-pill badge-success">Habilitada</span>
          </td>
    
          <td>
            <router-link
              v-if="userRol === 'UsersAdmins'"
              :to="selectedAccount(row)"
            >
              <i v-tooltip.top-center="'Ver detalles de la sucursal'" class="fas fa-desktop icon-size"></i>
            </router-link>
            &nbsp;
            <a
              v-if="userRol === 'UsersAdmins'"
            >
              <i
                @click.prevent="disableItem(row)"
                v-tooltip.top-center="row.data.status === 'A' ? 'Deshabilitar' : 'Habilitar'"
                class="fas fa-exclamation-triangle icon-size"
              ></i>
            </a>
            &nbsp;
            <a
             v-if="userRol === 'UsersAdmins'">
              <i
                @click.prevent="goPaymentsDetails(row)"
                v-tooltip.top-center="'Pagos'"
                class="fas fa-credit-card icon-size"
              ></i>
            </a>
            &nbsp;
            <router-link
              :to="goSurvey(row)"
              >
              <i v-tooltip.top-center="'Encuestas'" class="fas fa-poll icon-size"></i>
            </router-link>
             &nbsp;
            <!-- security button on branch -->
             <a
              v-if="userRol !== 'UsersAdmins' && userRol === 'UsersAccounts'">
              <i
                @click.prevent="goSecurity(row)"
                v-tooltip.top-center="'Seguridad'"
                class="fas fa-lock icon-size"
              ></i>
            </a>
          </td>
        </template>

      </base-table>
      <div class="card-footer d-flex justify-content-end">
        <base-pagination
          :total="totalElements"
          :perPage="pagination.size"
          :value="pagination.page"
          @input="updatePagination"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import "vue-select/dist/vue-select.css";
import monthSalesMixin from '../../mixins/monthSalesMixin';
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import enumRoutes from '../../enums/enumRoutes';
import { disableBranch, getBranchesV2, getEnterpriseInfo } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import BranchDetails from '../Modals/BranchDetails.vue';
import DownloadButton from "../Buttons/DownloadButton";
import enumRoles from '../../enums/enumRoles';
import { paginationLimit } from '../../utils/catalog';
  export default {
    components: { 
      BranchDetails,
      DownloadButton
    },
    name: 'branches-table-v2',
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin],
    props: {
      idEnterprise: Number,
      type: String,
      refreshTable: Boolean,
      showSearch: {
        default: true,
      },
      updateTable: Boolean,
      hiddeTotalsColumns: {
        default: false
      }
    },
    data() {
      return {
        enterprise: {
          id: 13,
          name: "Empresa 1",
          mail: "hello@swipeapps.io",
          phone: "443287278"
        },
        tableData: [],
        totalElements: 0,
        paginationDTO: {
          searchTerm: "",
          offset: 0,
          limit: paginationLimit,
          status: 'ALL'
        },
        idBranch: 0,
        showDetails: false,
        downloadPayload: {
          idEnterprise: this.idEnterprise,
          offset: 0,
          limit: 0,
          status: 'ALL',
          orderBy: "DESC",
          searchTerm: ""
        }
      }
    },
    computed: {
      userRol() {
        //  this.$toasted.show("permiso:"+this.$store.getters.jwtRole)
        return  this.$store.getters.jwtRole;
      }
    },
    methods: {
      updatePagination(page) {
        if (this.pagination.page !== page){
          if (page === 1){
            this.paginationDTO.offset = 0
          }else{
            let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
            this.paginationDTO.offset = offset
          }
          this.pagination.page = page;
          this.loadData();
        }
      },
      loadData() {
        let load = this.$loading.show({loader: 'dots'})
        this.downloadPayload.searchTerm = this.paginationDTO.searchTerm
        getBranchesV2(this.paginationDTO)
        .then(response => {
          load.hide()
          if (response.data.statusCode === 200){
            this.totalElements = response.data.result.count
            this.tableData = response.data.result.list
            this.downloadPayload.limit = response.data.result.count
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          load.hide()
          this.$emit('error', error)
        })
      },
      changeTableData(e) {
        switch (e) {
          case 'Habilitadas':
              this.paginationDTO.status = 'A'
            break;
          case 'Desabilitadas':
              this.paginationDTO.status = 'I'
            break;
          default:
              this.paginationDTO.status = 'ALL'
            break;
        }
        this.downloadPayload.status = this.paginationDTO.status
        this.loadData()
      },
      disableItem(row) {
        let loader = this.$loading.show({loader: 'dots'})
        disableBranch(row.data.idBranch)
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show("Operacion exitosa")
            if (this.paginationDTO.status === 'ALL'){
              row.data.status = row.data.status === 'A' ? 'I' : 'A'
            }else{
              let index = this.tableData.findIndex(e => e.data.idBranch === row.data.idBranch)
              if ( index >= 0 )  
                this.tableData.splice(index,1) 
            }
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      },
      prepareUpdateItem(row){
        this.$emit("update-item", row);
      },
      goToDetailsItem(row) {
        if (this.userRol === enumRoles.ACCOUNT){
          this.goPaymentsDetails(row) 
        }else{
          this.$router.push({
            path: `${enumRoutes.DEVICES}${row.data.idBranch}`,
          });
        }
      },
      getEnterpriseData() {
        getEnterpriseInfo(this.idEnterprise)
        .then(response => {
          if (response.data.statusCode === 200) {
            this.enterprise = response.data.result
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
      },
      selectedAccount(row) { 
        return `/branch-detail/${row.data.idBranch}`;
      },
      goPaymentsDetails(row) {
        this.$router.push({
          name: 'transactions-details', 
          query: { idBranch: row.data.idBranch }
        });
      },
      showDetailsInfo(row){
        this.idBranch = row.data.idBranch
        this.showDetails = true
      },
      closeModal(){
        this.showDetails = false
        this.idBranch = 0
      },
      goSurvey(row){
        return `/account-survey/${row.data.idBranch}`;
      },
      goSecurity(row){
        // emitir
        this.$emit("security-item", row.data.idBranch);
      }
    },
    mounted() {
      this.pagination.size = this.paginationDTO.limit
      if (this.hiddeTotalsColumns){
        this.monthsToDisplay = 0
      }
      this.initializeMonthData();
      this.loadData();
    },
    watch: {
      updateTable: {
        handler(newValue){
          if (newValue > 0) {
            this.loadData()
            this.$emit('table-loaded')
          }
        }
      },
      idEnterprise: {
        handler(newValue){
          if (newValue > 0) {
            this.getEnterpriseData()
            this.paginationDTO.idEnterprise = newValue
            this.downloadPayload.idEnterprise = newValue
            this.loadData()
          }
        }
      }
    },
  }
</script>
<style lang="scss">

</style>
