// export default {
//   methods:{
//     simpleCurrency : (value) => {
//       let val = (value/1).toFixed(2)
//       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//     }
//   }
// }


export const clone = (obj) => {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export const simpleCurrency = (value) => {
  let val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const validatePassword = (password) => {
  const MINIMUM_LENGTH = 8;
  const ONE_UPPER_CASE_REGEXP = /[A-Z]/;
  const ONE_DIGIT_REGEXP = /[0-9]/;
  return password.length >= MINIMUM_LENGTH && ONE_UPPER_CASE_REGEXP.test(password) && ONE_DIGIT_REGEXP.test(password);
}

export const removeComma = (value) => {
  if (isEmptyOrNull(value)) {
    return value
  }
  return parseInt(value.replaceAll(',', ''))
}

export const formatJson = (txt, compress) => {
  var indentChar = "  ";
  if (/^\s*$/.test(txt)) {
    return;
  }
  try {
    var data = eval("(" + txt + ")");
  } catch (e) {
    throw ("Error inesperado: " + e.description, "err");
  }
  var draw = [],
    // eslint-disable-next-line no-unused-vars
    last = false,
    // eslint-disable-next-line no-unused-vars
    This = this,
    line = compress ? "" : "\n",
    // eslint-disable-next-line no-unused-vars
    nodeCount = 0,
    // eslint-disable-next-line no-unused-vars
    maxDepth = 0;
  var notify = function (name, value, isLast, indent, formObj) {
    nodeCount++;
    for (var i = 0, tab = ""; i < indent; i++) tab += indentChar;
    tab = compress ? "" : tab;
    maxDepth = ++indent;
    if (value && value.constructor == Array) {
      draw.push(
        tab + (formObj ? '"' + name + '":' : "") + "[" + line
      );
      for (var q = 0; q < value.length; q++)
        notify(q, value[q], q == value.length - 1, indent, false);
      draw.push(
        tab + "]" + (isLast ? line : "," + line)
      );
    } else if (value && typeof value == "object") {
      draw.push(
        tab + (formObj ? '"' + name + '":' : "") + "{" + line
      );
      var len = 0,
        i2 = 0;
      // eslint-disable-next-line no-unused-vars
      for (var key in value) len++;
      for (var key2 in value) notify(key2, value[key2], ++i2 == len, indent, true);
      draw.push(
        tab + "}" + (isLast ? line : "," + line)
      );
    } else {
      if (typeof value == "string") value = '"' + value + '"';
      draw.push(
        tab +
        (formObj ? '"' + name + '":' : "") +
        value +
        (isLast ? "" : ",") +
        line
      );
    }
  };
  var isLast = true,
    indent = 0;
  notify("", data, isLast, indent, false);
  return draw.join("");
}

export const isEmptyOrNull = (data) => {
  return data === '' || data === null || data === undefined
}

export const getFocus = () => {
  setTimeout(() => {
    document.body.classList.add('modal-open');
  }, 100)
}
import enumsRoles from '../enums/enumRoles'
export const hasEnterpriseAccess = (role, level) => {
  if (role === enumsRoles.ACCOUNT){
    switch(level) {
      case 'Account':
        return true
      case 'Enterprise':
        return true
      case 'Branch':
        return false
    }
  }
  return true
}