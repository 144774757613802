<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row">
        <div class="col mt-2">
          <h3 class="mb-0">
            Mis Sucursales
          </h3>
        </div>
        <div class="col-xl-3 text-right"  style="{background-color: #0A0;}">
          <base-input
            placeholder="Búsqueda"
            v-model="searchText"
          >
          </base-input>
        </div>
        <div class="col-xl-1 text-right"  style="{background-color: #00A;}">
           <download-button
            :endpoint="downloadEndpoint"
            :payload="myBranchesPayload"
            reportName="Empresa"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="filteredData">
        <template slot="columns">
          <th>Sucursal</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td v-bind:class="{ parentBranch: row.sucursales_level === 1}">
            <span v-if="row.sucursales_status === 2" class="badge badge-pill badge-danger">Deshabilitado</span>
            <router-link v-if="row.webUser_id " :to="branchTransactionLink(row)">
              <div class="h4">
                {{row.usuario_IdUsuario}} - {{row.usuario_NombreNegocio}}
              </div>
            </router-link>
            <div >
              {{row.usuario_Nombre}} {{row.usuario_ApellidoPaterno}} {{row.usuario_ApellidoMaterno}}
            </div>
            <div >
              {{row.usuario_Celular}} / {{row.usuario_Correo}}
            </div>
          </td>
          <td v-bind:class="{ parentBranch: row.sucursales_level === 1 }"
            class="text-right"
            v-for="n in monthsToDisplay" :key="n">
            {{ (row[monthNames[monthsToDisplay - n]] || "0.00") | currency}}
          </td>
          <td class="text-right"
             v-bind:class="{ parentBranch: row.sucursales_level === 1 }">
            <!--router-link v-if="row.webUser_id " :to="branchTransactionLink(row)">
              <i v-tooltip.top-center="tooltipTransactionMsg" class="ni ni-bullet-list-67 icon-size"></i>
            </router-link-->
          </td>
        </template>

      </base-table>
    </div>
  </div>
</template>
<script>

  import genericJoin from '../../mixins/joinArraysHelper';
  import monthSalesMixin from '../../mixins/monthSalesMixin';
  import paginationMixin from "../../mixins/paginationMixin";
  import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";

  import DownloadButton from '../Buttons/DownloadButton';

  export default {
    name: 'my-branches-table',
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin],
    components: {
      DownloadButton,
    },
    props: {
      title: String,
      refreshTable: Boolean,
      showSearch: {
        default: true,
      },

    },
    data() {
      return {
        mainBranchId: 0,
        tableData: [],
        searchText: '',
        mobileUserId: 0,

        tooltipTransactionMsg: 'Transacciones sucursal',
        searched: false,
        idParentBranch: 0,
      }
    },
    computed: {
      filteredData(){
        return this.tableData.filter(e => {
          if(this.searchText){
            return e.usuario_NombreNegocio.includes(this.searchText) 
            ||e.usuario_ApellidoPaterno.includes(this.searchText)
            || e.usuario_ApellidoMaterno.includes(this.searchText)
            || e.usuario_Nombre.includes(this.searchText)
          } else{
            return true;
          }
        });
      },
      myBranchesPayload() {
        return {
          searchTerm: this.searchText,
        }
      },
      downloadEndpoint(){
        return `/sales/yearreport/${this.idParentBranch}/download`;
      }
    },
    watch: {
      refreshTable: {
        handler(newValue){
          if(newValue){
            this.loadData();
          }
        }
      },
      mobileUserId: {
        handler(newValue){
          if(newValue > 0){
            this.loadData();
          }
        }
      },
    },
    methods: {
      loadData() {
        if(this.mobileUserId > 0) {
          const endpoint =  `/mobileUser/${this.mobileUserId}/branches`;
          this.idParentBranch = this.$store.state.user.userInfo.idParentBranch;
          this.axios.get(endpoint)
            .then((result) => {
              function compare( a, b ) {
                if ( a.sucursales_status < b.sucursales_status ){
                  return -1;
                }
                if ( a.sucursales_status > b.sucursales_status ){
                  return 1;
                }
                return 0;
              }
              this.tableData = result.data.sort( compare);
              this.$emit("loaded-branches");
              let endpoint = `/sales/yearreport/${this.idParentBranch}`;
              return this.axios.post(endpoint);
            })
          .then(result =>{
            let activeTransactions = result.data.sales.filter(e => e.estatusVenta === 'approved');
            for(let r of activeTransactions){
              r.idBranch = parseInt(r.idBranch);
            }
            let joinedData = genericJoin("sucursales_idBranch", "idBranch" , this.tableData, activeTransactions);
            this.tableData = joinedData;
          })
          .catch((error) => {
            this.$emit('error', error);
          });
        }
      },
      selectBranchLink(row) {
        return `/branch/${row.sucursales_idBranch}`;
      },
      branchTransactionLink(row) {
        return `/branch/${row.sucursales_idBranch}/transaction-detail`
      },
    },
    mounted() {
      this.mobileUserId = this.$store.getters.jwtData.userId;
      this.initializeMonthData();
      this.loadData();
    }
  }
</script>
<style lang="scss">

   @media (max-width: 1400px) {
    .md-screen-hide{
      display: none;
    }
  }
  .ligth-text {
    color: #828f7f;
  }

  .parentBranch{
    background-color: cornsilk;
  }
  .deleted{
    background-color: darksalmon;
  }
  .icon-size {
    font-size: 1rem;
  }
  .tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
