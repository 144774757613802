<template>
  <div class="card shadow">
    <client-info-modal :clientId="clientId" v-on:close="clientId = 0"></client-info-modal>
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="type"
      :isMultipleSelection="getMultiSelect()"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="itemsSelected"
      v-on:error="handlerError"
    ></assign-item-modal>
    <update-user-password-modal
      :selectedUserId="selectedUserId"
      :subtitle="subtitle"
      :showChangePassword="showChangePassword"
      v-on:close="closeChangePassword"
    ></update-user-password-modal>
    <access-modal
      :showModal="showAccessModal"
      :idUser="idUserSelected"
      v-on:close="closeAccessModal"
    >
    </access-modal>
    <div class="card-header border-0">
      <div class="row" >
        <div class="col mt-2  align-self-center">
          <h3 class="mb-0">
            {{title}}
          </h3>
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <v-select 
            placeholder = 'Todos'
            :options="['Habilitados', 'Desabilitados']"
            @input = "changeTableData"

          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <base-input 
            style="margin-bottom: 0px"
            placeholder="Búsqueda"
            @keyup.enter="loadData"
            v-model="paginationDTO.searchTerm"
          >
          </base-input>
        </div>
        <div class="col-md-2 mt-2">
          <div class="text-right">
            <a @click="$emit('new-client')" class="btn btn-primary text-white">Nuevo</a>
          </div>
        </div>
        <div 
          v-if="showButtonDownload"
          class="col-md-1 mt-2  text-center align-self-center">
          <download-button
            :endpoint='endpointDownload'
            :payload="downloadPayload"
            reportName="Reporte_usuarios"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th v-if="getVisibilityLabelAccount()">Cuenta</th>
          <th v-if="getVisibilityLabelBranch()">Sucursal</th>
          <th>{{getColumnThreeName()}}</th>
          <th v-if="this.type !== 'external'">F. Alta</th>
          <th v-if="this.type !== 'external'">Tipo de usuario</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
          <th>Estatus</th>
          <th v-if="isEnableOptionsButtons()"></th>
        </template>

        <template slot-scope="{row}">
          <td v-if="getVisibilityLabelAccount()" class="text-left" style="back">
             <div>
                {{row.hierarchyName}}
              </div>
          </td>
          <td v-if="getVisibilityLabelBranch()">
             <div v-if="row.branchName">
                {{row.branchName}}
              </div>
              <div v-else>
                No asignado
              </div>
          </td>
          <td>
            <div class="h4" @click="showClientInfo(row)" 
                style="cursor: pointer;">{{row.fullname}}</div>
            <div>{{row.contactPhoneNumber}}</div>
            <div>{{row.email}}</div>
            <div>{{row.username}}</div>
          </td>
          <th class="text-left" v-if="type !== 'external'" >{{row.createdAt | formatDate}}</th>
          <th class="text-left" v-if="type !== 'external'" >{{row.userGroup}}</th>
          <td
            class="text-left"
            v-for="n in monthsToDisplay" :key="n">
            <div v-if="row.totals">
              {{ (row.totals[monthNamesEnglish[monthsToDisplay - n]] || "0.00") | currency}}
            </div>
            <div v-else>
              {{ "0.00" | currency}}
            </div>
          </td>
          <td>
            <span v-if="row.userStatus === 'I'" class="badge badge-pill badge-danger">Deshabilitado</span>
            <span v-if="row.userStatus === 'A'" class="badge badge-pill badge-success">Habilitado</span>
          </td>
          <td v-if="isEnableOptionsButtons()">
            <a>
              <i
                @click.prevent="disableClient(row)"
                v-tooltip.top-center="row.userStatus === 'A' ? 'Deshabilitar' : 'Habilitar'"
                class="fas fa-exclamation-triangle icon-size"
              ></i>
            </a>
            &nbsp;
            <a>
              <i
                @click.prevent="prepareUpdateClient(row)"
                v-tooltip.top-center="'Editar'"
                class="fas fa-edit icon-size"
              ></i>
            </a>
            &nbsp;
            <a >
              <i
                @click.prevent="changePassword(row)"
                v-tooltip.top-center="'Cambiar contraseña'"
                class="fas fa-lock icon-size"
              ></i>
            </a>
            &nbsp;
            <a v-if="type === 'client-account'">
              <i
                @click.prevent="showAccessM(row)"
                v-tooltip.top-center="'Accesos'"
                class="fas fa-plus icon-size"
              ></i>
            </a>
            <a v-if="type === 'partner'">
              <i
                @click.prevent="addAccounts(row)"
                v-tooltip.top-center="'Agregar cuentas'"
                class="fas fa-plus icon-size"
              ></i>
            </a>
          </td>
        </template>

      </base-table>
    </div>
     <div
      class="card-footer d-flex justify-content-end"
    >
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>

  import "vue-select/dist/vue-select.css";
  import monthSalesMixin from '../../mixins/monthSalesMixin';
  import paginationMixin from "../../mixins/paginationMixin";
  import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
  import { assingAccounts, disableItemByType, getClientsByType } from '../../utils/api';
  import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
  import ClientInfoModal from '../Modals/ClientInfoModal.vue';
  import enums from '../../enums/enums';
  import AssignItemModal from '../Modals/AssignItemModal.vue';
import UpdateUserPasswordModal from '../Modals/UpdateUserPasswordModal.vue';
import enumRoutes from '../../enums/enumRoutes';
import enumRoles from '../../enums/enumRoles';
import DownloadButton from "../Buttons/DownloadButton";
import AccessModal from '../Modals/AccessModal.vue';
import { paginationLimit } from '../../utils/catalog';

  export default {
    name: 'clients-account-table',
    components: {
      ClientInfoModal,
      AssignItemModal,
      UpdateUserPasswordModal,
      DownloadButton,
      AccessModal
    },
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin,axiosResponseMixin],
    props: {
      title: String,
      type: String,
      refreshTable: Boolean,
      clientUp: {},
      updateTable: Boolean,
      showSearch: {
        default: true,
      },

    },
    data() {
      return {
        mainBranchId: 0,
        showAssignItem: false,
        tableData: [],
        searchText: '',
        mobileUserId: 0,
        tooltipTransactionMsg: 'Transacciones sucursal',
        searched: false,
        idParentBranch: 0,
        clientId: 0,
        paginationDTO: {
          searchTerm: "",
          offset: 0,
          limit: paginationLimit,
          status: 'ALL'
        },
        partnerToAddAccounts: 0,
        selectedUserId: 0,
        showChangePassword: false,
        subtitle: '',
        downloadPayload: {
          userType: "",
          offset: 0,
          limit: 0,
          status: 'ALL',
          orderBy: "DESC",
          searchTerm: ""
        },
        endpointDownload: '',
        showButtonDownload: false,
        showAccessModal: false,
        idUserSelected:''
      }
    },
    methods: {
      isEnableOptionsButtons(){
        switch(this.$store.getters.jwtRole){
          case enumRoles.ADMIN:
           return true
        }
        return false
      },
      getMultiSelect() {
        return this.type === enums.PARTNER
      },
      getColumnThreeName() {
        switch (this.type) {
          case enums.C_ACCOUNT:
            this.endpointDownload = '/users/download/month'
            this.downloadPayload.userType = enumRoles.ACCOUNT
            this.showButtonDownload = true
            return "Cliente";
          case enums.ADMIN:
            this.monthsToDisplay = 0
            this.endpointDownload = '/users/download'
            this.downloadPayload.userType = enumRoles.ADMIN
            this.showButtonDownload = true
            return "Usuario";
          case enums.EXTERNAL:
            return "Cliente";
          case enums.DEVICE:
            return "Dispositivo";
          case enums.PARTNER:
            this.endpointDownload = '/users/download/month'
            this.downloadPayload.userType = enumRoles.PARTNER
            this.showButtonDownload = true
            return "Vendedor";
          default:
            return false;
        }
      },
      getVisibilityLabelAccount() {
        switch (this.type) {
          case enums.C_ACCOUNT:
            return true;
          case enums.ADMIN:
            return false;
          case enums.EXTERNAL:
            return false;
          case enums.DEVICE:
            return false;
          case enums.PARTNER:
            return false;
          default:
            return false;
        }
      },
      getVisibilityLabelBranch() {
        switch (this.type) {
          case enums.C_ACCOUNT:
            return false;
          case enums.ADMIN:
            return false;
          case enums.EXTERNAL:
            return true;
          case enums.DEVICE:
            return true;
          case enums.PARTNER:
            return false;
          default:
            return false;
        }
      },
      showClientInfo(row) {
        if (this.type !== enums.PARTNER){
          return
        }
        this.$router.push({
          path: `${enumRoutes.PARNTER_ACCOUNTS}${row.idUser}`
        });
      },
      showDialoNewClient() {

      },
      disableClient(row) {
        let loader = this.$loading.show({loader: 'dots'});
        disableItemByType(this.type, row)
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show("El cliente fue actualizado correctamente")
            if (this.paginationDTO.status === 'ALL'){
              row.userStatus = row.userStatus === 'A' ? 'I' : 'A'
            }else{
              let index = this.tableData.findIndex(e => e.idUser === row.idUser)
              if ( index >= 0 )  
                this.tableData.splice(index,1) 
            }
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit("error", error)
        })
      },
      prepareUpdateClient(row) {
        this.$emit("update-client", row);
      },
      updatePagination(page) {
        if (this.pagination.page !== page){
          if (page === 1){
            this.paginationDTO.offset = 0
          }else{
            let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
            this.paginationDTO.offset = offset
          }
          this.pagination.page = page;
          this.loadData();
        }
      },
      loadData() {
        this.downloadPayload.searchTerm = this.paginationDTO.searchTerm
        this.tableData = []
        let loader = this.$loading.show({loader: 'dots'});
        getClientsByType(this.type, this.paginationDTO)
        .then(response =>{
          loader.hide()
          if (response.data.statusCode === 200) {
            this.totalElements = response.data.result.count
            this.tableData = response.data.result.list
            this.downloadPayload.limit = response.data.result.count

          }else {
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error);
        })
      },
      handlerError(error){
        this.$emit('error', error)
      },
      changeTableData(e) {
        switch (e) {
          case 'Habilitados':
              this.paginationDTO.status = 'A'
            break;
          case 'Desabilitados':
              this.paginationDTO.status = 'I'
            break;
          default:
              this.paginationDTO.status = 'ALL'
            break;
        }
        this.downloadPayload.status = this.paginationDTO.status
        this.loadData()
      },
      addAccounts(row){
        this.partnerToAddAccounts = row.idUser
        this.showAssignItem = true
      },
      itemsSelected(event){
        this.showAssignItem = false
        let ids = []
        event.forEach(element => {
          ids.push(element.id)
        });
        let loader = this.$loading.show({loader: 'dots'})
        assingAccounts({idUserPartner: this.partnerToAddAccounts, idAccounts: ids})
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show('Cuentas vinculadas correctamente')
          }else{
            processErrorResponse(response.data, this.$toasted )
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      },
      changePassword(row){
        this.subtitle = row.fullname
        this.selectedUserId = Number(row.idUser)
        this.showChangePassword = true
      },
      closeChangePassword(){
        this.subtitle = ''
        this.selectedUserId = 0
        this.showChangePassword =false
      },
      showAccessM(row){
        this.idUserSelected = row.idUser
        this.showAccessModal = true
      },
      closeAccessModal(){
        this.idUserSelected = 0
        this.showAccessModal = false
      }
    },
    mounted() {
      this.pagination.size = this.paginationDTO.limit
      this.loadData()
      this.initializeMonthData();

    },
    watch: {
      updateTable: {
        handler(newValue) {
          if (newValue) {
            this.loadData()
            this.$emit('update-table-completed')
          }
        }
      },
      clientUp:{
        handler(newValue){
          let index = this.tableData.findIndex(e => e.idUser === newValue.idUser);
          if (index < 0) {
            index = this.tableData.length;
          }
          this.tableData[index].fullname = newValue.fullname
          this.tableData[index].username = newValue.username
          this.tableData[index].email = newValue.email
          this.$emit("update-table-completed");
        }
      }
    }
}
</script>
<style lang="scss">

   @media (max-width: 1400px) {
    .md-screen-hide{
      display: none;
    }
  }
  .ligth-text {
    color: #828f7f;
  }

  .parentBranch{
    background-color: cornsilk;
  }
  .deleted{
    background-color: darksalmon;
  }
  .icon-size {
    font-size: 1rem;
  }
  .tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
