var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("new-account-modal", {
        attrs: {
          showCreateAccount: _vm.showCreateAccount,
          itemId: _vm.idAccount,
          clean: _vm.clean,
          type: _vm.type
        },
        on: {
          close: _vm.closeAccountModal,
          error: _vm.handleError,
          "account-created": _vm.accountCreated,
          cleaned: function($event) {
            _vm.clean = false
          },
          "Account-updated": _vm.accountUpdated
        }
      }),
      _c(
        "base-header",
        {
          staticClass: "pb-6 pb-8 pt-5 pt-md-8",
          attrs: { type: "gradient-primary" }
        },
        [
          _vm.isUserAccount
            ? _c("card-stats", { attrs: { period: _vm.period } })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("accounts-table", {
                attrs: { updateTable: _vm.updteTable, type: _vm.type },
                on: {
                  "new-item": _vm.newItem,
                  error: _vm.handleError,
                  "update-item": _vm.prepareUpdateItem,
                  "table-loaded": _vm.tableLoaded
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }