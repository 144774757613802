import { render, staticRenderFns } from "./TicketInfoModal.vue?vue&type=template&id=188f1c38&"
import script from "./TicketInfoModal.vue?vue&type=script&lang=js&"
export * from "./TicketInfoModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/zp_frontend_vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('188f1c38')) {
      api.createRecord('188f1c38', component.options)
    } else {
      api.reload('188f1c38', component.options)
    }
    module.hot.accept("./TicketInfoModal.vue?vue&type=template&id=188f1c38&", function () {
      api.rerender('188f1c38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Modals/TicketInfoModal.vue"
export default component.exports