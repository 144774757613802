<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <total-session-table
          :idSession="idSession"
          :idUser="idUser">
          </total-session-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import TotalSessionTable from './Tables/TotalSessionTable.vue';

export default {
  mixins: [handleErrorMixin],
  components: {
    TotalSessionTable
  },
  data() {
    return {
      idSession: Number(this.$route.params.idSession),
      idUser: Number(this.$route.params.idUser)
    };
  }
};
</script>
<style>
.disabledTab {
  pointer-events: none;
}
</style>
