const routes = {
    ENTERPRISE: '/enterprises/',
    DEVICES: '/devices/',
    BRANCHES: '/branches/',
    DEVICES_DETAILS: '/devices/details/',
    SESSION: '/session/',
    PARNTER_ACCOUNTS: '/partner-accounts/',
    PRODUCTS: '/products/',
}

export default Object.freeze(routes)