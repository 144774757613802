<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-xl-4">
          <h3 class="mb-0">Operaciones</h3>
        </div>
        <div class="col-xl-2">
          <base-select label="Año" :options="years" v-model="period.year"></base-select>
        </div>
        <div class="col-xl-2">
          <base-select label="Mes" :options="months" v-model="period.month"></base-select>
        </div>
        <div class="col-xl-2 text-right">
          <download-button
            endpoint="/sales/download"
            :payload="downloadPayload"
            reportName="Estado_cuenta"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="tableData">
        <template slot="columns">
          <th>Fecha</th>
          <th>Num. Operacion</th>
          <th>Num. Autorizacion</th>
          <th>Tarjetahabiente</th>
          <th>Tarjeta</th>
          <th>Monto</th>
          <th v-if="detail">Comision</th>
          <th v-if="detail">IVA</th>
          <th v-if="detail">Deposito</th>
          <th>Estado</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">{{row.fecha | formatDate}}</th>
          <td>{{row.noOperacion}}</td>
          <td>{{row.noAutorizacion}}</td>
          <td>{{row.nomTarjeta}}</td>
          <td>**{{row.noTarjeta}}</td>
          <td class="text-right">{{row.monto | currency}}</td>
          <td class="text-right" v-if="detail">{{row.monto * 0.035 | currency}}</td>
          <td class="text-right" v-if="detail">{{row.monto * 0.035 * 0.16 | currency}}</td>
          <td
            class="text-right"
            v-if="detail"
          >{{row.monto - (row.monto * 0.035) - (row.monto * 0.035 * 0.16) | currency}}</td>
          <td>
            <span v-if="row.estatusVenta === 'canceled'">Cancelada</span>
            <span v-if="row.estatusVenta === 'returned'">Devolución</span>
            <span v-if="row.estatusVenta === 'approved'">Aprobada</span>
            <span v-if="row.estatusVenta === 'rejected'">Rechazada</span>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import periodSearchMixin from "../../mixins/periodSearchMixin";
import DownloadButton from "../Buttons/DownloadButton";

export default {
  name: "period-sales-table",
  mixins: [periodSearchMixin],
  components: {
    DownloadButton
  },
  props: {
    detail: {
      type: Boolean,
      default: false,
      description: "Show the detail of comission per sale"
    },
    showDownloadButton: {
      type: Boolean,
      default: false,
      description: "Show the download button"
    }
  },
  data() {
    return {
      tableData: [],
      error: "",
      loading: false
    };
  },
  computed: {
    downloadPayload() {
      return {
        period: this.period
      };
    }
  },
  // https://router.vuejs.org/guide/advanced/data-fetching.html
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.tableData = null;
      this.loading = true;

      this.axios
        .post("/sales/search", { period: this.period })
        .then(result => {
          this.tableData = result.data;
          this.$emit("period-changed", this.period);
        })
        .catch(error => {
          this.$emit("error", error);
        });
    }
  },
  mounted() {
    this.initializeYearArray();
    this.period.month = this.getCurrentMonth();
  }
};
</script>
<style>
</style>