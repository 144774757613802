<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col mt-2 align-self-center">
          <div>
           <h3 h3 class="mb-0">
              Sesiones
           </h3>
          </div>
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center">
          <base-input-no-info addon-left-icon="ni ni-calendar-grid-58">
            <flat-pickr
              slot-scope="{focus, blur}"
              @on-open="focus"
              @on-close="blur"
              :config="{allowInput: true, mode: 'range'}"
              class="form-control datepicker"
              v-model="dateRange"
            ></flat-pickr>
          </base-input-no-info>
        </div>
      </div>
    </div>

    <div class="card-body border-0">
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="tableData">
          <template slot="columns">
            <th v-if="idBranch > 0">Dispositivo</th>
            <th>Operaciones</th>
            <th>totales</th>
            <th>Fecha de cerrado</th>
            <th></th>
          </template>

          <template 
            slot-scope="{row}"
            >
            <th
              v-if="idBranch > 0"
              @click="goTransactions(row)" 
              style="cursor: pointer; background-color: white" 
              scope="row">
              {{row.username}}
            </th>
            <td
              @click="goTransactions(row)" 
              style="cursor: pointer; background-color: white" 
              >{{row.totalOperations}}</td>
            <td 
              class="text-left"
              @click="goTransactions(row)" 
              style="cursor: pointer; background-color: white" 
              >{{row.totalAmountWithTip | currency}}</td>
            <th scope="row">
              {{row.creationDate | formatDate}} {{row.creationDate | formatTime}}              
            </th>
            <th>
              <a>
                <i
                  @click.prevent="goTransactions(row)"
                   style="cursor: pointer; background-color: white" 
                  v-tooltip.top-center="'Detalles'"
                  class="fas fa-money-check icon-size"
                ></i>
              </a>
              &nbsp;
              <router-link
                :to="selectedAccount(row)"
              >
                <i v-tooltip.top-center="'Totales'" class="fas fa-dollar-sign icon-size"></i>
              </router-link>
              
            </th>
          </template>
        </base-table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end dark">
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
flatpickr.localize(Spanish);
import "vue-select/dist/vue-select.css";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import periodSearchMixin from "../../mixins/periodSearchMixin";
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import flatpickr from "flatpickr";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { getSessions } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { paginationLimit } from '../../utils/catalog';

export default {
  name: "session-table",
  mixins: [periodSearchMixin, paginationMixin, infoCSSClasesMixin],
  components: {
    flatPickr
  },
  props: {
    idDevice: Number,
    idBranch: Number

  },
  data() {
    return {
      tableData: [],
      error: "",
      dateRange: "",
      totalElements: 0,
      paginationDTO: {
        idUserDevice: this.idDevice,
        idBranch: this.idBranch,
        offset: 0,
        limit: paginationLimit,
        start: '',
        end: '',
      }
    };
  },
  watch: {
    dateRange(newVal) {
      if (newVal.includes(" a ")) {
        this.paginationDTO.start = this.formateDate(newVal.split(" a ")[0] + ' 00:00');
        this.paginationDTO.end =  this.formateDate(newVal.split(" a ")[1] + ' 23:59');
        if (this.paginationDTO.start && this.paginationDTO.end) {
          this.fetchData();
        }
      }
    }
  },
  created() {
    this.paginationDTO.size = this.paginationDTO.limit
    this.fetchData();
  },
  methods: {
    updatePagination(page) {
      if (this.pagination.page !== page){
        if (page === 1){
          this.paginationDTO.offset = 0
        }else{
          let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
          this.paginationDTO.offset = offset
        }
        this.fetchData();
      }
    },
    fetchData() {
      let loader = this.$loading.show({loader: 'dots'})
      getSessions(this.paginationDTO)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.totalElements = response.data.result.count
          this.tableData = response.data.result.list
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    formateDate: function(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ssZZ')
    },
    goTransactions(row){
      var dto = {}
      if (this.idDevice > 0)
        dto.idDevice = this.idDevice
      if (this.idBranch > 0) {
        dto.idBranch = this.idBranch
      }
      dto.idSession = row.idSession
      this.$router.push({
          name: 'session', 
          query: dto
        });
      // this.$router.push({
      //   path: `${enumRoutes.SESSION}${row.idSession}/${this.idDevice}`,
      // });
    },
     selectedAccount(row) { 
      return `/total-session/${row.idSession}/${this.idDevice}`;
    },
  }
}
</script>
<style>

</style>