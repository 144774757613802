<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-11">
          <card shadow>
            <form @submit.prevent>
              <b-tabs
                pills
                card
                v-model="tabIndex"
                vertical
                no-key-nav
              >
                <b-tab
                  title-item-class="ftext-center mt-1" 
                  v-for="product in products" v-bind:key="product.id" :title="product.paymentsProduct"
                  >
                  <div>
                    <span class="h1">{{product.paymentsProduct}}</span>
                    <b-row class="align-self-center mt-4">
                      <b-col class="text-left  align-self-center" >
                        <span class="h4">Estado del producto:</span>
                      </b-col>
                      <b-col class="text-right align-self-center">  
                        <span  
                          @click.prevent="product.status = product.status === 'A' ? 'I' : 'A'" 
                          style="cursor: pointer" 
                          v-if="product.status === 'I'" 
                          class="badge badge-pill badge-danger">
                          Deshabilitado
                        </span>
                        <span  
                          @click.prevent="product.status = product.status === 'A' ? 'I' : 'A'" 
                          style="cursor: pointer" 
                          v-if="product.status === 'A'" 
                          class="badge badge-pill badge-success">
                          Habilitado
                        </span>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="bg-white border-0">
                    <div class="row align-items-center">
                      <div class="col-12 text-right">
                        <base-button type="primary" class="my-4 ml-3 mr-3" @click.prevent="saveProductDev()">Guardar</base-button>
                      </div>
                    </div>
                  </div>

                </b-tab>
              </b-tabs>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import enumRoles from '../enums/enumRoles';
import { getEnterprisesPaymentsProducts, saveProductEnterprise, getDevicesPaymentsProducts , saveProductDevice } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { removeComma, simpleCurrency } from '../utils/generalMethods';
import { isEmptyOrNull } from '../utils/utilMethods';
import enumStepFlow from '../enums/enumStepFlow';
import enumProducts from '../enums/enumProducts';
import enumProductsDev from '../enums/enumProductsDevices';


export default {
  mixins: [handleErrorMixin],
  components: {
  },
  data() {
    return {
      summaryStep: enumStepFlow.PaymentSummary,
      restaurantProduct: enumProducts.RESTAURANTE,
      restaurantProductBzpay: enumProducts.RESTAURANT_BZPAY,
      rolAdmin: enumRoles.ADMIN,
      tabIndex: 0,
      textButton: 'Siguiente',
      selectedAccountId: 0,
      selectedEnterpriseId: 0,
      showUserModal: false,
      showErrorMessages: false,
      products: [],
    };
  },

  computed: {
    canalesP() {
      if (typeof this.operativas.tarjetaPresente.canal === "string") {
        return this.operativas.tarjetaPresente.canal.split(",");
      }
      return this.operativas.tarjetaPresente.canal;
    },
    canalesNP() {
      if (typeof this.operativas.tarjetaNoPresente.canal === "string") {
        return this.operativas.tarjetaNoPresente.canal.split(",");
      }
      return this.operativas.tarjetaNoPresente.canal;
    },
    userRole() {
      return this.$store.getters.jwtRole;
    }
  },

  methods: {
    updateStepFlow(pflow, product){
      pflow.status = pflow.status === 'A' ? 'I' : 'A'
      if (pflow.flowCode === enumStepFlow.PaymentSplit){
        product.productsFlows.forEach(item =>{
          if (item.flowCode === enumStepFlow.PaymentSummary){
            item.status = pflow.status
          }
        })
      }
    },
    setupProductsFlows(){
     console.log(this.products)
     this.products.forEach(product =>{
      var flows = []
      switch(product.paymentsProduct){
        case enumProductsDev.RETAIL:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentPromotion)
          product.productsFlows = flows
          break
        case enumProductsDev.RETAIL_BZPAY:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentPromotion)
          product.productsFlows = flows
          break
        case enumProductsDev.RESTAURANTE:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentTip || e.flowCode === enumStepFlow.PaymentSplit || e.flowCode === enumStepFlow.PaymentSummary)
          product.productsFlows = flows
          break
        case enumProductsDev.RESTAURANT_BZPAY:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentTip || e.flowCode === enumStepFlow.PaymentSplit || e.flowCode === enumStepFlow.PaymentSummary)
          product.productsFlows = flows
          break
        case enumProductsDev.LINK_MIT:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentPromotion)
          product.productsFlows = flows
          break
        case enumProductsDev.LINK_BZPAY:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentPromotion)
          product.productsFlows = flows
          break
        case enumProductsDev.LINK_PRAGA:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentPromotion)
          product.productsFlows = flows
          break
        case enumProductsDev.CODI:
          flows = product.productsFlows.filter(e =>  e.flowCode === enumStepFlow.PaymentPromotion)
          product.productsFlows = flows
          break
        case enumProductsDev.QPOS:
          product.productsFlows = []
          break
        
      }
     })
    },
    currencyAmounts() {
      this.products.forEach(product => {
        product.promotions.forEach(promotion => {
          promotion.minAmount = simpleCurrency(promotion.minAmount)
          promotion.maxAmount = simpleCurrency(promotion.maxAmount)
        });
      })
      
    },
    clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    },
    removeCurrency() {
       this.products.forEach(product => {
        product.promotions.forEach(promotion => {
          promotion.minAmount = removeComma(promotion.minAmount)
          promotion.maxAmount = removeComma(promotion.maxAmount)
        });
      })
    },
    removeComma(value){
      return parseInt(value.replaceAll(',',''))
    },
    validatePaymentPromotions(){
      for (let product of this.products){
        for (let promotion of product.promotions){
          if (isEmptyOrNull(promotion.minAmount) || isEmptyOrNull(promotion.maxAmount)) {
            this.$toasted.show(`Información incompleta: ${promotion.keyDesc}`)
            return false
          }
        }
      }
      return true
    },
    getProductFormated(product){
      let productCopy = JSON.parse(JSON.stringify(product))
      // productCopy.promotions.forEach(promotion => {
      //   promotion.minAmount = removeComma(promotion.minAmount)
      //   promotion.maxAmount = removeComma(promotion.maxAmount)
      //   promotion.minAmount = Number(promotion.minAmount)
      //   promotion.maxAmount = Number(promotion.maxAmount)
      // });
      return productCopy
    },
    saveProduct(product){
      if (this.validatePaymentPromotions()){
        let productFormated = this.getProductFormated(product)
        let dto = {idEnterprise: Number(this.selectedEnterpriseId), hierarchyProduct: productFormated}
        let loader = this.$loading.show({loader: 'dots'})
        saveProductEnterprise(dto)
        .then(response =>{
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show('Producto actualizado correctamente')
            this.removeCurrency()
            this.currencyAmounts()
          }else{
            processErrorResponse(response.data, this.$toasted)
          }    
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })

      }
    },
    saveProductDev(){
        let productos = {paymentsProducts:this.products}
        let dto = {idUserDevice: Number(this.selectedEnterpriseId), paymentsProducts:productos}
        let loader = this.$loading.show({loader: 'dots'})
        saveProductDevice(dto)
        .then(response =>{
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show('Producto actualizado correctamente')
           // this.removeCurrency()
            //this.currencyAmounts()
          }else{
            processErrorResponse(response.data, this.$toasted)
          }    
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
    },
    getDescription(code){
      switch(Number(code)){
        case 3:
          return "3 MSI"
        case 6:
          return "6 MSI"
        case 9:
          return "9 MSI"
        case 12:
          return "12 MSI"
        default:
          return "Contado" 
      }
    }
  },
  async mounted() {
    if (this.$route.params.id > 0) {
      this.selectedAccountId = this.$route.params.id;
      this.selectedEnterpriseId = this.$route.params.id;
      let loader = this.$loading.show({loader: 'dots'})
      getDevicesPaymentsProducts(this.selectedEnterpriseId)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.products = response.data.result;
          this.resultado = response.data.result;
          //this.setupProductsFlows()
          //this.currencyAmounts()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  }
};
</script>
<style>
.nav-pills .nav-item:not(:last-child) {
    padding-right: 0 !important;
}

.borders {
  border-style: solid;
  border-left-color: coral;
}

</style>
