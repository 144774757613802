<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- <card-stats :period=period></card-stats> -->
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
            <card shadow>
              <div v-if="idBranch > 0">
                <h3 h3 class="mb-0">
                  Sucursal:
                  <span> {{branch.idBranch}} - {{branch.name}}</span>
                </h3>
                <i class="ni ni-email-83 icon-size-sm align-middle"></i>
                <span :class="detailClass">{{" "}}{{branch.contactMail}}</span>
                &nbsp;
                <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
                <span :class="detailClass">{{" "}}{{branch.contactMobileNumber}}</span>
              </div>
              <div v-if="idDevice > 0">
                <h3 h3 class="mb-0">
                  Dispositivo:
                  <span> {{device.idUser}} - {{device.deviceName}}</span>
                </h3>
                <i class="ni ni-email-83 icon-size-sm align-middle"></i>
                <span :class="detailClass">{{" "}}{{device.email}}</span>
                &nbsp;
                <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
                <span :class="detailClass">{{" "}}{{device.deviceDescription}}</span>
              </div>
              <tabs
                fill
                class="flex-column flex-md-row"
                :value="activeTab"
                v-on:new-active-tab="updateState"
              >
                <tab-pane title="Empresas">
                  <span slot="title">Pagos</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary" style="background-color: red">
                      <template>
                        <transactions-table-v-2
                          :idDevice="idDevice"
                          :idBranch="idBranch"
                        />
                      </template>
                    </card>
                  </div>
                </tab-pane>
                <tab-pane v-if="!idBranch" title="Nuevos Clientes">
                  <span slot="title">Sesiones</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary">
                      <template>
                        <sessions-table
                          :idDevice="idDevice"
                          :idBranch="idBranch"
                        />
                      </template>
                    </card>
                  </div>
                </tab-pane>
              </tabs>
            </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import handleErrorMixin from '../mixins/handleErrorMixin';
import periodSearchMixin from '../mixins/periodSearchMixin';
import infoCSSClasesMixin from '../mixins/infoCSSClasesMixin';
import TransactionsTableV2 from './Tables/TransactionsTableV2.vue';
import SessionsTable from './Tables/SessionsTable.vue';
import { getBranchInfo, getDeviceInfo } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';

  export default {
    components: {
      TransactionsTableV2,
      SessionsTable
    },
    mixins: [handleErrorMixin, periodSearchMixin, infoCSSClasesMixin],
    data() {
      return {
        idDevice: Number(this.$route.query.idDevice),
        idBranch: Number(this.$route.query.idBranch),
        branch: {
        },
        device: {
        },
        activeTab: ""
      };
    },
    methods: {
      updateState(){
      }
    },
    mounted(){
      if (this.idBranch > 0) {
        getBranchInfo(this.idBranch)
        .then(response=>{
          if (response.data.statusCode === 200) {
            this.branch = response.data.result
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
      }else if (this.idDevice > 0) {
        getDeviceInfo(this.idDevice)
        .then(response=>{
          if (response.data.statusCode === 200) {
            this.device = response.data.result
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
      }
    }
  };
</script>
<style>
.card-body {
  padding: 10px
}
</style>
