<template>
  <div>
    <modal
      :show="showMITData"
      :showClose=true
      v-on:close="showMITData=false">
      <div slot="header">Información de coneccion MIT</div>
      <form @submit.prevent="saveMITData" ref="MITDataForm">
        <div class="container-fluid ">
          <div class="row mt-1 ">
            <div class="col-xl-6">
              <base-input
                  label="Usuario MIT"
                  input-classes="form-control-alternative"
                  v-model="mitData.User"
                />
            </div>
            <div class="col-xl-6">
              <base-input
                  label="Password MIT"
                  input-classes="form-control-alternative"
                  v-model="mitData.Password"
                />
            </div>
          </div>
          <div class="row ">
            <div class="col-xl-6">
              <base-input
                  label="Id Empresa"
                  input-classes="form-control-alternative"
                  v-model="mitData.Company"
                />
            </div>
            <div class="col-xl-6">
              <base-input
                  label="Id Sucursal"
                  input-classes="form-control-alternative"
                  v-model="mitData.Branch"
                />
            </div>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-12 text-right">
              <base-button type="primary" class="my-4" nativeType="submit">Guardar</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>

    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <card shadow>
            <branches-table
                title="Sucursales"
                v-on:show-mit-data="prepareMITDataPopup"
                :mitDataSaved="mitDataSaved"
                v-on:loaded-branches="refreshBranchesTable=false"
                :refreshTable="refreshBranchesTable"
                :mobileUserId="mobileUserId"
                :showSearch=true
                v-on:error="handleError"
            />
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import BranchesTable from './Tables/BranchesTable';
  import handleErrorMixin from '../mixins/handleErrorMixin';

  export default {
    components: {
      BranchesTable,
    },
    mixins: [handleErrorMixin],

    data() {
      return {
        showMITData: false,
        mitData: {
          IdUsuario: 0,
          User: '',
          Password: '',
          Company: '',
          Branch: ''
        },
        defaultMitData: {
          IdUsuario: 0,
          User: '',
          Password: '',
          Company: '',
          Branch: ''
        },
        mitDataSaved:0,
        webUserToCreate: 0,
        webUserCreated:0,
        mobileUserId: 0,
        refreshBranchesTable: false,
      };
    },
    computed: {
    },
    methods: {
      saveMITData(){
        this.axios.post('/userconfiguration', this.mitData)
          .then(() => {
            this.mitDataSaved = this.mitData.IdUsuario;
            this.$toasted.show('Informacion actualizada');
          })
          .catch(error => {
            this.$emit('error', error);
          })
          .finally( () => {
            this.showMITData = false;
          });
      },
      prepareMITDataPopup(event) {
        this.mitData = Object.assign({}, this.defaultMitData);
        this.mitData.IdUsuario = event.usuario_IdUsuario;
        this.showMITData = true;
      },
    },
    mounted() {
      this.mobileUserId = parseInt(this.$route.params.idwebuser);
    },
  };
</script>
<style>
</style>
