var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showDialogAutoCompleate },
      on: {
        close: function($event) {
          _vm.showDialogAutoCompleate = false
        }
      }
    },
    [
      _c(
        "card",
        { attrs: { shadow: "", type: "secondary" } },
        [
          [_vm._v("\n      ¿Deseas autocompletar la información?\n    ")],
          _c(
            "div",
            {
              staticClass: "bg-white border-0",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-12 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("Cerrar")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.accept()
                        }
                      }
                    },
                    [_vm._v("Aceptar")]
                  )
                ])
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }