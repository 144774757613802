<template>
    <div>
        <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>
        <div class="container-fluid mt--7">
          <div class="row mt-5">
            <div class="col-xl-12">
              <period-sales-table
                v-on:period-changed="changePeriod"
                v-on:error="handleError"
                :detail="true"
                :showDownloadButton="true"
              >
              </period-sales-table>
            </div>
          </div>
        </div>
    </div>
</template>
<script>

  import PeriodSalesTable from './Dashboard/PeriodSalesTable';
  import handleErrorMixin from '../mixins/handleErrorMixin';

  export default {
    components: {
      PeriodSalesTable,
    },
    mixins: [handleErrorMixin],
    data() {
      return {
        months:["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre",
                "Octubre", "Noviembre", "Diciembre"],
        period:{
          year: 2019,
          month: 9,
        },
      };
    },
    computed: {
    },
    methods: {
      changePeriod(period) {
        this.period = period;
      },
    },
    mounted() {
    },
   
  };
</script>
<style></style>
