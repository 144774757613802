<template>
  <modal :bigModal="true" :show="showCreateAccount"  :showClose="true" v-on:close="$emit('close')">
    <auto-compleate-info-modal
      :idElement="enterpriseSelectedId"
      :showDialogAutoCompleate="showDialogAutoCompleate"
      v-on:close="closeAutoCompleteModal"
      v-on:accept="getEnterpriseData"
      ></auto-compleate-info-modal>
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="type"
      :isMultipleSelection="false"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="itemsSelected"
      v-on:error="handleError"
    ></assign-item-modal>
    <div slot="header">
      <div class="container-fluid">
        <div class="row">Nueva sucursal</div>
      </div>
    </div>
    <card shadow type="secondary">
        <b-tabs pills card no-nav-style v-model="tabIndex" no-key-nav class="local-card">
            <!-- General data -->
            <b-tab title-item-class="disabledTab">
              <div class="col-xl-11">
                <h3 class="mb-3">Datos generales:</h3>
              </div>
              <b-container style="width:100%" >
                <b-row v-if="hideAssignEnterprise">
                  <b-col>
                    <label class="form-control-label">
                        Asignar sucursal a la Empresa: 
                      </label>
                      <input
                        alternative
                        @click="showAssignItem = true" 
                        style="cursor: pointer; background-color: white" 
                        class="form-control" 
                        v-model="enterpriseName"
                        readonly="true"
                        required>
                      <slot name="helpBlock">
                        <div class="text-danger invalid-feedback" style="display: block;" >
                            {{validateField('idEnterprise')}}
                        </div>
                      </slot>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de la sucursal: *"
                      v-model="item.name"
                      :error = "validateField('name')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Clave interna:"
                      v-model="item.internalKey"
                      :error = "validateField('internalKey')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de contacto: *"
                      v-model="item.contactName"
                      :error = "validateField('contactName')"
                      :capitalize='true'
                      :maxlength="128"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número Telefonico: *"
                      v-model="item.contactPhoneNumber"
                      :error = "validateField('contactPhoneNumber')"
                      :maxlength="16"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Extención:"
                      v-model="item.contactPhoneExt"
                      :error = "validateField('contactPhoneExt')"
                      :maxlength="8"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Celular: *"
                      v-model="item.contactMobileNumber"
                      :error = "validateField('contactMobileNumber')"
                      :capitalize='true'
                      :maxlength="16"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Email: *"
                      v-model="item.contactMail"
                      :error = "validateField('contactMail')"
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

              </b-container>
            </b-tab>

            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Datos de ubicación</h3>
              </div>
              <b-container>
                <b-row class="mt-4">
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Código postal: * "
                      v-model="item.zipcode"
                      :error = "validateField('zipcode')"
                      :capitalize='true'
                      :maxlength="6"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Calle: * "
                      v-model="item.street"
                      :error = "validateField('street')"
                      :capitalize='true'
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número ext: * "
                      v-model="item.extNumber"
                      :error = "validateField('extNumber')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número int: "
                      v-model="item.intNumber"
                      :error = "validateField('intNumber')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Colonia: *"
                      v-model="item.suburb"
                      :error = "validateField('suburb')"
                      :capitalize='true'
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Municipio: "
                      v-model="item.county"
                      :error = "validateField('county')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Ciudad: * "
                      v-model="item.city"
                      :error = "validateField('city')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Estado: * "
                      v-model="item.state"
                      :error = "validateField('state')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Tipo Cliente (Marketing): * "
                      v-model="item.country"
                      :error = "validateField('country')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>
              </b-container>

            </b-tab>
            
            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Datos de la sucursal</h3>
              </div>
              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo de ventas por día:"
                      v-model="item.maxByDay"
                      :error = "validateField('maxByDay')"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo de ventas por mes:"
                      v-model="item.maxByMonth"
                      :error = "validateField('maxByMonth')"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo diario no presencial:"
                      v-model="item.maxByDayNoSwipped"
                      :error = "validateField('maxByDayNoSwipped')"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo mensual no presencial:"
                      v-model="item.maxByMonthNoSwipped"
                      :error = "validateField('maxByMonthNoSwipped')"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Seguridad</h3>
              </div>
              <b-container>
                <b-row>

                  <b-col>
                     <b-form-checkbox
                     style="margin-top:35px;"
                      v-model="item.pinRequired"
                      value="Y"
                      unchecked-value="N"
                      :error = "validateField('pinRequired')"
                    >
                      Seguridad
                    </b-form-checkbox>
                    <!-- <base-input
                      input-classes="form-control-alternative"
                      :error = "validateField('pinSecurity')"
                      label="PIN"
                      v-model="item.pin"
                      :capitalize='true'
                      :maxlength="4"
                      validation="number"
                      :placeholder="placeHold"
                    ></base-input> -->
                  
                  </b-col>

                  <b-col>
                    <!-- <base-input
                      input-classes="form-control-alternative"
                      label="Pin de seguridad"
                      v-model="item.pin"
                      validation="number"
                    ></base-input> -->
                     <base-input
                      input-classes="form-control-alternative"
                      :error = "validateField('pinRequired')"
                      label="PIN"
                      v-model="item.pin"
                      :capitalize='true'
                      :maxlength="4"
                      validation="number"
                      placeholder="****"
                    ></base-input>
                  </b-col>
                  <b-col>
                  <!-- <base-input
                    input-classes="form-control-alternative"
                    label="Aplicar herencia:"
                    validation="number"
                  ></base-input> -->
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Extras</h3>
              </div>
              <b-container>
                <b-row class="mt-6 ">
                    <b-col>
                    <!-- Action list  Notempty-->
                    <b-form-group  label="Copias de Correo Comercio">
                        <b-form-checkbox  v-model="item.jsonConfig.sendMail.voucher" value="A" unchecked-value="I"> Voucher Comercio </b-form-checkbox>
                        <b-form-checkbox  v-model="item.jsonConfig.sendMail.cancellation" value="A" unchecked-value="I"> Voucher Cancelación </b-form-checkbox>
                        <b-form-checkbox  v-model="item.jsonConfig.sendMail.link" value="A" unchecked-value="I"> Voucher Link </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    &nbsp;
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
        </b-tabs>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goPreviousPage" v-if="tabIndex > 0" >Atras</base-button>
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goNextPage" >{{textButton}}</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import flatpickr from "flatpickr";
import Spanish from "flatpickr/dist/l10n/es.js";
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import AssignItemModal from './AssignItemModal.vue';
import { getEnterpriseInfo, saveBranch } from '../../utils/api';
import { catalogs } from '../../utils/catalog';
import { validateMail } from '../../utils/utilMethods';
import enums from '../../enums/enums';
import AutoCompleateInfoModal from './AutoCompleateInfoModal.vue';
import { getFocus, isEmptyOrNull } from '../../utils/generalMethods';

export default {
  name: "new-branch-modal",
  components: {
    AssignItemModal,
    AutoCompleateInfoModal
  },
  mixins: {axiosResponseMixin},
  props: {
    ownerId: Number,
    showCreateAccount: {
      type: Boolean
    },
    itemId: {},
    clean: {}
  },
  data() {
    return {
      jsonData: {
        
      },
      type: enums.BRANCH,
      item: {
        idEnterprise: '',
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: 'N',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        maxByDayNoSwipped : '',
        maxByMonthNoSwipped : '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        zipcode: '',
        pinRequired:'N',
        pin:'',
        jsonConfig: {
          sendMail:{
            voucher: "A", 
            cancellation: "A", 
            link: "A"
          }
        }
      },
      emptyItem: {
        idEnterprise: '',
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: 'N',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        maxByDayNoSwipped : '',
        maxByMonthNoSwipped : '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        zipcode: '',
        pinRequired:'N',
        pin:'',
        jsonConfig: {
          sendMail:{
            voucher: "A", 
            cancellation: "A", 
            link: "A"
          }
        }
      },
      textButton: 'Siguiente',
      catalogs: catalogs,
      tabIndex: 0,
      numberTabs: 4,
      searchTerm: '',
      showErrorMessages: false,
      showAssignItem: false,
      selectedItems:{},
      enterpriseName: '',
      enterpriseSelectedId: 0,
      showDialogAutoCompleate: false,
      hideAssignEnterprise:true,
    };
  },
  methods: {
    handleError(error) {
      this.$emit('error', error)
    },
    itemsSelected(event) {
      this.enterpriseName = event[0].fullName;
      this.item.idEnterprise = event[0].id
      this.showAssignItem = false
      this.enterpriseSelectedId = this.item.idEnterprise
      this.showDialogAutoCompleate = true
    },
    goPreviousPage(){
      this.tabIndex --
    },
    goNextPage() {
      this.showErrorMessages = true                
      if (!this.canContinue()) {
        this.showErrorMessages = true
        return
      } else{
        this.showErrorMessages = false
      }
      if (this.tabIndex === this.numberTabs ) {
          this.saveBranch()
      } else {
          this.tabIndex++;
          this.textButton = this.tabIndex === this.numberTabs ? "Continuar" : "Siguiente"
      }
    },
    canContinue() {
      var keys = Object.keys(this.item)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    validateField(key) {
      if (this.showErrorMessages) {
        if (this.tabIndex === 0) {
          switch (key) {
            case 'name':
              return this.item.name === '' ? 'Ingresa el nombre de la sucursal' : ''
            case 'contactName':
              return this.item.contactName === '' ? 'Ingresa el nombre de contacto' : ''
            case 'contactPhoneNumber':
              return this.item.contactPhoneNumber === '' 
              || this.item.contactPhoneNumber.length < 10 ? 'El número telefónico no es valido' : ''
            case 'contactMobileNumber':
              return this.item.contactMobileNumber === ''
              || this.item.contactMobileNumber.length < 10 ? 'El número de celular no es valido' : ''
            case 'contactMail':
              return !validateMail(this.item.contactMail) ? 'El email no es valido' : ''
            case 'idEnterprise':
              return this.item.idEnterprise <= 0 ? 'Selecciona una empresa' : '' 
            case 'internalKey':
              return this.isEmptyOrNull(this.item.internalKey) ? 'Ingresa la clave interna' : '' 
          }
        }else if (this.tabIndex === 1) {
          switch (key) {
            case 'street':
              return this.item.street === '' ? 'Ingresa la calle' : ''
            case 'extNumber':
              return this.item.extNumber === '' ? 'Ingresa el número del domicilio' : ''
            case 'city':
              return this.item.city === '' ? 'Ingresa la ciudad' : ''
            case 'state':
              return this.item.state === '' ? 'Ingresa el estado' : ''
            case 'country':
              return this.item.country === '' ? 'Ingresa el tipo de cliente' : ''
            case 'zipcode':
              return isEmptyOrNull(this.item.zipcode) ? 'Ingresa el código postal' : ''
          }
        }else if (this.tabIndex === 2) {
          switch (key) {
            case 'maxByDay':
              return this.item.maxByDay === '' ? 'Ingresa la cantidad maxima por día' : ''
            case 'maxByDayNoSwipped':
              return this.item.maxByDayNoSwipped === '' ? 'Ingresa la cantidad maxima por día no presencial' : ''
            case 'maxByMonth':
              return this.item.maxByMonth === '' ? 'Ingresa la cantidad maxima por mes' : ''
            case 'maxByMonthNoSwipped':
              return this.item.maxByMonthNoSwipped === '' ? 'Ingresa la cantidad maxima por mes no presencial' : ''
            case 'businessName':
              return this.item.businessName === '' ? 'Ingresa el nombre del negocio' : ''
            // case 'pinSecurity':
            //   return this.item.pinRequired === "Y"  ? 'Ingrese un PIN' : ''
          }
        } else if (this.tabIndex === 3){
          switch (key) {
            case 'pinRequired':
              if (this.item.pinRequired === "Y"){
                if (isEmptyOrNull(this.item.pin)){
                  return "Ingrese un PIN"
                }else if (this.item.pin.length < 4){
                    return "El pin debe contener 4 dígitos"
                }
                
              }
              return ""
          }
            
        }
        return '';
      }            
    },
    saveBranch() {
      let loaded = this.$loading.show({loader: 'dots'});
      this.item.maxByDay = Number(this.item.maxByDay);
      this.item.maxByMonth = Number(this.item.maxByMonth);
      this.item.maxByDayNoSwipped = Number(this.item.maxByDayNoSwipped);
      this.item.maxByMonthNoSwipped = Number(this.item.maxByMonthNoSwipped);
      
      saveBranch(this.item)
      .then(response => {
        loaded.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show('La sucursal fue agregada correctamente');
          if(this.itemId != '-1'){
            this.setEnterpriseSelected();
           }else{
            this.hideAssignEnterprise = true;
           }


          this.$emit('branch-created');
        }else{
          processErrorResponse(response.data, this.$toasted)
        }

      })
      .catch(error => {
       loaded.hide()
       this.$emit('error', error)
      })
    },
    isEmptyOrNull(data) {
      return data === '' || data === null
    },
    cleanData() {
      this.item = this.emptyItem
      this.tabIndex = 0
      this.enterpriseName = ''
    },
    getEnterpriseData() {
      getFocus();
      this.showDialogAutoCompleate = false
      getEnterpriseInfo(this.enterpriseSelectedId)
      .then(response=>{
        if (response.data.statusCode === 200) {
          //actualmente el edpoint de get enterprise no regresa estos campos por lo que se 
          //setean en cero debido a que para empresa no son modificables ni el pin viene
          let resp = Object.assign({}, response.data.result)
          resp.maxByDayNoSwipped  = "0";
          resp.maxByMonthNoSwipped = "0";
          resp.pin= '';
          resp.pinRequired='N';
          
          this.item = Object.assign({}, resp)
          this.item.name = ''
          this.item.inheritable = 'N'
          this.item.internalKey = ''
          this.item.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
          this.item.jsonConfig = {
            sendMail:{
              voucher: "A", 
              cancellation: "A", 
              link: "A"
            }
          }
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
     getEnterpriseData2() {
      this.showDialogAutoCompleate = false;
      getEnterpriseInfo(this.enterpriseSelectedId)
      .then(response=>{
        if (response.data.statusCode === 200) {
          this.item = Object.assign({}, response.data.result)
          this.item.name = ''
          this.item.inheritable = 'Y'
          this.item.internalKey = ''
          this.item.maxByDayNoSwipped  = "0";
          this.item.maxByMonthNoSwipped = "0";
          this.item.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
          this.item.jsonConfig = {
            sendMail:{
              voucher: "A", 
              cancellation: "A", 
              link: "A"
            }
          }
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    closeAutoCompleteModal(){
      this.showDialogAutoCompleate = false
      getFocus()
    },
    setEnterpriseSelected(){
      this.enterpriseSelectedId = this.itemId;
      this.showDialogAutoCompleate = false;
      this.hideAssignEnterprise = false;
      this.getEnterpriseData2();
    },
    
  },
  watch: {
    'jsonData': function () {
			let c = this.formatJson(JSON.stringify(this.jsonData))
			this.drawResCode(c)
		},
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    }
  },
  mounted() {
    if (isNaN(this.item.idEnterprise))
      this.item.idEnterprise = undefined
    this.$nextTick(() => {
      if(this.itemId != '-1'){
        this.setEnterpriseSelected();
      }
    });
    
  },
};
</script>
<style >
.disabledTab {
  pointer-events: none;
}

.local-card .card-header {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}

.body {
  font-size: 14px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.t {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.editor-w {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.w-2 {
  float: left;
  width: 100%;
}

.editor {
  padding: 20px 60px;
}

.pure-form select{
  min-height: 2.75em;
}

/* .big-modal-dialog {
  max-width: 90% ;
} */
</style>