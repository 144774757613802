var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-header",
        {
          staticClass: "pb-6 pb-8 pt-5 pt-md-8",
          attrs: { type: "gradient-primary" }
        },
        [
          _vm.isRegularUser
            ? _c("card-stats", { attrs: { period: _vm.period } })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("transactions-table", {
                attrs: {
                  showDownloadButton: true,
                  retrieveCompany: _vm.$route.meta.retrieveCompany,
                  retrieveClientInfo: false
                },
                on: { error: _vm.handleError }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }