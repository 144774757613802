<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-12">
          <card shadow>
            <form @submit.prevent>
              <tabs
                fill
                class="flex-column flex-md-row"
                :value="activeTab"
                v-on:new-active-tab="updateActiveTab"
              >
                <tab-pane title="Sucursal">
                  <span slot="title">Sucursal</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary">
                      <template>
                        <h6 class="heading-small text-muted mb-4">Identificación Comercio</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-3">
                              <input type="hidden" v-model="sucursal.IdBranch" />
                              <base-input
                                alternative
                                label="Identificador de la Empresa"
                                placeholder="idEmpresa"
                                input-classes="form-control-alternative"
                                v-model="sucursal.idEmpresa"
                                id="sucursal-idEmpresa"
                                :required="branchFieldRestrictions.idEmpresa.required"
                                v-on:blur="validateModel"
                                :maxlength="branchFieldRestrictions.idEmpresa.maxlength"
                                disabled
                              />
                            </div>
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Identificador de la Sucursal"
                                placeholder="id Sucursal"
                                input-classes="form-control-alternative"
                                v-model="sucursal.idSucursal"
                                id="sucursal-idSucursal"
                                :required="branchFieldRestrictions.idSucursal.required"
                                :maxlength="branchFieldRestrictions.idSucursal.maxlength"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <base-input
                                alternative
                                label="Nombre de la Sucursal"
                                placeholder="Nombre Sucursal"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nbSucursal"
                                id="sucursal-nbSucursal"
                                :required="branchFieldRestrictions.nbSucursal.required"
                                :maxlength="branchFieldRestrictions.nbSucursal.maxlength"
                              />
                            </div>
                          </div>
                        </div>
                        <h6 class="heading-small text-muted mb-4">Comisiones</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Base AMEX"
                                validation="number"
                                input-classes="form-control-alternative"
                                v-model="sucursal.branch_decFeeBaseAMEX"
                                id="sucursal-Branch_decFeeBaseAMEX"
                                :required="branchFieldRestrictions.branch_decFeeBaseAMEX.required"
                                v-on:blur="validateModel"
                                :maxlength="branchFieldRestrictions.branch_decFeeBaseAMEX.maxlength"

                              />
                            </div>
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Base VMC"
                                validation="number"
                                input-classes="form-control-alternative"
                                v-model="sucursal.branch_decFeeBaseVMC"
                                id="sucursal-Branch_decFeeBaseVMC"
                                :required="branchFieldRestrictions.branch_decFeeBaseVMC.required"
                                :maxlength="branchFieldRestrictions.branch_decFeeBaseVMC.maxlength"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Sobre tasa 3 Meses"
                                validation="number"
                                input-classes="form-control-alternative"
                                v-model="sucursal.branch_decFeeOver3"
                                id="sucursal-Branch_decFeeOver3"
                                :required="branchFieldRestrictions.branch_decFeeOver3.required"
                                :maxlength="branchFieldRestrictions.branch_decFeeOver3.maxlength"
                              />
                            </div>
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Sobre tasa 6 Meses"
                                validation="number"
                                input-classes="form-control-alternative"
                                v-model="sucursal.branch_decFeeOver6"
                                id="sucursal-Branch_decFeeOver6"
                                :required="branchFieldRestrictions.branch_decFeeOver6.required"
                                :maxlength="branchFieldRestrictions.branch_decFeeOver6.maxlength"
                              />
                            </div>
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Sobre tasa 9 Meses"
                                validation="number"
                                input-classes="form-control-alternative"
                                v-model="sucursal.branch_decFeeOver9"
                                id="sucursal-Branch_decFeeOver9"
                                :required="branchFieldRestrictions.branch_decFeeOver9.required"
                                :maxlength="branchFieldRestrictions.branch_decFeeOver9.maxlength"
                              />
                            </div>
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Sobre tasa 12 Meses"
                                validation="number"
                                input-classes="form-control-alternative"
                                v-model="sucursal.branch_decFeeOver12"
                                id="sucursal-Branch_decFeeOver12"
                                :required="branchFieldRestrictions.branch_decFeeOver12.required"
                                :maxlength="branchFieldRestrictions.branch_decFeeOver12.maxlength"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="my-4" />
                        <!-- Address -->
                        <h6 class="heading-small text-muted mb-4">Dirección</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-12">
                              <base-input
                                alternative
                                label="Calle"
                                placeholder="Calle"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nbCalle"
                                id="sucursal-nbCalle"
                                :required="branchFieldRestrictions.nbCalle.required"
                                :maxlength="branchFieldRestrictions.nbCalle.maxlength"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                alternative
                                label="# Interior"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nuInterior"
                                id="sucursal-nuInterior"
                                :required="branchFieldRestrictions.nuInterior.required"
                                :maxlength="branchFieldRestrictions.nuInterior.maxlength"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                alternative
                                label="# Exterior"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nuExterior"
                                id="sucursal-nuExterior"
                                :required="branchFieldRestrictions.nuExterior.required"
                                :maxlength="branchFieldRestrictions.nuExterior.maxlength"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                alternative
                                label="C.P."
                                input-classes="form-control-alternative"
                                v-model="sucursal.nuCp"
                                id="sucursal-nuCp"
                                :required="branchFieldRestrictions.nuCp.required"
                                :maxlength="branchFieldRestrictions.nuCp.maxlength"
                              />
                            </div>
                            <div class="col-md-3">
                              <base-input
                                alternative
                                label="Colonia"
                                placeholder="Colonia"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nbColonia"
                                id="sucursal-nbColonia"
                                :required="branchFieldRestrictions.nbColonia.required"
                                :maxlength="branchFieldRestrictions.nbColonia.maxlength"
                              />
                            </div>
                            <div class="col-md-3">
                              <base-input
                                alternative
                                label="Delegación / Municipio"
                                placeholder="Delegación"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nbDelegacionMunicipio"
                                id="sucursal-nbDelegacionMunicipio"
                                :required="branchFieldRestrictions.nbDelegacionMunicipio.required"
                                :maxlength="branchFieldRestrictions.nbDelegacionMunicipio.maxlength"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Ciudad"
                                placeholder="Ciudad"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nbCiudadPoblacion"
                                id="sucursal-nbCiudadPoblacion"
                                :required="branchFieldRestrictions.nbCiudadPoblacion.required"
                                :maxlength="branchFieldRestrictions.nbCiudadPoblacion.maxlength"
                              />
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group has-label">
                                <label class="form-control-label">
                                  Estado
                                  <span>*</span>
                                </label>
                                <select v-model="sucursal.nbEstado" class="form-control">
                                  <option v-for="state in states" :key="state.key">{{state.value}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-2">
                              <base-input
                                alternative
                                label="Lada"
                                placeholder="000"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nuLada"
                                id="sucursal-nuLada"
                                :required="branchFieldRestrictions.nuLada.required"
                                :maxlength="branchFieldRestrictions.nuLada.maxlength"
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Teléfono"
                                placeholder="000000000"
                                input-classes="form-control-alternative"
                                v-model="sucursal.nuTelefono"
                                id="sucursal-nuTelefono"
                                :required="branchFieldRestrictions.nuTelefono.required"
                                :maxlength="branchFieldRestrictions.nuTelefono.maxlength"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="my-4" />
                        <!-- Description -->
                        <h6 class="heading-small text-muted mb-4">Miscelánea</h6>
                        <div class="row">
                          <div class="col-lg-4">
                            <base-input
                              alternative
                              label="Vendedor"
                              input-classes="form-control-alternative"
                              v-model="sucursal.idSeller"
                              id="sucursal-idSeller"
                              :required="branchFieldRestrictions.idSeller.required"
                              :maxlength="branchFieldRestrictions.idSeller.maxlength"
                            />
                          </div>
                          <div class="col-lg-4">
                            <base-select
                              label="Giro del Comercio"
                              :options="mccs"
                              v-model="sucursal.nuMcc"
                              id="sucursal-nuMcc"
                              :required="branchFieldRestrictions.nuMcc.required"
                              :maxlength="branchFieldRestrictions.nuMcc.maxlength"
                            ></base-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                            <base-input
                              alternative
                              label="Número de tarjeta"
                              input-classes="form-control-alternative"
                              v-model="sucursal.lastCardDigits"
                              id="sucursal-lastCardDigits"
                              :required="false"
                              :maxlength="16"
                              validation="number"
                            />
                          </div>
                        </div>
                      </template>
                      <div slot="footer" class="bg-white border-0">
                        <div class="row align-items-center">
                          <div class="col-12 text-right">
                            <a
                              @click.prevent="nextStep()"
                              class="btn btn-primary text-white"
                              id="next-1"
                            >Siguiente</a>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </tab-pane>
                <tab-pane v-if="userRole === 'SUPER_ADMIN'" title="Informacion MIT">
                  <span slot="title">Información MIT</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary">
                      <template>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Usuario"
                                placeholder="usuario MIT"
                                input-classes="form-control-alternative"
                                v-model="mit.usuario"
                                id="mit-usuario"
                              />
                            </div>
                            <div class="col-lg-3">
                              <base-input
                                alternative
                                label="Contraseña"
                                placeholder="Contraseña MIT"
                                input-classes="form-control-alternative"
                                v-model="mit.contrasena"
                                id="mit-contrasena"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                      <div slot="footer" class="bg-white border-0">
                        <div class="row align-items-center">
                          <div class="col-12 text-right">
                            <a
                              @click.prevent="nextStep()"
                              class="btn btn-primary text-white"
                              id="next-4"
                            >Siguiente</a>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </tab-pane>
                <tab-pane title="Administrador">
                  <span slot="title">Administrador</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary">
                      <template>
                        <h6 class="heading-small text-muted mb-4">Generales</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-6">
                              <base-input
                                alternative
                                label="Nombre"
                                placeholder="Nombre"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nbNombre"
                                id="administradorSucursal-nbNombre"
                                required
                              />
                            </div>
                            <div class="col-lg-6">
                              <base-input
                                alternative
                                label="Segundo Nombre"
                                placeholder="Segundo Nombre"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nbSegundoNombre"
                                id="administradorSucursal-nbSegundoNombre"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6">
                              <base-input
                                alternative
                                label="Apellido Paterno"
                                placeholder="Apellido Paterno"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.apPaterno"
                                id="administradorSucursal-apPaterno"
                                required
                              />
                            </div>
                            <div class="col-lg-6">
                              <base-input
                                alternative
                                label="Apellido Materno"
                                placeholder="Apellido Materno"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.apMaterno"
                                id="administradorSucursal-apMaterno"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="form-group has-label">
                                <label class="form-control-label">
                                  Fecha de Nacimiento
                                  <span>*</span>
                                </label>
                                <base-input-no-info addon-left-icon="ni ni-calendar-grid-58">
                                  <flat-pickr
                                    slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true, dateFormat: 'd/m/Y'}"
                                    class="form-control datepicker"
                                    v-model="administradorSucursal.fhNacimiento"
                                  ></flat-pickr>
                                </base-input-no-info>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="RFC"
                                placeholder="XXX010101HHH"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nbRFC"
                                id="administradorSucursal-nbRFC"
                                required
                                :maxlength="13"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="my-4" />
                        <!-- Address -->
                        <h6 class="heading-small text-muted mb-4">Contacto</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-8">
                              <base-input
                                alternative
                                label="Correo Electrónico"
                                placeholder="correo@zuma.com"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.txEmail"
                                id="administradorSucursal.txEmail"
                                required
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <base-input
                                alternative
                                label="Celular"
                                placeholder="Celular"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nuCelular"
                                id="administradorSucursal-nuCelular"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3">
                              <base-input
                                alternative
                                label="Lada"
                                placeholder="Lada"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nuLada"
                                id="administradorSucursal-nuLada"
                                required
                              />
                            </div>
                            <div class="col-md-6">
                              <base-input
                                alternative
                                label="Teléfono"
                                placeholder="Telefono"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nuTelefono"
                                id="administradorSucursal-nuTelefono"
                                required
                              />
                            </div>
                            <div class="col-md-3">
                              <base-input
                                alternative
                                label="Extensión"
                                placeholder="Extensión"
                                input-classes="form-control-alternative"
                                v-model="administradorSucursal.nuExtension"
                                id="administradorSucursal-nuExtension"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                      <div slot="footer" class="bg-white border-0">
                        <div class="row align-items-center">
                          <div class="col-12 text-right">
                            <a
                              @click.prevent="nextStep()"
                              class="btn btn-primary text-white"
                              id="next-2"
                            >Siguiente</a>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </tab-pane>
                <tab-pane v-if="userRole === 'SUPER_ADMIN'" title="Operativas">
                  <span slot="title">Operativas</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary">
                      <template>
                        <h6 class="heading-small text-muted mb-4">Tarjeta Presente</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Afiliación VMC"
                                placeholder="Afiliación VMC"
                                input-classes="form-control-alternative"
                                v-model="operativas.tarjetaPresente.nuAfiliacionVMC"
                                id="operativas-tarjetaPresente-nuAfiliacionVMC"
                                disabled
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Afiliación AMEX"
                                placeholder="Afiliación AMEX"
                                input-classes="form-control-alternative"
                                v-model="operativas.tarjetaPresente.nuAfiliacionAMEX"
                                id="operativas-tarjetaPresente-nuAfiliacionAMEX"
                                disabled
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-select
                                label="Canales de cobro"
                                :options="cardPresentChanels"
                                :multiple="true"
                                v-model="canalesP"
                                id="operativas-tarjetaPresente-canal"
                                v-on:multiple="processTP"
                              ></base-select>
                            </div>
                          </div>
                        </div>
                        <hr class="my-4" />
                        <!-- Address -->
                        <h6 class="heading-small text-muted mb-4">Tarjeta no presente</h6>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Afiliación VMC"
                                placeholder="Afiliación VMC"
                                input-classes="form-control-alternative"
                                v-model="operativas.tarjetaNoPresente.nuAfiliacionVMC"
                                id="operativas-tarjetaNoPresente-nuAfiliacionVMC"
                                disabled
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Afiliación AMEX"
                                placeholder="Afiliación AMEX"
                                input-classes="form-control-alternative"
                                v-model="operativas.tarjetaNoPresente.nuAfiliacionAMEX"
                                id="operativas-tarjetaNoPresente-nuAfiliacionAMEX"
                                disabled
                              />
                            </div>
                            <div class="col-lg-4">
                              <!--select v-model="operativas.tarjetaNoPresente.canal" multiple>
                                      <option v-for="option in cardNoPresentChanels" v-bind:key="option.key">
                                        {{option.value}}
                                      </option>
                              </select-->
                              <base-select
                                label="Canales de cobro"
                                :options="cardNoPresentChanels"
                                :multiple="true"
                                v-model="canalesNP"
                                id="operativas-tarjetaNoPresente-canal"
                                v-on:multiple="processTNP"
                              ></base-select>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div slot="footer" class="bg-white border-0">
                        <div class="row align-items-center">
                          <div class="col-12 text-right">
                            <a
                              @click.prevent="nextStep()"
                              class="btn btn-primary text-white"
                              id="next-3"
                            >Siguiente</a>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </tab-pane>
                <tab-pane title="Prevencion">
                  <span slot="title">Prevención</span>
                  <div class="col-xl-12">
                    <card shadow type="secondary">
                      <template>
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Importe Mínimo"
                                input-classes="form-control-alternative"
                                v-model="prevencion.imMinimo"
                                id="prevencion-imMinimo"
                                required
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Importe Máximo"
                                input-classes="form-control-alternative"
                                v-model="prevencion.imMaximo"
                                id="prevencion-imMaximo"
                                required
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Número de Intentos"
                                input-classes="form-control-alternative"
                                v-model="prevencion.nuTrxTDCx24hrs"
                                id="prevencion-nuTrxTDCx24hrs"
                                required
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input
                                alternative
                                label="Maximo día"
                                input-classes="form-control-alternative"
                                v-model="prevencion.branch_decMaxAmountDay"
                                id="prevencion-branch_decMaxAmountMonth"
                                required
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                                alternativek
                                label="Maximo mes"
                                input-classes="form-control-alternative"
                                v-model="prevencion.branch_decMaxAmountMonth"
                                id="prevencion-branch_decMaxAmountMonth"
                                required
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <base-checkbox
                                class="mb-3"
                                v-model="prevencion.stChip"
                                id="prevencion-stChip"
                                disabled
                              >Aceptar Tarjetas Con Chip</base-checkbox>
                              <base-checkbox
                                class="mb-3"
                                v-model="prevencion.stBanda"
                                id="prevencion-stBanda"
                              >Aceptar Tarjetas con Banda</base-checkbox>
                              <base-checkbox
                                class="mb-3"
                                v-model="prevencion.stTDCNacional"
                                id="prevencion-stTDCNacional"
                                disabled
                              >Aceptar Tarjetas Nacionales</base-checkbox>
                              <base-checkbox
                                class="mb-3"
                                v-model="prevencion.stTDCExtranjera"
                                id="prevencion-stTDCExtranjera"
                              >Aceptar Tarjetas Internacionales</base-checkbox>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div slot="footer" class="bg-white border-0">
                        <div class="row align-items-center">
                          <div class="col-12 text-right">
                            <a
                              v-if="mitStatus === 'NOT_CREATED'"
                              @click.prevent="saveBranch"
                              class="btn btn-primary text-white"
                              id="save-data"
                            >Guardar</a>
                            <a
                              v-if="mitStatus === 'CREATED'"
                              @click.prevent="updateBranch"
                              class="btn btn-primary text-white"
                              id="save-data"
                            >Actualizar</a>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </tab-pane>
                <!--tab-pane title="Usuarios">
                      <span slot="title">
                          Usuarios
                      </span>
                      <div class="col-xl-12">
                        <card shadow type="secondary">
                            <template>
                              <div class="pl-lg-4">
                                <div class="row">
                                  <branch-users-table
                                    title="Usuarios sucursal"
                                    :branchId="sucursal.IdBranch"
                                    :refreshTable="loadUsersData"
                                    v-on:selected-row="activateLoadUser"
                                    v-on:clear-selection="clearUserData = true"
                                    v-on:data-loaded="loadUsersData=false"
                                    v-on:error="handleError"
                                  />
                                </div>

                              </div>
                            </template>
                            <div slot="footer" class="bg-white border-0">
                              <user-data-modal
                                :showUserModal="showUserModal"
                                v-on:close="showUserModal=false"
                                v-on:assigned-branch="refreshUsers"
                                :idBranch="selectedBranchId"
                                :nombreNegocio="sucursal.nbSucursal"
                                :clearUserData="clearUserData"
                                :loadUser="loadUser"
                                v-on:user-loaded="loadUser = 0"
                                v-on:user-cleared="clearUserData = false"
                                v-on:reload-data="loadUsersData = true"
                                v-on:error="handleError"
                              ></user-data-modal>
                            </div>
                        </card>
                      </div>
                </tab-pane-->
              </tabs>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  mixins: [handleErrorMixin],
  components: {
    flatPickr
    //BranchUsersTable,
    //UserDataModal
  },
  data() {
    return {
      activeTab: "Sucursal",
      selectedBranchId: 0,
      showUserModal: false,
      steps: {
        Sucursal: "Informacion MIT",
        "Informacion MIT": "Administrador",
        Administrador: "Operativas",
        Operativas: "Prevencion"
      },
      sucursal: {
        IdBranch: "",
        idEmpresa: "",
        idSucursal: "",
        nbSucursal: "",
        cdGiro: "",
        nbCalle: "",
        nuExterior: "",
        nuInterior: "",
        nuCp: "",
        nbColonia: "",
        nbDelegacionMunicipio: "",
        nbCiudadPoblacion: "",
        nbEstado: "",
        nuLada: "",
        nuTelefono: "",
        idSeller: "",
        nuMcc: "",
        branch_decFeeBaseAMEX: "",
        branch_decFeeBaseVMC: "",
        branch_decFeeOver3: "",
        branch_decFeeOver6: "",
        branch_decFeeOver9: "",
        branch_decFeeOver12: "",
      },
      administradorSucursal: {
        nbNombre: "",
        nbSegundoNombre: "",
        apPaterno: "",
        apMaterno: "",
        fhNacimiento: "",
        nbRFC: "",
        txEmail: "",
        nuCelular: "",
        nuLada: "",
        nuTelefono: "",
        nuExtension: ""
      },
      operativas: {
        tarjetaNoPresente: {
          nuAfiliacionVMC: "",
          nuAfiliacionAMEX: "",
          canal: [],
          canalArray: []
        },
        tarjetaPresente: {
          nuAfiliacionVMC: "",
          nuAfiliacionAMEX: "",
          canal: [],
          canalArray: []
        }
      },
      prevencion: {
        imMinimo: 50.0,
        imMaximo: 50000.0,
        nuTrxTDCx24hrs: 3,
        branch_decMaxAmountDay: "",
        branch_decMaxAmountMonth: "",
        stChip: true,
        stBanda: false,
        stTDCNacional: true,
        stTDCExtranjera: false
      },
      /* This one is used on the first branch, MIT create the first branch and this data is
           provided */
      mit: {
        usuario: "",
        contrasena: "",
        data0: "",
        aesKey: ""
      },
      mccs: [],
      cardNoPresentChanels: [
        { key: "13", value: "WebPay" },
        { key: "30", value: "Ecommerce" }
      ],
      cardPresentChanels: [
        { key: "5", value: "Ventas Móbiles" },
        { key: "9", value: "PcPay Banda" },
        { key: "32", value: "Mpos Banda" }
      ],
      branchFieldRestrictions: {
        idEmpresa: { required: true, allowedChars: "", maxlength: 4 },
        idSucursal: { required: true, allowedChars: "", maxlength: 11 },
        nbSucursal: { required: true, allowedChars: "", maxlength: 100 },
        cdGiro: { required: true, allowedChars: "", maxlength: 2 },
        nbCalle: { required: true, allowedChars: "", maxlength: 100 },
        nuExterior: { required: true, allowedChars: "", maxlength: 10 },
        nuInterior: { required: false, allowedChars: "", maxlength: 10 },
        nuCp: { required: true, allowedChars: "", maxlength: 5 },
        nbColonia: { required: true, allowedChars: "", maxlength: 60 },
        nbDelegacionMunicipio: {
          required: true,
          allowedChars: "",
          maxlength: 50
        },
        nbCiudadPoblacion: { required: false, allowedChars: "", maxlength: 50 },
        nbEstado: { required: true, allowedChars: "", maxlength: 50 },
        nuLada: { required: true, allowedChars: "", maxlength: 3 },
        nuTelefono: { required: true, allowedChars: "", maxlength: 8 },
        idSeller: { required: true, allowedChars: "", maxlength: 9 },
        nuMcc: { required: true, allowedChars: "", maxlength: 4 },
        branch_decFeeBaseAMEX: { required: true, allowedChars: "", maxlength: 6 },
        branch_decFeeBaseVMC: { required: true, allowedChars: "", maxlength: 6 },
        branch_decFeeOver3: { required: true, allowedChars: "", maxlength: 6 },
        branch_decFeeOver6: { required: true, allowedChars: "", maxlength: 6 },
        branch_decFeeOver9: { required: true, allowedChars: "", maxlength: 6 },
        branch_decFeeOver12: { required: true, allowedChars: "", maxlength: 6 }
      },
      branchFieldErrors: {
        idEmpresa: "",
        idSucursal: "",
        nbSucursal: "",
        cdGiro: "",
        nbCalle: "",
        nuExterior: "",
        nuInterior: "",
        nuCp: "",
        nbColonia: "",
        nbDelegacionMunicipio: "",
        nbCiudadPoblacion: "",
        nbEstado: "",
        nuLada: "",
        nuTelefono: "",
        idSeller: "",
        nuMcc: ""
      },
      loadUser: 0,
      clearUserData: false,
      loadUsersData: false,
      mitStatus: "NOT_CREATED",
      states: [
        { key: "AGUASCALIENTES", value: "AGUASCALIENTES" },
        { key: "BAJA CALIFORNIA", value: "BAJA CALIFORNIA" },
        { key: "BAJA CALIFORNIA SUR", value: "BAJA CALIFORNIA SUR" },
        { key: "CAMPECHE", value: "CAMPECHE" },
        { key: "CHIAPAS", value: "CHIAPAS" },
        { key: "CHIHUAHUA", value: "CHIHUAHUA" },
        { key: "CIUDAD DE MEXICO", value: "CIUDAD DE MEXICO" },
        { key: "COAHUILA", value: "COAHUILA" },
        { key: "COLIMA", value: "COLIMA" },
        { key: "DURANGO", value: "DURANGO" },
        { key: "ESTADO DE MEXICO", value: "ESTADO DE MEXICO" },
        { key: "GUANAJUATO", value: "GUANAJUATO" },
        { key: "GUERRERO", value: "GUERRERO" },
        { key: "HIDALGO", value: "HIDALGO" },
        { key: "JALISCO", value: "JALISCO" },
        { key: "MICHOACAN", value: "MICHOACAN" },
        { key: "MORELOS", value: "MORELOS" },
        { key: "NAYARIT", value: "NAYARIT" },
        { key: "NUEVO LEON", value: "NUEVO LEON" },
        { key: "OAXACA", value: "OAXACA" },
        { key: "PUEBLA", value: "PUEBLA" },
        { key: "QUERÉTARO", value: "QUERÉTARO" },
        { key: "QUINTANA ROO", value: "QUINTANA ROO" },
        { key: "SAN LUIS POTOSI", value: "SAN LUIS POTOSI" },
        { key: "SINALOA", value: "SINALOA" },
        { key: "SONORA", value: "SONORA" },
        { key: "TABASCO", value: "TABASCO" },
        { key: "TAMAULIPAS", value: "TAMAULIPAS" },
        { key: "TLAXCALA", value: "TLAXCALA" },
        { key: "VERACRUZ", value: "VERACRUZ" },
        { key: "YUCATAN", value: "YUCATAN" },
        { key: "ZACATECAS", value: "ZACATECAS" }
      ]
    };
  },

  computed: {
    canalesP() {
      if (typeof this.operativas.tarjetaPresente.canal === "string") {
        return this.operativas.tarjetaPresente.canal.split(",");
      }
      return this.operativas.tarjetaPresente.canal;
    },
    canalesNP() {
      if (typeof this.operativas.tarjetaNoPresente.canal === "string") {
        return this.operativas.tarjetaNoPresente.canal.split(",");
      }
      return this.operativas.tarjetaNoPresente.canal;
    },
    userRole() {
      return this.$store.getters.jwtRole;
    }
  },

  methods: {
    updateActiveTab(event) {
      this.activeTab = event.title;
    },
    processTP(event) {
      this.operativas.tarjetaPresente.canalArray = event;
    },
    processTNP(event) {
      this.operativas.tarjetaNoPresente.canalArray = event;
    },
    nextStep() {
      this.activeTab = this.steps[this.activeTab];
      window.scrollTo(0, 0);
    },
    saveBranch() {
      let requestData = {
        altaSucursal: {
          sucursal: this.sucursal,
          administradorSucursal: this.administradorSucursal,
          operativas: this.operativas,
          prevencion: this.prevencion
        },
        mit: this.mit
      };
      requestData.altaSucursal.operativas.tarjetaNoPresente.canal = requestData.altaSucursal.operativas.tarjetaNoPresente.canalArray.join(
        ","
      );
      requestData.altaSucursal.operativas.tarjetaPresente.canal = requestData.altaSucursal.operativas.tarjetaPresente.canalArray.join(
        ","
      );
      this.preconfigureReception(true)
      this.axios
        .post("/branch", requestData)
        .then(response => {
          this.preconfigureReception(false)
          this.$toasted.show("Sucursal guardada");
          if (response.status == 200) {
            this.mitStatus = "CREATED";
            this.$forceUpdate();
          }
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {});
    },
    updateBranch() {
      this.operativas.tarjetaNoPresente.canal = this.operativas.tarjetaNoPresente.canalArray.join(
        ","
      );
      this.operativas.tarjetaPresente.canal = this.operativas.tarjetaPresente.canalArray.join(
        ","
      );

      this.preconfigureReception(true)

      let requestData = {
        modificacionSucursal: {
          sucursal: this.sucursal,
          operativas: this.operativas,
          prevencion: this.prevencion
        },
        mit: this.mit
      };
      this.axios
        .put(`/branch/${this.sucursal.IdBranch}`, requestData)
        .then(() => {
          this.preconfigureReception(false)
          this.$toasted.show("Sucursal actualizada");
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {});
    },
    preconfigureReception(remove) {
      if (remove) {
        this.prevencion.branch_decMaxAmountDay = this.removeComma(this.prevencion.branch_decMaxAmountDay)
        this.prevencion.branch_decMaxAmountMonth = this.removeComma(this.prevencion.branch_decMaxAmountMonth)
        this.prevencion.imMinimo = this.removeComma(this.prevencion.imMinimo)
        this.prevencion.imMaximo = this.removeComma(this.prevencion.imMaximo)
      } else {
          this.prevencion.branch_decMaxAmountDay = this.capitalize(this.prevencion.branch_decMaxAmountDay)
          this.prevencion.branch_decMaxAmountMonth = this.capitalize(this.prevencion.branch_decMaxAmountMonth)
          this.prevencion.imMinimo = this.capitalize(this.prevencion.imMinimo)
          this.prevencion.imMaximo = this.capitalize(this.prevencion.imMaximo)
      }
    },
    responseDataToSucursal(data) {
      this.sucursal.IdBranch = data.branch_IdBranch;
      this.sucursal.idEmpresa = data.branch_IdEmpresa;
      this.sucursal.idSucursal = data.branch_idSucursal;
      this.sucursal.nbSucursal = data.branch_nbSucursal;
      this.sucursal.cdGiro = data.branch_cdGiro;
      this.sucursal.nbCalle = data.branch_nbCalle;
      this.sucursal.nuExterior = data.branch_nuExterior;
      this.sucursal.nuInterior = data.branch_nuInterior;
      this.sucursal.nuCp = data.branch_nuCp;
      this.sucursal.nbColonia = data.branch_nbColonia;
      this.sucursal.nbDelegacionMunicipio = data.branch_nbDelegacionMunicipio;
      this.sucursal.nbCiudadPoblacion = data.branch_nbCiudadPoblacion;
      this.sucursal.nbEstado = data.branch_nbEstado;
      this.sucursal.nuLada = data.branch_nuLada;
      this.sucursal.nuTelefono = data.branch_nuTelefono;
      this.sucursal.idSeller = data.branch_idSeller;
      this.sucursal.nuMcc = data.branch_nuMcc;
      this.sucursal.lastCardDigits = data.branch_lastCardDigits;
      this.sucursal.branch_decFeeBaseAMEX = this.capitalize(data.branch_decFeeBaseAMEX);
      this.sucursal.branch_decFeeBaseVMC = this.capitalize(data.branch_decFeeBaseVMC);
      this.sucursal.branch_decFeeOver3 = this.capitalize(data.branch_decFeeOver3);
      this.sucursal.branch_decFeeOver6 = this.capitalize(data.branch_decFeeOver6);
      this.sucursal.branch_decFeeOver9 = this.capitalize(data.branch_decFeeOver9);
      this.sucursal.branch_decFeeOver12 = this.capitalize(data.branch_decFeeOver12);
    },
    capitalize(value) {
       let val = (value/1).toFixed(2)
       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    removeComma(value){
      return value.replace(",", "")
    },
    responseDataToMITInformation(data) {
      this.mit.usuario = data.userReader_User;
      this.mit.contrasena = data.userReader_Password;
    },
    responseDataToBranchAdministrator(data) {
      this.administradorSucursal.nbNombre = data.user_nbNombre;
      this.administradorSucursal.nbSegundoNombre = data.user_nbSegundoNombre;
      this.administradorSucursal.apPaterno = data.user_apPaterno;
      this.administradorSucursal.apMaterno = data.user_apMaterno;
      this.administradorSucursal.fhNacimiento = data.user_fhNacimiento;
      this.administradorSucursal.nbRFC = data.user_nbRFC;
      this.administradorSucursal.txEmail = data.user_txEmail;
      this.administradorSucursal.nuCelular = data.user_nuCelular;
      this.administradorSucursal.nuLada = data.user_nuLada;
      this.administradorSucursal.nuTelefono = data.user_nuTelefono;
      this.administradorSucursal.nuExtension = data.user_nuExtension;
    },
    responseDataToOperational(data) {
      this.operativas.tarjetaNoPresente.nuAfiliacionVMC =
        data.branch_nuAfiliacionVMCNP;
      this.operativas.tarjetaNoPresente.nuAfiliacionAMEX =
        data.branch_nuAfiliacionAMEXNP;
      if (data.branch_canalNP) {
        let chanels = data.branch_canalNP.replace(/\s/g, "") || "";
        this.operativas.tarjetaNoPresente.canal = chanels.split(",");
        this.operativas.tarjetaNoPresente.canalArray = chanels.split(",");
      }

      this.operativas.tarjetaPresente.nuAfiliacionVMC =
        data.branch_nuAfiliacionVMCP;
      this.operativas.tarjetaPresente.nuAfiliacionAMEX =
        data.branch_nuAfiliacionAMEXP;
      if (data.branch_canalP) {
        let chanels = data.branch_canalP.replace(/\s/g, "") || "";
        this.operativas.tarjetaPresente.canal = chanels.split(",");
        this.operativas.tarjetaPresente.canalArray = chanels.split(",");
      }
    },
    responseDataToPrevention(data) {
      this.prevencion.imMinimo = this.capitalize(data.branch_imMinimo);
      this.prevencion.imMaximo = this.capitalize(data.branch_imMaximo);
      this.prevencion.nuTrxTDCx24hrs = data.branch_nuTrxTDCx24hrs;
      this.prevencion.stChip = data.branch_stChip === "1";
      this.prevencion.stBanda = data.branch_stBanda === "1";
      this.prevencion.stTDCNacional = data.branch_stTDCNacional === "1";
      this.prevencion.stTDCExtranjera = data.branch_stTDCExtranjera === "1";
      this.prevencion.branch_decMaxAmountDay = this.capitalize(data.branch_decMaxAmountDay);
      this.prevencion.branch_decMaxAmountMonth = this.capitalize(data.branch_decMaxAmountMonth);
    },

    refreshUsers() {},
    showNewUser() {
      this.showUserModal = true;
    },
    activateLoadUser(event) {
      this.loadUser = event.id;
    },
    validateModel() {
      //const name = event.currentTarget.id.split('-')[1];
      /* if(name){
          if (this.sucursal[name].length > this.branchFieldRestrictions[name].maxLength) {
            this.branchFieldErrors[name] = `Longitud ma`
            return false;
          }
        } */
      return true;
    },
    updateEstado(event) {
      this.sucursal.nbEstado = event;
    }
  },

  async mounted() {
    if (this.$route.params.idbranch > 0) {
      this.selectedBranchId = this.$route.params.idbranch;
      await this.axios
        .get(`/branch/${this.$route.params.idbranch}`)
        .then(async results => {
          this.responseDataToSucursal(results.data);
          this.responseDataToMITInformation(results.data);
          this.responseDataToBranchAdministrator(results.data);
          this.responseDataToOperational(results.data);
          this.responseDataToPrevention(results.data);
          this.mitStatus = results.data.branch_mitStatus;
        })
        /*.then(results => {
          this.sucursal.idEmpresa = results.data.ID_EMPRESA;
          this.mit.data0 = results.data.DATA_0;
          this.mit.aesKey = results.data.AES_KEY;
          this.operativas.tarjetaNoPresente.nuAfiliacionVMC = results.data.AFILIACION_VMC_TNP;
          this.operativas.tarjetaNoPresente.nuAfiliacionAMEX = results.data.AFILIACION_AMEX_TNP;
          this.operativas.tarjetaPresente.nuAfiliacionVMC = results.data.AFILIACION_VMC_TP;
          this.operativas.tarjetaPresente.nuAfiliacionAMEX = results.data.AFILIACION_AMEX_TP;
        })*/
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {});

      await this.axios
        .get("/catMcc")
        .then(results => {
          this.mccs = results.data.map(m => {
            var mcc = { key: m.key, value: `${m.key} - ${m.description}` };
            return mcc;
          });
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {});

      if (this.$store.getters.jwtRole === "ADMIN") {
        this.steps = {
          Sucursal: "Administrador",
          Administrador: "Prevencion"
        };
      } else if (this.$store.getters.jwtRole === "SUPER_ADMIN") {
        this.steps = {
          Sucursal: "Informacion MIT",
          "Informacion MIT": "Administrador",
          Administrador: "Operativas",
          Operativas: "Prevencion"
        };
      }
    }
  }
};
</script>
<style>
</style>
