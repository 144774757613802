/* eslint-disable no-duplicate-case */
<template>
  <modal
    :show="showModal"
    :showClose=true
    v-on:close="closeModal">
    <div slot="header">Selecciona una sucursal</div>
    <form @submit.prevent="assignItems" ref="AssignClientForm">
      <b-container>
        <b-row>
          <b-col>
            <div class="card shadow" style="height: 400px" >
              <div class="table-responsive">
                <base-table 
                  v-if="items.length > 0"
                  class="table align-items-center table-flush"
                  tbody-classes="list"
                  :data="items">
                  <template slot="columns">
                    <th>Seleccionar</th>
                    <th></th>
                  </template>

                  <template slot-scope="{row}">
                    <th scope="row">
                      <base-checkbox :checked='row.isSelected' v-on:input="checkBoxSelected(row)"></base-checkbox>
                    </th>
                    <td>
                      <div v-for="field in fields" :key="field.key">
                        <i :class="field.icon" class="icon-size-sm align-middle"></i>
                        <span style="h4">{{' '}}{{field.description}}{{getValue(row, field.key)}}</span>
                      </div>
                    </td>
                  </template>

                </base-table>
                <div v-else class="text-center center-data ">
                  La cuenta seleccionada no tiene sucursales disponibles
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div slot="footer">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4" @click="closeModal()">Cerrar</base-button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>

export default {
    name: 'select-items-modal',
    props: {
      showModal: {
        type: Boolean,
        default: false
      },
      items: {
        default: []
      },
      //Array to specify the elements to be displayed in the table, the key is the value that allows to obtain the value to display in the table row
      fields: {
        default: [
        ]
      }
    },
    data() {
      return {
        clean: false
      }
    },
    methods:{
      closeModal(){
        this.clean = true;
        this.$emit('close');
      },
      error(error) {
        this.$emit('error', error)
      },
      checkBoxSelected(row){
        row.isSelected = !row.isSelected
      },
      getValue(row, key){
        return row[key]
      }
    },
  }
</script>
<style scoped>
.center-data  {
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>