var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-xl-11" },
            [
              _c("card", { attrs: { shadow: "" } }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: {
                          pills: "",
                          card: "",
                          vertical: "",
                          "no-key-nav": ""
                        },
                        model: {
                          value: _vm.tabIndex,
                          callback: function($$v) {
                            _vm.tabIndex = $$v
                          },
                          expression: "tabIndex"
                        }
                      },
                      _vm._l(_vm.products, function(product) {
                        return _c(
                          "b-tab",
                          {
                            key: product.id,
                            attrs: {
                              "title-item-class": "ftext-center mt-1",
                              title: product.name
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("span", { staticClass: "h1" }, [
                                  _vm._v(_vm._s(product.name))
                                ]),
                                _c(
                                  "b-row",
                                  { staticClass: "align-self-center mt-4" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "text-left  align-self-center"
                                      },
                                      [
                                        _c("span", { staticClass: "h4" }, [
                                          _vm._v("Estado del producto:")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "text-right align-self-center"
                                      },
                                      [
                                        product.status === "I"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-danger",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    product.status =
                                                      product.status === "A"
                                                        ? "I"
                                                        : "A"
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Deshabilitado\n                      "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        product.status === "A"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-success",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    product.status =
                                                      product.status === "A"
                                                        ? "I"
                                                        : "A"
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Habilitado\n                      "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            product.paymentsMethods.length > 0 &&
                            (product.code === _vm.restaurantProduct ||
                              product.code === _vm.restaurantProductBzpay ||
                              product.code === _vm.restaurantProductAFIRME)
                              ? _c(
                                  "div",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "b-row",
                                      { staticClass: "align-self-center" },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "text-left  align-self-center"
                                          },
                                          [
                                            _c("span", { staticClass: "h4" }, [
                                              _vm._v("Pago en efectivo: ")
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "text-right align-self-center"
                                          },
                                          [
                                            product.paymentsMethods[0]
                                              .status === "I"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-pill badge-danger",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        product.paymentsMethods[0].status =
                                                          product
                                                            .paymentsMethods[0]
                                                            .status === "A"
                                                            ? "I"
                                                            : "A"
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Deshabilitado\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            product.paymentsMethods[0]
                                              .status === "A"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-pill badge-success",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        product.paymentsMethods[0].status =
                                                          product
                                                            .paymentsMethods[0]
                                                            .status === "A"
                                                            ? "I"
                                                            : "A"
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Habilitado\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            product.productsFlows.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("div", { staticClass: "h4" }, [
                                      _vm._v("Pasos del producto:")
                                    ]),
                                    _vm._l(product.productsFlows, function(
                                      pflow
                                    ) {
                                      return _c(
                                        "b-row",
                                        {
                                          key: pflow.id,
                                          staticClass: "align-self-center ml-4"
                                        },
                                        [
                                          pflow.flowCode !== _vm.summaryStep
                                            ? _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "text-left  align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        pflow.description
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          pflow.flowCode !== _vm.summaryStep
                                            ? _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "text-right align-self-center"
                                                },
                                                [
                                                  _c("div", [
                                                    pflow.status === "I"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge badge-pill badge-danger",
                                                            staticStyle: {
                                                              cursor: "pointer"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.updateStepFlow(
                                                                  pflow,
                                                                  product
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Deshabilitado\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    pflow.status === "A"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge badge-pill badge-success",
                                                            staticStyle: {
                                                              cursor: "pointer"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.updateStepFlow(
                                                                  pflow,
                                                                  product
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Habilitado\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c("div", { staticClass: "h4" }, [
                                  _vm._v("Promociones:")
                                ]),
                                _c(
                                  "b-container",
                                  { staticClass: "m-2 mt-4" },
                                  [
                                    _c(
                                      "b-row",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "text-center  align-self-center",
                                            attrs: { cols: "4" }
                                          },
                                          [_c("h4", [_vm._v("Descripción")])]
                                        ),
                                        _c(
                                          "b-col",
                                          { staticClass: "text-center" },
                                          [_c("h4", [_vm._v("Mínimo")])]
                                        ),
                                        _c(
                                          "b-col",
                                          { staticClass: "text-center" },
                                          [_c("h4", [_vm._v("Máximo")])]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "2" }
                                          },
                                          [_c("h4", [_vm._v("Estatus")])]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(product.promotions, function(
                                      promotion
                                    ) {
                                      return _c(
                                        "b-row",
                                        {
                                          key: promotion.id,
                                          staticClass: "align-self-center  mt-2"
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-center  align-self-center",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.getDescription(
                                                      promotion.cardPromotionCode
                                                    )
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-left align-self-center",
                                              attrs: { cols: "3" }
                                            },
                                            [
                                              _c("base-input", {
                                                staticStyle: {
                                                  "margin-bottom": "0"
                                                },
                                                attrs: {
                                                  "input-classes":
                                                    "form-control-alternative",
                                                  validation: "number"
                                                },
                                                model: {
                                                  value: promotion.minAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      promotion,
                                                      "minAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "promotion.minAmount"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-left align-self-center",
                                              attrs: { cols: "3" }
                                            },
                                            [
                                              _c("base-input", {
                                                staticStyle: {
                                                  "margin-bottom": "0"
                                                },
                                                attrs: {
                                                  "input-classes":
                                                    "form-control-alternative",
                                                  validation: "number"
                                                },
                                                model: {
                                                  value: promotion.maxAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      promotion,
                                                      "maxAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "promotion.maxAmount"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "text-left align-self-center",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  promotion.status === "I"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "badge badge-pill badge-danger",
                                                          staticStyle: {
                                                            cursor: "pointer"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              promotion.status =
                                                                promotion.status ===
                                                                "A"
                                                                  ? "I"
                                                                  : "A"
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            Deshabilitado\n                          "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  promotion.status === "A"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "badge badge-pill badge-success",
                                                          staticStyle: {
                                                            cursor: "pointer"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              promotion.status =
                                                                promotion.status ===
                                                                "A"
                                                                  ? "I"
                                                                  : "A"
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            Habilitado\n                          "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "bg-white border-0" }, [
                              _c(
                                "div",
                                { staticClass: "row align-items-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-right" },
                                    [
                                      _c(
                                        "base-button",
                                        {
                                          staticClass: "my-4 ml-3 mr-3",
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.saveProduct(product)
                                            }
                                          }
                                        },
                                        [_vm._v("Guardar")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }