export default {
  methods:{
      processErrorResponse: (response, toast) => {
          if (response.friendlyMessage === undefined){
            toast.show("No se pudo completar la operación correctamente, contacte con el administrador del sistema");
          } else {
            toast.show(response.friendlyMessage);
          }
      }
  }
}
export const processErrorResponse = (response, toast)  => {
  if (response.friendlyMessage === undefined){
    toast.show("No se pudo completar la operación correctamente, contacte con el administrador del sistema");
  } else {
    var errors = response.friendlyMessage
    let message = ''
    errors.forEach((error, index ) => {
      message+= `${index+1}.- ${error}. `  + "<br>"
    });
    toast.show(message);
  }
};