<template>
  <div class="row">
    <div class="col-xl-3 col-lg-6">
      <stats-card title="Sucursales"
                  type="text-blue"
                  :sub-title="branches | currency('', 0)"
                  icon="ni ni-shop"
                  class="mb-4 mb-xl-0"
      >
      </stats-card>
    </div>
    <div class="col-xl-3 col-lg-6">
      <stats-card :title="salesTitle"
                  type="text-red"
                  :sub-title="salesData.sum | currency('$', 2)"
                  icon="ni ni-money-coins"
                  class="mb-4 mb-xl-0"
      >
      </stats-card>
    </div>
  </div>
</template>
<script>
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
 import { getBranchesCount, getBranchesTotalSum } from '../../utils/api';
export default {
  name: 'card-stats',
  props: ['period'],
  watch: {
    period:{
     handler(){
      this.retrieveSalesResume();
     },
     deep: true
    }
  },
  data() {
    return {
      salesData: {
        sum: 0.0,
      },
      months:["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre",
                "Octubre", "Noviembre", "Diciembre"],
      branches: 0,
    }
  },
  computed: {
    salesTitle() {
      return `Total Sucursales ${this.months[this.period.month - 1]} ${this.period.year} `;
    },
  },
  methods: {
    retrieveBranches() {
      getBranchesCount(this.$store.getters.jwtData.userId)
      .then(response => {
        if(response.data.statusCode === 200){
          this.branches = response.data.result.count
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
      },
    retrieveSalesResume() {
      getBranchesTotalSum(this.$store.getters.jwtData.userId)
      .then(response => {
        if(response.data.statusCode === 200){
          this.salesData = response.data.result
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    goToTransactions() {
      this.$router.push(
        {
          path: `/branch/${this.$store.state.user.userInfo.idParentBranch}/transaction-detail`, 
          query: { all: 'true' }
        }
      );
    },
    goToBranches(){
      this.$router.push('/');
    },
  },
  created(){
    this.retrieveBranches();
  }
}
</script>
<style>
  .card-stat {
    cursor: pointer;
  }
</style>