var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("assign-item-modal", {
        attrs: {
          showAssignItem: _vm.showAssignItem,
          type: "client-account",
          isMultipleSelection: false,
          titleD: "Selecciona una cuenta"
        },
        on: {
          close: function($event) {
            _vm.showAssignItem = false
          },
          selectedItems: _vm.accountSelected,
          error: _vm.handlerError
        }
      }),
      _c("select-items-modal", {
        attrs: {
          items: _vm.itemsList,
          fields: _vm.fieldList,
          showModal: _vm.showSelectModal
        },
        on: { close: _vm.closeSelectModal }
      }),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("\n            Pertenece a:\n          ")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.name,
                        expression: "client.name"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: {
                      cursor: "pointer",
                      "background-color": "white"
                    },
                    attrs: {
                      alternative: "",
                      disabled: _vm.idUser > 0,
                      readonly: "true",
                      required: ""
                    },
                    domProps: { value: _vm.client.name },
                    on: {
                      click: function($event) {
                        _vm.showAssignItem = true
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.client, "name", $event.target.value)
                      }
                    }
                  }),
                  _vm._t("helpBlock", [
                    _c(
                      "div",
                      {
                        staticClass: "text-danger invalid-feedback",
                        staticStyle: { display: "block" }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errorAccount) +
                            "\n            "
                        )
                      ]
                    )
                  ])
                ],
                2
              ),
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("\n            Tipo de acceso\n          ")
                  ]),
                  _c("v-select", {
                    staticClass: "mt-1",
                    attrs: {
                      disabled: _vm.client.name ? false : true,
                      placeholder: "Selecciona una opción",
                      options: _vm.accessType
                    },
                    on: { input: _vm.onTypeAccessChange },
                    model: {
                      value: _vm.accessTypeSelected,
                      callback: function($$v) {
                        _vm.accessTypeSelected = $$v
                      },
                      expression: "accessTypeSelected"
                    }
                  }),
                  _vm._t("helpBlock", [
                    _c(
                      "div",
                      {
                        staticClass: "text-danger invalid-feedback",
                        staticStyle: { display: "block" }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errorAccessType) +
                            "\n            "
                        )
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm.accessTypeSelected === "Empresa" ||
          _vm.accessTypeSelected === "Sucursal"
            ? _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "base-button",
                          {
                            staticStyle: { width: "100%", marginTop: "30px" },
                            attrs: { "base-button": "", type: "primary" },
                            on: { click: _vm.showSelectItemsModal }
                          },
                          [_vm._v(_vm._s(_vm.getTextButton()))]
                        ),
                        _vm._t("helpBlock", [
                          _c(
                            "div",
                            {
                              staticClass: "text-danger invalid-feedback",
                              staticStyle: { display: "block" }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.errorEnterpriseBranch) +
                                  "\n                "
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }