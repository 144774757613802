<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <partner-acount-table
            :idUserPartner="this.idPartner"
            v-on:error="handleError">
          </partner-acount-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import handleErrorMixin from '../mixins/handleErrorMixin';
import periodSearchMixin from '../mixins/periodSearchMixin';  
import enums from '../enums/enums';
import PartnerAcountTable from './Tables/PartnerAcountTable.vue';
  export default {
    components: {
      PartnerAcountTable
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        idPartner: Number(this.$route.params.id),
        type: enums.ACCOUNT,
        clean: Boolean,
        period:{},
        updteTable: false
      };
    },
    methods: {
      handleError(error) {
        this.$emit('error', error)
      }
    },
    mounted() {
      this.initializePeriod();
    }
  };
</script>
<style></style>
