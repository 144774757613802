/**
 *
 * @param {*} joinField name of the attribute on the first array of objects
 * @param {*} joinField2 name of the attribute on the second array of objects
 * @ param  {...any} input arrays of objects, is necesary to have this on this format
 *           for the internal proces
 */
function genericJoin(joinField, joinField2, ...input) {
  //Get all possible keys
  let template = new Set();

  input.forEach(arr => {
    if (arr.length) {
      Object.keys(arr[0]).forEach(key => {
        template.add(key);
      });
    }
  });

  let [a, b] = input;
  // Merge items with duplicate ids
  let result = new Map();

  a.forEach(elem => {
    result.set(elem[joinField], elem);
  });

  b.forEach(item => {
    result.set(
      item[joinField2],
      Object.assign(result.get(item[joinField2]) || {}, item)
    );
  });

  // Convert the map back to an array of objects
  // and set any missing properties to null
  return Array.from(result.values(), item => {
    template.forEach(key => {
      item[key] = item[key] || null;
    });
    
    return item;
  });
}

export default genericJoin;
