const steps = {
    PaymentInput: 'PaymentInput',
    PaymentTip: 'PaymentTip',
    PaymentSplit: 'PaymentSplit',
    PaymentSummary: 'PaymentSummary',
    PaymentType: 'PaymentType',
    PaymentPromotion: 'PaymentPromotion',
    PaymentProcess: 'PaymentProcess',
    PaymentPhone: 'PaymentPhone'
}

export default Object.freeze(steps)