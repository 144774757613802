<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <form @submit.prevent="login" ref="loginForm">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Usuario"
              addon-left-icon="ni ni-email-83"
              v-model="model.username"
              :maxlength="64"
            ></base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Contraseña"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
              :maxlength="65"
            ></base-input>
            <div class="text-center" style="color: #CACACA">
              F-v{{versionApp}}-{{env}}
            </div>
            <input type="hidden" v-model="model.timezone" />
            <input type="hidden" v-model="model.offset" />

            <!--base-checkbox class="custom-control-alternative">
                                <span class="text-muted">Remember me</span>
            </base-checkbox-->
            <div class="text-center">
              <base-button type="primary" class="my-4" nativeType="submit">Ingresar</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!--a href="#" class="text-light">
            <small>Olvido su contraseña?</small>
          </a-->
        </div>
        <!--<div class="col-6 text-right">
          <router-link to="/register" class="text-light"><small style="color: black">Registrarse</small></router-link>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { AUTH_REQUEST } from "../store/actions/auth";
import {version} from '../../package.json'
import { USER_INFO_REQUEST } from "../store/actions/user";
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import enumEnv from "../enums/enumEnv";
import { hasEnterpriseAccess } from '../utils/generalMethods';
export default {
  name: "login",
  data() {
    return {
      versionApp: version,
      model: {
        username: "",
        password: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        currentTimeOffset:  new Date().getTimezoneOffset(),
      },
      valid: true
    };
  },
  computed: {
    env() {
      return process.env.NODE_ENV === enumEnv.DEVELOPMENT ? 'Dev' : 'Prod'
    },
  },
  methods: {
    getVersion() {
      return this.$store.getters.appVersion
    },
    signUp() {
      this.$router.push("/register");

    },
    async login() {
      const { username, password } = this.model;
      const { timezone, currentTimeOffset }  = this.model;
      if (username === "" || password === "") {
        this.$toasted.show("Proporcione Usuario y Contraseña");
        return;
      }
      let loader = this.$loading.show({loader: 'dots'})
      try {
        this.loading = true;
        await this.$store
          .dispatch(AUTH_REQUEST, {
            username,
            password,
            timezone,
            currentTimeOffset
          })
          .then(async () => {
            await this.$store.dispatch(
              USER_INFO_REQUEST
            );
            loader.hide()
            this.hasError = false;
            let home = "";
            switch (this.$store.getters.jwtRole){
              case 'UsersAdmins':
                home = '/accounts'
                break;
              case 'UsersPartners':
                home = '/my-clients'
                break;
              case 'UsersAccounts':
                if (hasEnterpriseAccess(this.$store.getters.jwtRole, this.$store.getters.jwtUserLevel)){
                  home = '/enterprises'
                }else{
                  home = '/branches'
                }
                break;
              case 'UsersExternals':
                home = '/my-clients'
                break;
              case 'UsersDevices':
                home = '/my-clients'
                break;
            }
            this.$router.push(home);
          }).catch(error => {
            let response = error.response
            if (response !== null && response !== undefined) {
              if (response.data.friendlyMessage === undefined){
                this.$toasted.show("No se pudo completar la operación correctamente, contacte con el administrador del sistema");
              } else {
                this.$toasted.show(response.data.friendlyMessage);
              }
            }else{
              let data = error.data
              if (data !== null){
                processErrorResponse(data, this.$toasted)
              }
            }
            this.$emit('error', error)
            loader.hide()
          });
      } catch (error) {
        loader.hide()
        if (error.response && error.response.status === 401) {
          this.$toasted.show("Usuario o contraseña incorrectos");
        }
        return;
      } finally {
        this.loading = false;
      }
    }
  },
 
};
</script>
<style>
body {
  background-color: #eee !important;
}
</style>
