var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-3", staticStyle: { padding: "0px" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("label", { staticClass: "form-control-label" }, [
                _vm._v("\n        Selecciona una empresa\n      ")
              ]),
              _c(
                "div",
                { staticClass: "mt-1", staticStyle: { background: "white" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "label",
                      value: _vm.enterpriseSelect,
                      reduce: function(label) {
                        return label.idEnterprise
                      },
                      placeholder: "Seleccione una opción",
                      options: _vm.enterprises
                    },
                    on: { input: _vm.enterpriseSelected },
                    model: {
                      value: _vm.idEnterprise,
                      callback: function($$v) {
                        _vm.idEnterprise = $$v
                      },
                      expression: "idEnterprise"
                    }
                  })
                ],
                1
              ),
              _vm._t("helpBlock", [
                _c(
                  "div",
                  {
                    staticClass: "text-danger invalid-feedback",
                    staticStyle: { display: "block" }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errorEnterprise) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("label", { staticClass: "form-control-label mt-3" }, [
                _vm._v("\n        Selecciona una sucursal\n      ")
              ]),
              _c(
                "div",
                { staticClass: "mt-1", staticStyle: { background: "white" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "label",
                      value: _vm.branchS,
                      reduce: function(label) {
                        return label.idBranch
                      },
                      placeholder: "Seleccione una opción",
                      options: _vm.branches
                    },
                    on: { input: _vm.branchSelected },
                    model: {
                      value: _vm.idBranch,
                      callback: function($$v) {
                        _vm.idBranch = $$v
                      },
                      expression: "idBranch"
                    }
                  })
                ],
                1
              ),
              _vm._t("helpBlock", [
                _c(
                  "div",
                  {
                    staticClass: "text-danger invalid-feedback",
                    staticStyle: { display: "block" }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.errorBranch) + "\n        "
                    )
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }