<template >
  <b-container style="padding: 0px" class="mb-3">
    <b-row>
      <b-col>
        <label class="form-control-label">
          Selecciona una empresa
        </label>
        <div class="mt-1" style="background: white" >
          <v-select 
            label="label"
            :value="enterpriseSelect"
            :reduce="label => label.idEnterprise"
            v-model="idEnterprise"
            placeholder = 'Seleccione una opción'
            :options="enterprises"
            @input = "enterpriseSelected"
            ></v-select>
        </div>
        <slot name="helpBlock">
          <div class="text-danger invalid-feedback" style="display: block;" >
              {{errorEnterprise}}
          </div>
        </slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label class="form-control-label mt-3">
          Selecciona una sucursal
        </label>
        <div class="mt-1" style="background: white" >
          <v-select 
            label="label"
            :value="branchS"
            :reduce="label => label.idBranch"
            v-model="idBranch"
            placeholder = 'Seleccione una opción'
            :options="branches"
            @input = "branchSelected"
            ></v-select>
        </div>
        <slot name="helpBlock">
          <div class="text-danger invalid-feedback" style="display: block;" >
              {{errorBranch}}
          </div>
        </slot>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { getBranchesSimpleList, getEnterprisesSimpleList } from '../utils/api';
export default {
  name: 'enterprise-branch-selector',
  props: {
    errorEnterprise: String,
    errorBranch: String
  },
  data() {
    return {
      enterprises: [{label: '', idEnterprise: null}],
      enterpriseSelect: {label: '', idEnterprise: null},
      branches: [{label: '', idBranch: null}],
      branchS: {label: '', idBranch: null},
      idBranch: '',
      idEnterprise: '',
    }
  },
  mounted(){
    this.loadEnterprises()
  },
  methods:{
    createLabelSelectorBranch() {
      this.branches.map(function (x){
        return x.label = x.idBranch + ' - ' + x.name;
      });
    },
    createLabelSelectorEnterprise() {
      this.enterprises.map(function (x){
        return x.label = x.idEnterprise + ' - ' + x.name;
      });
    },
    setDefaultBranch() {
      if (this.branches.length > 0) {
        this.idBranch = this.branches[0].idBranch
        this.branchSelected()
      }else{
        this.branchS= {label: '', idEnterprise: null}
        this.idBranch = null
        this.branchSelected()
      }
    },
    setDefaultEnterprise() {
      if (this.enterprises.length > 0) {
        this.idEnterprise = this.enterprises[0].idEnterprise
        this.enterpriseSelected()
      }
    },
    loadEnterprises() {
      let loader = this.$loading.show({loader: 'dots'});
      getEnterprisesSimpleList()
      .then(resp => {
        loader.hide()
        if (resp.data.statusCode === 200) {
          this.enterprises = resp.data.result.list
          this.createLabelSelectorEnterprise()
          this.setDefaultEnterprise()
        } else {
          processErrorResponse(resp.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error);
      })
      .finally(() => {

      });
    },
    loadBranches() {
      let loader = this.$loading.show({
        loader: 'dots'
      });
      getBranchesSimpleList(this.idEnterprise)
      .then(resp => {
        loader.hide()
        if (resp.data.statusCode === 200) {
          this.branches = resp.data.result.list
          this.createLabelSelectorBranch()
          this.setDefaultBranch()
        } else {
          processErrorResponse(resp.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error);
      })
    },
    branchSelected() {
       this.$emit('branch-selected', this.idBranch)
    },
    enterpriseSelected() {
      this.$emit('enterprise-selected', this.idEnterprise)
      if (this.idEnterprise === null){
        this.branches = []
        this.branchS= {label: '', idEnterprise: null}
        this.idBranch = null
        this.branchSelected()
        return
      }
      this.loadBranches()
    }
  }
};
</script>
<style>
  .big-icon {
    font-size: 2.3em !important;
}
</style>
