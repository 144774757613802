<template >
  <div>
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="'client-account'"
      :isMultipleSelection="false"
      :titleD="'Selecciona una cuenta'"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="accountSelected"
      v-on:error="handlerError"
    ></assign-item-modal>
    <select-items-modal
      :items='itemsList'
      :fields="fieldList"
      :showModal='showSelectModal'
      v-on:close="closeSelectModal"
      >
    </select-items-modal>
    <b-container>
        <b-row class="mt-3">
          <b-col>
            <label class="form-control-label">
              Pertenece a:
            </label>
            <input
              alternative
              :disabled='idUser > 0'
              @click="showAssignItem = true" 
              style="cursor: pointer; background-color: white" 
              class="form-control" 
              v-model="client.name"
              readonly="true"
              required>
            <slot name="helpBlock">
              <div class="text-danger invalid-feedback" style="display: block;" >
                  {{errorAccount}}
              </div>
            </slot>
          </b-col>
          <b-col >
            <label class="form-control-label">
              Tipo de acceso
            </label>
            <v-select
              :disabled='client.name ? false: true'
              class="mt-1"
              v-model="accessTypeSelected"
              placeholder = 'Selecciona una opción' 
              :options="accessType"
              @input="onTypeAccessChange"
              ></v-select>

            <slot name="helpBlock">
              <div class="text-danger invalid-feedback" style="display: block;" >
                  {{errorAccessType}}
              </div>
            </slot>
          </b-col>
        </b-row>
        <b-row v-if="accessTypeSelected === 'Empresa' || accessTypeSelected === 'Sucursal'"  class="mt-3">
          <b-col>
            <div style="width: 100%;" class="text-center">
              <base-button base-button 
                type="primary" 
                style="width: 100%; marginTop: 30px"
                @click="showSelectItemsModal">{{getTextButton()}}</base-button>
                <slot name="helpBlock">
                  <div class="text-danger invalid-feedback" style="display: block;" >
                      {{errorEnterpriseBranch}}
                  </div>
                </slot>
            </div>
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>
<script>
import enumsAccessType, { getTypeAccessEnglish, getTypeAccessSpanish } from '../enums/enumsAccessType';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { getAccountInfo, getBranchesSimpleList, getEnterprisesSimpleList, getUserHierarchiesLinked } from '../utils/api';
import AssignItemModal from '../views/Modals/AssignItemModal.vue';
import SelectItemsModal from '../views/Modals/SelectItemsModal.vue';

export default {
  components: { SelectItemsModal, AssignItemModal },
  name: 'access-selector',
  props: {
    accessTypeProp: {
      default: ''
    },
    isUpdate: {
      default: false
    },
    idUser:{
      default: 0
    },
    errorEnterpriseBranch: String,
    errorAccessType: String,
    errorAccount: String,
    clean: Boolean

  },
  data() {
    return {
      client: {
        idAccount: 0,
        name: '',
        idEnterprises: [],
        idBranches: []
      },
      defaultClient: {
        idAccount: 0,
        name: '',
        idEnterprises: [],
        idBranches: []
      },
      accessTypeSelected: '',
      accessType: [
        enumsAccessType.ACCOUNT,
        enumsAccessType.ENTERPRISE,
        enumsAccessType.BRANCH
      ],
      paginationDTO:  {
        idAccount: 0,
        searchTerm: "",
        offset: 0,
        limit: 10000,
        status: 'ALL'
      },
      namesItemsSelected: '',
      showSelectModal: false,
      fieldList: [],
      itemsList: [],
      itemsSelected: [],
      showAssignItem: false
    }
  },
  watch: {
    idUser: {
      handler(newValue){
        if(newValue > 0){
          this.getHieranchiesLinked(newValue)
        }
      }
    },
    clean: {
      handler(newValue){
        if (newValue){
          this.client = Object.assign({}, this.defaultClient)
          this.accessTypeSelected = ''
          this.itemsList = []
          this.clearList()
        }
      }
    }
  },
  mounted(){
  },
  methods:{
    getHieranchiesLinked(id){
      let loader = this.$loading.show({loader: 'dots'})
      getUserHierarchiesLinked(id)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          if (response.data.result.userLevel === enumsAccessType.ENTERPRISE_E){
            this.itemsSelected = response.data.result.idEnterprise
          } else if (response.data.result.userLevel === enumsAccessType.BRANCHE_E){
             this.itemsSelected = response.data.result.idBranch
          }
          this.accessTypeSelected = getTypeAccessSpanish(response.data.result.userLevel)
          this.client.idAccount = response.data.result.idAccount
          this.getAccountInformation(this.client.idAccount)
          this.onTypeAccessChange()
        } else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide();
        this.$emit('error', error)
      })
    },
    handlerError(error) {
      this.$emit('error', error)
    },
    saveChanges(){
      let object = {
        idAccount: this.client.idAccount,
        userLevel: this.getHierarchyType(),
        accountName: this.client.name
      }
      if (object.userLevel === enumsAccessType.ENTERPRISE_E){
        object.idEnterprise = this.getIdItems()
      } else if (object.userLevel === enumsAccessType.BRANCHE_E){
        object.idBranch = this.getIdItems()
      }
      this.$emit('access-info-selected', object)
    },
    getIdItems(){
      let ids = []
      this.itemsList.forEach(item =>{
        if(item.isSelected){
          if (this.accessTypeSelected === enumsAccessType.ENTERPRISE)
            ids.push(item.idEnterprise)
          else
            ids.push(item.idBranch)
        }
      })
      return ids
    },
    getHierarchyType(){
      return getTypeAccessEnglish(this.accessTypeSelected)
    },
    getAccountInformation(id){
      let loader = this.$loading.show({loader: 'dots'})
      getAccountInfo(id)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          this.client = response.data.result
          this.saveChanges()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    validateField(){

    },
    accountSelected(event){
      this.getFocus()
      this.accessTypeSelected = ''
      this.namesItemsSelected = ''
      this.itemsList = []
      this.client.name = event[0].fullName
      this.client.idAccount = event[0].id
      this.showAssignItem = false
      this.saveChanges()
    },
    onTypeAccessChange(){
      this.itemsList = []
      this.namesItemsSelected = ''
      if (this.accessTypeSelected === enumsAccessType.ENTERPRISE){
        this.getEnterprisesList()
      }else  if (this.accessTypeSelected === enumsAccessType.BRANCH){
        this.getBranchesList()
      }
      this.saveChanges()
    },
    showSelectItemsModal(){
      this.showSelectModal = true
    },
    getEnterprisesList(){
      getEnterprisesSimpleList(this.client.idAccount)
      .then(response=>{
        if (response.data.statusCode === 200) {
          response.data.result.list.forEach(enterprise => {
            this.itemsList.push(
              {
                name: enterprise.name, 
                idEnterprise: enterprise.idEnterprise, 
                contactMail: enterprise.contactMail,
                isSelected: this.isSelectedItem(enterprise.idEnterprise)
              }
            )
          });
          this.fieldList = [
            {
              icon: 'ni ni-circle-08',
              key: 'name'
            },
            {
              icon: 'ni ni-email-83',
              key: 'contactMail'
            }
          ]
          this.clearList()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    getBranchesList(){
      this.paginationDTO.idAccount = this.client.idAccount
      getBranchesSimpleList(undefined, this.client.idAccount)
      .then(response=>{
        if (response.data.statusCode === 200) {
          response.data.result.list.forEach(branch => {
            this.itemsList.push(
              {
                name: branch.name, 
                idBranch: branch.idBranch, 
                isSelected: this.isSelectedItem(branch.idBranch),
                contactMail: branch.contactMail,
                enterpriseName: branch.enterpriseName
              })
          });
          this.fieldList = [
            {
              icon: 'ni ni-circle-08',
              key: 'name'
            },
            {
              icon: 'ni ni-email-83',
              key: 'contactMail'
            },
            {
              icon: 'ni ni-building',
              description: 'Empresa: ',
              key: 'enterpriseName'
            }
          ]
          this.clearList()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    isSelectedItem(idItem){
      let item = this.itemsSelected.find(item => item === idItem)
      return item ? true : false
    },
    closeSelectModal(){
      this.getFocus()
      this.showSelectModal = false
      this.clearList()
    },
    clearList(){
      this.client.idEnterprise = []
      this.client.idBranches = []
      this.namesItemsSelected =  this.getNamesOfItemsSelected(this.itemsList)
      this.saveChanges()
    },
    getNamesOfItemsSelected(list){
      let names = ''
      list.forEach(item => {
        if (item.isSelected){
          names += item.name + ', '
        }
      })
      names = names.substring(0, names.length-2)
      return names
    },
    getFocus(){
      setTimeout(() => {
        document.body.classList.add('modal-open');
      }, 100)
    },
    getTextButton(){
      let count = this.itemsList.filter(item => item.isSelected).length
      if(this.accessTypeSelected === enumsAccessType.ENTERPRISE){
        return `Ver y/o seleccionar empresas (${count})`
      }else{
        return `Ver y/o seleccionar sucursales (${count})`
      }
    }
  }
};
</script>
<style>
</style>
