var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card container-fluid mt--4 w-75" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "col-xl-12" },
      _vm._l(_vm.tableData, function(card, index) {
        return _c(
          "card",
          {
            key: card.brand + card.title,
            class: index === 0 ? "" : "mt-5",
            attrs: { shadow: "" }
          },
          [
            _c(
              "b-container",
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      card.brand
                        ? _c("img", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: { src: _vm.getImageBrand(card.brand) }
                          })
                        : _vm._e()
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "align-self-center text-center" },
                      [
                        _c(
                          "h3",
                          {
                            staticStyle: { "margin-bottom": "0px !important" }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(card.title) +
                                "\n              "
                            )
                          ]
                        )
                      ]
                    ),
                    _c("b-col")
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-container",
              { staticClass: "mt-2" },
              _vm._l(card.info, function(item, index) {
                return _c(
                  "b-row",
                  {
                    key: item.name,
                    staticClass: "p-1",
                    style: [
                      index % 2 === 0
                        ? { background: "#F7F7F7" }
                        : { background: "white" }
                    ]
                  },
                  [
                    _c("b-col", [
                      _c("span", { class: item.name === "TOTAL" ? "h3" : "" }, [
                        _vm._v(_vm._s(item.name))
                      ])
                    ]),
                    _c("b-col", { staticClass: "text-center" }, [
                      _c("span", { class: item.name === "TOTAL" ? "h3" : "" }, [
                        _vm._v(_vm._s(item.qty))
                      ])
                    ]),
                    _c("b-col", { staticClass: "text-right" }, [
                      _c("span", { class: item.name === "TOTAL" ? "h3" : "" }, [
                        _vm._v(_vm._s(item.amount))
                      ])
                    ])
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col mt-2 align-self-center" }, [
          _c("div", [
            _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
              _vm._v("\n              Totales del turno\n           ")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }