var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showAssignModal, showClose: true },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Asignar sucursal raíz")
      ]),
      _c(
        "form",
        {
          ref: "AssignBranchForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.assignBranch($event)
            }
          }
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row mt-1" }, [
              _c(
                "div",
                { staticClass: "col-xl-12" },
                [
                  _c("base-input", {
                    attrs: {
                      label: "Nombre del negocio",
                      "input-classes": "form-control-alternative"
                    },
                    model: {
                      value: _vm.branch.name,
                      callback: function($$v) {
                        _vm.$set(_vm.branch, "name", $$v)
                      },
                      expression: "branch.name"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row mt-1" }, [
              _c(
                "div",
                { staticClass: "col-xl-12" },
                [
                  _c("search-branch-table", {
                    attrs: { title: "Empresas", searchTerm: _vm.branch.name },
                    on: { "branch-selected": _vm.selectBranch }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                { staticClass: "col-12 text-right" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4",
                      attrs: { type: "primary", nativeType: "submit" }
                    },
                    [_vm._v("Guardar")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }