<!-- https://codepen.io/kresogalic/pen/dGzvRV -->
<template>
  <div>
    <send-mail-modal
      :showSendMailModal="showSendMailModal"
      v-on:close="showSendMailModal=false"
      v-on:error="handleMailSendError"
    />
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="card shadow" style="width: 100%">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="mb-0">
                            Mi Cuenta
                        </h3>
                    </div>
                </div>
            </div>
            <div class="card-body">
              <b-container>
                <b-row>
                  <b-col>
                    <base-input
                      alternative
                      label="Nombre completo"
                      input-classes="form-control-alternative"
                      v-model="user.fullname"
                      :capitalize='true'
                      :maxlength="50"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      alternative
                      label="Nombre de usuario"
                      input-classes="form-control-alternative"
                      v-model="user.username"
                      :capitalize='true'
                      :maxlength="50"
                      disabled
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      alternative
                      label="Email"
                      input-classes="form-control-alternative"
                      v-model="user.email"
                      :maxlength="64"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <base-button
                              @click="updateAccount"
                              :disabled= "$v.user.$invalid">Actualizar perfil</base-button>
                  </b-col>
                </b-row>
                
              </b-container>
              <form class="row ma-2">
                <hr class="my-4">
                <div class="col-xl-12">
                    <h3>Contraseña</h3>
                </div>
                <div class="col-xl-12">
                  <base-password
                      label="Contraseña anterior"
                      input-classes="form-control-alternative"
                      v-model="password.formerPassword"
                      id="former-password"
                      :error="oldPasswordError"
                    />
                </div>
                <div class="col-xl-6">
                    <base-password
                      label="Nueva contraseña"
                      input-classes="form-control-alternative"
                      v-model="password.new"
                      id="user-password"
                      :error="validationMsg($v.password.new)"
                    />
                </div>
                <div class="col-xl-6">
                    <base-password
                      label="Confirmar Contraseña"
                      input-classes="form-control-alternative"
                      v-model="password.confirm"
                      id="user-confirm"
                      :error="validationMsg($v.password.confirm)"
                    />
                </div>
              </form>
            </div>
            <div class="card-footer">
              <div class="row ma-2">
                <div class="col"><base-button
                      @click="showSendMailModal=true"
                      >Contacto</base-button></div>
                <div class="col-xl-2 ">
                    <base-button
                      @click="changePassword"
                      :disabled= "$v.password.$invalid">Cambiar Contraseña</base-button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SendMailModal from './Modals/SendMailModal';

import { required, sameAs, maxLength, email } from 'vuelidate/lib/validators';
import validationMessagesMixin from '../mixins/validationMessagesMixin';

import { isStrong } from '../utils/utilMethods';
import { changePassword, getOwnData, updateProfile } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { UPDATE_USER_INFO } from '../store/actions/user';

export default {
  components:{
    SendMailModal
  },
  mixins: [validationMessagesMixin],
  data() {
    return {
      user: {},
      password: {},
      disabled: true,
      passwordStrength: '',
      passwordConfirm: '',
      showSendMailModal: false,
      oldPasswordError: '',
    };
  },
  methods: {
    loadData() {
      let loader = this.$loading.show({loader: 'dots'})
      getOwnData()
        .then (result =>{ 
          loader.hide()
          if (result.data.statusCode === 200) {
            this.user = result.data.result
          }else{
            processErrorResponse(result.data, this.$toasted)
          }
        })
      .catch(error => {
        loader.hide()
        this.$emit('error', error);
      })
    },
    updateAccount() {
      let loader = this.$loading.show({loader: 'dots'})
      updateProfile(this.user)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          let storeUser = Object.assign({}, this.$store.state.user.userInfo);
          storeUser.fullname = this.user.fullname
          this.$store.dispatch(
              UPDATE_USER_INFO,
              storeUser
            )
          this.$toasted.show('Información actualizada correctamente')
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    changePassword() {
      if(this.password.new !==  this.password.confirm) {
        this.$toasted.show('Las contraseñas no coinciden');
        this.password = {};
        return;
      }
      let dto = {oldPassword: this.password.formerPassword , newPassword: this.password.new}
      let loader = this.$loading.show({loader: 'dots'})
      changePassword(dto)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show('Contraseña actualizada')
        }else{
          processErrorResponse(response.data, this.toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    handleMailSendError(error){
      this.showSendMailModal = false;
      this.$emit('error', error);
    }

  },
  mounted() {
    this.loadData();
  },
  validations: {
    user:{
      username: {required},
      email: {email},
    },
    password:{
      new: {required, maxLength: maxLength(20), isStrong},
      confirm: {sameAsPassword: sameAs('new')},
    }
  },

};
</script>
<style lang="scss" scoped>
</style>