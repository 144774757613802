var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row justify-content-center" },
    [
      [
        _c("modal", { attrs: { show: _vm.showModal } }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("img", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: "img/signup/success.png" }
                }),
                _c(
                  "div",
                  {
                    staticClass: "p-3",
                    staticStyle: { color: "green", "font-size": "large" }
                  },
                  [_vm._v("Felicidades")]
                ),
                _c("div", [
                  _vm._v(
                    "\n                Tu solicitud ha sido registrada de manera exitosa en el sistema Zuma Pagos.\n                En breve recibirás la información de acceso a su cuenta.\n              "
                  )
                ]),
                _c(
                  "base-button",
                  {
                    staticClass: "my-4",
                    attrs: { type: "primary" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v("Entendido")]
                )
              ],
              1
            )
          ])
        ])
      ],
      _c("div", { staticClass: "col-lg-9" }, [
        _c("div", { staticClass: "card bg-secondary shadow border-0" }, [
          _c(
            "div",
            [
              _c(
                "b-tabs",
                {
                  staticClass: "local-card",
                  attrs: {
                    pills: "",
                    card: "",
                    "no-nav-style": "",
                    "no-key-nav": ""
                  },
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { "title-item-class": "disabledTab" } },
                    [
                      _c("div", { staticClass: "col-xl-11" }, [
                        _c("h3", { staticClass: "mb-3" }, [
                          _vm._v("Datos generales del titular")
                        ])
                      ]),
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Nombre(s):",
                                      error: _vm.validateField("strFirstName"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strFirstName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strFirstName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strFirstName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Apellido paterno:",
                                      error: _vm.validateField("strLastName"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strLastName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strLastName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strLastName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Apellido materno:",
                                      error: _vm.validateField("strLastName2"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strLastName2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strLastName2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strLastName2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("h4", [_vm._v("Tipo de persona")]),
                                  _c(
                                    "div",
                                    { staticStyle: { background: "white" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Selecciona una opción",
                                          options: _vm.typePersonCatalog
                                        },
                                        on: { input: _vm.resetIneData },
                                        model: {
                                          value:
                                            _vm.model.generalData
                                              .strTaxPayerType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.generalData,
                                              "strTaxPayerType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.generalData.strTaxPayerType"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "strTaxPayerType"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-container",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                this.model.generalData.strTaxPayerType ===
                                "Persona física",
                              expression:
                                "this.model.generalData.strTaxPayerType === 'Persona física'"
                            }
                          ]
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Número de INE:",
                                      error: _vm.validateField(
                                        "strPPDocNumber"
                                      ),
                                      uppercase: true
                                    },
                                    model: {
                                      value:
                                        _vm.model.generalData.strPPDocNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strPPDocNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strPPDocNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("INE de la persona física")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "file-select" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select-button" },
                                          [
                                            _vm.ineDocumentPersonalAdverseText
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ineDocumentPersonalAdverseText
                                                    )
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v("Adjuntar Anverso INE")
                                                ])
                                          ]
                                        ),
                                        _c("input", {
                                          attrs: {
                                            id: "anverso",
                                            type: "file"
                                          },
                                          on: { change: _vm.onChangeFileUpload }
                                        })
                                      ]
                                    ),
                                    _vm._t("helpBlock", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger invalid-feedback",
                                          staticStyle: { display: "block" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.validateField(
                                                  "strPPDocFile"
                                                )
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ]),
                              _c("b-col", [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "file-select" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select-button" },
                                          [
                                            _vm.ineDocumentPersonalBackText
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ineDocumentPersonalBackText
                                                    )
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v("Adjuntar reverso INE")
                                                ])
                                          ]
                                        ),
                                        _c("input", {
                                          attrs: { type: "file" },
                                          on: { change: _vm.onChangeFileUpload }
                                        })
                                      ]
                                    ),
                                    _vm._t("helpBlock", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger invalid-feedback",
                                          staticStyle: { display: "block" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.validateField(
                                                  "strPPDocFileBack"
                                                )
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Fecha de expedición\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-3 ",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.yearsBefore
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value:
                                            _vm.model.generalData
                                              .dtmPPDocIssueDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.generalData,
                                              "dtmPPDocIssueDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.generalData.dtmPPDocIssueDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "dtmPPDocIssueDate"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Vigencia\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-3 ",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.yearsAfter
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value:
                                            _vm.model.generalData
                                              .dtmPPDocExpire,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.generalData,
                                              "dtmPPDocExpire",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.generalData.dtmPPDocExpire"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "dtmPPDocExpire"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mt-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Email:",
                                      maxlength: 64,
                                      error: _vm.validateField("strEmail")
                                    },
                                    model: {
                                      value: _vm.model.generalData.strEmail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strEmail",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strEmail"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mt-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Confirmar email:",
                                      maxlength: 64,
                                      error: _vm.validateField("confirmEmail")
                                    },
                                    model: {
                                      value: _vm.confirmEmail,
                                      callback: function($$v) {
                                        _vm.confirmEmail = $$v
                                      },
                                      expression: "confirmEmail"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Teléfono",
                                      error: _vm.validateField(
                                        "strPhoneNumber"
                                      ),
                                      maxlength: 10,
                                      validation: "number"
                                    },
                                    model: {
                                      value:
                                        _vm.model.generalData.strPhoneNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strPhoneNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strPhoneNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "RFC:",
                                      error: _vm.validateField("strTaxPayerID")
                                    },
                                    model: {
                                      value:
                                        _vm.model.generalData.strTaxPayerID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strTaxPayerID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strTaxPayerID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Calle y número exterior:",
                                      error: _vm.validateField("strAddress"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strAddress",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strAddress"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Número interior:",
                                      error: _vm.validateField("strIntNumber")
                                    },
                                    model: {
                                      value: _vm.model.generalData.strIntNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strIntNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strIntNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Colonia:",
                                      error: _vm.validateField("strSuburb"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strSuburb,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strSuburb",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strSuburb"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Delegación o municipio:",
                                      error: _vm.validateField("strCounty"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strCounty,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strCounty",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strCounty"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Ciudad:",
                                      error: _vm.validateField("strCity"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strCity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strCity",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strCity"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Estado:",
                                      error: _vm.validateField("strState"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value: _vm.model.generalData.strState,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strState",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strState"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Código postal:",
                                      error: _vm.validateField("strZipCode"),
                                      maxlength: 5,
                                      validation: "number"
                                    },
                                    model: {
                                      value: _vm.model.generalData.strZipCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strZipCode",
                                          $$v
                                        )
                                      },
                                      expression: "model.generalData.strZipCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("h4", [_vm._v("Actividad o giro ")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-3 ",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.catalogs
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value:
                                            _vm.model.generalData
                                              .strFiscalRegimen,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.generalData,
                                              "strFiscalRegimen",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.generalData.strFiscalRegimen"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.validateField(
                                                "strFiscalRegimen"
                                              )
                                            ) +
                                            "\n                      "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.model.generalData.strFiscalRegimen ===
                                    "Otros",
                                  expression:
                                    "model.generalData.strFiscalRegimen === 'Otros'"
                                }
                              ]
                            },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mt-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Especifique el Giro:",
                                      error: _vm.validateField(
                                        "strFiscalRegimenCustom"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.model.generalData
                                          .strFiscalRegimenCustom,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.generalData,
                                          "strFiscalRegimenCustom",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.generalData.strFiscalRegimenCustom"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { "title-item-class": "disabledTab" } },
                    [
                      _c("div", { staticClass: "col-xl-11" }, [
                        _c("h3", { staticClass: "mb-3" }, [
                          _vm._v("Datos generales de la empresa")
                        ])
                      ]),
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isMoralType(),
                                  expression: "isMoralType()"
                                }
                              ]
                            },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Razón social",
                                      error: _vm.validateField("strLegalName"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strLegalName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strLegalName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strLegalName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Nombre comercial",
                                      error: _vm.validateField("strBrandName"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strBrandName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strBrandName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strBrandName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label:
                                        "Nombre de la Sucursal (Solo en caso de que sea distinto al Nombre Comercial)",
                                      error: _vm.validateField("strBranchName"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strBranchName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strBranchName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strBranchName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-container",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.isMoralType(),
                              expression: "this.isMoralType()"
                            }
                          ]
                        },
                        [
                          _c("b-row", [
                            _c("div", { staticClass: "col-xl-11" }, [
                              _c("h4", { staticClass: "mb-3" }, [
                                _vm._v("Datos del representante")
                              ])
                            ])
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Nombre(s)",
                                      error: _vm.validateField("strLRFirstName")
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strLRFirstName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strLRFirstName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strLRFirstName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Apellido paterno",
                                      error: _vm.validateField("strLRLastName")
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strLRLastName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strLRLastName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strLRLastName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Apellido materno",
                                      error: _vm.validateField("strLRLastName2")
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strLRLastName2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strLRLastName2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strLRLastName2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "No. de escritura",
                                      error: _vm.validateField(
                                        "strLegalInfoNum"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData
                                          .strLegalInfoNum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strLegalInfoNum",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strLegalInfoNum"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Fecha de escritura\n                  "
                                      )
                                    ]
                                  ),
                                  _c("base-input-no-info", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var focus = ref.focus
                                          var blur = ref.blur
                                          return _c("flat-pickr", {
                                            staticClass:
                                              "form-control datepicker",
                                            attrs: {
                                              config: {
                                                allowInput: true,
                                                dateFormat: "Y/m/d"
                                              }
                                            },
                                            on: {
                                              "on-open": focus,
                                              "on-close": blur
                                            },
                                            model: {
                                              value:
                                                _vm.model.enterpriseData
                                                  .strLegalInfoDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.model.enterpriseData,
                                                  "strLegalInfoDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "model.enterpriseData.strLegalInfoDate"
                                            }
                                          })
                                        }
                                      }
                                    ])
                                  }),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "strLegalInfoDate"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Número de INE",
                                      error: _vm.validateField(
                                        "strLRDocNumber"
                                      ),
                                      uppercase: true
                                    },
                                    model: {
                                      value:
                                        _vm.model.enterpriseData.strLRDocNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.enterpriseData,
                                          "strLRDocNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.enterpriseData.strLRDocNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h4", [
                                  _vm._v("INE del representante legal")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "file-select" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select-button" },
                                          [
                                            _vm.ineDocumentText
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.ineDocumentText)
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v("Adjuntar anverso INE")
                                                ])
                                          ]
                                        ),
                                        _c("input", {
                                          attrs: {
                                            id: "anverso",
                                            type: "file"
                                          },
                                          on: { change: _vm.onChangeFileUpload }
                                        })
                                      ]
                                    ),
                                    _vm._t("helpBlock", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger invalid-feedback",
                                          staticStyle: { display: "block" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.validateField(
                                                  "strLRDocFile"
                                                )
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ]),
                              _c("b-col", [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "file-select" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "select-button" },
                                          [
                                            _vm.ineDocumentBackText
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ineDocumentBackText
                                                    )
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v("Adjuntar reverso INE")
                                                ])
                                          ]
                                        ),
                                        _c("input", {
                                          attrs: { type: "file" },
                                          on: { change: _vm.onChangeFileUpload }
                                        })
                                      ]
                                    ),
                                    _vm._t("helpBlock", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger invalid-feedback",
                                          staticStyle: { display: "block" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.validateField(
                                                  "strLRDocFileBack"
                                                )
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Fecha de expedición\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-3 ",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.yearsBefore
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value:
                                            _vm.model.enterpriseData
                                              .dtmLRDocIssueDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.enterpriseData,
                                              "dtmLRDocIssueDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.enterpriseData.dtmLRDocIssueDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "dtmLRDocIssueDate"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Vigencia\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-3 ",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.yearsAfter
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value:
                                            _vm.model.enterpriseData
                                              .dtmLRDocExpire,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.enterpriseData,
                                              "dtmLRDocExpire",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.enterpriseData.dtmLRDocExpire"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "dtmLRDocExpire"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { "title-item-class": "disabledTab" } },
                    [
                      _c("div", { staticClass: "col-xl-11" }, [
                        _c("h3", { staticClass: "mb-3" }, [
                          _vm._v("Datos bancarios")
                        ])
                      ]),
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Banco\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.banks
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value: _vm.model.bankData.strBankName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.bankData,
                                              "strBankName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.bankData.strBankName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField("strBankName")
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Cuenta de cheques",
                                      validation: "number",
                                      maxlength: 11,
                                      error: _vm.validateField(
                                        "strAccountNumber"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.model.bankData.strAccountNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.bankData,
                                          "strAccountNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.bankData.strAccountNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "CLABE",
                                      placeholder:
                                        "La cuenta para depósito debe estar al mismo nombre del titular del servicio",
                                      maxlength: 18,
                                      validation: "number",
                                      error: _vm.validateField("strCLABE")
                                    },
                                    model: {
                                      value: _vm.model.bankData.strCLABE,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.bankData,
                                          "strCLABE",
                                          $$v
                                        )
                                      },
                                      expression: "model.bankData.strCLABE"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "file-select col-12" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "select-button-bank" },
                                        [
                                          _vm.bankDocumentText
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.bankDocumentText)
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "Adjuntar documento bancario"
                                                )
                                              ])
                                        ]
                                      ),
                                      _c("input", {
                                        attrs: { type: "file" },
                                        on: { change: _vm.onChangeFileUpload }
                                      })
                                    ]
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "strBankDocFile"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-xl-11 mt-4" }, [
                        _c("h3", { staticClass: "mb-3" }, [
                          _vm._v("Referencias personales")
                        ])
                      ]),
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Nombre(s)",
                                      error: _vm.validateField(
                                        "strPRFirstName"
                                      ),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.referenceData.strPRFirstName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.referenceData,
                                          "strPRFirstName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.referenceData.strPRFirstName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Apellido paterno",
                                      error: _vm.validateField("strPRLastName"),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.referenceData.strPRLastName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.referenceData,
                                          "strPRLastName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.referenceData.strPRLastName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Apellido materno",
                                      error: _vm.validateField(
                                        "strPRLastName2"
                                      ),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.referenceData.strPRLastName2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.referenceData,
                                          "strPRLastName2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.referenceData.strPRLastName2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Teléfono",
                                      validation: "number",
                                      error: _vm.validateField(
                                        "strPRPhoneNumber"
                                      ),
                                      maxlength: 10
                                    },
                                    model: {
                                      value:
                                        _vm.model.referenceData
                                          .strPRPhoneNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.referenceData,
                                          "strPRPhoneNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.referenceData.strPRPhoneNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      "input-classes":
                                        "form-control-alternative",
                                      label: "Correo electrónico",
                                      error: _vm.validateField("strPREmail"),
                                      maxlength: 64
                                    },
                                    model: {
                                      value: _vm.model.referenceData.strPREmail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.referenceData,
                                          "strPREmail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.referenceData.strPREmail"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    [
                      _c("div", { staticClass: "col-xl-11" }, [
                        _c("h3", { staticClass: "mb-3" }, [_vm._v("Contrato")])
                      ]),
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      alternative: "",
                                      "input-classes":
                                        "form-control-alternative",
                                      label:
                                        "Nombre del ejecutivo que te atendió",
                                      error: _vm.validateField(
                                        "strExecutiveContact"
                                      ),
                                      capitalize: true,
                                      maxlength: 40
                                    },
                                    model: {
                                      value:
                                        _vm.model.contractData
                                          .strExecutiveContact,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.contractData,
                                          "strExecutiveContact",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.contractData.strExecutiveContact"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-control-label" },
                                    [
                                      _vm._v(
                                        "\n                    Cómo te enteraste de Zuma Pagos\n                  "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: { background: "white" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          placeholder: "Seleccione una opción",
                                          options: _vm.optionsFindOut
                                        },
                                        on: { input: _vm.checkOtherValue },
                                        model: {
                                          value:
                                            _vm.model.contractData
                                              .strChannelContact,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.contractData,
                                              "strChannelContact",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.contractData.strChannelContact"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "strChannelContact"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("div", [
                                  _c("h3", { staticClass: "mb-3 mt-2" }, [
                                    _vm._v("Productos que deseas contratar")
                                  ])
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox-group",
                                    {
                                      attrs: { name: "flavour-2" },
                                      model: {
                                        value:
                                          _vm.model.contractData
                                            .strProductRequired,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model.contractData,
                                            "strProductRequired",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.contractData.strProductRequired"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        { attrs: { value: "LINK" } },
                                        [
                                          _vm._v(
                                            "Zuma Link (Cobra a distancia generando ligas de pago desde tu dispositivo móvil)"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-form-checkbox",
                                        { attrs: { value: "MINI" } },
                                        [
                                          _vm._v(
                                            "Zuma MINI (Requieres una forma rápida para cobrar con un lector móvil, esta es la opción)"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-form-checkbox",
                                        { attrs: { value: "SMART" } },
                                        [
                                          _vm._v(
                                            "Zuma Smart (Terminal Inteligente con opción de voucher digital o impreso)"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "strProductRequired"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("base-input", {
                                    attrs: {
                                      alternative: "",
                                      "input-classes":
                                        "form-control-alternative ",
                                      label: "Comentarios",
                                      error: _vm.validateField("strComments"),
                                      capitalize: true,
                                      maxlength: 512
                                    },
                                    model: {
                                      value: _vm.model.contractData.strComments,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model.contractData,
                                          "strComments",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.contractData.strComments"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("div", [
                                  _c("h4", { staticClass: "mt-2" }, [
                                    _vm._v("Condiciones comerciales")
                                  ])
                                ]),
                                _c("div", [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.zumaPagosTextHTML)
                                    }
                                  })
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-3",
                                      staticStyle: { display: "flex" }
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          value: "true",
                                          "unchecked-value": "false"
                                        },
                                        model: {
                                          value:
                                            _vm.model.contractData
                                              .isAgreementAccepted,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.contractData,
                                              "isAgreementAccepted",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.contractData.isAgreementAccepted"
                                        }
                                      }),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "terms-conditions" },
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Acepto términos y condiciones\n                    "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._t("helpBlock", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger invalid-feedback",
                                        staticStyle: { display: "block" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.validateField(
                                                "isAgreementAccepted"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.tabIndex > 0
                ? _c(
                    "base-button",
                    {
                      staticClass: "my-4",
                      attrs: { type: "primary" },
                      on: { click: _vm.goPreviousPage }
                    },
                    [_vm._v("Anterior")]
                  )
                : _vm._e(),
              _c(
                "base-button",
                {
                  staticClass: "my-4",
                  attrs: { type: "primary" },
                  on: { click: _vm.goNextPage }
                },
                [_vm._v(_vm._s(_vm.textButton))]
              )
            ],
            1
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }