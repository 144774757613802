<template>
  <div>
    <modal
        :show="showAppUser"
        v-on:close="closeAppUser"
      >
      <!--div slot="header">Información General</div-->
      <card shadow type="secondary">
        <template>
          <h4 class="mt-4">
            Cuenta:
          </h4>
          <div>
            {{ticket.accountName}}
          </div>
          <h4 class="mt-4">
            Empresa:
          </h4>
          <div>
            {{ticket.enterpriseName}}
          </div>
          <h4 class="mt-4">
            Sucursal:
          </h4>
          <div>
            {{ticket.branchName}}
          </div>
          <h4 class="mt-4">
            Dispositivo:
          </h4>
          <div>
            {{ticket.deviceName}}
          </div>
          <h4 class="mt-4">
            Fecha de alta:
          </h4>
          <div>
            {{ticket.createdOn | formatDateTime}}
          </div>
          <h4 class="mt-4">
            Tipo :
          </h4>
          <div>
            {{ticket.type}}
          </div>
          <h4 class="mt-4">
            Título:
          </h4>
          <div>
            {{ticket.description1}}
          </div>
          <h4 class="mt-4">
            Descripción:
          </h4>
          <div>
            {{ticket.description2}}
          </div>
          <h4 class="mt-4">
            Estatus:
          </h4>
          <div>
            {{ticket.status === 'I' ?  'Revisado' : 'Pendiente'}}
          </div>
        </template>
        <div slot="footer" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-12 text-right">
              <base-button
                @click.prevent="closeAppUser()"
                class="btn btn-primary text-white">
                  Salir
              </base-button>
            </div>
            </div>
        </div>
      </card>
    </modal>
  </div>
</template>
<script>
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { getTicketInfo } from '../../utils/api';

export default {
  name: 'ticket-info-modal',
  props: ['idTicket'],
  data() {
    return {
      showAppUser: false,
      ticket: {
        accountName: '',
        enterpriseName: '',
        branchName: '',
        deviceName: '',
        title: '',
        description: '',
        status: '',
      },
      ticketDefault: {
        accountName: '',
        enterpriseName: '',
        branchName: '',
        deviceName: '',
        title: '',
        description: '',
        status: '',
      }
    }
  },
  methods: {
    closeAppUser() {
      this.clearData();
      this.showAppUser=false;
      this.$emit('close');
    },
    clearData() {
      this.ticket = this.ticketDefault
    },
  },
  watch: {
    idTicket(newVal){
      if(newVal && newVal > 0){
        let loader = this.$loading.show({loader: 'dots'})
        getTicketInfo(newVal)
        .then(response=>{
          loader.hide()
          if (response.data.statusCode === 200) {
            this.ticket = response.data.result
            this.showAppUser = true;
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      }
    }
  },
}
</script>