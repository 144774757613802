var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("assign-branch-modal", {
        attrs: {
          showAssignModal: _vm.showAssignModal,
          webUserToCreate: _vm.webUserToCreate
        },
        on: {
          close: function($event) {
            _vm.showAssignModal = false
          },
          "assigned-branch": _vm.refreshTables,
          error: _vm.handleError
        }
      }),
      _c(
        "modal",
        {
          attrs: { show: _vm.showMITData, showClose: true },
          on: {
            close: function($event) {
              _vm.showMITData = false
            }
          }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Información de coneccion MIT")
          ]),
          _c(
            "form",
            {
              ref: "MITDataForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveMITData($event)
                }
              }
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row mt-1" }, [
                  _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("base-input", {
                        attrs: {
                          label: "Usuario MIT",
                          "input-classes": "form-control-alternative"
                        },
                        model: {
                          value: _vm.mitData.User,
                          callback: function($$v) {
                            _vm.$set(_vm.mitData, "User", $$v)
                          },
                          expression: "mitData.User"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("base-input", {
                        attrs: {
                          label: "Password MIT",
                          "input-classes": "form-control-alternative"
                        },
                        model: {
                          value: _vm.mitData.Password,
                          callback: function($$v) {
                            _vm.$set(_vm.mitData, "Password", $$v)
                          },
                          expression: "mitData.Password"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("base-input", {
                        attrs: {
                          label: "Id Empresa",
                          "input-classes": "form-control-alternative"
                        },
                        model: {
                          value: _vm.mitData.Company,
                          callback: function($$v) {
                            _vm.$set(_vm.mitData, "Company", $$v)
                          },
                          expression: "mitData.Company"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xl-6" },
                    [
                      _c("base-input", {
                        attrs: {
                          label: "Id Sucursal",
                          "input-classes": "form-control-alternative"
                        },
                        model: {
                          value: _vm.mitData.Branch,
                          callback: function($$v) {
                            _vm.$set(_vm.mitData, "Branch", $$v)
                          },
                          expression: "mitData.Branch"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 text-right" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "my-4",
                          attrs: { type: "primary", nativeType: "submit" }
                        },
                        [_vm._v("Guardar")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "modal",
        {
          attrs: { show: _vm.showCreateWebUser, showClose: true },
          on: {
            close: function($event) {
              _vm.showCreateWebUser = false
            }
          }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Permisos usuario")
          ]),
          _c(
            "form",
            {
              ref: "MITDataForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createWebUser($event)
                }
              }
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row mt-1" }, [
                  _c("p", [
                    _vm._v(
                      "\n            Esta accion hará que el negocio relacionado al usuario se asigne como negocio de primer nivel y permitirá\n            que otros negocios puedan ser asignados como sucursal al mismo\n          "
                    )
                  ]),
                  _c("p", [_vm._v("¿Desea continuar?")])
                ])
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.showCreateWebUser = false
                          }
                        }
                      },
                      [_vm._v("Cancelar")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-6 text-right" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "my-4",
                          attrs: { type: "primary", nativeType: "submit" }
                        },
                        [_vm._v("Guardar")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c(
                "card",
                { attrs: { shadow: "" } },
                [
                  _c(
                    "tabs",
                    {
                      staticClass: "flex-column flex-md-row",
                      attrs: { fill: "", value: _vm.activeTab },
                      on: { "new-active-tab": _vm.updateState }
                    },
                    [
                      _c("tab-pane", { attrs: { title: "Empresas" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("Empresas")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xl-12" },
                          [
                            _c(
                              "card",
                              { attrs: { shadow: "", type: "secondary" } },
                              [
                                [
                                  _c("companies-table", {
                                    attrs: {
                                      title: "Empresas",
                                      mitDataSaved: _vm.mitDataSaved,
                                      webUserCreated: _vm.webUserCreated,
                                      refreshTable: _vm.refreshCompanyTable,
                                      usersType: "top-level"
                                    },
                                    on: {
                                      "show-mit-data": _vm.prepareMITDataPopup,
                                      "show-web-user": _vm.prepatecreateWebUser,
                                      "assign-to-company":
                                        _vm.prepareAssignToCompany,
                                      "loaded-users": function($event) {
                                        _vm.refreshCompanyTable = false
                                      },
                                      error: _vm.handleError
                                    }
                                  })
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]),
                      _c("tab-pane", { attrs: { title: "Nuevos Clientes" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("Nuevos Clientes")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xl-12" },
                          [
                            _c(
                              "card",
                              { attrs: { shadow: "", type: "secondary" } },
                              [
                                [
                                  _c("clients-table", {
                                    attrs: {
                                      title: "Clientes registrados",
                                      mitDataSaved: _vm.mitDataSaved,
                                      webUserCreated: _vm.webUserCreated,
                                      refreshTable: _vm.refreshNewUserTable,
                                      usersType: "unassigned"
                                    },
                                    on: {
                                      "show-mit-data": _vm.prepareMITDataPopup,
                                      "show-web-user": _vm.prepatecreateWebUser,
                                      "assign-to-company":
                                        _vm.prepareAssignToCompany,
                                      "loaded-users": function($event) {
                                        _vm.refreshNewUserTable = false
                                      },
                                      error: _vm.handleError
                                    }
                                  })
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }