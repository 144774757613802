<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <card-stats :period=period></card-stats>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <my-branches-table>
          </my-branches-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CardStats from './Dashboard/CardStats';
  import MyBranchesTable from './Tables/MyBranchesTable';

  import handleErrorMixin from '../mixins/handleErrorMixin';
  import periodSearchMixin from '../mixins/periodSearchMixin';  

  export default {
    components: {
      MyBranchesTable,
      CardStats,
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        period:{},
      };
    },
    methods: {
    },
    mounted() {
      this.initializePeriod();
    }
  };
</script>
<style></style>
