<template>
  <modal
  :show="showUserInfo"
  
  v-on:close="showUserInfo = false"
  >
    <card shadow type="secondary">
      <template>
        <div>
          <span>
            <h2>{{user.fullName}}<span class="heading-small text-muted"> {{userTypes[user.role]}}</span></h2> 
          </span>
        </div>
        
        <div class="mt-4" v-if="user.createdAt">
          <i class="ni ni-calendar-grid-58 icon-size-sm align-middle"></i> 
          &nbsp;<label class="heading-small text-muted">Creado: </label> {{user.createdAt | formatDateTime}}  
        </div>
        <div v-if="user.loggedInAt">
          <i class="ni ni-calendar-grid-58 icon-size-sm align-middle"></i> 
          &nbsp;<label class="heading-small text-muted">Último acceso: </label> {{user.loggedInAt | formatDateTime}}
        </div>       

        <hr class="my-4" />
        <h6 class="heading-small text-muted mb-4">Contacto</h6>
        <div v-if="user.mail">
          <i class="ni ni-email-83 icon-size-sm align-middle"></i>
          <span> {{user.mail}}</span>
        </div>

        <div v-if="user.phone">
          <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
          <span> {{user.phone}}</span>
        </div>

      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
              <div class="col-12 text-right">
                  <base-button 
                    @click.prevent="close()" 
                    class="btn btn-primary text-white">
                      Cerrar
                  </base-button>
              </div>
          </div>
      </div>
    </card>
  </modal>
</template>
<script>
import { getClient } from '../../utils/api';

  export default {
    name: "client-info-modal",
    props: {
      clientId: {
        description: 'User name to show',
      }
    },
    data() {
      return {
        user: {
          role: 'PARTNER',
        },
        defaultUser: {
          role: 'PARTNER',
        },
        userTypes: {
          'ACCOUNT': 'Cuenta',
          'ADMIN': 'Administrador',
          'EXTERNAL': 'Externo',
          'DEVICE': 'Dispositivo',
          'PARTNER': 'Vendedor',
          
        },
        title: 'Información Usuario',  
        showUserInfo: false,
      }
    },
    methods: {
      cleanData(){
        this.user = this.defaultUser;
      },
      close(){
        this.cleanData();
        this.showUserInfo = false;
        this.$emit('close');
      }
    },
    watch: {
      clean:{
        handler(newValue) {
          if (newValue){
            this.cleanData();
            this.$emit('cleaned');
          }
        }
      },
      clientId: {
        handler(newValue){
          if(newValue && newValue>0) {
            //load user
            let l = this.$loading.show({loader: 'dots'})
            getClient(this.user.role, newValue)
            .then(response => {
              l.hide()
              if (response.data.statusCode == 200) {
                this.user = response.data.result
                this.showUserInfo = true;
              }
            })
            .catch(error => {
              l.hide()
              this.$emit('error', error)
            })            
          }else {
            this.title = 'Informacion Usuario';
          }
        }
      }
    },
  }
</script>