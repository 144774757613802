<template>
  <modal
    :bigModal="true" 
    :show="showModal"
    :showClose=true
    v-on:close="closeModal">
    <div slot="header">Accesos de la cuenta:</div>
    <div>
      <access-selector
        :idUser="idUser"
        :errorAccount="errorAccount"
        :errorAccessType="errorAccessType"
        :errorEnterpriseBranch="errorItems"
        :clean="cleanAccessSelector"
        v-on:access-info-selected="updateAccessInfo"
        ></access-selector>
      <div slot="footer" >
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4" @click="saveChanges()">Guardar</base-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import AccessSelector from '../../components/AccessSelector.vue';
import enumsAccessType from '../../enums/enumsAccessType';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { updateUserHierarchiesLinked } from '../../utils/api';
import { isEmptyOrNull } from '../../utils/generalMethods';

export default {
  components: { AccessSelector },
    name: 'access-modal',
    props: {
      showModal: {
        type: Boolean,
        default: false
      },
      idUser: {
        default: 0
      }
    },
    data() {
      return {
        clean: false,
        client: {
          idUser: this.idUser,
          idAccount: 0,
          accountName: '',
          userLevel: '',
          idEnterprise: [],
          idBranch: []
        },
        showErrorMessages: false,
        errorAccount: '',
        errorAccessType: '',
        errorItems: '',
        cleanAccessSelector: false
      }
    },
    watch: {
      showModal: {
        handler(newValue){
          if (newValue){
            this.cleanAccessSelector = false
          }
        }
      }
    },
    methods:{
      closeModal(){
        this.clean = true
        this.errorAccount = ''
        this.errorAccessType = ''
        this.errorItems = ''
        this.cleanAccessSelector = true
        this.$emit('close')
      },
      error(error) {
        this.$emit('error', error)
      },
      updateAccessInfo(event){
        this.client.idAccount = event.idAccount
        this.client.userLevel = event.userLevel
        this.client.idEnterprise = event.idEnterprise
        this.client.idBranch = event.idBranch
        this.client.accountName = event.accountName
        this.canContinue()
      },
      saveChanges(){
        this.showErrorMessages = true
        if (this.canContinue()) {
          let loader = this.$loading.show({loader: 'dots'})
          updateUserHierarchiesLinked(this.idUser, this.client)
          .then(response=>{
            loader.hide()
            if (response.data.statusCode === 200) {
              this.$toasted.show('Cliente actualizado correctamente')
              this.$emit('close')
            }else{
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        }
      },
      canContinue() {
        var keys = Object.keys(this.client)
        for (let i = 0; i < keys.length; i++) {
          if (this.validateField(keys[i]) !== '')
            return false;
        }
        return true;
      },
      validateField(key) {
        if (this.showErrorMessages) {
          switch (key) {
            case 'accountName': 
              this.errorAccount = isEmptyOrNull(this.client.accountName) ? 'Es necesario seleccionar una cuenta' : ''
              return this.errorAccount
            case 'userLevel':
              this.errorAccessType = isEmptyOrNull(this.client.userLevel) ? 'Es necesario seleccionar un nivel de acceso' : ''
              return this.errorAccessType
            case 'idEnterprise':
              if (this.client.userLevel ===  enumsAccessType.ENTERPRISE_E){
                this.errorItems =  isEmptyOrNull(this.client.idEnterprise) || this.client.idEnterprise.length === 0 ? 'Debes seleccionar por lo menos una empresa' : ''
              }else{
                this.errorItems = ''
              }
              return this.errorItems
            case 'idBranch':
              if (this.client.userLevel === enumsAccessType.BRANCHE_E){
                this.errorItems = isEmptyOrNull(this.client.idBranch) || this.client.idBranch.length === 0 ? 'Debes seleccionar por lo menos una sucursal' : ''
              }else{
                this.errorItems = ''
              }
              return this.errorItems
            default:
              return '';
          }
        }            
      },
    },
  }
</script>
<style scoped>
.center-data  {
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>