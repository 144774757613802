<template>
  <div class="card shadow">
    <!--div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{title}}
          </h3>
        </div>
      </div>
    </div-->

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  tbody-classes="list"
                  :data="users">
        <template slot="columns">
          <th>Seleccionar</th>
          <th>Usuario</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <base-checkbox v-on:input="clientSelect(row, $event)"></base-checkbox>
          </th>
          <td>
            <div class="media align-items-center">
                {{row.nbNombre}} {{row.apPaterno}} {{row.apMaterno}}
                <br/>
                {{row.txEmail}}
                
            </div>
            <div>
              <label>Vend: </label> &nbsp;<strong>{{row.saler || 'No asignado'}}</strong>
            </div>
          </td>
        </template>

      </base-table>
    </div>

    <!--div v-if="users.length" class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="users.length"></base-pagination>
    </div-->

  </div>
</template>
<script>
  import _ from 'lodash';
  export default {
    name: 'search-client-table',
    props: {
      title: String,
      searchTerm: {
        type: String,
        description:
          'The search term for user, this gona search on the email field (username)',
      },
      clean: {
        type: Boolean,
        description:
          'Indicates this component to clean itself'
      }
    },
    data() {
      return {
        users: [],
      }
    },
    watch: {
      searchTerm: {
        handler: _.debounce(function(newVal){
          if(newVal.length > 1){
            this.axios.post('/user/search', {txEmail: `${newVal}%`})
            .then(result => {
              this.users = result.data;
            })
            .catch(error => {
              this.$emit('load-users-error', error);
            })
            .finally(() => {
              this.$emit('load-users-completed');
            });
          }
        }, 500),
        deep: true
      },
      
      clean(newVal){
        if(newVal){
          this.users = [];
          this.$emit('cleaned');
        }
      }
    },
    computed: {
    },
    mounted() {
    },
    methods:{
      userTypeDescription(row){
        const findType = this.userTypes.find( (type) => row.role === type.key );
        if(findType){
          return findType.value;
        }
        return "";
      },
      clientSelect(row, event) {
        this.$emit('client-selected', {selected: event, data:row});
      }
    }
  }
</script>
<style>
</style>
