var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("salers-resume-detail-table", {
                attrs: {
                  title: "Vendedores",
                  refreshTable: _vm.refreshSalersTable
                },
                on: {
                  "loaded-users": function($event) {
                    _vm.refreshSalersTable = false
                  },
                  error: _vm.handleError
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }