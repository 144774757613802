var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card shadow",
      class: _vm.type === "dark" ? "bg-default" : ""
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header border-0",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c("h3", { staticClass: "mb-0" }, [
                _vm._v(
                  "Desglose de ventas " +
                    _vm._s(_vm.currentSaler.nbNombre) +
                    " " +
                    _vm._s(_vm.currentSaler.apPaterno)
                )
              ]),
              _c("i", {
                staticClass: "ni ni-email-83 icon-size-sm align-middle"
              }),
              _c("span", { class: _vm.detailClass }, [
                _vm._v(_vm._s(" ") + _vm._s(_vm.currentSaler.txEmail))
              ]),
              _vm._v("\n         \n        "),
              _c("i", {
                staticClass: "ni ni-mobile-button icon-size-sm align-middle"
              }),
              _c("span", { class: _vm.detailClass }, [
                _vm._v(_vm._s(" ") + _vm._s(_vm.currentSaler.nuCelular))
              ])
            ]),
            _c(
              "div",
              { staticClass: "col-xl-3 text-right" },
              [
                _c("base-input-no-info", {
                  attrs: { placeholder: "Búsqueda" },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.fetchData($event)
                    }
                  },
                  model: {
                    value: _vm.queryTerm,
                    callback: function($$v) {
                      _vm.queryTerm = $$v
                    },
                    expression: "queryTerm"
                  }
                })
              ],
              1
            ),
            _vm.currentSaler.role !== "SALER"
              ? _c(
                  "div",
                  { staticClass: "col-xl-1 text-right" },
                  [
                    _c("download-button", {
                      attrs: {
                        endpoint: _vm.downloadLink,
                        payload: _vm.salersPayload,
                        reportName: _vm.reportName
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              class: _vm.type === "dark" ? "table-dark" : "",
              attrs: {
                "thead-classes":
                  _vm.type === "dark" ? "thead-dark" : "thead-light",
                "tbody-classes": "list",
                data: _vm.tableData
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("td", [_vm._v(_vm._s(row.owner_mobileUserId))]),
                      _c("td", [_vm._v(_vm._s(row.nbSucursal))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("formatDate")(row.mitCreation)))
                      ]),
                      _vm._l(_vm.monthsToDisplay, function(n) {
                        return _c("td", { key: n, staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                row[_vm.monthNames[_vm.monthsToDisplay - n]] ||
                                  0.0
                              )
                            )
                          )
                        ])
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "columns" },
                [
                  _c("th", [_vm._v("BUC")]),
                  _c("th", [_vm._v("Empresa")]),
                  _c("th", [_vm._v("F. Ingreso")]),
                  _vm._l(_vm.monthsToDisplay, function(n) {
                    return _c("th", { key: n, staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.monthNames[_vm.monthsToDisplay - n]))
                    ])
                  })
                ],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card-footer d-flex justify-content-end",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("base-pagination", {
            attrs: {
              total: _vm.totalElements,
              perPage: _vm.pagination.size,
              value: _vm.pagination.page
            },
            on: { input: _vm.updatePagination }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }