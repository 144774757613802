<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row">
        <div class="col mt-2 align-self-center">
          <div v-if="idUserPartner > 0">
            <h3 h3 class="mb-0">
              Vendendor: 
              <span> {{partner.idUser}} - {{partner.fullname}}</span>
            </h3>
            <i class="ni ni-email-83 icon-size-sm align-middle"></i>
              <span :class="detailClass">{{" "}}{{partner.email}}</span>
          </div>
          <div v-else>
            <h3 h3 class="mb-0">
                Cuentas
            </h3>
          </div>
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center"  style="{background-color: #0A0;}">
          <base-input
            style="margin-bottom: 0px"
            placeholder="Búsqueda"
            v-model="paginationDTO.searchTerm"
            @keyup.enter="loadData"
          >
          </base-input>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>ID</th>
          <th>Cuenta</th>
          <th>F. Alta</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
        </template>

        <template slot-scope="{row}">
          <td>
            <div>
                {{row.data.idAccount}}
            </div>
            
          </td>
          <td>
            <div
              class="h4">
                {{row.data.name}}
            </div>
            <div >
              {{row.data.contactMail}}
            </div>
          </td>
          <td>
            {{row.data.createdDate | formatDateTime}}
          </td>
          <td
            class="text-left"
            v-for="n in monthsToDisplay" :key="n">
            {{ (row.totals[monthNamesEnglish[monthsToDisplay - n]] || "0.00") | currency}}
          </td>
        </template>

      </base-table>
      <div class="card-footer d-flex justify-content-end">
        <base-pagination
          :total="totalElements"
          :perPage="pagination.size"
          :value="pagination.page"
          @input="updatePagination"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import "vue-select/dist/vue-select.css";
import monthSalesMixin from '../../mixins/monthSalesMixin';
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { getClient,getPartnerAccounts } from '../../utils/api';
import enums from '../../enums/enums';
import { paginationLimit } from '../../utils/catalog';

  export default {
    name: 'partner-account-table',
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin],
    props: {
      idUserPartner: Number,
      showSearch: {
        default: true,
      },
    },
    data() {
      return {
        tableData: [],
        totalElements: 0,
        partner: {},
        paginationDTO: {
          searchTerm: "",
          offset: 0,
          limit: paginationLimit,
          status: 'ALL'
        }
      }
    },
    methods: {
      updatePagination(page) {
        if (this.pagination.page !== page){
          if (page === 1){
            this.paginationDTO.offset = 0
          }else{
            let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
            this.paginationDTO.offset = offset
          }
          this.loadData();
        }
      },
      loadData() {
        let load = this.$loading.show({loader: 'dots'})
        getPartnerAccounts(this.paginationDTO)
        .then(response => {
          load.hide()
          if (response.data.statusCode === 200){
            this.totalElements = response.data.result.count
            this.tableData = response.data.result.list
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          load.hide()
          this.$emit('error', error)
        })
      },
      getPartnerData() {
        let loader = this.$loading.show({loader : 'dots'})
        getClient(enums.PARTNER, this.idUserPartner)
        .then(response => {
          loader.hide()
          if(response.data.statusCode === 200) {
            this.partner = response.data.result
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })

      }
    },
    mounted() {
      if (this.idUserPartner > 0) {
        this.paginationDTO.idUserPartner = this.idUserPartner
        this.getPartnerData()
      }
      this.pagination.size = this.paginationDTO.limit
      this.initializeMonthData();
      this.loadData();
    }
  }
</script>
<style lang="scss">

   @media (max-width: 1400px) {
    .md-screen-hide{
      display: none;
    }
  }
  .ligth-text {
    color: #828f7f;
  }

  .parentBranch{
    background-color: cornsilk;
  }
  .deleted{
    background-color: darksalmon;
  }
  .icon-size {
    font-size: 1rem;
  }
  .tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
