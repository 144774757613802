export default {
  data(){
    return {
      formMessages: {
        //required: () => 'Campo requerido',
        minLength: ({$params}) => `Al menos ${$params.minLength.min} caracteres`,
        pinLength: () => `Ingresa un pin de 4 caracteres`,
        email: () => 'Formato de correo inválido',
        sameAsPin: () => 'El pin no coincide',
        sameAsPassword: () => 'La contraseña no coincide',
        isStrong: () => 'Al menos 8 caracteres, 1 mayúscula y 1 número',
      }
    }
  },
  methods: {
    validationMsg(field) {
      for (let k in field) {
        if (this.formMessages.hasOwnProperty(k) && !field[k]) {
          return (this.formMessages[k](field));
        }
      }
      
      return '';
    }
  }
}