var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showCreateUser, showClose: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _vm.userId === 0
            ? _c("div", { staticClass: "row" }, [_vm._v(_vm._s(_vm.title))])
            : _vm._e(),
          _vm.userId !== 0
            ? _c("div", { staticClass: "row" }, [
                _c("span", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.user.nbNombre) +
                        " " +
                        _vm._s(_vm.user.apPaterno)
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm.userId !== 0
            ? _c("div", { staticClass: "row" }, [
                _vm.user.createdAt
                  ? _c("span", [
                      _c("label", [_vm._v("Creado el:")]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("formatDateTime")(_vm.user.createdAt)) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.user.loggedInAt
                  ? _c("span", [
                      _c("label", [_vm._v("Último acceso")]),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("formatDateTime")(_vm.user.loggedInAt)
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "card",
        { attrs: { shadow: "", type: "secondary" } },
        [
          [
            _c("div", { staticClass: "pl-lg-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-6" },
                  [
                    _c("base-select", {
                      attrs: {
                        label: "Tipo Usuario",
                        options: _vm.userTypes,
                        required: ""
                      },
                      model: {
                        value: _vm.user.role,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "role", $$v)
                        },
                        expression: "user.role"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-6" },
                  [
                    _c("base-input", {
                      attrs: {
                        alternative: "",
                        label: "Nombre",
                        "input-classes": "form-control-alternative",
                        capitalize: true,
                        maxlength: 50,
                        required: ""
                      },
                      model: {
                        value: _vm.user.nbNombre,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "nbNombre", $$v)
                        },
                        expression: "user.nbNombre"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-6" },
                  [
                    _c("base-input", {
                      attrs: {
                        alternative: "",
                        label: "Paterno",
                        "input-classes": "form-control-alternative",
                        capitalize: true,
                        maxlength: 50,
                        required: ""
                      },
                      model: {
                        value: _vm.user.apPaterno,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "apPaterno", $$v)
                        },
                        expression: "user.apPaterno"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-6" },
                  [
                    _c("base-input", {
                      attrs: {
                        alternative: "",
                        label: "Materno",
                        "input-classes": "form-control-alternative",
                        maxlength: 50,
                        capitalize: true
                      },
                      model: {
                        value: _vm.user.apMaterno,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "apMaterno", $$v)
                        },
                        expression: "user.apMaterno"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group has-label" },
                    [
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v(
                          "\n                Fecha Nacimiento\n                "
                        ),
                        _c("span", [_vm._v("*")])
                      ]),
                      _c("base-input-no-info", {
                        attrs: { "addon-left-icon": "ni ni-calendar-grid-58" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var focus = ref.focus
                              var blur = ref.blur
                              return _c("flat-pickr", {
                                staticClass: "form-control datepicker",
                                attrs: {
                                  config: {
                                    allowInput: true,
                                    dateFormat: "d/m/Y"
                                  },
                                  required: ""
                                },
                                on: { "on-open": focus, "on-close": blur },
                                model: {
                                  value: _vm.user.fhNacimiento,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "fhNacimiento", $$v)
                                  },
                                  expression: "user.fhNacimiento"
                                }
                              })
                            }
                          }
                        ])
                      }),
                      _vm._t("helpBlock", [
                        _vm.validDate !== "" &&
                        _vm.user.fhNacimiento !== undefined
                          ? _c(
                              "div",
                              {
                                staticClass: "text-danger invalid-feedback",
                                staticStyle: { display: "block" }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.validDate) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    2
                  )
                ])
              ])
            ]),
            _c("hr", { staticClass: "my-4" }),
            _c("h6", { staticClass: "heading-small text-muted mb-4" }, [
              _vm._v("Contacto")
            ]),
            _c("div", { staticClass: "pl-lg-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-8" },
                  [
                    _c("base-input", {
                      attrs: {
                        alternative: "",
                        label: "Correo Electrónico",
                        "input-classes": "form-control-alternative",
                        required: "",
                        maxlength: 100,
                        error: _vm.validEmail
                      },
                      model: {
                        value: _vm.user.txEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "txEmail", $$v)
                        },
                        expression: "user.txEmail"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.userId === 0
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("base-password", {
                          attrs: {
                            alternative: "",
                            label: "Contraseña",
                            placeholder: "",
                            "input-classes": "form-control-alternative",
                            required: "",
                            minlength: 4,
                            error: _vm.validPassword
                          },
                          model: {
                            value: _vm.user.password,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "password", $$v)
                            },
                            expression: "user.password"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("base-password", {
                          attrs: {
                            alternative: "",
                            label: "Confirmar Contraseña",
                            placeholder: "",
                            "input-classes": "form-control-alternative",
                            required: "",
                            minlength: 4,
                            error: _vm.validPasswordConfirm
                          },
                          model: {
                            value: _vm.user.passwordConfirm,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "passwordConfirm", $$v)
                            },
                            expression: "user.passwordConfirm"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("base-input", {
                      attrs: {
                        alternative: "",
                        label: "Celular",
                        "input-classes": "form-control-alternative",
                        required: _vm.isCellPhoneRequired,
                        maxlength: 10,
                        validation: "number",
                        error: _vm.validCellPhone
                      },
                      model: {
                        value: _vm.user.nuCelular,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "nuCelular", $$v)
                        },
                        expression: "user.nuCelular "
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("base-input", {
                      attrs: {
                        alternative: "",
                        label: "Teléfono",
                        "input-classes": "form-control-alternative",
                        maxlength: 10,
                        validation: "number",
                        error: _vm.validPhone
                      },
                      model: {
                        value: _vm.user.nuTelefono,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "nuTelefono", $$v)
                        },
                        expression: "user.nuTelefono"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ],
          _c(
            "div",
            {
              staticClass: "bg-white border-0",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-right" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "btn btn-primary text-white",
                        attrs: { disabled: _vm.$v.user.$invalid },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveUser()
                          }
                        }
                      },
                      [_vm._v("Guardar")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }