<template>
  <div></div>
</template>
<script>
import { hasEnterpriseAccess } from '../utils/generalMethods';

export default {
    data (){
      return {
      }
    },
    created:function(){
      switch (this.$store.getters.jwtRole){
        case 'UsersAdmins':
          this.$router.push('/accounts');
          break;
        case 'UsersPartners':
          this.$router.push('/my-clients');
          break;
        case 'UsersAccounts':
          if (hasEnterpriseAccess(this.$store.getters.jwtRole, this.$store.getters.jwtUserLevel)){
            this.$router.push('/enterprises');
          }else{
            this.$router.push('/branches');
          }
          break;
        case 'UsersExternals':
          this.$router.push('/my-clients');
          break;
        case 'UsersDevices':
          this.$router.push('/my-clients');
          break;
      }
    }
  }
</script>