<template >
  <div class="row justify-content-center">
     <template>
          <modal :show="showModal" >
            <div>
              <div class="text-center">
                <img :src="this.messageDialog.isCorrect ?' img/signup/success.png' : 'img/signup/error.png'" style="width: 100px; height: 100px" />
                <div class="p-3" style="color: green; font-size: large ">{{messageDialog.title}}</div>
                <div>
                  {{messageDialog.message}}
                </div>
                 <base-button type="primary" class="my-4" @click="closeModal" >Entendido</base-button>
              </div>
            </div>
          </modal>
      </template>      
    <div class="container-fluid mt--7" v-if="dataZumaLink.status">
      <div  v-if="dataZumaLink.status !== 'approved'" class="card bg-secondary shadow border-0" style="margin-bottom: 250px">
        <div style="background: #EEEEEE; padding: 10px">
          <div>
            <label class="form-control-label-title"  style="padding: 0px; margin: 0px;">
              {{dataZumaLink.amount|currency}}
            </label>
            <label v-if="dataZumaLink.amount" class="form-control-label-subtitle"  style="padding: 0px; margin: 0px; color: gray">
              &nbsp;MXN
            </label>
          </div>
          <div>
            <label class="form-control-label-subtitle" style="padding: 0px; margin: 0px">
              {{dataZumaLink.reference}}
            </label>
          </div>
        </div>
        <b-container class="pt-3" style="background: white">
          <b-row  >
            <b-col>
              <base-input
                input-classes="form-control-alternative"
                label="Número de la tarjeta:"
                v-model="entity.cardNumber "
                :error = "validateField('cardNumber ')"
                :maxlength="40"
              ></base-input>
            </b-col>
          </b-row>
          <b-row style="padding-start: 15px">
            <label class="form-control-label" style="padding: 0px; margin: 0px;" >
                  Fecha de expiración
            </label>
          </b-row>
          <b-row >
            <b-col>
              <div class="mt-3 " style="background: white" >
                <v-select 
                  v-model="entity.expMonth"
                  placeholder = 'Seleccione el mes'
                  :options="months"
                  ></v-select>
                <slot name="helpBlock">
                  <div class="text-danger invalid-feedback" style="display: block;" >
                      {{validateField('expMonth')}}
                  </div>
                </slot>
            </div>
            </b-col>
            <b-col>
              <div class="mt-3 " style="background: white" >
                <v-select 
                  v-model="entity.expYear"
                  placeholder = 'Seleccione el año'
                  :options="years"
                  ></v-select>
                <slot name="helpBlock">
                  <div class="text-danger invalid-feedback" style="display: block;" >
                      {{validateField('expYear')}}
                  </div>
                </slot>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <!-- style="display: flex; justify-content: flex-end" -->
            <b-col >
              <base-button style="width: 100%" type="warning" class="my-4" @click="processIncorrectPayment">Simular rechazo</base-button>
            </b-col>
            <b-col>
              <base-button style="width: 100%" type="primary" class="my-4" @click="processCorrectPayment">Simular pago</base-button>
            </b-col>
          </b-row>
        </b-container>
        <div style="background: #EEEEEE; padding: 10px; text-align: center;">
          <label class="form-control-label-subtitle" style="padding: 0px; margin: 0px">
            Por favor manten abierta la ventana hasta concluir
          </label>
          <br>
          <img
            src="img/brand/green.png"
            width="20%"
          />
        </div>
      </div>
      <div v-if="dataZumaLink.status ==='approved'" class="card bg-secondary shadow border-0 text-xs-center" style="margin-bottom: 250px;">
          <b-container>
            <b-row>
              <b-col class="text-center" style="padding: 150px">
                <div>
                  <div>
                    <img :src="this.messageDialog.isCorrect ?' img/signup/success.png' : 'img/signup/error.png'" style="height: 100px"/>
                  </div>
                  <div >
                    <label class="form-control-label-title" style="padding: 10px"  >
                      {{messageDialog.title}}
                    </label>
                  </div>
                </div>
                
              </b-col>
            </b-row>
          </b-container>
      </div>
    </div>
  </div>
</template>
<script>
  import { getZumaLinkInfo, processPayment } from '../utils/api';
  import "vue-select/dist/vue-select.css";
  import moment from 'moment'
  import axiosResponseMixin, { processErrorResponse }  from "../mixins/axiosResponseMixin"

  export default {
    name: 'register',
    mixins: [axiosResponseMixin],
    data() {
      return {
        code: 0,
        showErrorMessages: "",
        dataZumaLink: {
          status: "",
          reference: "",
          amount: "",
          expire: ""
        },
        entity: {
          operation: true,
          urlId:  "",
          expMonth:  "",
          expYear:  "",
          cardNumber :  "",
          code: ""
        },
        messageDialog: {
          isCorrect: false,
          title: "Este código ya fué utilizado",
          message: ""
        },
        years: [2020, 2021, 2022, 2023, 2024],
        months: [1,2,3,4,5,6,7,8,9,10,11,12],
        showModal: false
      }
    },
    methods: {
      closeModal() {
        this.showModal = false
      },
      processIncorrectPayment() {
        if (this.canContinue()) {
          this.entity.operation = false
          this.processPayments()
        }
      },
      processCorrectPayment() {
        if (this.canContinue()) {
          this.entity.operation = true
          this.processPayments()
        }
      },
      processPayments() {
        let loader = this.$loading.show({
              loader: 'dots'
        });
        let url = window.location.href
        if(url.toString().includes("localhost")){
          this.entity.code = 'http://zpdashv3.swipeapps.io/#/i/' + this.$route.params.code
        }else{
          this.entity.code = url
        }
        let yearString = this.entity.expYear.toString()
        let year = yearString.substring(yearString.length-2, yearString.length)
        this.entity.expYear = year
        this.entity.expMonth = this.entity.expMonth.toString()
        processPayment(this.entity)
          .then(resp => {
            loader.hide()
            if (resp.data.code === 200) {
              if (this.entity.operation) {
                this.dataZumaLink.status = "approved"
                this.messageDialog.isCorrect = true
                this.messageDialog.title = "Se mando la simulación del pago correctamente"
                this.messageDialog.mesasge = ""
              }else{
                this.showModal = true
                this.messageDialog.isCorrect = false
                this.messageDialog.title = "Transferencia rechazada"
                this.messageDialog.message = "Se mando la simulación del rechazo correctamente"
              }
            } else {
              processErrorResponse(resp.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            processErrorResponse(error.response.data, this.$toasted)
            this.$emit('error', error);
          })
          .finally(() => {

          }
        );
      },
      getUrlCode(){
        let url = window.location.href
        if(url.toString().includes("localhost")){
          return 'http://zpdashv3.swipeapps.io/#/i/' + this.$route.params.code
        }
        return url
      },
      getDataByCode() {
        let loader = this.$loading.show({
              loader: 'dots'
        });
        getZumaLinkInfo(this.getUrlCode())
        .then(resp => {
          loader.hide()
          if (resp.data.statusCode === 200) {
            this.dataZumaLink = resp.data.result
          } else {
            processErrorResponse(resp.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error);
        })
        .finally(() => {

        });
      },
      validateField(key) {
          if (this.showErrorMessages) {
            switch (key) {
              case 'expMonth': 
                return this.entity.expMonth === '' ? 'Es necesario seleccionar un mes' : ''
              case 'expYear': 
                return this.entity.expYear === '' ? 'Es necesario seleccionar un año' : ''
              case 'cardNumber ':
                return this.entity.cardNumber  === '' ? 'Es necesario ingresar el número de la tarjeta' : ''
              default:
                return '';
            }
          }            
      },
      canContinue() {
        this.showErrorMessages = true
        let keys = Object.keys(this.entity)
        for (let i = 0; i < keys.length; i++) {
          let canContinue = this.validateField(keys[i]);
          if (canContinue !== '')
            return false;
        }
        return true;
      }
    },
    mounted() {
      this.getDataByCode()
    },
    filters: {
      capitalize: function (value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      formateDate: function(value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
      }
    }
  }
</script>
<style>

</style>
