module.exports = {
  isStrong: (password)  => {
    if(!password) {
      return true;    //In combination with required password, otherwise it pop ups error message without interaction
    }
    const MINIMUM_LENGTH = 8;
    const ONE_UPPER_CASE_REGEXP = /[A-Z]/;
    const ONE_DIGIT_REGEXP = /[0-9]/;
    return password.length >= MINIMUM_LENGTH && ONE_UPPER_CASE_REGEXP.test(password) && ONE_DIGIT_REGEXP.test(password);
  },

  validateMail: (email)  => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  isEmptyOrNull: (data) =>{
    return data === '' || data === null || data === undefined
  },
}
