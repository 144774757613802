var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c(
                "card",
                { attrs: { shadow: "" } },
                [
                  _vm.idBranch > 0
                    ? _c("div", [
                        _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
                          _vm._v(
                            "\n                Sucursal:\n                "
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.branch.idBranch) +
                                " - " +
                                _vm._s(_vm.branch.name)
                            )
                          ])
                        ]),
                        _c("i", {
                          staticClass:
                            "ni ni-email-83 icon-size-sm align-middle"
                        }),
                        _c("span", { class: _vm.detailClass }, [
                          _vm._v(_vm._s(" ") + _vm._s(_vm.branch.contactMail))
                        ]),
                        _vm._v("\n               \n              "),
                        _c("i", {
                          staticClass:
                            "ni ni-mobile-button icon-size-sm align-middle"
                        }),
                        _c("span", { class: _vm.detailClass }, [
                          _vm._v(
                            _vm._s(" ") + _vm._s(_vm.branch.contactMobileNumber)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.idDevice > 0
                    ? _c("div", [
                        _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
                          _vm._v(
                            "\n                Dispositivo:\n                "
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.device.idUser) +
                                " - " +
                                _vm._s(_vm.device.deviceName)
                            )
                          ])
                        ]),
                        _c("i", {
                          staticClass:
                            "ni ni-email-83 icon-size-sm align-middle"
                        }),
                        _c("span", { class: _vm.detailClass }, [
                          _vm._v(_vm._s(" ") + _vm._s(_vm.device.email))
                        ]),
                        _vm._v("\n               \n              "),
                        _c("i", {
                          staticClass:
                            "ni ni-mobile-button icon-size-sm align-middle"
                        }),
                        _c("span", { class: _vm.detailClass }, [
                          _vm._v(
                            _vm._s(" ") + _vm._s(_vm.device.deviceDescription)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "tabs",
                    {
                      staticClass: "flex-column flex-md-row",
                      attrs: { fill: "", value: _vm.activeTab },
                      on: { "new-active-tab": _vm.updateState }
                    },
                    [
                      _c("tab-pane", { attrs: { title: "Empresas" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("Pagos")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xl-12" },
                          [
                            _c(
                              "card",
                              {
                                staticStyle: { "background-color": "red" },
                                attrs: { shadow: "", type: "secondary" }
                              },
                              [
                                [
                                  _c("transactions-table-v-2", {
                                    attrs: {
                                      idDevice: _vm.idDevice,
                                      idBranch: _vm.idBranch
                                    }
                                  })
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]),
                      !_vm.idBranch
                        ? _c(
                            "tab-pane",
                            { attrs: { title: "Nuevos Clientes" } },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v("Sesiones")]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-xl-12" },
                                [
                                  _c(
                                    "card",
                                    {
                                      attrs: { shadow: "", type: "secondary" }
                                    },
                                    [
                                      [
                                        _c("sessions-table", {
                                          attrs: {
                                            idDevice: _vm.idDevice,
                                            idBranch: _vm.idBranch
                                          }
                                        })
                                      ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }