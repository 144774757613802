<template>
  <div>
    <modal :show="showUserInfoModal" v-on:close="close" :bigModal="true">
      <!--div slot="header">Información General</div-->
      <card shadow type="secondary">
        <template>
          <div>
            <!-- {{sucursal.idEmpresa}} -->
            <!-- {{sucursal.idSucursal}} -->
            <!-- {{sucursal.IdBranch}} -->
            <!-- {{sucursal.idSeller}} -->
            <!-- {{sucursal.nuMcc}} -->
            <!-- {{sucursal.lastCardDigits}}            -->
            <!-- {{administradorSucursal.fhNacimiento}} -->
            <!-- {{administradorSucursal.nbRFC}}        -->
            <!-- {{mit.usuario}} -->
            <!-- {{mit.contrasena}} -->
            <!-- {{sucursal.nbDelegacionMunicipio}} -->

            <span>
              <h2>{{administradorSucursal.mobileUserId}} - {{sucursal.nbSucursal}}</h2>
            </span>

            <div class="mt-3">
              {{sucursal.nbCalle}} # {{sucursal.nuExterior}}
              <span v-if="sucursal.nuInterior">Int. {{sucursal.nuInterior}}</span>
            </div>
            <div>
              <span v-if="sucursal.nuCp">CP {{sucursal.nuCp}}</span>
              <span>Col. {{sucursal.nbColonia}}</span>
            </div>
            <div>{{sucursal.nbCiudadPoblacion}}, {{sucursal.nbEstado | capitalize}}</div>
            <div>
              <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
              ({{sucursal.nuLada}}) {{sucursal.nuTelefono}}
            </div>
            <div class="mt-4">
              <h3>{{administradorSucursal.nbNombre}} {{administradorSucursal.nbSegundoNombre}} {{administradorSucursal.apPaterno}} {{administradorSucursal.apMaterno}}</h3>
            </div>
            <div class="mt-3">
              <i class="ni ni-email-83 icon-size-sm align-middle"></i>
              {{administradorSucursal.txEmail}}
            </div>
            <div>
              <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
              {{administradorSucursal.nuCelular}}
            </div>
            <div>
              <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
              ({{administradorSucursal.nuLada}}) {{administradorSucursal.nuTelefono}}
              <span
                v-if="administradorSucursal.nuExtension"
              >Ext. {{administradorSucursal.nuExtension}}</span>
            </div>
          </div>
        </template>
        <div slot="footer" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-12 text-right">
              <base-button @click.prevent="close()" class="btn btn-primary text-white">Cerrar</base-button>
            </div>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
export default {
  name: "branch-data-info",
  props: ["selectedBranchId"],
  data() {
    return {
      showAppUser: false,
      showUserInfoModal: false,
      sucursal: {
        IdBranch: "",
        idEmpresa: "",
        idSucursal: "",
        nbSucursal: "",
        cdGiro: "",
        nbCalle: "",
        nuExterior: "",
        nuInterior: "",
        nuCp: "",
        nbColonia: "",
        nbDelegacionMunicipio: "",
        nbCiudadPoblacion: "",
        nbEstado: "",
        nuLada: "",
        nuTelefono: "",
        idSeller: "",
        nuMcc: ""
      },
      administradorSucursal: {
        nbNombre: "",
        nbSegundoNombre: "",
        apPaterno: "",
        apMaterno: "",
        fhNacimiento: "",
        nbRFC: "",
        txEmail: "",
        nuCelular: "",
        nuLada: "",
        nuTelefono: "",
        nuExtension: ""
      },
      operativas: {
        tarjetaNoPresente: {
          nuAfiliacionVMC: "",
          nuAfiliacionAMEX: "",
          canal: [],
          canalArray: []
        },
        tarjetaPresente: {
          nuAfiliacionVMC: "",
          nuAfiliacionAMEX: "",
          canal: [],
          canalArray: []
        }
      },
      prevencion: {
        imMinimo: 50.0,
        imMaximo: 50000.0,
        nuTrxTDCx24hrs: 3,
        stChip: true,
        stBanda: false,
        stTDCNacional: true,
        stTDCExtranjera: false
      },
      /* This one is used on the first branch, MIT create the first branch and this data is
           provided */
      mit: {
        usuario: "",
        contrasena: "",
        data0: "",
        aesKey: ""
      },
      mccs: [],
      cardNoPresentChanels: [
        { key: "13", value: "WebPay" },
        { key: "30", value: "Ecommerce" }
      ],
      cardPresentChanels: [
        { key: "5", value: "Ventas Móbiles" },
        { key: "9", value: "PcPay Banda" },
        { key: "32", value: "Mpos Banda" }
      ],
      branchFieldErrors: {
        idEmpresa: "",
        idSucursal: "",
        nbSucursal: "",
        cdGiro: "",
        nbCalle: "",
        nuExterior: "",
        nuInterior: "",
        nuCp: "",
        nbColonia: "",
        nbDelegacionMunicipio: "",
        nbCiudadPoblacion: "",
        nbEstado: "",
        nuLada: "",
        nuTelefono: "",
        idSeller: "",
        nuMcc: ""
      },
      loadUser: 0,
      clearUserData: false,
      loadUsersData: false,
      mitStatus: "NOT_CREATED"
    };
  },

  watch: {
    selectedBranchId: {
      async handler(newValue) {
        if (newValue && newValue > 0) {
          this.showUserInfoModal = true;
          await this.axios
            .get(`/branch/${newValue}`)
            .then(async results => {
              this.responseDataToSucursal(results.data);
              this.responseDataToMITInformation(results.data);
              this.responseDataToBranchAdministrator(results.data);
              this.responseDataToOperational(results.data);
              this.responseDataToPrevention(results.data);
              this.mitStatus = results.data.branch_mitStatus;
            })
            .catch(error => {
              this.$emit("error", error);
            })
            .finally(() => {});
        }
      }
    }
  },

  computed: {
    canalesP() {
      if (typeof this.operativas.tarjetaPresente.canal === "string") {
        return this.operativas.tarjetaPresente.canal.split(",");
      }
      return this.operativas.tarjetaPresente.canal;
    },
    canalesNP() {
      if (typeof this.operativas.tarjetaNoPresente.canal === "string") {
        return this.operativas.tarjetaNoPresente.canal.split(",");
      }
      return this.operativas.tarjetaNoPresente.canal;
    },
    userRole() {
      return this.$store.getters.jwtRole;
    }
  },

  methods: {
    responseDataToSucursal(data) {
      this.sucursal.IdBranch = data.branch_IdBranch;
      this.sucursal.idEmpresa = data.branch_IdEmpresa;
      this.sucursal.idSucursal = data.branch_idSucursal;
      this.sucursal.nbSucursal = data.branch_nbSucursal;
      this.sucursal.cdGiro = data.branch_cdGiro;
      this.sucursal.nbCalle = data.branch_nbCalle;
      this.sucursal.nuExterior = data.branch_nuExterior;
      this.sucursal.nuInterior = data.branch_nuInterior;
      this.sucursal.nuCp = data.branch_nuCp;
      this.sucursal.nbColonia = data.branch_nbColonia;
      this.sucursal.nbDelegacionMunicipio = data.branch_nbDelegacionMunicipio;
      this.sucursal.nbCiudadPoblacion = data.branch_nbCiudadPoblacion;
      this.sucursal.nbEstado = data.branch_nbEstado;
      this.sucursal.nuLada = data.branch_nuLada;
      this.sucursal.nuTelefono = data.branch_nuTelefono;
      this.sucursal.idSeller = data.branch_idSeller;
      this.sucursal.nuMcc = data.branch_nuMcc;
      this.sucursal.lastCardDigits = data.branch_lastCardDigits;
    },
    responseDataToMITInformation(data) {
      this.mit.usuario = data.userReader_User;
      this.mit.contrasena = data.userReader_Password;
    },
    responseDataToBranchAdministrator(data) {
      this.administradorSucursal.nbNombre = data.user_nbNombre;
      this.administradorSucursal.nbSegundoNombre = data.user_nbSegundoNombre;
      this.administradorSucursal.apPaterno = data.user_apPaterno;
      this.administradorSucursal.apMaterno = data.user_apMaterno;
      this.administradorSucursal.fhNacimiento = data.user_fhNacimiento;
      this.administradorSucursal.nbRFC = data.user_nbRFC;
      this.administradorSucursal.txEmail = data.user_txEmail;
      this.administradorSucursal.nuCelular = data.user_nuCelular;
      this.administradorSucursal.nuLada = data.user_nuLada;
      this.administradorSucursal.nuTelefono = data.user_nuTelefono;
      this.administradorSucursal.nuExtension = data.user_nuExtension;
      this.administradorSucursal.mobileUserId = data.user_mobileUserId;
    },
    responseDataToOperational(data) {
      this.operativas.tarjetaNoPresente.nuAfiliacionVMC =
        data.branch_nuAfiliacionVMCNP;
      this.operativas.tarjetaNoPresente.nuAfiliacionAMEX =
        data.branch_nuAfiliacionAMEXNP;
      if (data.branch_canalNP) {
        let chanels = data.branch_canalNP.replace(/\s/g, "") || "";
        this.operativas.tarjetaNoPresente.canal = chanels.split(",");
        this.operativas.tarjetaNoPresente.canalArray = chanels.split(",");
      }

      this.operativas.tarjetaPresente.nuAfiliacionVMC =
        data.branch_nuAfiliacionVMCP;
      this.operativas.tarjetaPresente.nuAfiliacionAMEX =
        data.branch_nuAfiliacionAMEXP;
      if (data.branch_canalP) {
        let chanels = data.branch_canalP.replace(/\s/g, "") || "";
        this.operativas.tarjetaPresente.canal = chanels.split(",");
        this.operativas.tarjetaPresente.canalArray = chanels.split(",");
      }
    },
    responseDataToPrevention(data) {
      this.prevencion.imMinimo = data.branch_imMinimo;
      this.prevencion.imMaximo = data.branch_imMaximo;
      this.prevencion.nuTrxTDCx24hrs = data.branch_nuTrxTDCx24hrs;
      this.prevencion.stChip = data.branch_stChip === "1";
      this.prevencion.stBanda = data.branch_stBanda === "1";
      this.prevencion.stTDCNacional = data.branch_stTDCNacional === "1";
      this.prevencion.stTDCExtranjera = data.branch_stTDCExtranjera === "1";
    },
    activateLoadUser(event) {
      this.loadUser = event.id;
    },
    close() {
      this.showUserInfoModal = false;
      this.$emit("close");
    }
  }
};
</script>
