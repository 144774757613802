<template>
  <div>
    <new-account-modal
      :showCreateAccount="showCreateAccount"
      v-on:close="closeAccountModal"
      v-on:error="handleError"
      v-on:account-created="accountCreated"
      :itemId="idAccount"
      :clean="clean"
      :type="type"
      v-on:cleaned="clean = false"
      v-on:Account-updated="accountUpdated"
    ></new-account-modal>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <card-stats v-if="isUserAccount" :period=period></card-stats>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <accounts-table
            v-on:new-item="newItem"
            v-on:error="handleError"
            v-on:update-item="prepareUpdateItem"
            v-on:table-loaded="tableLoaded"
            :updateTable="updteTable"
            :type="type">
          </accounts-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CardStats from './Dashboard/CardStats';
import handleErrorMixin from '../mixins/handleErrorMixin';
import periodSearchMixin from '../mixins/periodSearchMixin';  
import AccountsTable from './Tables/AccountsTable.vue';
import enums from '../enums/enums';
import NewAccountModal from './Modals/NewAccountModalV3.vue';
import enumRoles from '../enums/enumRoles';
  export default {
    components: {
      CardStats,
      AccountsTable,
      NewAccountModal
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        showCreateAccount: false,
        idAccount: 0,
        type: enums.ACCOUNT,
        clean: false,
        period:{},
        updteTable: false
      };
    },
    computed: {
      isUserAccount(){
        return this.$store.getters.jwtRole === enumRoles.ACCOUNT
      }
    },
    methods: {
      closeAccountModal() {
        this.showCreateAccount = false;
        this.idAccount = 0;
      },
      handleError(error) {
        this.$emit('error', error)
      },
      accountCreated() {
        this.clean = true
        this.updteTable = true
        this.showCreateAccount = false;
      },
      tableLoaded(){
        this.updteTable = false
      },
      accountUpdated() {
        this.idAccount = 0;
        this.showCreateAccount = false;
      },
      newItem() {
        this.showCreateAccount = true;
      },
      prepareUpdateItem(row) {
        this.idAccount = row.id;
        this.showCreateAccount = true;
      },
    },
    mounted() {
      this.initializePeriod();
    }
  };
</script>
<style></style>
