<template>
  <modal :show="showSendMailModal" :showClose="true" v-on:close="closeModal" loader="dots">
    <div slot="header">Contacto</div>
    <form @submit.prevent="sendMail" ref="SendMailForm">
      <div class="container-fluid">
        <div class="row mt-1">
          <div class="col-xl-12">
            <base-select label="Tipo de Solicitud" v-model="subject" :options="subjectsToShow" />
          </div>
          <div class="col-xl-12">
            <base-input label="Correo" v-model="user.email" :maxlength="64" :error="validEmail" />
          </div>
          <div class="col-xl-12">
            <base-input
              label="Teléfono Contacto"
              v-model="user.phoneNumber"
              :maxlength="10"
              validation="number"
              :error="validPhone"
            />
          </div>
        </div>
        <div class="row mt-1" v-if="!predefinedSubject">
          <div class="col-xl-12">
            <div class="form-group has-label">
              <label class="form-control-label">Comentarios</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder
                v-model="body"
                maxlength="500"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-xl-12">
            <span>
              <small>* Nuestro horario de atencion es de 8:00 a 20:00 hrs de lunes a viernes.</small>
            </span>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="row align-items-center">
          <div class="col-4 text-right"></div>
          <div class="col-4 text-right">
            <a @click="closeModal" class="link">Cancelar</a>
          </div>
          <div class="col-4 text-right">
            <base-button
              type="primary"
              class="my-4 error"
              nativeType="submit"
              :disabled="$v.$invalid"
            >Enviar</base-button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
// Import component
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { required, email, minLength } from "vuelidate/lib/validators";
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { sendTicket } from '../../utils/api';

export default {
  name: "send-mail-modal",
  props: {
    showSendMailModal: {
      type: Boolean,
      default: false
    },
    predefinedSubject: {
      type: String
    },
    support: {
      type: Boolean,
      default: true,
      description: "Which dropdown options to show, support or sales"
    }
  },
  data() {
    return {
      user: {},
      subject: "GENERAL",
      subjects: [
        { key: "GENERAL", value: "Solicitud de soporte" }
        // { key: "UPDATE_PHONE", value: "Actualizar mi número telefónico" },
        // { key: "UPDATE_ACCOUNT", value: "Actualizar mi número de cuenta" }
        //{key: 'UPDATE_EMAIL', value:'Actualizar mi correo electrónico'},
      ],
      salesSubjects: [
        { key: "SALES_INFO", value: "Información de Ventas" },
        { key: "BUY_QPOS", value: "Contratar ZUMA QPOS" },
        { key: "BUY_ZUMA_LINK", value: "Contratar ZUMA Link" }
      ],
      body: "",
      fullPage: true
    };
  },
  methods: {
    clearForm() {
      this.user = {};
      this.subject = "GENERAL";
      this.body = "";
    },
    closeModal() {
      this.clearForm();
      this.$emit("close");
    },
    sendMail() {
      let payload = {
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        body: this.body,
        subject: this.subject
      };

      if (this.predefinedSubject) {
        payload.subject = this.predefinedSubject + "\n" + payload.subject;
      }
      let loader = this.$loading.show({loader: 'dots'})
      sendTicket(payload)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show(response.data.message);
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  },
  computed: {
    validEmail() {
      if (!this.$v.user.email.email) {
        return "Formato de correo electrónico inválido";
      }
      return undefined;
    },
    validPhone() {
      if (!this.$v.user.phoneNumber.minLength) {
        return "Proporcione los 10 dígitos del teléfono";
      }
      return undefined;
    },
    subjectsToShow() {
      if (this.support) {
        return this.subjects;
      }
      return this.salesSubjects;
    }
  },
  mounted() {
    if (this.support) {
      this.subject = "GENERAL";
    } else {
      this.subject = "SALES_INFO";
    }
  },
  validations: {
    user: {
      email: { required, email },
      phoneNumber: { minLength: minLength(10) }
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  &:hover {
    cursor: pointer;
  }
}
</style>