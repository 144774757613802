var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-lg-5 col-md-7" }, [
      _c("div", { staticClass: "card bg-secondary shadow border-0" }, [
        _c("div", { staticClass: "card-body px-lg-5 py-lg-5" }, [
          _c(
            "form",
            {
              ref: "loginForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.login($event)
                }
              }
            },
            [
              _c("base-input", {
                staticClass: "input-group-alternative mb-3",
                attrs: {
                  placeholder: "Usuario",
                  "addon-left-icon": "ni ni-email-83",
                  maxlength: 64
                },
                model: {
                  value: _vm.model.username,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "username", $$v)
                  },
                  expression: "model.username"
                }
              }),
              _c("base-input", {
                staticClass: "input-group-alternative",
                attrs: {
                  placeholder: "Contraseña",
                  type: "password",
                  "addon-left-icon": "ni ni-lock-circle-open",
                  maxlength: 65
                },
                model: {
                  value: _vm.model.password,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "password", $$v)
                  },
                  expression: "model.password"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "text-center",
                  staticStyle: { color: "#CACACA" }
                },
                [
                  _vm._v(
                    "\n            F-v" +
                      _vm._s(_vm.versionApp) +
                      "-" +
                      _vm._s(_vm.env) +
                      "\n          "
                  )
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.timezone,
                    expression: "model.timezone"
                  }
                ],
                attrs: { type: "hidden" },
                domProps: { value: _vm.model.timezone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "timezone", $event.target.value)
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.offset,
                    expression: "model.offset"
                  }
                ],
                attrs: { type: "hidden" },
                domProps: { value: _vm.model.offset },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "offset", $event.target.value)
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4",
                      attrs: { type: "primary", nativeType: "submit" }
                    },
                    [_vm._v("Ingresar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-6" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }