var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { bigModal: true, show: _vm.showCreateDevice, showClose: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("assign-item-modal", {
        attrs: {
          showAssignItem: _vm.showAssignItem,
          type: _vm.type,
          isMultipleSelection: false
        },
        on: {
          close: _vm.closeAssignItem,
          selectedItems: _vm.itemsSelected,
          error: _vm.handleError
        }
      }),
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [_vm._v(_vm._s(_vm.title))])
        ])
      ]),
      _c(
        "card",
        { attrs: { shadow: "", type: "secondary" } },
        [
          [
            _c("div", { staticClass: "pl-lg-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("base-input", {
                      attrs: {
                        disabled:
                          _vm.idDevice > 0 || _vm.userRoll !== "UsersAdmins",
                        alternative: "",
                        label: "Nombre del dispositivo",
                        "input-classes": "form-control-alternative",
                        isUserName: true,
                        maxlength: 64,
                        required: "",
                        error: _vm.validateField("deviceName")
                      },
                      model: {
                        value: _vm.device.deviceName,
                        callback: function($$v) {
                          _vm.$set(_vm.device, "deviceName", $$v)
                        },
                        expression: "device.deviceName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("base-input", {
                      attrs: {
                        disabled: _vm.userRoll !== "UsersAdmins",
                        alternative: "",
                        label: "Nombre corto",
                        "input-classes": "form-control-alternative",
                        capitalize: true,
                        maxlength: 8,
                        required: "",
                        error: _vm.validateField("sequence")
                      },
                      model: {
                        value: _vm.device.sequence,
                        callback: function($$v) {
                          _vm.$set(_vm.device, "sequence", $$v)
                        },
                        expression: "device.sequence"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "pl-lg-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("base-input", {
                      attrs: {
                        disabled: _vm.userRoll !== "UsersAdmins",
                        alternative: "",
                        label: "Descripción del dispositivo",
                        "input-classes": "form-control-alternative",
                        capitalize: true,
                        maxlength: 128,
                        required: "",
                        error: _vm.validateField("deviceDescription")
                      },
                      model: {
                        value: _vm.device.deviceDescription,
                        callback: function($$v) {
                          _vm.$set(_vm.device, "deviceDescription", $$v)
                        },
                        expression: "device.deviceDescription"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("form", [
              _c("div", { staticClass: "pl-lg-4" }, [
                _vm.idDevice <= 0
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("base-password", {
                            attrs: {
                              label: "Contraseña",
                              placeholder: "",
                              error: _vm.validateField("password")
                            },
                            model: {
                              value: _vm.device.password,
                              callback: function($$v) {
                                _vm.$set(_vm.device, "password", $$v)
                              },
                              expression: "device.password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("base-password", {
                            attrs: {
                              label: "Confirmar Contraseña",
                              placeholder: "",
                              error: _vm.validateField("passwordConfirm")
                            },
                            model: {
                              value: _vm.device.passwordConfirm,
                              callback: function($$v) {
                                _vm.$set(_vm.device, "passwordConfirm", $$v)
                              },
                              expression: "device.passwordConfirm"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.idDevice <= 0
                  ? _c("div", { staticClass: "row mt-4" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("label", { staticClass: "form-control-label" }, [
                            _vm._v(
                              "\n                Asignar dispositivo a la sucursal \n              "
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.device.branchName,
                                expression: "device.branchName"
                              }
                            ],
                            staticClass: "form-control",
                            staticStyle: {
                              cursor: "pointer",
                              "background-color": "white"
                            },
                            attrs: {
                              alternative: "",
                              readonly: "true",
                              required: ""
                            },
                            domProps: { value: _vm.device.branchName },
                            on: {
                              click: function($event) {
                                _vm.showAssignItem = true
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.device,
                                  "branchName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._t("helpBlock", [
                            _c(
                              "div",
                              {
                                staticClass: "text-danger invalid-feedback",
                                staticStyle: { display: "block" }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.validateField("idBranch")) +
                                    "\n                "
                                )
                              ]
                            )
                          ])
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ],
          _c(
            "div",
            {
              staticClass: "bg-white border-0",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-right" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "my-4 ml-3 mr-3",
                        attrs: { type: "primary" },
                        on: { click: _vm.saveDevice }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.userRoll === "UsersAdmins"
                              ? "Guardar"
                              : "Cerrar"
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }