var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card shadow", staticStyle: { height: "400px" } },
    [
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              attrs: { "tbody-classes": "list", data: _vm.items },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.isMultipleSelection
                        ? _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("base-checkbox", {
                                on: {
                                  input: function($event) {
                                    return _vm.checkBoxSelected(row, $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "td",
                        {
                          class: !_vm.isMultipleSelection ? "mycursor" : "",
                          on: {
                            click: function($event) {
                              return _vm.rowSelected(row)
                            }
                          }
                        },
                        [
                          _c("div", [
                            _c("i", {
                              staticClass:
                                "ni ni-circle-08 icon-size-sm align-middle"
                            }),
                            row.name
                              ? _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(row.name))
                                ])
                              : row.fullname
                              ? _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(row.fullname))
                                ])
                              : _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(_vm.desconocido))
                                ])
                          ]),
                          _c("div", [
                            _c("i", {
                              staticClass:
                                "ni ni-email-83 icon-size-sm align-middle"
                            }),
                            row.contactMail
                              ? _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(row.contactMail))
                                ])
                              : row.email
                              ? _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(row.email))
                                ])
                              : _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(_vm.desconocido))
                                ])
                          ]),
                          row.contactEnterprise
                            ? _c("div", [
                                _c("i", {
                                  staticClass:
                                    "ni ni-building icon-size-sm align-middle"
                                }),
                                _c("span", { class: _vm.detailClass }, [
                                  _vm._v(
                                    _vm._s(" ") + _vm._s(row.contactEnterprise)
                                  )
                                ])
                              ])
                            : _vm._e(),
                          row.partnerName
                            ? _c("div", [
                                _c("i", {
                                  staticClass:
                                    "ni ni-badge icon-size-sm align-middle"
                                }),
                                _c("span", { class: _vm.detailClass }, [
                                  _vm._v(_vm._s(" ") + _vm._s(row.partnerName))
                                ])
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "columns" }, [
                _vm.isMultipleSelection
                  ? _c("th", [_vm._v("Seleccionar")])
                  : _vm._e(),
                _c("th", [_vm._v(_vm._s(_vm.getType()))])
              ])
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }