var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card shadow" }, [
    _c(
      "div",
      { staticClass: "table-responsive" },
      [
        _c(
          "base-table",
          {
            staticClass: "table align-items-center table-flush",
            attrs: { "tbody-classes": "list", data: _vm.branches },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "th",
                      { attrs: { scope: "row" } },
                      [
                        _c("base-checkbox", {
                          on: {
                            input: function($event) {
                              return _vm.branchSelect(row, $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("td", [
                      _c("div", { staticClass: "media align-items-center" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(row.nbSucursal) +
                            "\n          "
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          },
          [
            _c("template", { slot: "columns" }, [
              _c("th"),
              _c("th", [_vm._v("Negocio")])
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }