var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card shadow",
      class: _vm.type === "dark" ? "bg-default" : ""
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header border-0",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h3",
                {
                  staticClass: "mb-0",
                  class: _vm.type === "dark" ? "text-white" : ""
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            ]),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "col-xl-3",
                    staticStyle: { "{background-color": "#0A0" }
                  },
                  [
                    _c("base-input-no-info", {
                      attrs: { "addon-left-icon": "ni ni-calendar-grid-58" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var focus = ref.focus
                            var blur = ref.blur
                            return _c("flat-pickr", {
                              staticClass: "form-control datepicker",
                              attrs: {
                                config: { allowInput: true, mode: "range" }
                              },
                              on: {
                                "on-open": focus,
                                "on-close": blur,
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchCompanies($event)
                                }
                              },
                              model: {
                                value: _vm.dateRange,
                                callback: function($$v) {
                                  _vm.dateRange = $$v
                                },
                                expression: "dateRange"
                              }
                            })
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "col-xl-3 text-right",
                    staticStyle: { "{background-color": "#0A0" }
                  },
                  [
                    _c("base-input-no-info", {
                      attrs: { placeholder: "Búsqueda" },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchCompanies($event)
                        }
                      },
                      model: {
                        value: _vm.searchText,
                        callback: function($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "text-center align-self-center",
                    staticStyle: { "{background-color": "#00A", width: "80px" }
                  },
                  [
                    _c("download-button", {
                      attrs: {
                        endpoint: "/user/searchCompanies/download",
                        payload: _vm.companiesDownloadPayload,
                        reportName: "Empresas"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              class: _vm.type === "dark" ? "table-dark" : "",
              attrs: {
                "thead-classes":
                  _vm.type === "dark" ? "thead-dark" : "thead-light",
                "tbody-classes": "list",
                data: _vm.tableData
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(row.usuario_IdUsuario))
                      ]),
                      _c(
                        "td",
                        [
                          row.webUser_id
                            ? _c(
                                "router-link",
                                {
                                  attrs: { to: _vm.companyTransactionLink(row) }
                                },
                                [
                                  _c("div", { staticClass: "h4" }, [
                                    row.level === 1
                                      ? _c("i", {
                                          staticClass: "ni ni-building"
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.usuario_NombreNegocio) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "ligth-text" }, [
                            _vm._v(
                              _vm._s(row.usuario_ApellidoPaterno) +
                                " " +
                                _vm._s(row.usuario_ApellidoMaterno) +
                                " " +
                                _vm._s(row.usuario_Nombre)
                            )
                          ]),
                          _c("div", [_vm._v(_vm._s(row.usuario_Correo))]),
                          _c("div", [_vm._v(_vm._s(row.usuario_Celular))])
                        ],
                        1
                      ),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(row.webUser_createdAt))
                        )
                      ]),
                      _c("td", { staticClass: "md-screen-hide text-center" }, [
                        _vm._v(
                          _vm._s(row.vendedor_nbNombre) +
                            " " +
                            _vm._s(row.vendedor_apPaterno)
                        )
                      ]),
                      _vm._l(_vm.monthsToDisplay, function(n) {
                        return _c("td", { key: n, staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                row[_vm.monthNames[_vm.monthsToDisplay - n]] ||
                                  "0.00"
                              )
                            )
                          )
                        ])
                      }),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _vm._v("\n            \n          "),
                          row.webUser_id
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: _vm.branchesLink(row.webUser_id)
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipCompanyDetailMsg,
                                        expression: "tooltipCompanyDetailMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "ni ni-shop icon-size"
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "columns" },
                [
                  _c("th", { staticClass: "text-center" }, [_vm._v("ID")]),
                  _c("th", [_vm._v("Negocio")]),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v("Fecha alta")
                  ]),
                  _c("th", { staticClass: "md-screen-hide text-center" }, [
                    _vm._v("Vendedor")
                  ]),
                  _vm._l(_vm.monthsToDisplay, function(n) {
                    return _c("th", { key: n, staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.monthNames[_vm.monthsToDisplay - n]))
                    ])
                  }),
                  _c("th")
                ],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card-footer d-flex justify-content-end",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("base-pagination", {
            attrs: {
              total: _vm.totalElements,
              perPage: _vm.pagination.size,
              value: _vm.pagination.page
            },
            on: { input: _vm.updatePagination }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }