<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <new-branch-modal
      :showCreateAccount="showCreateAccount"
      :ownerId="Number(selectedAccountId)"
      v-on:close="closeBranchModal"
      v-on:error="handleError"
      v-on:branch-created="branchCreated"
      :itemId="Number(selectedAccountId)"
      :clean="clean"
      :type="type"
      v-on:cleaned="clean = false"
      v-on:Account-updated="accountUpdated"
    ></new-branch-modal>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-12">
          <card shadow>
            <form @submit.prevent>
              <b-tabs
                pills
                v-model="tabRootIndex"
                vertical
                content-class="mt-3"
                no-key-nav
              >
                <b-tab title="Detalles de la empresa" title-item-class="text-center">
                  <b-tabs
                    pills
                    v-model="tabIndex"
                    content-class="mt-3"
                    no-key-nav
                  >
                    <b-tab  :title-item-class="canContinue() ? 'flex-fill text-center' : 'flex-fill text-center disabledTab'" >
                      <span slot="title">Generales2</span>
                      <b-container style="width:100%" >
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Nombre de la empresa: *"
                              v-model="item.name"
                              :error = "validateField('name')"
                              :capitalize='true'
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Clave interna:"
                              v-model="item.internalKey"
                              :error = "validateField('internalKey')"
                              :capitalize='true'
                              :maxlength="16"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Nombre de contacto: *"
                              v-model="item.contactName"
                              :error = "validateField('contactName')"
                              :capitalize='true'
                              :maxlength="128"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número Telefonico: *"
                              v-model="item.contactPhoneNumber"
                              :error = "validateField('contactPhoneNumber')"
                              :maxlength="16"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Extención:"
                              v-model="item.contactPhoneExt"
                              :error = "validateField('contactPhoneExt')"
                              :maxlength="8"
                              validation="number"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Celular: *"
                              v-model="item.contactMobileNumber"
                              :error = "validateField('contactMobileNumber')"
                              :capitalize='true'
                              :maxlength="16"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Email: *"
                              v-model="item.contactMail"
                              :error = "validateField('contactMail')"
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>
                    <b-tab  :title-item-class="canContinue() ? 'flex-fill text-center' : 'flex-fill text-center disabledTab'" >
                      <span slot="title">Dirección</span>
                      <b-container>
                        <b-row class="mt-4">
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Código postal: * "
                              v-model="item.zipcode"
                              :error = "validateField('zipcode')"
                              :capitalize='true'
                              :maxlength="6"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Calle: * "
                              v-model="item.street"
                              :error = "validateField('street')"
                              :capitalize='true'
                              :maxlength="256"
                            ></base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número ext: * "
                              v-model="item.extNumber"
                              :error = "validateField('extNumber')"
                              :capitalize='true'
                              :maxlength="16"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número int: "
                              v-model="item.intNumber"
                              :error = "validateField('intNumber')"
                              :capitalize='true'
                              :maxlength="16"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Colonia: *"
                              v-model="item.suburb"
                              :error = "validateField('suburb')"
                              :capitalize='true'
                              :maxlength="256"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Municipio: *"
                              v-model="item.county"
                              :error = "validateField('county')"
                              :capitalize='true'
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Ciudad: * "
                              v-model="item.city"
                              :error = "validateField('city')"
                              :capitalize='true'
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Estado: * "
                              v-model="item.state"
                              :error = "validateField('state')"
                              :capitalize='true'
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Tipo Cliente (Marketing): * "
                              v-model="item.country"
                              :error = "validateField('country')"
                              :capitalize='true'
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>
                    <b-tab  :title-item-class="canContinue() ? 'flex-fill text-center' : 'flex-fill text-center disabledTab'" >
                      <span slot="title">Empresa</span>
                      <b-container>
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Nombre del negocio:"
                              v-model="item.businessName"
                              :error = "validateField('businessName')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                        </b-row>
                        <!-- <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo de ventas por día:"
                              v-model="item.maxByDay"
                              :error = "validateField('maxByDay')"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo de ventas por mes:"
                              v-model="item.maxByMonth"
                              :error = "validateField('maxByMonth')"
                              validation="number"
                            ></base-input>
                          </b-col>
                        </b-row> -->
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número de cuenta CLABE:"
                              v-model="item.bankTransferNumber"
                              :error = "validateField('bankTransferNumber')"
                              :maxlength="18"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="RFC:"
                              v-model="item.tin"
                              :error = "validateField('tin')"
                              :maxlength="13"
                            ></base-input>
                          </b-col>
                        </b-row>
                        <b-row>

                          <b-col>
                            <slot name="helpBlock">
                              <div>
                                  Giro de la empresa:
                              </div>
                            </slot>
                            <div class="mt-1" style="background: white" >
                              <v-select 
                                label="label"
                                :value="bussinesSelected"
                                :reduce="label => label.id"
                                v-model="idBussines"
                                placeholder = 'Seleccione una opción'
                                :options="businessactivitiesList"
                                @input = "businessSelected"
                                ></v-select>
                            </div>
                            <slot name="helpBlock">
                              <div class="text-danger invalid-feedback" style="display: block;" >
                                  {{validateField('idBusinessActivity')}}
                              </div>
                            </slot>
                          </b-col>                  
                        </b-row>
                        <b-row v-show="item.strFiscalRegimen === 'Otros'">
                          <b-col class="mt-3">
                            <base-input
                              input-classes="form-control-alternative"
                              label="Especifique el Giro:"
                              v-model="item.idBusinessActivity"
                              :error = "validateField('idBusinessActivity')"
                            ></base-input>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>
                  </b-tabs>
                  <div  class="bg-white border-0">
                    <div class="row align-items-center">
                      <div class="col-12 text-right">
                        <base-button type="primary" class="my-4 ml-3 mr-3"  @click="nextStep" >Actualizar</base-button>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Sucursales" title-item-class="text-center">
                  <branches-table-v-2
                    :idEnterprise="Number(selectedAccountId)"
                    :updateTable="updateTable"
                    :hiddeTotalsColumns="true"
                    v-on:table-loaded="tableLoaded"
                    v-on:new-item="newItem"
                    v-on:error="handleError"
                    v-on:update-item="prepareUpdateItem"
                    :type="type">
                  </branches-table-v-2>
                </b-tab>
                <!-- se debera mandar en lugar de cuentaID será EnterpriseID -->
                <b-tab title="Comisiones" title-item-class="text-center">
                   <fee-tables
                    :idEnterprise="Number(selectedAccountId)"
                  />
                </b-tab>
              </b-tabs>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import enumRoles from '../enums/enumRoles';
import { isEmptyOrNull, validateMail } from '../utils/utilMethods';
import { getBusinessActivities, getEnterpriseInfo, updatedEnterprise } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { clone, simpleCurrency } from '../utils/generalMethods';
import { catalogs } from '../utils/catalog';
import "vue-select/dist/vue-select.css";
import enums from '../enums/enums';
import BranchesTableV2 from './Tables/BranchesTableV2.vue';
import FeeTables from './Tables/FeeTablesV3.vue';
import NewBranchModal from './Modals/NewBranchModalV2.vue';

export default {
  components: { BranchesTableV2, NewBranchModal , FeeTables},
  mixins: [handleErrorMixin],
  data() {
    return {
      bussinesSelected: {label: '', id: null},
      businessactivitiesList: [{label: '', id: null}],
      idBussines: 0,
      rolAdmin: enumRoles.ADMIN,
      tabIndex: 0,
      numberTabs: 2,
      textButton: 'Siguiente',
      selectedAccountId: 0,
      placeHolder:"",
      showUserModal: false,
      showErrorMessages: false,
      catalogs: catalogs,
      item: {
        accountId: this.ownerId,
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        internalKey: '',
        maxByDay: '0',
        maxByMonth: '0',
        maxByDayNoSwipped : '0',
        maxByMonthNoSwipped : '0',
        bankData: {},
        businessName: '',
        idBusinessActivity: '',
        tin: '',
        bankTransferNumber: '',
        zipcode: ''
      },
      emptyItem: {
        accountId: this.ownerId,
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        maxByDayNoSwipped : '0',
        maxByMonthNoSwipped : '0',
        bankData: {},
        businessName: '',
        idBusinessActivity: '',
        tin: '',
        bankTransferNumber: '',
        zipcode: ''
      },
      tabRootIndex: 0,
      showCreateAccount: false,
      type: enums.BRANCH,
      clean: Boolean,
      period:{},
      updateTable: false
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.jwtRole;
    }
  },
  watch: {
  },
  methods: {
    updateActiveTab(event) {
      this.activeTab = event.title;
    },   
    currencyAmounts() {
      this.item.maxByDay = simpleCurrency(this.item.maxByDay)
      this.item.maxByMonth = simpleCurrency(this.item.maxByMonth)
    },
    previusStep() {
      this.tabIndex --;
    },
    nextStep() {
      if (!this.canContinue()) {
        return
      }
      this.saveAccount()
    },
    canContinue() {
      var keys = Object.keys(this.item)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    saveAccount() {
      let item = this.removeCurrency()
      item.maxByDayNoSwipped = 0
      item.maxByMonthNoSwipped = 0
      let loader = this.$loading.show({loader: 'dots'})
      updatedEnterprise(item)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.$toasted.show('Empresa actualizada correctamente')
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    removeCurrency() {
      let copyItem = clone(this.item)
      copyItem.maxByDay = this.removeComma(copyItem.maxByDay)
      copyItem.maxByMonth = this.removeComma(copyItem.maxByMonth)
      return copyItem
    },
    removeComma(value){
      return parseInt(value.replaceAll(',',''))
    },
    validateField(key) {
      if (this.showErrorMessages) {
        if (this.tabIndex === 0) {
          switch (key) {
            case 'name':
              return this.item.name === '' ? 'Ingresa el nombre de la empresa' : ''
            case 'contactName':
              return this.item.contactName === '' ? 'Ingresa el nombre de contacto' : ''
            case 'contactPhoneNumber':
              return this.item.contactPhoneNumber === '' 
              || this.item.contactPhoneNumber.length < 10 ? 'El número telefónico no es valido' : ''
            case 'contactMobileNumber':
              return this.item.contactMobileNumber === ''
              || this.item.contactMobileNumber.length < 10 ? 'El número de celular no es valido' : ''
            case 'contactMail':
              return !validateMail(this.item.contactMail) ? 'El número de celular no es valido' : ''
            case 'accountId':
              return this.item.accountId <= 0 ? 'Selecciona una cuenta' : ''
            case 'internalKey':
              return isEmptyOrNull(this.item.internalKey) ? 'Ingresa la clave interna' : ''
          }
        }else if (this.tabIndex === 1) {
          switch (key) {
            case 'street':
              return this.item.street === '' ? 'Ingresa la calle' : ''
            case 'extNumber':
              return this.item.extNumber === '' ? 'Ingresa el número del domicilio' : ''
            case 'city':
              return this.item.city === '' ? 'Ingresa la ciudad' : ''
            case 'state':
              return this.item.state === '' ? 'Ingresa el estado' : ''
            case 'country':
              return this.item.country === '' ? 'Ingresa el tipo Cliente' : ''
            case 'suburb':
              return this.item.suburb === '' ? 'Ingresa la colonia' : ''
            case 'county':
              return this.item.county === '' ? 'Ingresa el municipio' : ''
            case 'zipcode':
              return isEmptyOrNull(this.item.zipcode) ? 'Ingresar el código postal' : ''
          }
        }else {
          switch (key) {
            case 'maxByDay':
              return this.item.maxByDay === '' ? 'Ingresa la cantidad maxima por día' : ''
            case 'maxByMonth':
              return this.item.maxByMonth === '' ? 'Ingresa la cantidad maxima por mes' : ''
            case 'businessName':
              return this.item.businessName === '' ? 'Ingresa el nombre del negocio' : ''
            case 'idBusinessActivity':
              return isEmptyOrNull(this.item.idBusinessActivity) ? 'Selecciona un giro' : ''
            case 'tin':
              return this.item.tin === '' ? 'Ingresa el RFC' : ''
            default:
              break;
          }
        }
        return '';
      }
    }, 
    checkOtherValue() {
      if (this.item.idBusinessActivity !== 'Otros'){
        // this.item.idBusinessActivity = ''
      }          
    },
    businessSelected() {
      this.item.idBusinessActivity = this.idBussines
    },
    getDropdownValues() {
    },
    getBusinessActivity() {
      getBusinessActivities()
      .then(response => { 
        if (response.data.statusCode === 200){
          response.data.result.list.sort((a,b) => a.externalKey - b.externalKey)
          this.businessactivitiesList = []
          response.data.result.list.forEach(element => {
            if (element.idBusinessActivities === this.item.idBusinessActivity){
              this.idBussines = element.idBusinessActivities 
            } 
            this.businessactivitiesList.push({id: element.idBusinessActivities, name: `${element.externalKey} - ${element.name}`})
            this.createLabelSelectorBusiness()
          });
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    createLabelSelectorBusiness() {
      this.businessactivitiesList.map(function (x){
        return x.label = x.name;
      });
    },
    closeBranchModal() {
        this.showCreateAccount = false;
        this.branchId = 0;
      },
      handleError(error) {
        this.$emit('error', error)
      },
      branchCreated() {
        this.updateTable = true
        this.clean = true
        this.showCreateAccount = false
      },
      tableLoaded(){
        this.updateTable = false
      },
      accountUpdated() {
        this.branchId = 0;
        this.showCreateAccount = false;
      },
      newItem() {
        this.showCreateAccount = true;
      },
      prepareUpdateItem(row) {
        this.branchId = row.id;
        this.showCreateAccount = true;
      },
  },
  async mounted() {
    if (this.$route.params.id > 0) {
      this.selectedAccountId = this.$route.params.id;
      let loader = this.$loading.show({loader: 'dots'})
      getEnterpriseInfo(this.selectedAccountId)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.showErrorMessages = true
          this.item = response.data.result
          this.currencyAmounts()
          this.getBusinessActivity()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  }
};
</script>
<style>
.dropdown-input{
   background: white !important;
   min-width: 100% !important;
   height: 45px !important;
   border-radius: 8px !important;
   box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.dropdown-content {
   min-width: 100% !important;
   background: white !important;

}

.dropdown-item {
  height: 35px !important;
  font-size: .9em !important;
  color: #525f7f;
  font-weight: 600;
}

.disabledTab {
  pointer-events: none;
}

.pure-form select{
  min-height: 2.75em;
}

.nav-pills .nav-item:not(:last-child) {
    padding-right: 0 !important;
}
</style>
