var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { bigModal: true, show: _vm.showModal, showClose: true },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Accesos de la cuenta:")
      ]),
      _c(
        "div",
        [
          _c("access-selector", {
            attrs: {
              idUser: _vm.idUser,
              errorAccount: _vm.errorAccount,
              errorAccessType: _vm.errorAccessType,
              errorEnterpriseBranch: _vm.errorItems,
              clean: _vm.cleanAccessSelector
            },
            on: { "access-info-selected": _vm.updateAccessInfo }
          }),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                { staticClass: "col-12 text-right" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.saveChanges()
                        }
                      }
                    },
                    [_vm._v("Guardar")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }