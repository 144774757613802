<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <div class="row justify-content-center">
            <div v-if="!this.showLinkInfo" class="col-lg-6">
              <div class="card bg-secondary shadow border-0 " style="margin-bottom: 250px">
                <span slot="title" class="m-3" style="color: #9C1C00">CoDI</span>
                  <b-container>
                    <enterprise-branch-selector
                      v-on:branch-selected="branchSelected"
                      v-on:enterprise-selected="enterpriseSelected"
                      :errorEnterprise="validateField('idEnterprise')"
                      :errorBranch="validateField('idBranch')"
                    />
                    <b-row>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Ingresa el monto*:"
                          v-model="data.amount"
                          :error = "validateField('amount')"
                          validation="number"
                        ></base-input>
                      </b-col>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Vigencia del código(Minutos)*:"
                          v-model="data.minutes"
                          :error = "validateField('minutes')"
                          validation="number"
                        ></base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Número telefónico*:"
                          v-model="data.phoneNumber"
                          :error = "validateField('phoneNumber')"
                          @input="validate"
                          :maxlength="10"
                          validation="number"
                        ></base-input>
                      </b-col>
                      
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Referencia:"
                          v-model="data.reference"
                          :error = "validateField('reference')"
                          @input="validate"
                        ></base-input>
                      </b-col>
                    </b-row>
                  </b-container>
                  <base-button type="primary" class="my-4 ml-3 mr-3"  @click="validateAmounts" >Solicitar cobro</base-button>
              </div>
            </div>

             <div  v-if="this.showLinkInfo" class="col-lg-6">
              <div class="card bg-secondary shadow border-0 " style="margin-bottom: 250px">
                    <span class="m-3">La liga de pago fue generada exitosamente</span>
                   <b-container>
                    <b-row align-v="center">
                      <b-col cols="7">
                        <base-input
                          input-classes="form-control-alternative"
                          label="CoDi:"
                          v-model="dataUrl.link"
                          disabled = "true"
                        ></base-input>
                      </b-col>
                      <b-col cols="5" >
                        <base-button  
                          v-clipboard:copy="this.dataUrl.link" 
                          v-clipboard:success="copy" 
                          type="primary" 
                          class="my-4 ml-3 mr-3"  
                          style="width: 70%"> Copiar</base-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="7" >
                        <base-input
                          input-classes="form-control-alternative"
                          label="Email:"
                          v-model="dataUrl.mail"
                          :maxlength="64"
                          :error = "validateField('mail')"
                        ></base-input>
                      </b-col>
                      <b-col cols="5" >
                        <base-button type="primary" class="my-4 ml-3 mr-3" style="width: 70%"  @click="sendEmail" >Compartir por correo de Zuma</base-button>
                      </b-col>
                    </b-row>
                  </b-container>
                <base-button type="primary" class="my-4 ml-3 mr-3"  @click="resetData" >Solicitar nuevo cobro</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EnterpriseBranchSelector from '../components/EnterpriseBranchSelector.vue';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { generateCodi, sendLinkByEmail, validateAmount } from '../utils/api';
import { isEmptyOrNull } from '../utils/generalMethods';
import { validateMail } from '../utils/utilMethods';
 export default {
  components: { EnterpriseBranchSelector },
      name: 'link-generator', 
      data() {
        return {
          showErrorMessages: false,
          showLinkInfo: false,
          data: {
            idBranch: '',
            idEnterprise: '',
            phoneNumber: '',
            amount: '',
            reference: '',
            minutes: 60,
            paymentChannel: "ZUMA_ZPOS",
            productCode: "ZUMA_CODI",
          },
          dataUrl: {
            link: '',
            mail: '',
            idBranch: '' 
          },
          isDevelop: false
        }
      },
      methods: {
        resetData() {
          this.showErrorMessages = false;
          this.showLinkInfo = false
          this.data = {
            idBranch: '',
            idEnterprise: '',
            phoneNumber: '',
            amount: '',
            reference: '',
            minutes: 60,
            paymentChannel: "ZUMA_ZPOS",
            productCode: "ZUMA_CODI",
          }
          this.dataUrl = {
            link: '',
            mail: '',
            idBranch: ''
          }
        },
        validateAmounts(){
          this.showErrorMessages = true
          if (this.canContinue()) {
            let dto = {}
            dto.amount = Number(this.data.amount)
            dto.promotion = "1"
            dto.productCode = this.data.productCode 
            dto.idBranch = this.data.idBranch
            let loader = this.$loading.show({loader: 'dots'})
            validateAmount(dto)
            .then(response=>{
              loader.hide()
              if (response.data.statusCode === 200) {
                this.generateLink()
              }else{
                processErrorResponse(response.data, this.$toasted)
              }
            })
            .catch(error => {
              loader.hide()
              this.$emit('error', error)
            })
          }
        },
        generateLink() {
          this.showErrorMessages = true
          if (this.canContinue()) {
             let loader = this.$loading.show({
              loader: 'dots'
            });
            this.data.amount = Number(this.data.amount)
            generateCodi(this.data)
            .then((response) => {
                loader.hide()
                if (response.data.statusCode === 200) {                  
                  this.showErrorMessages = false
                  this.showLinkInfo = true
                  this.dataUrl.link = response.data.result.code
                }else {
                  processErrorResponse(response.data, this.$toasted)
                }
              })
              .catch(error => {
                loader.hide()
                this.$emit('error', error)
              })
          }
        },
        canContinue() {
          let keys = Object.keys(this.data)
          for (let i = 0; i < keys.length; i++) {
            let canContinue = this.validateField(keys[i]);
            if (canContinue !== '')
              return false;
          }
          return true;
        },
        sendEmail() {
          this.showErrorMessages = true
          if (this.validateField('mail') === '') {            
            let loader = this.$loading.show({
              loader: 'dots'
            });
            this.dataUrl.idBranch = this.data.idBranch
            sendLinkByEmail(this.dataUrl)
            .then(response=>{
              loader.hide()
              if (response.data.statusCode === 200) {
                this.$toasted.show("El email fue enviado exitosamente");
              }else{                
                processErrorResponse(response.data, this.$toasted)
              }
            })
            .catch(error => {
              loader.hide()
              this.$emit('error', error)
            })
          }
        },
        validate() {
          if(this.data.reference.length > 40) {
            this.data.reference = this.data.reference.substring(0, 40)
          }          
        },
        validateField(key) {
          if (this.showErrorMessages) {
            switch (key) {
              case 'amount': {
                let amount = this.data.amount;
                if (amount === '') {
                  return 'Ingresa un monto para continuar'
                }else{
                  // let amount = parseInt(this.data.amount);
                  // if (amount < 50 || amount > 50000) {
                  //   return 'El monto de pago debe ser mínimo $50.00 máximo $50,000.00'
                  // }else{
                  //   return ''
                  // }
                  return ""
                } 
              }
              case 'minutes':{
                let minutes = this.data.minutes;
                if (minutes === '') {
                  return 'Ingresa la valides del código'
                }else{
                  let minutes = parseInt(this.data.minutes);
                  if (minutes < 0 || minutes > 60) {
                    return 'La valides debe ser menor a 60 minutos'
                  }else{
                    this.data.minutes = Number(this.data.minutes)
                    return ''
                  }
                } 
              }
              case 'reference':
                return ''
              case 'phoneNumber':
                return isEmptyOrNull(this.data.phoneNumber) || 
                  this.data.phoneNumber.length < 10 ? 'Es número telefónico no es valido' : ''
              case 'idBranch':
                return isEmptyOrNull(this.data.idBranch) ? 'Es necesario seleccionar una sucursal' : ''
              case 'idEnterprise':
                return isEmptyOrNull(this.data.idEnterprise) ? 'Es necesario seleccionar una empresa' : ''
              case 'mail':
                return this.dataUrl.mail === '' 
                        || !validateMail(this.dataUrl.mail) ? 'El correo electrónico no es valido' : ''
              default:
                return '';
            }
          }            
        },
        copy() {
          this.$toasted.show("En link fue copiado al porta papeles");
        },
        branchSelected(value){
          this.data.idBranch = value
        },
        enterpriseSelected(value){
          this.data.idEnterprise = value
        } 
      }
  }
</script>
<style lang="stylus">

</style>