var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showUserInfo },
      on: {
        close: function($event) {
          _vm.showUserInfo = false
        }
      }
    },
    [
      _c(
        "card",
        { attrs: { shadow: "", type: "secondary" } },
        [
          [
            _c("div", [
              _c("span", [
                _c("h2", [
                  _vm._v(_vm._s(_vm.user.fullName)),
                  _c("span", { staticClass: "heading-small text-muted" }, [
                    _vm._v(" " + _vm._s(_vm.userTypes[_vm.user.role]))
                  ])
                ])
              ])
            ]),
            _vm.user.createdAt
              ? _c("div", { staticClass: "mt-4" }, [
                  _c("i", {
                    staticClass:
                      "ni ni-calendar-grid-58 icon-size-sm align-middle"
                  }),
                  _vm._v(" \n         "),
                  _c("label", { staticClass: "heading-small text-muted" }, [
                    _vm._v("Creado: ")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatDateTime")(_vm.user.createdAt)) +
                      "  \n      "
                  )
                ])
              : _vm._e(),
            _vm.user.loggedInAt
              ? _c("div", [
                  _c("i", {
                    staticClass:
                      "ni ni-calendar-grid-58 icon-size-sm align-middle"
                  }),
                  _vm._v(" \n         "),
                  _c("label", { staticClass: "heading-small text-muted" }, [
                    _vm._v("Último acceso: ")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatDateTime")(_vm.user.loggedInAt)) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _c("hr", { staticClass: "my-4" }),
            _c("h6", { staticClass: "heading-small text-muted mb-4" }, [
              _vm._v("Contacto")
            ]),
            _vm.user.mail
              ? _c("div", [
                  _c("i", {
                    staticClass: "ni ni-email-83 icon-size-sm align-middle"
                  }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.user.mail))])
                ])
              : _vm._e(),
            _vm.user.phone
              ? _c("div", [
                  _c("i", {
                    staticClass: "ni ni-mobile-button icon-size-sm align-middle"
                  }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.user.phone))])
                ])
              : _vm._e()
          ],
          _c(
            "div",
            {
              staticClass: "bg-white border-0",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-right" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "btn btn-primary text-white",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.close()
                          }
                        }
                      },
                      [_vm._v("\n                    Cerrar\n                ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }