<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-12">
          <card shadow>
            <form @submit.prevent>
              <b-tabs
                pills
                v-model="tabIndex"
                content-class="mt-3"
                no-key-nav
              >
                <!-- <b-tab title-item-class="flex-fill text-center disabledTab" >
                  <span slot="title">Productos</span>
                  <b-container style="width:100%" >
                    <b-row class="align-self-center mt-3" v-for="product in item.products" v-bind:key="product.id">
                      <b-col class="text-left  align-self-center" >
                        <h3>
                          {{product.name}}
                        </h3>
                      </b-col>
                      <b-col class="text-right align-self-center">  
                        <div>
                          <span  
                            @click.prevent="product.status = product.status === 'A' ? 'I' : 'A'" 
                            style="cursor: pointer" 
                            v-if="product.status === 'I'" 
                            class="badge badge-pill badge-danger">
                            Deshabilitado
                          </span>
                          <span  
                            @click.prevent="product.status = product.status === 'A' ? 'I' : 'A'" 
                            style="cursor: pointer" 
                            v-if="product.status === 'A'" 
                            class="badge badge-pill badge-success">
                            Habilitado
                          </span>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab> -->
                <b-tab title-item-class="flex-fill text-center disabledTab" >
                  <span slot="title">Informacion banco adquirente</span>
                  <b-container>
                    <b-row>
                      <b-col>
                        <JsonEditor
                          :options="{
                              confirmText: 'confirm',
                              cancelText: 'cancel',
                          }"
                          :objData="item.json" 
                          v-model="item.json" >
                        </JsonEditor>
                      </b-col>
                      <b-col>
                        <div class="w-2 ">
                          <div class="code-pre ">
                            <div slot="content" class="">
                              <pre>
                                <code class="json hljs" id="res_code"></code>
                              </pre>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    
                  </b-container>
                </b-tab>

              </b-tabs>
              <div slot="footer" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12 text-right">
                    <!-- <base-button type="primary" class="my-4 ml-3 mr-3"  @click="previusStep" v-if="tabIndex > 0" >Atras</base-button> -->
                    <base-button type="primary" class="my-4 ml-3 mr-3"  @click="saveDevice" >Guardar</base-button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import enumRoles from '../enums/enumRoles';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { formatJson } from '../utils/generalMethods';
import hljs from 'highlight.js'
import { getDeviceData, saveDeviceData } from '../utils/api';

export default {
  mixins: [handleErrorMixin],
  components: {
  },
  data() {
    return {
      rolAdmin: enumRoles.ADMIN,
      tabIndex: 0,
      numberTabs: 1,
      textButton: 'Siguiente',
      diviceSelected: 0,
      showUserModal: false,
      showErrorMessages: false,
      item: {
        idPaymentClient: 0,
        idUser: 0,
        json: {}
      }
    };
  },
  watch: {
    'item.json'() {
			let c = formatJson(JSON.stringify(this.item.json))
			this.drawResCode(c)
		}
  },
  methods: {
    drawResCode: function (content) {
      var target = document.getElementById('res_code');
      target.textContent = content
			hljs.highlightBlock(target)
    },
    saveDevice() {
      let loader = this.$loading.show({loader: 'dots'})
      let dto = this.item
      saveDeviceData(dto)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.$toasted.show('Información actualizada correctamente')
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
  },
  async mounted() {
    if (this.$route.params.id > 0) {
      this.diviceSelected = this.$route.params.id;
      let loader = this.$loading.show({loader: 'dots'})
      getDeviceData(this.diviceSelected)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.item = response.data.result
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  }
};
</script>
<style>
.disabledTab {
  pointer-events: none;
}

.pure-form select{
  min-height: 2.75em;
}
</style>
