<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Desglose de ventas {{currentSaler.nbNombre}} {{currentSaler.apPaterno}}</h3>
          <i class="ni ni-email-83 icon-size-sm align-middle"></i>
          <span :class="detailClass">{{" "}}{{currentSaler.txEmail}}</span>
          &nbsp;
          <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
          <span :class="detailClass">{{" "}}{{currentSaler.nuCelular}}</span>
        </div>
        <div class="col-xl-3 text-right">
          <base-input-no-info placeholder="Búsqueda" v-model="queryTerm" @keyup.enter="fetchData" />
        </div>
        <div v-if="currentSaler.role !== 'SALER'" class="col-xl-1 text-right">
          <download-button
            :endpoint="downloadLink"
            :payload="salersPayload"
            :reportName="reportName"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>BUC</th>
          <th>Empresa</th>
          <th>F. Ingreso</th>
          <th
            v-for="n in monthsToDisplay"
            :key="n"
            class="text-right"
          >{{monthNames[monthsToDisplay - n]}}</th>
        </template>

        <template slot-scope="{row}">
          <td>{{row.owner_mobileUserId}}</td>
          <td>{{row.nbSucursal}}</td>
          <td>{{row.mitCreation | formatDate}}</td>
          <td
            class="text-right"
            v-for="n in monthsToDisplay"
            :key="n"
          >{{ (row[monthNames[monthsToDisplay - n]] || 0.00) | currency}}</td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent': ''"
    >
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size "
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import periodSearchMixin from "../../mixins/periodSearchMixin";
import monthSalesMixin from "../../mixins/monthSalesMixin";
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import DownloadButton from "../Buttons/DownloadButton";

export default {
  name: "salers-resume-detail-table",
  mixins: [
    periodSearchMixin,
    monthSalesMixin,
    paginationMixin,
    infoCSSClasesMixin
  ],
  components: {
    DownloadButton
  },
  props: {
    type: {
      type: String
    },
    title: String,
    selectedUser: Number,
    refreshTable: Boolean
  },
  data() {
    return {
      tableData: [],
      currentSaler: {},

      queryTerm: "",
      salesPayLoad: {}
    };
  },
  computed: {
    salersPayload() {
      return {
        period: this.period
      };
    },
    downloadLink() {
      return `/sales/detail/${this.currentSaler.id}/download`;
    },
    reportName() {
      return `Reporte ${this.currentSaler.nbNombre} ${this.currentSaler.apPaterno}`;
    }
  },
  methods: {
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchData();
    },
    async fetchData() {
      this.error = this.tableData = null;
      this.loading = true;
      if (this.$route.params.id) {
        const response = await this.axios.get(`/user/${this.$route.params.id}`);
        this.currentSaler = response.data;
      } else {
        const response = await this.axios.get(`/user/me`);
        this.currentSaler = response.data;
      }

      this.salesPayLoad = {
        period: this.period,
        pagination: this.pagination,
        queryTerm: this.queryTerm
      };

      this.axios
        .post(`/sales/detail/${this.currentSaler.id}`, this.salesPayLoad)
        .then(result => {
          this.tableData = result.data.companies; //  IMPORTANT
          this.pagination = result.data.pagination || this.defaultPagination;
          this.totalElements = parseInt(result.data.total);
          this.$emit("period-changed", this.period);
        })
        .catch(error => {
          this.$emit("error", error);
        });
    }
  },
  mounted() {
    this.initializeMonthData();
    this.initializeYearArray();
    this.initializePeriod();
    this.fetchData();
    if (this.$route.params.month) {
      this.period.month = this.$route.params.month;
    }
    if (this.$route.params.year) {
      this.period.year = this.$route.params.year;
    }
  }
};
</script>