var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("assign-user-modal", {
        attrs: {
          showAssignClient: _vm.showAssignClient,
          salerSelected: _vm.salerSelected
        },
        on: {
          close: function($event) {
            _vm.showAssignClient = false
          },
          error: _vm.handleError
        }
      }),
      _c("new-user-modal", {
        attrs: {
          showCreateUser: _vm.showCreateUser,
          userTypes: _vm.userTypes,
          isCellPhoneRequired: this.activeTab === "Vendedores",
          userId: _vm.userId,
          clean: _vm.clean
        },
        on: {
          close: _vm.closeUserModal,
          error: _vm.handleError,
          "user-created": _vm.updateNewUser,
          cleaned: function($event) {
            _vm.clean = false
          },
          "user-updated": _vm.userUpdated
        }
      }),
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c(
                "card",
                { attrs: { shadow: "" } },
                [
                  _c(
                    "tabs",
                    {
                      staticClass: "flex-column flex-md-row",
                      attrs: { fill: "", value: _vm.activeTab },
                      on: { "new-active-tab": _vm.changeTab }
                    },
                    [
                      _c("tab-pane", { attrs: { title: "Vendedores" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("Vendedores")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xl-12" },
                          [
                            _c(
                              "card",
                              { attrs: { shadow: "", type: "secondary" } },
                              [
                                [
                                  _c("users-table", {
                                    attrs: {
                                      title: "Vendedores",
                                      newUser: _vm.newSaler,
                                      salers: true
                                    },
                                    on: {
                                      "show-clients": _vm.showClients,
                                      "assign-client": _vm.assignClients,
                                      "new-user": function($event) {
                                        _vm.showCreateUser = true
                                      },
                                      "update-table-completed": function(
                                        $event
                                      ) {
                                        _vm.newSaler = {}
                                      },
                                      error: _vm.handleError,
                                      "update-user": _vm.prepareUpdateUser
                                    }
                                  })
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]),
                      _c("tab-pane", { attrs: { title: "Administradores" } }, [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("Administradores")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xl-12" },
                          [
                            _c(
                              "card",
                              { attrs: { shadow: "", type: "secondary" } },
                              [
                                [
                                  _c("users-table", {
                                    attrs: {
                                      title: "Administradores",
                                      newUser: _vm.newUser,
                                      admins: true
                                    },
                                    on: {
                                      "show-clients": _vm.showClients,
                                      "assign-client": _vm.assignClients,
                                      "new-user": function($event) {
                                        _vm.showCreateUser = true
                                      },
                                      "update-table-completed": function(
                                        $event
                                      ) {
                                        _vm.newUser = {}
                                      },
                                      error: _vm.handleError,
                                      "update-user": _vm.prepareUpdateUser
                                    }
                                  })
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }