var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card shadow",
      class: _vm.type === "dark" ? "bg-default" : ""
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header border-0",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("div", { staticClass: "row align-items-center" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "text-center align-self-center",
                staticStyle: { "{background-color": "#00A", width: "80px" }
              },
              [
                _c("download-button", {
                  attrs: {
                    endpoint: "/sales/salers/download",
                    payload: _vm.salersPayload,
                    reportName: "Vendedores"
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              class: _vm.type === "dark" ? "table-dark" : "",
              attrs: {
                "thead-classes":
                  _vm.type === "dark" ? "thead-dark" : "thead-light",
                "tbody-classes": "list",
                data: _vm.tableData
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "h4" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.sellerSalesLink(row, _vm.period)
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(row.saler_nbNombre) +
                                    " " +
                                    _vm._s(row.saler_apPaterno) +
                                    " " +
                                    _vm._s(row.saler_apMaterno)
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(row.saler_txEmail))]),
                        _c("div", [_vm._v(_vm._s(row.saler_nuCelular))])
                      ]),
                      _vm._l(_vm.monthsToDisplay, function(n) {
                        return _c("td", { key: n, staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                row[_vm.monthNames[_vm.monthsToDisplay - n]] ||
                                  "0.00"
                              )
                            )
                          )
                        ])
                      }),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.sellerSalesLink(row, _vm.period)
                              }
                            },
                            [_vm._v("Detalle")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "columns" },
                [
                  _c("th", [_vm._v("Vendedor")]),
                  _vm._l(_vm.monthsToDisplay, function(n) {
                    return _c("th", { key: n, staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.monthNames[_vm.monthsToDisplay - n]))
                    ])
                  }),
                  _c("th")
                ],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card-footer d-flex justify-content-end",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("base-pagination", {
            attrs: {
              total: _vm.totalElements,
              perPage: _vm.pagination.size,
              value: _vm.pagination.page
            },
            on: { input: _vm.updatePagination }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-11" }, [
      _c("h3", { staticClass: "mb-0" }, [_vm._v("Ventas por Comisionista")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }