<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <update-user-password-modal
      :selectedUserId="selectedUserId"
      :subtitle="subtitle"
      :showChangePassword="showChangePassword"
      v-on:close="closeChangePassword"
    ></update-user-password-modal>
    <user-info-modal :userId="userId" v-on:close="userId = 0"></user-info-modal>

    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">{{title}}</h3>
        </div>
        <div class="col-xl-3 text-right">
          <base-input-no-info placeholder="Búsqueda" v-model="queryTerm" @keyup.enter="fetchData" />
        </div>
        <div class="text-center align-self-center" style="{background-color: #00A; width: 80px;}">
          <download-button
            :endpoint="downloadEndpoint"
            :payload="usersDownloadPayload"
            :reportName="reportName"
          />
        </div>
        <div class="text-right">
          <a @click="$emit('new-user')" class="btn btn-primary text-white">Nuevo</a>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
        tbody-classes="list"
        :data="users"
      >
        <template slot="columns">
          <th>Usuario</th>
          <th>Correo</th>
          <th>Telefono</th>
          <th>Tipo Usuario</th>
          <th>Fecha Alta</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <span v-if="row.enabled" class="badge badge-pill badge-success">&nbsp;</span>
              <span v-else class="badge badge-pill badge-danger">&nbsp;</span>
              &nbsp;
              <a
                @click="selectUser(row)"
                style="cursor: pointer;"
              >{{row.nbNombre}} {{row.apPaterno}} {{row.apMaterno}}</a>
            </div>
          </th>
          <td class="budget">{{row.txEmail}}</td>
          <td>{{row.nuCelular}}</td>
          <td>{{userTypeDescription(row)}}</td>
          <td>{{row.createdAt | formatDate}}</td>
          <td>
            <a @click.prevent="toggleUser(row)" v-if="!!row.enabled">
              <i
                v-tooltip.top-center="tooltipDisablehMsg"
                class="fas fa-exclamation-triangle icon-size"
              ></i>
            </a>
            <a @click.prevent="toggleUser(row)" v-else>
              <i v-tooltip.top-center="tooltipEnableMsg" class="ni ni-check-bold icon-size"></i>
            </a>
            &nbsp;
            <a
              v-if="showAddClientButton(row)"
              @click.prevent="$emit('assign-client', row)"
            >
              <i v-tooltip.top-center="tooltipAddClientMsg" class="fas fa-plus icon-size"></i>
              &nbsp;
            </a>
            <a
              v-if="!row.configuracionesLectorUsuario_IdConfiguraciones"
              @click.prevent="prepareChangePassword(row)"
            >
              <i v-tooltip.top-center="tooltipChangePassword" class="fas fa-unlock-alt icon-size"></i>
            </a>
            &nbsp;
            <a
              v-if="!row.configuracionesLectorUsuario_IdConfiguraciones"
              @click.prevent="prepareUpdateUser(row)"
            >
              <i v-tooltip.top-center="tooltipUpdateUser" class="fas fa-edit icon-size"></i>
            </a>
          </td>
        </template>
      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end dark">
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import UpdateUserPasswordModal from "../Modals/UpdateUserPasswordModal";
import UserInfoModal from "../Modals/UserInfoModal";
import DownloadButton from "../Buttons/DownloadButton";
import paginationMixin from "../../mixins/paginationMixin";

export default {
  name: "users-table",
  mixins: [paginationMixin],
  components: {
    UpdateUserPasswordModal,
    DownloadButton,
    UserInfoModal
  },
  props: {
    type: {
      type: String
    },
    title: String,
    newUser: {
      type: Object,
      description:
        "When a new user is created this field holds the new data, used to update the table"
    },
    salers: {
      type: Boolean,
      default: false
    },
    admins: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      users: [],
      userTypes: [
        { key: "ADMIN", value: "Administrador" },
        { key: "ADMIN_USER", value: "Administrador Negocio" },
        { key: "SALER", value: "Vendedor" },
        { key: "SUPER_ADMIN", value: "S. Administrador" }
      ],
      tooltipDisablehMsg: "Deshabilitar",
      tooltipEnableMsg: "Habilitar",
      tooltipAddClientMsg: "Agregar cliente",
      tooltipChangePassword: "Cambiar contraseña",
      tooltipUpdateUser: "Actualizar usuario",
      selectedUserId: 0,
      subtitle: "",
      showChangePassword: false,
      queryTerm: "",

      downloadEndpoint: "/user/adminusers/download",
      usersDownloadPayload: {},
      userId: 0
    };
  },
  watch: {
    newUser: {
      handler: function(newRow) {
        if (newRow.id) {
          let index = this.users.findIndex(e => e.id === newRow.id);

          if (index < 0) {
            index = this.users.length;
          }

          this.$set(this.users, index, newRow);
          this.$emit("update-table-completed");
        }
      },
      deep: true
    }
  },
  computed: {
    reportName() {
      if (this.salers) {
        return "Vendedores";
      }
      return "Administradores";
    },
    userRole() {
      return this.$store.state.user.userInfo.role;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    userTypeDescription(row) {
      const findType = this.userTypes.find(type => row.role === type.key);
      if (findType) {
        return findType.value;
      }
      return "";
    },
    toggleUser(row) {
      this.axios
        .put(`/user/${row.id}/toggle`)
        .then(result => {
          const objIdx = this.users.findIndex(
            user => user.id === result.data.id
          );
          this.$set(this.users, objIdx, result.data);
          this.$toasted.show("Usuario actualizado");
        })
        .catch(error => {
          this.$emit("error", error);
        });
    },
    prepareChangePassword(row) {
      this.selectedUserId = row.id;
      this.subtitle = `${row.nbNombre || ""} ${row.apPaterno ||
        ""} ${row.apMaterno || ""}`;
      this.showChangePassword = true;
    },
    closeChangePassword() {
      this.selectedUserId = 0;
      this.showChangePassword = false;
    },
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchData();
    },
    fetchData() {
      this.usersDownloadPayload = {
        pagination: this.pagination,
        queryTerm: this.queryTerm
      };

      if (this.admins) {
        this.usersDownloadPayload["roles"] = ["ADMIN", "SUPER_ADMIN"];
      }

      if (this.salers) {
        this.usersDownloadPayload["roles"] = ["SALER"];
      }

      this.axios
        .post("/user/adminusers", this.usersDownloadPayload)
        .then(result => {
          this.users = result.data.users;
          this.pagination = result.data.pagination;
          this.totalElements = parseInt(result.data.total);

          this.users.sort(function(a, b) {
            return b.enabled - a.enabled;
          });

          this.$emit("loaded-users");
        })
        .catch(error => {
          this.$emit("error", error);
        });
    },
    prepareUpdateUser(row) {
      this.$emit("update-user", row);
    },
    selectUser(row) {
      this.userId = row.id;
    },
    showAddClientButton(row) {
      return (
        !row.configuracionesLectorUsuario_IdConfiguraciones &&
        row.role === "SALER" &&
        this.userRole !== "ADMIN"
      );
    }
  }
};
</script>
<style lang="scss">
.parentBranch {
  background-color: cornsilk;
}
.icon-size {
  font-size: 1.2rem;
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>

