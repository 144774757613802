var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col-xl-8" }, [
          _c("h3", { staticClass: "mb-0" }, [
            _vm._v("\n          Operaciones\n          "),
            _vm.branch.user_mobileUserId
              ? _c("span", [
                  _vm._v(
                    ": " +
                      _vm._s(_vm.branch.user_mobileUserId) +
                      " - " +
                      _vm._s(_vm.branch.branch_nbSucursal)
                  )
                ])
              : _vm._e()
          ]),
          _c("i", { staticClass: "ni ni-email-83 icon-size-sm align-middle" }),
          _c("span", { class: _vm.detailClass }, [
            _vm._v(_vm._s(" ") + _vm._s(_vm.branch.user_txEmail))
          ]),
          _vm._v("\n         \n        "),
          _c("i", {
            staticClass: "ni ni-mobile-button icon-size-sm align-middle"
          }),
          _c("span", { class: _vm.detailClass }, [
            _vm._v(_vm._s(" ") + _vm._s(_vm.branch.user_nuCelular))
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-xl-3" },
          [
            _c("base-input-no-info", {
              attrs: { "addon-left-icon": "ni ni-calendar-grid-58" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var focus = ref.focus
                    var blur = ref.blur
                    return _c("flat-pickr", {
                      staticClass: "form-control datepicker",
                      attrs: { config: { allowInput: true, mode: "range" } },
                      on: { "on-open": focus, "on-close": blur },
                      model: {
                        value: _vm.dateRange,
                        callback: function($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange"
                      }
                    })
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "text-center align-self-center",
            staticStyle: { "{background-color": "#00A", width: "80px" }
          },
          [
            _c("download-button", {
              attrs: {
                endpoint: _vm.downloadEndpoint,
                payload: _vm.transactionsDownloadPayload,
                reportName: "Transacciones"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "card-body border-0" }, [
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              attrs: { "thead-classes": "thead-light", data: _vm.tableData },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.IdUsuario) +
                            "\n            "
                        ),
                        _c("tr"),
                        _vm._v(
                          "\n            " +
                            _vm._s(row.nbSucursal) +
                            "\n          "
                        )
                      ]),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("formatDate")(row.Fecha)) +
                            "\n            "
                        ),
                        _c("tr"),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("formatTime")(row.Fecha)) +
                            "\n          "
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(row.NoOperacion))]),
                      _c("td", [_vm._v(_vm._s(row.NoAutorizacion))]),
                      _c("td", [_vm._v(_vm._s(row.NomTarjeta))]),
                      _c("td", [_vm._v("**" + _vm._s(row.NoTarjeta))]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm._f("currency")(row.Monto)))
                      ]),
                      _vm.detail
                        ? _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm._f("currency")(row.Monto * 0.035))
                            )
                          ])
                        : _vm._e(),
                      _vm.detail
                        ? _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(row.Monto * 0.035 * 0.16)
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.detail
                        ? _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  row.Monto -
                                    row.Monto * 0.035 -
                                    row.Monto * 0.035 * 0.16
                                )
                              )
                            )
                          ])
                        : _vm._e(),
                      _c("td", [
                        row.EstatusVenta === "cancelado"
                          ? _c("span", [_vm._v("Cancelada")])
                          : _vm._e(),
                        row.EstatusVenta === "returned"
                          ? _c("span", [_vm._v("Devolución")])
                          : _vm._e(),
                        row.EstatusVenta === "approved"
                          ? _c("span", [_vm._v("Aprobada")])
                          : _vm._e(),
                        row.EstatusVenta === "rejected"
                          ? _c("span", [_vm._v("Rechazada")])
                          : _vm._e()
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "columns" }, [
                _c("th", [_vm._v("Sucursal")]),
                _c("th", [_vm._v("Fecha")]),
                _c("th", [_vm._v("Num. Op.")]),
                _c("th", [_vm._v("Num. Aut.")]),
                _c("th", [_vm._v("Tarjetahabiente")]),
                _c("th", [_vm._v("Tarjeta")]),
                _c("th", [_vm._v("Monto")]),
                _vm.detail ? _c("th", [_vm._v("Comisión")]) : _vm._e(),
                _vm.detail ? _c("th", [_vm._v("IVA")]) : _vm._e(),
                _vm.detail ? _c("th", [_vm._v("Depósito")]) : _vm._e(),
                _c("th", [_vm._v("Estado")])
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "card-footer d-flex justify-content-end dark" },
      [
        _c("base-pagination", {
          attrs: {
            total: _vm.totalElements,
            perPage: _vm.pagination.size,
            value: _vm.pagination.page
          },
          on: { input: _vm.updatePagination }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }