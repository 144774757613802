<template>
  <modal
  :show="showDialogAutoCompleate"
  
  v-on:close="showDialogAutoCompleate = false"
  >
    <card shadow type="secondary">
      <template>
        ¿Deseas autocompletar la información?
      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <button type="button" @click="close()" class="btn btn-secondary">Cerrar</button>
            <button type="button" @click="accept()" class="btn btn-primary">Aceptar</button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
  export default {
    name: "auto-compleate-info",
    props: {
      showDialogAutoCompleate: Boolean,
      idElement: Number
    },
    data() {
      return {
      }
    },
    methods: {
      close(){
        this.$emit('close');
      },
      accept(){
        this.$emit('accept', this.idElement);
      }
    },
  }
</script>