<template>
    <a 
      style="cursor: pointer"
      v-tooltip.top-center="'Descargar reporte'"
      @click="downloadReport"><i class="ni ni-cloud-download-95 download-icon-size"></i></a>
</template>
<script>
import moment from 'moment';
export default {
  name: 'download-button',
  props: {
    endpoint: {
      type: String,
      description: 'The endpoint from where we are downloading the excel file',
      required: true,
    },
    payload: {
      type: Object,
      description: 'The payload sendend to endpoint to download data',
      required: true,
    },
    reportName: {
      type: String,
      description: 'The name of the report whitout the extention',
      required: true,
    },
    appendDateTime: {
      type: Boolean,
      description: "Tell the component if we want de date and time in the download name",
      default: true,
    }
  },
  methods: {
    async downloadReport() {
      let loader = this.$loading.show({loader: 'dots'})
      this.error = null;
      this.loading = true;
      // let period = this.period;
      let that = this;
      await this.axios({
        method: 'post',
        url: this.endpoint,
        timeout: 1800000,
        responseType: 'arraybuffer',
        data: this.payload,
      })
      .then(function(response) {
        loader.hide()
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${that.reportName}.xlsx`;
        if(that.appendDateTime){
          link.download = `${that.reportName}_${moment().format("MMMDDYY") }.xlsx`;
        }
        link.click();
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      });
    },
  }
}
</script>
<style>
 .download-icon-size {
    font-size: 1.6rem;
  }
</style>