<template>
<!-- <div>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-12">
          <card shadow>
          </card>
        </div>
      </div>
    </div>
  </div> -->
  <div class="card container-fluid mt--4 w-75">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col mt-2 align-self-center">
          <div>
           <h3 h3 class="mb-0">
              Totales del turno
           </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-12">
      <card shadow v-for="(card, index) in tableData" :key="card.brand+card.title" :class=" index === 0 ? '' : 'mt-5'">
        <b-container>
          <b-row>
            <b-col>
              <img v-if="card.brand" :src="getImageBrand(card.brand)" style="width: 50px; height: 50px"/>
            </b-col>
            <b-col class="align-self-center text-center">
              <h3 style="margin-bottom: 0px !important">
                {{card.title}}
              </h3>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
        <b-container class="mt-2">
          <b-row 
            v-for="(item, index) in card.info" :key="item.name" 
            :style="[index%2 === 0 ? {'background': '#F7F7F7'} : {'background': 'white'}]"
            class="p-1"
            >
            <b-col>
              <span :class="item.name === 'TOTAL' ? 'h3' : ''">{{item.name}}</span>
            </b-col>
            <b-col class="text-center">
              <span :class="item.name === 'TOTAL' ? 'h3' : ''">{{item.qty}}</span>
            </b-col>
            <b-col class="text-right">
              <span :class="item.name === 'TOTAL' ? 'h3' : ''">{{item.amount}}</span>
            </b-col>
          </b-row>
        </b-container>
      </card>
    </div>
  </div>
</template>
<script>
import Card from '../../components/Card.vue';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { getTotalSession } from '../../utils/api';
import enumCardBrand from '../../enums/enumCardBrand'

export default {
  components: { Card },
  name: "total-session-table",
  props: {
    idSession: Number,
    idUser: Number
  },
  data() {
    return {
      tableData: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let loader = this.$loading.show({loader: 'dots'})
      getTotalSession(this.idSession, this.idUser)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.tableData = response.data.result.sessionTotalsDetails
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    getImageBrand(brand){
      switch(brand.toString().toLowerCase()) {
        case enumCardBrand.AMEX.toLowerCase():
        return enumCardBrand.AMEX_IMAGE
        case enumCardBrand.MASTER.toLowerCase():
        case enumCardBrand.MASTERCARD.toLowerCase():
        return enumCardBrand.MASTER_IMAGE
        case enumCardBrand.VISA.toLowerCase():
        return enumCardBrand.VISA_IMAGE
        case enumCardBrand.DISCOVER.toLowerCase():
        return enumCardBrand.DISCOVER_IMAGE
      }
      return enumCardBrand.NOTDEFINED_IMAGE
    }
  },
  mounted() {}
};
</script>
<style>
.gray {
  background: gray;
}
.white {
  background: gray;
}
</style>