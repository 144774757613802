<template>
  <modal :bigModal="true" :show="showCreateClient" :showClose="true" v-on:close="$emit('close')">
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="type"
      :isMultipleSelection="false"
      :titleD="'Selecciona una cuenta'"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="itemsSelected"
      v-on:error="handleError"
    ></assign-item-modal>
    <div slot="header">
      <div class="container-fluid">
        <div class="row">{{title}}</div>
      </div>
    </div>
    <card shadow type="secondary">
      <template>
        <b-container>
          <b-row>
            <b-col>
              <base-input
                alternative
                label="Nombre completo"
                input-classes="form-control-alternative"
                v-model="client.fullname"
                :capitalize='true'
                :maxlength="128"
                required
              :error="validateField('fullname')"/>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <base-input
                alternative
                label="Nombre de usuario"
                input-classes="form-control-alternative"
                v-model="client.username"
                :maxlength="64"
                :isUserName='true'
                required
                :disabled="idUser > 0"
                :error="validateField('username')"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="type !== 'external'">
              <base-input
                input-classes="form-control-alternative"
                label="Teléfono: "
                v-model="client.contactPhoneNumber"
                :capitalize='true'
                :maxlength="10"
                validation="number"
              ></base-input>
            </b-col>
            <b-col>
              <base-input
                alternative
                label="Correo Electrónico"
                input-classes="form-control-alternative"
                v-model="client.email"
                required
                :maxlength="64"
                :error="validateField('email')"
              />
            </b-col>
          </b-row>

          <b-row v-if="idUser === 0">
            <b-col>
              <base-password
                label="Contraseña"
                placeholder
                v-model="client.password"
                :error="validateField('password')"
              />
            </b-col>
            <b-col>
              <base-password
                label="Confirmar Contraseña"
                placeholder
                v-model="client.passwordConfirm"
                :error="validateField('passwordConfirm')"
              />
            </b-col>
          </b-row>

          <b-row v-if="getVisibilityUserType()">
            <b-col>
              <label class="form-control-label">
                Grupo de usuario
              </label>
              <v-select
                v-model="client.userGroup"
                placeholder = 'Selecciona una opción'
                :options="userGroups"
                :reduce="userGroup => userGroup.idUserGroup"
                label = "userGroupDescription"
                required
              ></v-select>
              <slot name="helpBlock">
                <div class="text-danger invalid-feedback" style="display: block;" >
                    {{validateField('userGroup')}}
                </div>
              </slot>
            </b-col>
          </b-row>

          <b-row v-if="getVisibilitySearchItem()">
            <b-col>
              <div>
                <label class="form-control-label">
                  {{titleSearch}}
                </label>
                <input
                  alternative
                  @click="showAssignItem = true" 
                  style="cursor: pointer; background-color: white" 
                  class="form-control" 
                  v-model="client.owner"
                  readonly="true"
                  required>
                <slot name="helpBlock">
                  <div class="text-danger invalid-feedback" style="display: block;" >
                      {{validateField('owner')}}
                  </div>
                </slot>
              </div>
            </b-col>
          </b-row>
          
          <access-selector
            v-if="type === 'client-account' && idUser <= 0"
            :clean='clearAccessSelector'
            :errorAccount="validateField('owner')"
            :errorAccessType="validateField('userlLevel')"
            :errorEnterpriseBranch="validateField('idEnterprise') + validateField('idBranch')"
            v-on:access-info-selected="updateAccessInfo"
            ></access-selector>
        </b-container>
      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="saveClient" >Guardar</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import { createClient, getClient, getUserGroups, updateClient } from '../../utils/api';
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import enums from '../../enums/enums';
import AssignItemModal from './AssignItemModal.vue';
import { isEmptyOrNull, validateMail } from '../../utils/utilMethods';
import { validatePassword } from '../../utils/generalMethods';
import enumsAccessType from '../../enums/enumsAccessType';
import AccessSelector from '../../components/AccessSelector.vue';

export default {
  name: "new-client-modal",
  components: {
    AssignItemModal,
    AccessSelector
  },
  mixins: {axiosResponseMixin},
  props: {
    type: String,
    showCreateClient: {
      type: Boolean
    },
    isCellPhoneRequired: {
      type: Boolean
    },
    idUser: {},
    clean: {}
  },
  data() {
    return {
      searchTerm: '',
      showErrorMessages: false,
      showAssignItem: false,
      client: {
        fullname: '',
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        userGroup: '',
        owner: '',
        idEnterprise: [],
        idBranch: [],
        userLevel: ''
      },
      defaultClient: {
        name: '',
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        userGroup: '',
        owner: '',
        idEnterprise: [],
        idBranch: [],
        userLevel: ''
      },
      selectedItems:{},
      title: "Nuevo usuario",
      titleSearch: '',
      userGroups: [
      ],
      clearAccessSelector: false
    };
  },
  methods: {
    getVisibilityUserType(){
      if (this.idUser !== 0) {
        return false
      }
      switch (this.type) {
        case enums.C_ACCOUNT:
          return true;
        case enums.ADMIN:
          return true;
        case enums.EXTERNAL:
          return false;
        case enums.DEVICE:
          return false;
        case enums.PARTNER:
          return false;
        default:
          return false;
      }
    },
    getVisibilitySearchItem(){
      switch (this.type) {
        case enums.C_ACCOUNT:
          return false;
        case enums.ADMIN:
          return false;
        case enums.EXTERNAL:
           if (this.idUser !== 0) {
            return false
          } 
          this.titleSearch = 'Asignar cliente a la sucursal :'
          return true;
        case enums.DEVICE:
           if (this.idUser !== 0) {
            return false
          } 
          this.titleSearch = 'Asignar cliente a la sucursal :'
          return true;
        case enums.PARTNER:
          return false;
        default:
          return false;
      }
    },
    isValid(){
      return this.$v.client.$invalid
    },
    handleError(error) {
      this.$emit('error', error)
    },
    itemsSelected(event) {
      this.getFocus()
      this.selectedItems = event;
      if (this.selectedItems.length > 0){
        this.client.owner = this.selectedItems[0].fullName
        this.setOwnerID(this.selectedItems[0].id)
      }
      this.showAssignItem = false
    },
    setOwnerID(id) {
      switch (this.type) {
        case enums.C_ACCOUNT:
          this.client.idAccount = id
          break
        case enums.EXTERNAL:
        case enums.PARTNER:
          this.client.idBranch = [id]
          break
      }
    },
    selectClient() {

    },
    showMessageUserCreated(){
      let message = ''
      switch (this.type) {
        case enums.C_ACCOUNT:
          message = 'Cliente creado correctamente'
          break;
        case enums.EXTERNAL:
          message = 'Cliente creado correctamente'
          break;
        case enums.PARTNER:
          message = 'Vendedor creado correctamente'
          break;
        case enums.DEVICE:
          message = 'Dispositivo creado correctamente'
          break;
        case enums.ADMIN:
          message = 'Usuario creado correctamente'
          break;
        default:
          break;
      }
      this.$toasted.show(message);

    },
    saveClient() {
      this.showErrorMessages = true
      if (this.canContinue()){
        let loader = this.$loading.show({loader: 'dots'});
        if (this.idUser === 0) {
          createClient(this.type, this.client)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.showMessageUserCreated()
              this.$emit('client-created')
            }else {
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        }else{
          updateClient(this.type, this.client)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.$toasted.show("Cliente actualizado correctamente");
              this.$emit('client-updated', this.client)
            }else {
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        }
      }
      
    },
    canContinue() {
      var keys = Object.keys(this.client)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    validateField(key) {
      if (this.showErrorMessages) {
        switch (key) {
          case 'fullname': 
            return isEmptyOrNull(this.client.fullname) ? 'Es necesario ingresar el nombre' : ''
          case 'username': 
            return isEmptyOrNull(this.client.username) ? 'Es necesario iongresar el nombre de usuario' : ''
          // case 'contactPhoneNumber':
          //   return isEmptyOrNull(this.client.contactPhoneNumber) ? 'Es necesario ingresar el teléfono de usuario' : ''
          case 'email': 
            return isEmptyOrNull(this.client.email) || !validateMail(this.client.email)  ? 'El correo electrónico no es valido' : ''
          case 'userGroup':
            if (this.getVisibilityUserType()) {
              return isEmptyOrNull(this.client.userGroup) ? 'Es necesario seleccionar un grupo de usuario' : ''
            }
            return ''
          case 'owner':
            if (this.getVisibilitySearchItem()) {
              return isEmptyOrNull(this.client.owner) ? 'Es necesario seleccionar una cuenta' : ''
            }else if ( this.type === enums.C_ACCOUNT){
              this.errorAccount = isEmptyOrNull(this.client.owner) ? 'Es necesario seleccionar una cuenta' : ''
              return this.errorAccount
            }
            return ''
          case 'passwordConfirm':
            if (this.idUser === 0) {
              return this.client.passwordConfirm !== this.client.password ? 'las contraseñas no coinciden' : ''
            }
            return ''
          case 'password':
            if (this.idUser === 0) {
              return !validatePassword(this.client.password) ? 'Al menos 8 caracteres, 1 mayúscula y 1 número' : ''
            }
            return ''
          case 'userLevel':
            if (this.type === 'client-account' && this.idUser <= 0){
              return isEmptyOrNull(this.client.userLevel) ? 'Es necesario seleccionar un nivel de acceso' : ''
            }
            return ''
          case 'idEnterprise':
            if (this.type === 'client-account' && this.client.userLevel ===  enumsAccessType.ENTERPRISE_E && this.idUser <= 0){
              return isEmptyOrNull(this.client.idEnterprise) || this.client.idEnterprise.length === 0 ? 'Debes seleccionar por lo menos una empresa' : ''
            }
            return ''
          case 'idBranch':
            if (this.type === 'client-account' && this.client.userLevel === enumsAccessType.BRANCHE_E && this.idUser <= 0){
              return isEmptyOrNull(this.client.idBranch) || this.client.idBranch.length === 0 ? 'Debes seleccionar por lo menos una sucursal' : ''
            }
            return ''
          default:
            return '';
        }
      }
      return '' 
    },
    cleanData() {
      this.client = Object.assign({},this.defaultClient)
      this.showErrorMessages = false
      this.clearAccessSelector = true
    },
    getUserG(){
      getUserGroups(this.type)
      .then(response => {
        if (response.data.statusCode == 200){
          this.userGroups = response.data.result
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    getFocus(){
      setTimeout(() => {
        document.body.classList.add('modal-open');
      }, 100)
    },
    updateAccessInfo(event){
      this.client.idAccount = event.idAccount
      this.client.userLevel = event.userLevel
      this.client.idEnterprise = event.idEnterprise
      this.client.idBranch = event.idBranch
      this.client.owner = event.accountName
      this.canContinue() 
    }
  },
  mounted() {
    if (this.idUser <= 0 ) {
      this.getUserG()
    }
  },
  watch: {
    clean: {
      handler(newValue) {
        if (newValue) {
          document.body.classList.remove('modal-open');
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    },
    idUser: {
      handler(newValue) {
        if (newValue) {
          //load user
          let loader = this.$loading.show({loader: 'dots'});
          this.title = "Actualizar cliente";
          getClient(this.type, newValue)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.client = response.data.result
            }else{
              processErrorResponse(response.datam, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        } else {
          this.title = "Nuevo usuario"
          this.cleanData()
        }
      }
    },
    showCreateClient: {
      handler(newValue){
        if (newValue){
          this.clearAccessSelector = false
        }
      }
    }
  }
};
</script>
<style scoped>
</style>