<template>
  <div class="card shadow">

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  tbody-classes="list"
                  :data="branches">
        <template slot="columns">
          <th></th>
          <th>Negocio</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <base-checkbox v-on:input="branchSelect(row, $event)"></base-checkbox>
          </th>
          <td>
            <div class="media align-items-center">
                {{row.nbSucursal}}
            </div>
          </td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
  import _ from 'lodash';
  export default {
    name: 'search-branch-table',
    props: {
      title: String,
      searchTerm: {
        type: String,
        description:
          'The search term for branch, this gona search on the nbSucursal field',
      }
    },
    data() {
      return {
        branches: [],
      }
    },
    watch: {
      searchTerm: {
        handler: _.debounce(function(newVal){
          if(newVal.length > 1){
            this.axios.post('/branch/toplevel', {searchTerm: `${newVal}%`})
            .then(result => {
              this.branches = result.data;
            })
            .catch(error => {
              this.$emit('load-branches-error', error);
            })
            .finally(() => {
              this.$emit('load-branches-completed');
            });
          } else {
            this.branches = [];
          }
        }, 500),
        deep: true
      },
    },
    computed: {
    },
    mounted() {
    },
    methods:{
      branchSelect(row, event) {
        this.$emit('branch-selected', {selected: event, data:row});
      }
    }
  }
</script>
<style>
</style>
