<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-xl-8">
          <h3 class="mb-0">
            Operaciones
            <span
              v-if="branch.user_mobileUserId"
            >: {{branch.user_mobileUserId}} - {{branch.branch_nbSucursal}}</span>
          </h3>
          <i class="ni ni-email-83 icon-size-sm align-middle"></i>
          <span :class="detailClass">{{" "}}{{branch.user_txEmail}}</span>
          &nbsp;
          <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
          <span :class="detailClass">{{" "}}{{branch.user_nuCelular}}</span>
        </div>
        <div class="col-xl-3">
          <base-input-no-info addon-left-icon="ni ni-calendar-grid-58">
            <flat-pickr
              slot-scope="{focus, blur}"
              @on-open="focus"
              @on-close="blur"
              :config="{allowInput: true, mode: 'range'}"
              class="form-control datepicker"
              v-model="dateRange"
            ></flat-pickr>
          </base-input-no-info>
        </div>
        <div class="text-center align-self-center" style="{background-color: #00A; width: 80px;}">
          <download-button
            :endpoint="downloadEndpoint"
            :payload="transactionsDownloadPayload"
            reportName="Transacciones"
          />
        </div>
      </div>
    </div>

    <div class="card-body border-0">
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="tableData">
          <template slot="columns">
            <th>Sucursal</th>
            <th>Fecha</th>
            <th>Num. Op.</th>
            <th>Num. Aut.</th>
            <th>Tarjetahabiente</th>
            <th>Tarjeta</th>
            <th>Monto</th>
            <th v-if="detail">Comisión</th>
            <th v-if="detail">IVA</th>
            <th v-if="detail">Depósito</th>
            <th>Estado</th>
          </template>

          <template slot-scope="{row}">
            <th scope="row">
              {{row.IdUsuario}}
              <tr />
              {{row.nbSucursal}}
            </th>
            <th scope="row">
              {{row.Fecha | formatDate}}
              <tr />
              {{row.Fecha | formatTime}}
            </th>
            <td>{{row.NoOperacion}}</td>
            <td>{{row.NoAutorizacion}}</td>
            <td>{{row.NomTarjeta}}</td>
            <td>**{{row.NoTarjeta}}</td>
            <td class="text-right">{{row.Monto | currency}}</td>
            <td class="text-right" v-if="detail">{{row.Monto * 0.035 | currency}}</td>
            <td class="text-right" v-if="detail">{{row.Monto * 0.035 * 0.16 | currency}}</td>
            <td
              class="text-right"
              v-if="detail"
            >{{row.Monto - (row.Monto * 0.035) - (row.Monto * 0.035 * 0.16) | currency}}</td>
            <td>
              <span v-if="row.EstatusVenta === 'cancelado'">Cancelada</span>
              <span v-if="row.EstatusVenta === 'returned'">Devolución</span>
              <span v-if="row.EstatusVenta === 'approved'">Aprobada</span>
              <span v-if="row.EstatusVenta === 'rejected'">Rechazada</span>
            </td>
          </template>
        </base-table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end dark">
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import periodSearchMixin from "../../mixins/periodSearchMixin";
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import moment from "moment";

import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import DownloadButton from "../Buttons/DownloadButton";

export default {
  name: "transactions-table",
  mixins: [periodSearchMixin, paginationMixin, infoCSSClasesMixin],
  components: {
    flatPickr,
    DownloadButton
  },
  props: {
    detail: {
      type: Boolean,
      default: false,
      description: "Show the detail of comission per sale"
    },
    showDownloadButton: {
      type: Boolean,
      default: false,
      description: "Show the download button"
    },
    retrieveCompany: {
      type: Boolean,
      description: "Retrieve transactions from company?",
      required: true
    },
    retrieveClientInfo: {
      type: Boolean,
      description: "Retrieve transactions for current user?",
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      error: "",
      loading: false,
      dates: {},
      dateRange: "",
      downloadEndpoint: "",
      branch: {}
    };
  },
  computed: {
    /**
     * Dont have pagination because we want and excel with all the information in the file
     */
    transactionsDownloadPayload() {
      return { dates: this.dates };
    }
  },
  watch: {
    dateRange(newVal) {
      if (newVal.includes(" a ")) {
        this.dates.start = newVal.split(" a ")[0];
        this.dates.end = newVal.split(" a ")[1];
        if (this.dates.start && this.dates.end) {
          this.fetchData();
        }
      }
    }
  },
  // https://router.vuejs.org/guide/advanced/data-fetching.html
  created() {
    this.initializeDates();
    this.fetchBranchData();
  },
  methods: {
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchData();
    },
    fetchData() {
      this.error = this.tableData = null;
      this.loading = true;

      let endpoint = `/sales/branch/${this.$route.params.id}/search`;
      this.downloadEndpoint = `/sales/branch/${this.$route.params.id}/download`;
      if (this.retrieveCompany) {
        endpoint = `/sales/company/${this.$route.params.id}/search`;
        this.downloadEndpoint = `/sales/company/${this.$route.params.id}/download`;
      } else if (this.retrieveClientInfo) {
        endpoint = `/sales/company/me/search`;
        this.downloadEndpoint = `/sales/company/me/download`;
      }

      const USER_ROLES = ["USER", "ADMIN_USER"];
      if (USER_ROLES.includes(this.$store.state.user.userInfo.role)) {
        endpoint = `/sales/branch/${this.$route.params.id}/search`;
        this.downloadEndpoint = `/sales/branch/${this.$route.params.id}/download`;
      }

      if (
        this.$store.state.user.userInfo.role == "USER" &&
        this.$route.query.all
      ) {
        endpoint = `/sales/company/me/search`;
        this.downloadEndpoint = `/sales/company/me/download`;
      }

      this.axios
        .post(endpoint, { dates: this.dates, pagination: this.pagination })
        .then(result => {
          this.tableData = result.data.transactions;
          this.pagination = result.data.pagination;
          this.totalElements = parseInt(result.data.total);
          this.$emit("data-range-changed", this.period);
        })
        .catch(error => {
          this.$emit("error", error);
        });
    },
    fetchBranchData() {
      this.axios
        .get(`/branch/${this.$route.params.id}`)
        .then(result => {
          this.branch = result.data;
        })
        .catch(error => {
          this.$emit("error", error);
        });
    },
    initializeDates() {
      const start = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      this.dateRange = `${start} a ${end}`;
    }
  },
  mounted() {}
};
</script>