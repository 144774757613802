import _ from 'lodash';
import moment from 'moment';

export default {
  data() {
    return {
      period: {
        year: '2019',
        month: '9'
      },
      years: [],
      months: [
        {key: 1, value:'ENERO'},
        {key: 2, value:'FEBRERO'},
        {key: 3, value:'MARZO'},
        {key: 4, value:'ABRIL'},
        {key: 5, value:'MAYO'},
        {key: 6, value:'JUNIO'},
        {key: 7, value:'JULIO'},
        {key: 8, value:'AGOSTO'},
        {key: 9, value:'SEPTIEMBRE'},
        {key: 10, value:'OCTUBRE'},
        {key: 11, value:'NOVIEMBRE'},
        {key: 12, value:'DICIEMBRE'},
      ],
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    period: {
      handler: function() {
        if(this.fetchData){
          this.fetchData();
        }
        this.$emit('period-changed', this.period);
      },
      deep: true
    },
  },
  methods:{
    initializeYearArray() {
      let firstYear = 2019;
      var d = new Date();
      let currentYear = d.getFullYear();
      let yearsArray = _.range(firstYear, currentYear + 1);
      for (let year of  yearsArray) {
        this.years.push({key: year, value: year});
      }
    },
    getCurrentMonth(){
      var d = new Date();
      return d.getMonth() + 1;
    },
    getCurrentYear() {
       var d = new Date();
       return d.getFullYear();
    },
    initializePeriod() {
      this.period.month = this.getCurrentMonth();
      this.period.year = moment().year();
    }
  },
}