export const catalogs = [
'Agencias de Viajes',
'Aseguradoras',
'Beneficencia',
'Colegios y Universidades',
'Comida Rapida',
'Educacion Basica',
'Entretenimiento',
'Estacionamientos',
'Farmacias',
'Gasolineras',
'Gobierno',
'Guarderias',
'Hospitales',
'Hoteles',
'Medicos y dentistas',
'Miscelaneas',
'Peaje',
'Refacciones y Ferreterias',
'Renta de Autos',
'Restaurantes',
'Salones de belleza',
'Supermercados',
'Telecomunicaciones',
'Transporte Aereo',
'Transporte Terrestre de Pasajeros',
'Ventas al detalle (Retail)',
'Otros',
];

export const banks = [
'ABC CAPITAL',
'ACCENDO BANCO',
'ACTINVER',
'AFIRME',
'AKALA',
'AMERICAN EXPRES',
'ASEA',
'ASP INTEGRA OPC',
'AUTOFIN',
'AZTECA',
'BAJIO',
'BANAMEX',
'BANCO FINTERRA',
'BANCO S3',
'BANCOMEXT',
'BANCOPPEL',
'BANCREA',
'BANJERCITO',
'BANK OF AMERICA',
'BANKAOOL',
'BANOBRAS',
'BANORTE',
'BANREGIO',
'BANSEFI',
'BANSI',
'BANXICO',
'BARCLAYS',
'BBASE',
'BBVA BANCOMER',
'BMONEX',
'CAJA POP MEXICA',
'CAJA TELEFONIST',
'CB INTERCAM',
'CI BOLSA',
'CIBANCO',
'COMPARTAMOS',
'CONSUBANCO',
'CREDIT SUISSE',
'CRISTOBAL COLON',
'DEUTSCHE',
'DONDE',
'ESTRUCTURADORES',
'EVERCORE',
'FINAMEX',
'FINCOMUN',
'FOMPED',
'FONDO (FIRA)',
'GBM',
'HDI SEGUROS',
'HIPOTECARIA FED',
'HSBC',
'ICBC',
'INBURSA',
'INDEVAL',
'INMOBILIARIO',
'INTERCAM BANCO',
'INVERCAP',
'INVEX',
'JP MORGAN',
'KUSPIT',
'LIBERTAD',
'MASARI',
'MIFEL',
'MIZUHO BANK',
'MONEXCB',
'MUFG',
'MULTIVA BANCO',
'MULTIVA CBOLSA',
'NAFIN',
'PAGATODO',
'PROFUTURO',
'REFORMA',
'SABADELL',
'SANTANDER',
'SCOTIABANK',
'SHINHAN',
'STP',
'TRANSFER',
'UNAGRA',
'VALMEX',
'VALUE',
'VE POR MAS',
'VECTOR',
'VOLKSWAGEN'
];

export const optionsFindOut = [
    'Redes Sociales',
    'Recomendación',
    'Sitio Web',
    'Fui contactado por un ejecutivo',
    'Otro'
];

export const paginationLimit = 10