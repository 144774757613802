var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card shadow",
      class: _vm.type === "dark" ? "bg-default" : ""
    },
    [
      _c("update-user-password-modal", {
        attrs: {
          selectedUserId: _vm.selectedUserId,
          subtitle: _vm.subtitle,
          showChangePassword: _vm.showChangePassword
        },
        on: { close: _vm.closeChangePassword }
      }),
      _c("branch-data-info", {
        attrs: { selectedBranchId: _vm.selectedBrach },
        on: {
          close: function($event) {
            _vm.selectedBrach = 0
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "card-header border-0",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col mt-2" }, [
              _c(
                "h3",
                {
                  staticClass: "mb-0",
                  class: _vm.type === "dark" ? "text-white" : ""
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            ]),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "col-xl-3 text-right",
                    staticStyle: { "{background-color": "#0A0" }
                  },
                  [
                    _c("base-input", {
                      attrs: { placeholder: "Búsqueda" },
                      model: {
                        value: _vm.searchText,
                        callback: function($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "text-center align-self-center",
                    staticStyle: { "{background-color": "#00A", width: "80px" }
                  },
                  [
                    _c("download-button", {
                      attrs: {
                        endpoint: _vm.downloadEndpoint,
                        payload: _vm.companiesDownloadPayload,
                        reportName: "Empresa"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      ),
      _c(
        "modal",
        {
          attrs: { show: _vm.showConfirmToggle, showClose: true },
          on: { close: _vm.clearDeleteData }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(
              _vm._s(_vm.enabled ? "Deshabilitar" : "Habilitar") + " sucursal"
            )
          ]),
          _c(
            "form",
            {
              ref: "DeleteBranchForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.toggleBranch(_vm.branchToDelete)
                }
              }
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row mt-1" }, [
                  _c("div", { staticClass: "col-xl-12" }, [
                    _vm._v(
                      "¿Está seguro que desea " +
                        _vm._s(_vm.enabled ? "deshabilitar" : "habilitar") +
                        " la sucursal " +
                        _vm._s(_vm.companyName) +
                        "?"
                    )
                  ])
                ])
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-4 text-right" }),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("a", { on: { click: _vm.clearDeleteData } }, [
                      _vm._v("Cancelar")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4 text-right" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "my-4 error",
                          attrs: { type: "primary", nativeType: "submit" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.enabled ? "Deshabilitar" : "Habilitar")
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "modal",
        {
          attrs: { show: _vm.showUnlinkBranchConfirmation, showClose: true },
          on: { close: _vm.clearUnlinkBranchConfirmation }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Desasignar sucursal")
          ]),
          _c(
            "form",
            {
              ref: "UnlinkBranchForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.unlinkBranch(_vm.branchToUnlink)
                }
              }
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row mt-1" }, [
                  _c("div", { staticClass: "col-xl-12" }, [
                    _vm._v("¿Está seguro que desea desasignarla como sucursal?")
                  ])
                ])
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-4 text-right" }),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c(
                      "a",
                      { on: { click: _vm.clearUnlinkBranchConfirmation } },
                      [_vm._v("Cancelar")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4 text-right" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "my-4 error",
                          attrs: { type: "primary", nativeType: "submit" }
                        },
                        [_vm._v("Aceptar")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              class: _vm.type === "dark" ? "table-dark" : "",
              attrs: {
                "thead-classes":
                  _vm.type === "dark" ? "thead-dark" : "thead-light",
                "tbody-classes": "list",
                data: _vm.filteredData
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "td",
                        { class: { parentBranch: row.sucursales_level === 1 } },
                        [
                          row.sucursales_status === 2
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-pill badge-danger"
                                },
                                [_vm._v("Deshabilitado")]
                              )
                            : _vm._e(),
                          row.webUser_id
                            ? _c(
                                "router-link",
                                {
                                  attrs: { to: _vm.branchTransactionLink(row) }
                                },
                                [
                                  _c("div", { staticClass: "h4" }, [
                                    row.sucursales_mitStatus === "CREATED"
                                      ? _c("i", {
                                          staticClass: "ni ni-building"
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.usuario_IdUsuario) +
                                        " " +
                                        _vm._s(row.usuario_NombreNegocio) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "light-blue" }, [
                            _vm._v(
                              _vm._s(row.usuario_ApellidoPaterno) +
                                " " +
                                _vm._s(row.usuario_ApellidoMaterno) +
                                " " +
                                _vm._s(row.usuario_Nombre)
                            )
                          ]),
                          _c("div", [_vm._v(_vm._s(row.usuario_Correo))]),
                          _c("div", [_vm._v(_vm._s(row.usuario_Celular))])
                        ],
                        1
                      ),
                      _vm._l(_vm.monthsToDisplay, function(n) {
                        return _c(
                          "td",
                          {
                            key: n,
                            class: { parentBranch: row.sucursales_level === 1 }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  row[
                                    _vm.monthNames[_vm.monthsToDisplay - n]
                                  ] || "0.00"
                                )
                              )
                            )
                          ]
                        )
                      }),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          class: { parentBranch: row.sucursales_level === 1 }
                        },
                        [
                          row.webUser_id && _vm.userRole === "SUPER_ADMIN"
                            ? _c(
                                "router-link",
                                { attrs: { to: _vm.selectBranchLink(row) } },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipDetailMsg,
                                        expression: "tooltipDetailMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "fas fa-desktop icon-size"
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" \n          "),
                          row.webUser_id
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showBranchDataInfo(row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipInfoDetailMsg,
                                        expression: "tooltipInfoDetailMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "fas fa-info-circle icon-size"
                                  }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e(),
                          row.webUser_id
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.prepareConfirmTogle(row)
                                    }
                                  }
                                },
                                [
                                  row.sucursales_status === 2
                                    ? _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top-center",
                                            value: _vm.tooltipEnnableMsg,
                                            expression: "tooltipEnnableMsg",
                                            modifiers: { "top-center": true }
                                          }
                                        ],
                                        staticClass: "fas fa-check icon-size"
                                      })
                                    : _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top-center",
                                            value: _vm.tooltipDisabbleMsg,
                                            expression: "tooltipDisabbleMsg",
                                            modifiers: { "top-center": true }
                                          }
                                        ],
                                        staticClass:
                                          "fas fa-exclamation-triangle icon-size"
                                      }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e(),
                          !row.configuracionesLectorUsuario_IdConfiguraciones &&
                          row.sucursales_level === 2
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.prepareToUnlinkBranch(row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipUnlinkMsg,
                                        expression: "tooltipUnlinkMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "fas fa-unlink icon-size"
                                  }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e(),
                          !row.configuracionesLectorUsuario_IdConfiguraciones &&
                          _vm.userRole === "SUPER_ADMIN"
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.prepareChangePassword(row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipChangePassword,
                                        expression: "tooltipChangePassword",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "fas fa-unlock-alt icon-size"
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "columns" },
                [
                  _c("th", [_vm._v("Sucursal")]),
                  _vm._l(_vm.monthsToDisplay, function(n) {
                    return _c("th", { key: n }, [
                      _vm._v(_vm._s(_vm.monthNames[_vm.monthsToDisplay - n]))
                    ])
                  }),
                  _c("th")
                ],
                2
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }