/* eslint-disable no-duplicate-case */
<template>
  <modal
    :show="showAssignItem"
    :showClose=true
    v-on:selectedItems="selectedItems"
    v-on:close="closeModal">
    <div slot="header">{{getTitle()}}</div>
    <form @submit.prevent="assignItems" ref="AssignClientForm">
      <div class="container-fluid ">
        <div class="row mt-1 ">
          <div class="col-xl-12">
            <label class="form-control-label">
                {{title}}
            </label>
            <base-input
                input-classes="form-control-alternative"
                v-model="searchTerm"
              />
          </div>
        </div>
          <div class="row mt-1 ">
          <div class="col-xl-12">
            <search-account-table
                :showAssignItem="showAssignItem"
                :searchTerm="searchTerm"
                :type="type"
                :isMultipleSelection="isMultipleSelection"
                v-on:item-selected="selectedItem"
                :clean="clean"
                v-on:cleaned="clean=false"
                v-on:error="error"
            />
          </div>
        </div>
      </div>
      <div slot="footer" v-if="isMultipleSelection">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4" nativeType="submit">Guardar</base-button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
import enums from '../../enums/enums';
import SearchAccountTable from '../Tables/SearchAccountTable.vue';

export default {
    name: 'assign-item-modal',
    components: {
      SearchAccountTable
    },
    props: {
      type: String,
      isMultipleSelection: Boolean,
      showAssignItem: {
        type: Boolean,
        default: false
      },
      titleD: String
    },
    data() {
      return {
        title: '',
        searchTerm: '',
        selectedItems: [],
        clean: false,
      }
    },
    methods:{
      getTitle(){
        if (this.titleD){
          this.title = 'Buscar'
          return this.titleD
        }
        switch (this.type) {
          case enums.P_ACCOUNT:
            this.title = 'Vendedor'
            return "Selecciona el vendedor que deseas asignar"
          case enums.ENTERPRISE:
            this.title = 'Cuenta'
            return "Selecciona una cuenta a la cual se vinculara la empresa";
          case enums.BRANCH:
            this.title = 'Empresa'
            return "Selecciona una empresa a la cual se vinculara la sucursal";
          case enums.C_ACCOUNT:
            this.title = 'Cuenta'
            return "Selecciona una cuenta a la cual se vinculara el nuevo cliente";
          case enums.ADMIN:
            return "";
          case enums.EXTERNAL:
            this.title = 'Sucursal'
            return "Selecciona una sucursal a la cual se vinculara el nuevo cliente";
          case enums.DEVICE:
            this.title = 'Sucursal'
            return "Selecciona una sucursal a la cual se vinculara el nuevo dispositivo";
          case enums.PARTNER:
           this.title = 'Cuenta'
            return "Selecciona las cuentas que deseas asignar";
          default:
            return false;
        }
      },
      selectedItem(event) {
        if (this.isMultipleSelection) {
          let item = event.data
          let index = this.selectedItems.findIndex(e => e.id === item.id)
          if ( index >= 0 )  
            this.selectedItems.splice(index,1) 
          else 
            this.selectedItems.push(item)
        }else{
          this.selectedItems = []
          this.selectedItems.push(event.data)
          this.$emit('selectedItems', this.selectedItems);
        }
     },
      closeModal(){
        this.searchTerm= '';
        this.clean = true;
        this.selectedItems = []
        this.$emit('close');
      },
      assignItems() {
        this.$emit('selectedItems', this.selectedItems);
        this.closeModal()
      },
      error(error) {
        this.$emit('error', error)
      }
    },
  }
</script>