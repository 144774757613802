<template>
  <div>
    <modal :show="showAppUser" :showClose="true" v-on:close="closeAppUser">
      <div slot="header" v-if="!mobileUser.idUsuario">Nuevo Usuario</div>
      <div
        slot="header"
        v-if="mobileUser.idUsuario"
      >{{ mobileUser.nombre }} {{ mobileUser.apellidoPaterno }}</div>
      <card shadow type="secondary">
        <template>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Correo electronico"
                  required
                  v-model="mobileUser.correo"
                  :maxlength="60"
                  :error="validationMsg($v.mobileUser.correo)"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-password
                  label="Pin"
                  v-model="mobileUser.pin"
                  required
                  :maxlength="4"
                  validation="number"
                  :error="validationMsg($v.mobileUser.pin)"
                />
              </div>
              <div class="col-md-6">
                <base-password
                  label="Confirmar Pin"
                  v-model="mobileUser.confirmPin"
                  required
                  :maxlength="4"
                  validation="number"
                  :error="validationMsg($v.mobileUser.confirmPin)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-password
                  label="Contraseña"
                  v-model="mobileUser.contrasena"
                  required
                  :maxlength="20"
                  :error="validationMsg($v.mobileUser.contrasena)"
                />
              </div>
              <div class="col-md-6">
                <base-password
                  label="Confirmar Contraseña"
                  v-model="mobileUser.confirmContrasena"
                  required
                  :maxlength="20"
                  :error="validationMsg($v.mobileUser.confirmContrasena)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Nombre Negocio"
                  required
                  v-model="mobileUser.nombreNegocio"
                  :maxlength="50"
                  :error="validationMsg($v.mobileUser.nombreNegocio)"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Número Tarjeta"
                  v-model="mobileUser.numeroTarjeta"
                  :maxlength="16"
                  validation="number"
                  :error="validationMsg($v.mobileUser.numeroTarjeta)"
                ></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <base-input
                  label="Nombre"
                  required
                  v-model="mobileUser.nombre"
                  :capitalize='true'
                  :maxlength="60"
                  :error="validationMsg($v.mobileUser.nombre)"
                ></base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  label="Paterno"
                  required
                  v-model="mobileUser.apellidoPaterno"
                  :maxlength="60"
                  :capitalize='true'
                  :error="validationMsg($v.mobileUser.apellidoPaterno)"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  label="Materno"
                  v-model="mobileUser.apellidoMaterno"
                  :maxlength="60"
                  :capitalize='true'
                  :error="validationMsg($v.mobileUser.apellidoMaterno)"
                ></base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  label="Celular"
                  required
                  v-model="mobileUser.celular"
                  :maxlength="10"
                  validation="number"
                  :error="validationMsg($v.mobileUser.celular)"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Serial Dispositivo"
                  v-model="mobileUser.serial"
                  :maxlength="50"
                  validation="number"
                  :error="validationMsg($v.mobileUser.serial)"
                ></base-input>
              </div>
            </div>
          </div>
        </template>
        <div slot="footer" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-12 text-right">
              <base-button
                :disabled="$v.mobileUser.$invalid"
                @click.prevent="updateAppUser()"
                class="btn btn-primary text-white"
              >Guardar</base-button>
            </div>
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>
<script>
import {
  required,
  sameAs,
  minLength,
  email,
  maxLength
} from "vuelidate/lib/validators";
import { isStrong } from "../../utils/utilMethods";
import validationMessagesMixin from "../../mixins/validationMessagesMixin";

export default {
  name: "app-user-modal",
  props: ["showAppUser", "idUser"],
  mixins: [validationMessagesMixin],
  data() {
    return {
      mobileUser: {},
      /** Names taken from the legacy database */
      defaultAppUser: {
        idUsuario: undefined,
        correo: "",
        pin: "",
        confirmPin: "",
        contrasena: "",
        confirmContrasena: "",
        nombreNegocio: "",
        numeroTarjeta: "",
        estado: 0,
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        celular: "",
        documentacion: false,
        serial: ""
      }
    };
  },
  methods: {
    closeAppUser() {
      this.clearData();
      this.$emit("close");
    },
    clearData() {
      this.mobileUser = Object.assign({}, this.defaultAppUser);
    },
    updateAppUser() {
      let payload = {
        mobileUser: this.mobileUser
      };

      if (this.mobileUser.idUsuario) {
        this.axios
          .put("/mobileuser", payload)
          .then(response => {
            this.$emit("user-updated", response.data.mobileUser);
            this.closeAppUser();
          })
          .catch(error => {
            this.$emit("error", error);
          });
      } else {
        this.axios
          .post("/mobileuser", payload)
          .then(response => {
            this.$emit("user-created", response.data.mobileUser);
            this.closeAppUser();
          })
          .catch(error => {
            this.$emit("error", error);
          });
      }
    }
  },
  watch: {
    idUser(newVal) {
      if (newVal) {
        this.axios
          .get(`/mobileUser/${newVal}`)
          .then(result => {
            result.data.confirmPin = result.data.pin;
            result.data.confirmContrasena = result.data.contrasena;
            this.mobileUser = result.data;
          })
          .catch(error => {
            this.$emit("error", error);
          });
      }
    }
  },
  validations: {
    mobileUser: {
      correo: { required, email },
      pin: { required, pinLength: minLength(4) },
      confirmPin: {
        required,
        sameAsPin: sameAs("pin")
      },
      contrasena: {
        required,
        isStrong,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
      confirmContrasena: {
        required,
        isStrong,
        sameAsPassword: sameAs("contrasena")
      },
      nombreNegocio: { required, maxLength: maxLength(50) },
      nombre: { required, maxLength: maxLength(60) },
      apellidoPaterno: { required, maxLength: maxLength(60) },
      apellidoMaterno: { maxLength: maxLength(60) },
      celular: { required, minLength: minLength(10) },
      serial: { maxLength: maxLength(50) }
    }
  }
};
</script>