<template>
  <modal :show="showAssignModal" :showClose="true" v-on:close="closeModal">
    <div slot="header">Asignar sucursal raíz</div>
    <form @submit.prevent="assignBranch" ref="AssignBranchForm">
      <div class="container-fluid">
        <div class="row mt-1">
          <div class="col-xl-12">
            <base-input
              label="Nombre del negocio"
              input-classes="form-control-alternative"
              v-model="branch.name"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-xl-12">
            <search-branch-table
              title="Empresas"
              :searchTerm="branch.name"
              v-on:branch-selected="selectBranch"
            />
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4" nativeType="submit">Guardar</base-button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
import SearchBranchTable from "../Tables/SearchBranchTable";
export default {
  name: "assign-branch-modal",
  components: {
    SearchBranchTable
  },
  props: {
    showAssignModal: {
      type: Boolean,
      default: false
    },
    webUserToCreate: {
      type: Number,
      description: "Id of the branch"
    }
  },
  data() {
    return {
      branch: {
        name: ""
      },
      selectedBranchs: new Set()
    };
  },
  methods: {
    selectBranch(event) {
      if (event.selected) {
        this.selectedBranchs = new Set(
          this.selectedBranchs.add(event.data.idBranch)
        );
      } else {
        this.selectedBranchs = new Set(
          this.selectedBranchs.delete(event.data.idBranch)
        );
      }
    },
    closeModal() {
      this.branch.name = "";
      this.selectedBranchs = new Set();
      this.$emit("close");
    },
    assignBranch() {
      if (this.selectedBranchs.size === 1) {
        var setIter = this.selectedBranchs.values();
        var parentBranch = setIter.next().value;
        const requestBody = {
          mobileUserId: this.webUserToCreate,
          parentBranchId: parentBranch
        };
        this.axios
          .post(`/user/createwebuser`, requestBody)
          .then(() => {
            this.$toasted.show("Sucursal asignada");
            this.$emit("assigned-branch");
          })
          .catch(error => {
            this.$emit("error", error);
          })
          .finally(() => {
            this.closeModal();
          });
      } else {
        this.$toasted.show("Selecciona sólo una empresa");
      }
    }
  }
};
</script>