var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { bigModal: true, show: _vm.showCreateClient, showClose: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("assign-item-modal", {
        attrs: {
          showAssignItem: _vm.showAssignItem,
          type: _vm.type,
          isMultipleSelection: false,
          titleD: "Selecciona una cuenta"
        },
        on: {
          close: function($event) {
            _vm.showAssignItem = false
          },
          selectedItems: _vm.itemsSelected,
          error: _vm.handleError
        }
      }),
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [_vm._v(_vm._s(_vm.title))])
        ])
      ]),
      _c(
        "card",
        { attrs: { shadow: "", type: "secondary" } },
        [
          [
            _c(
              "b-container",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("base-input", {
                          attrs: {
                            alternative: "",
                            label: "Nombre completo",
                            "input-classes": "form-control-alternative",
                            capitalize: true,
                            maxlength: 128,
                            required: "",
                            error: _vm.validateField("fullname")
                          },
                          model: {
                            value: _vm.client.fullname,
                            callback: function($$v) {
                              _vm.$set(_vm.client, "fullname", $$v)
                            },
                            expression: "client.fullname"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("base-input", {
                          attrs: {
                            alternative: "",
                            label: "Nombre de usuario",
                            "input-classes": "form-control-alternative",
                            maxlength: 64,
                            isUserName: true,
                            required: "",
                            disabled: _vm.idUser > 0,
                            error: _vm.validateField("username")
                          },
                          model: {
                            value: _vm.client.username,
                            callback: function($$v) {
                              _vm.$set(_vm.client, "username", $$v)
                            },
                            expression: "client.username"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _vm.type !== "external"
                      ? _c(
                          "b-col",
                          [
                            _c("base-input", {
                              attrs: {
                                "input-classes": "form-control-alternative",
                                label: "Teléfono: ",
                                capitalize: true,
                                maxlength: 10,
                                validation: "number"
                              },
                              model: {
                                value: _vm.client.contactPhoneNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.client,
                                    "contactPhoneNumber",
                                    $$v
                                  )
                                },
                                expression: "client.contactPhoneNumber"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      [
                        _c("base-input", {
                          attrs: {
                            alternative: "",
                            label: "Correo Electrónico",
                            "input-classes": "form-control-alternative",
                            required: "",
                            maxlength: 64,
                            error: _vm.validateField("email")
                          },
                          model: {
                            value: _vm.client.email,
                            callback: function($$v) {
                              _vm.$set(_vm.client, "email", $$v)
                            },
                            expression: "client.email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.idUser === 0
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("base-password", {
                              attrs: {
                                label: "Contraseña",
                                placeholder: "",
                                error: _vm.validateField("password")
                              },
                              model: {
                                value: _vm.client.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.client, "password", $$v)
                                },
                                expression: "client.password"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c("base-password", {
                              attrs: {
                                label: "Confirmar Contraseña",
                                placeholder: "",
                                error: _vm.validateField("passwordConfirm")
                              },
                              model: {
                                value: _vm.client.passwordConfirm,
                                callback: function($$v) {
                                  _vm.$set(_vm.client, "passwordConfirm", $$v)
                                },
                                expression: "client.passwordConfirm"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getVisibilityUserType()
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("label", { staticClass: "form-control-label" }, [
                              _vm._v(
                                "\n              Grupo de usuario\n            "
                              )
                            ]),
                            _c("v-select", {
                              attrs: {
                                placeholder: "Selecciona una opción",
                                options: _vm.userGroups,
                                reduce: function(userGroup) {
                                  return userGroup.idUserGroup
                                },
                                label: "userGroupDescription",
                                required: ""
                              },
                              model: {
                                value: _vm.client.userGroup,
                                callback: function($$v) {
                                  _vm.$set(_vm.client, "userGroup", $$v)
                                },
                                expression: "client.userGroup"
                              }
                            }),
                            _vm._t("helpBlock", [
                              _c(
                                "div",
                                {
                                  staticClass: "text-danger invalid-feedback",
                                  staticStyle: { display: "block" }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.validateField("userGroup")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getVisibilitySearchItem()
                  ? _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            [
                              _c(
                                "label",
                                { staticClass: "form-control-label" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.titleSearch) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.client.owner,
                                    expression: "client.owner"
                                  }
                                ],
                                staticClass: "form-control",
                                staticStyle: {
                                  cursor: "pointer",
                                  "background-color": "white"
                                },
                                attrs: {
                                  alternative: "",
                                  readonly: "true",
                                  required: ""
                                },
                                domProps: { value: _vm.client.owner },
                                on: {
                                  click: function($event) {
                                    _vm.showAssignItem = true
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.client,
                                      "owner",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._t("helpBlock", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-danger invalid-feedback",
                                    staticStyle: { display: "block" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.validateField("owner")) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type === "client-account" && _vm.idUser <= 0
                  ? _c("access-selector", {
                      attrs: {
                        clean: _vm.clearAccessSelector,
                        errorAccount: _vm.validateField("owner"),
                        errorAccessType: _vm.validateField("userlLevel"),
                        errorEnterpriseBranch:
                          _vm.validateField("idEnterprise") +
                          _vm.validateField("idBranch")
                      },
                      on: { "access-info-selected": _vm.updateAccessInfo }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          _c(
            "div",
            {
              staticClass: "bg-white border-0",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "row align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-right" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "my-4 ml-3 mr-3",
                        attrs: { type: "primary" },
                        on: { click: _vm.saveClient }
                      },
                      [_vm._v("Guardar")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }