var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card shadow",
      class: _vm.type === "dark" ? "bg-default" : ""
    },
    [
      _c("app-user-modal", {
        attrs: { showAppUser: _vm.showAppUser, idUser: _vm.idUser },
        on: {
          close: function($event) {
            _vm.showAppUser = false
            _vm.idUser = 0
          },
          error: _vm.handleError,
          "user-created": _vm.insertClientIntoTable,
          "user-updated": _vm.updateClientIntoTable
        }
      }),
      _c(
        "modal",
        {
          attrs: { show: _vm.showUserDeleteConfirmation, showClose: true },
          on: { close: _vm.clearUserDeleteConfirmation }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Eliminar usuario")
          ]),
          _c(
            "form",
            {
              ref: "DeleteUserForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.deleteUser(_vm.userToDelete)
                }
              }
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row mt-1" }, [
                  _c("div", { staticClass: "col-xl-12" }, [
                    _vm._v(
                      "¿Está seguro que desea eliminar de forma permanente el usuario " +
                        _vm._s(_vm.userToDelete.usuario_ApellidoPaterno) +
                        " " +
                        _vm._s(_vm.userToDelete.usuario_ApellidoMaterno) +
                        " " +
                        _vm._s(_vm.userToDelete.usuario_Nombre) +
                        ", sus ventas, dispositivos y configuraciones?"
                    )
                  ])
                ])
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-4 text-right" }),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c(
                      "a",
                      { on: { click: _vm.clearUserDeleteConfirmation } },
                      [_vm._v("Cancelar")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4 text-right" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "my-4 error",
                          attrs: { type: "primary", nativeType: "submit" }
                        },
                        [_vm._v("Eliminar")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "modal",
        {
          attrs: { show: _vm.showConfirmDelete, showClose: true },
          on: { close: _vm.clearDeleteData }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Eliminar sucursal")
          ]),
          _c(
            "form",
            {
              ref: "DeleteBranchForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.deleteBranch(_vm.branchToDelete)
                }
              }
            },
            [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row mt-1" }, [
                  _c("div", { staticClass: "col-xl-12" }, [
                    _vm._v(
                      "Está seguro que desea eliminar la sucursal " +
                        _vm._s(_vm.branchToDelete.usuario_NombreNegocio)
                    )
                  ])
                ])
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-4 text-right" }),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c("a", { on: { click: _vm.clearDeleteData } }, [
                      _vm._v("Cancelar")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4 text-right" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "my-4 error",
                          attrs: { type: "primary", nativeType: "submit" }
                        },
                        [_vm._v("Eliminar")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c("app-user-info-modal", {
        attrs: { idUser: _vm.idUserInfo },
        on: {
          close: function($event) {
            _vm.idUserInfo = 0
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "card-header border-0",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h3",
                {
                  staticClass: "mb-0",
                  class: _vm.type === "dark" ? "text-white" : ""
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            ]),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "col-xl-3 text-right",
                    staticStyle: { "{background-color": "#0A0" }
                  },
                  [
                    _c("base-input-no-info", {
                      attrs: { placeholder: "Búsqueda" },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchUsers($event)
                        }
                      },
                      model: {
                        value: _vm.searchText,
                        callback: function($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSearch
              ? _c(
                  "div",
                  {
                    staticClass: "text-center align-self-center",
                    staticStyle: { "{background-color": "#00A", width: "80px" }
                  },
                  [
                    _c("download-button", {
                      attrs: {
                        endpoint: "/user/download",
                        payload: _vm.usersPayload,
                        reportName: "Usuarios"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary text-white",
                  on: { click: _vm.newAppUser }
                },
                [_vm._v("Nuevo")]
              )
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              class: _vm.type === "dark" ? "table-dark" : "",
              attrs: {
                "thead-classes":
                  _vm.type === "dark" ? "thead-dark" : "thead-light",
                "tbody-classes": "list",
                data: _vm.tableData
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(row.usuario_IdUsuario))
                      ]),
                      _c("td", [
                        _c("div", { staticClass: "h4" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.prepareShowUser(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.usuario_NombreNegocio))]
                          )
                        ]),
                        _c("div", { staticClass: "ligth-text" }, [
                          _vm._v(
                            _vm._s(row.usuario_ApellidoPaterno) +
                              " " +
                              _vm._s(row.usuario_ApellidoMaterno) +
                              " " +
                              _vm._s(row.usuario_Nombre)
                          )
                        ]),
                        _c("div", [_vm._v(_vm._s(row.usuario_Correo))]),
                        _c("div", [_vm._v(_vm._s(row.usuario_Celular))])
                      ]),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          class: { parentBranch: row.sucursales_level === 1 }
                        },
                        [
                          row.webUser_id && _vm.usersType === "branches"
                            ? _c(
                                "router-link",
                                { attrs: { to: _vm.selectBranchLink(row) } },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipDetailMsg,
                                        expression: "tooltipDetailMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "ni ni-shop icon-size"
                                  })
                                ]
                              )
                            : _vm._e(),
                          row.webUser_id && _vm.usersType === "branches"
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.prepareConfirmDelete(row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipDeleteMsg,
                                        expression: "tooltipDeleteMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "ni ni-fat-remove icon-size"
                                  })
                                ]
                              )
                            : _vm._e(),
                          !row.webUser_id
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.$emit("show-web-user", row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipPrincipalMsg,
                                        expression: "tooltipPrincipalMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "fas fa-home icon-size"
                                  }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e(),
                          !row.webUser_id
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.$emit("assign-to-company", row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipBranchMsg,
                                        expression: "tooltipBranchMsg",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass:
                                      "fas fa-network-wired icon-size"
                                  }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e(),
                          !row.webUser_id
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.prepareUpdateUser(row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipUpdateUser,
                                        expression: "tooltipUpdateUser",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "fas fa-edit icon-size"
                                  }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e(),
                          !row.webUser_id
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.prepareDeleteUser(row)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top-center",
                                        value: _vm.tooltipDeleteUser,
                                        expression: "tooltipDeleteUser",
                                        modifiers: { "top-center": true }
                                      }
                                    ],
                                    staticClass: "far fa-trash-alt icon-size"
                                  }),
                                  _vm._v("\n             \n          ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "columns" }, [
                _c(
                  "th",
                  { staticClass: "text-center", attrs: { width: "10%" } },
                  [_vm._v("ID")]
                ),
                _c("th", [_vm._v("Negocio")]),
                _c("th")
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card-footer d-flex justify-content-end",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("base-pagination", {
            attrs: {
              total: _vm.totalElements,
              perPage: _vm.pagination.size,
              value: _vm.pagination.page
            },
            on: { input: _vm.updatePagination }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }