var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-xl-2 mt-2 align-self-center" },
          [
            _c("v-select", {
              attrs: {
                placeholder: "Todas",
                options: ["Todas", "Aprobadas", "Canceladas"]
              },
              on: { input: _vm.changeTableData }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xl-3 mt-2 text-right align-self-center" },
          [
            _c(
              "base-input-no-info",
              { attrs: { "addon-left-icon": "ni ni-calendar-grid-58" } },
              [
                _c("flat-pickr", {
                  staticClass: "form-control datepicker",
                  attrs: { config: { allowInput: true, mode: "range" } },
                  on: { "on-close": _vm.startSearch },
                  model: {
                    value: _vm.dateRange,
                    callback: function($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "col-xl-3 mt-2 text-right align-self-center",
            staticStyle: { "{background-color": "#0A0" }
          },
          [
            _c("base-input", {
              staticStyle: { "margin-bottom": "0px" },
              attrs: {
                placeholder: "Búsqueda: (No. transacción, No. tarjeta)"
              },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                }
              },
              model: {
                value: _vm.paginationDTO.searchTerm,
                callback: function($$v) {
                  _vm.$set(_vm.paginationDTO, "searchTerm", $$v)
                },
                expression: "paginationDTO.searchTerm"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col  mt-2  text-center align-self-center" },
          [
            _c("download-button", {
              attrs: {
                endpoint: "/reports/transactions/download",
                payload: _vm.downloadPayload,
                reportName: "Reporte_transacciones"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "card-body border-0" }, [
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              attrs: { "thead-classes": "thead-light", data: _vm.tableData },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.idBranch <= 0
                        ? _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.branchName) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm.idBranch > 0
                        ? _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.username) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _c("td", [_vm._v(_vm._s(row.idPayment))]),
                      _c("td", [_vm._v(_vm._s(row.reference))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("typePayment")(row.paymentType)) +
                            " " +
                            _vm._s(_vm._f("typeCard")(row.cardType))
                        )
                      ]),
                      _c("td", { staticClass: "text-left" }, [
                        row.tip
                          ? _c("div", [
                              _vm._v(
                                "\n              monto:    " +
                                  _vm._s(_vm._f("currency")(row.amount)) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        row.tip
                          ? _c("div", [
                              _vm._v(
                                "\n              propina:  " +
                                  _vm._s(_vm._f("currency")(row.tip)) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _c("div", [
                          _vm._v(
                            "\n              Importe:  " +
                              _vm._s(_vm._f("currency")(row.amount + row.tip)) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("td", [
                        row.paymentStatus === "cancelled"
                          ? _c(
                              "span",
                              { staticClass: "badge badge-pill badge-danger" },
                              [_vm._v("Cancelada")]
                            )
                          : _vm._e(),
                        row.paymentStatus === "approved"
                          ? _c(
                              "span",
                              { staticClass: "badge badge-pill badge-success" },
                              [_vm._v("Aprobada")]
                            )
                          : _vm._e()
                      ]),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("formatDate")(row.creationDate)) +
                            " " +
                            _vm._s(_vm._f("formatOnlyTime")(row.creationDate)) +
                            "\n          "
                        )
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "columns" }, [
                _vm.idBranch <= 0 ? _c("th", [_vm._v("Sucursal")]) : _vm._e(),
                _vm.idBranch > 0
                  ? _c("th", [_vm._v("Dispositivos")])
                  : _vm._e(),
                _c("th", [_vm._v("Num. Op.")]),
                _c("th", [_vm._v("Referencia")]),
                _c("th", [_vm._v("Tipo Pago")]),
                _c("th", [_vm._v("Monto")]),
                _c("th", [_vm._v("Estado")]),
                _c("th", [_vm._v("Fecha")])
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "card-footer d-flex justify-content-end dark" },
      [
        _c("base-pagination", {
          attrs: {
            total: _vm.totalElements,
            perPage: _vm.pagination.size,
            value: _vm.pagination.page
          },
          on: { input: _vm.updatePagination }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-3 mt-2 align-self-center" }, [
      _c("div", [
        _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
          _vm._v("\n            Transacciones\n         ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }