var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showModal, showClose: true },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Selecciona una sucursal")
      ]),
      _c(
        "form",
        {
          ref: "AssignClientForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.assignItems($event)
            }
          }
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "card shadow",
                        staticStyle: { height: "400px" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "table-responsive" },
                          [
                            _vm.items.length > 0
                              ? _c(
                                  "base-table",
                                  {
                                    staticClass:
                                      "table align-items-center table-flush",
                                    attrs: {
                                      "tbody-classes": "list",
                                      data: _vm.items
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "th",
                                              { attrs: { scope: "row" } },
                                              [
                                                _c("base-checkbox", {
                                                  attrs: {
                                                    checked: row.isSelected
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.checkBoxSelected(
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              _vm._l(_vm.fields, function(
                                                field
                                              ) {
                                                return _c(
                                                  "div",
                                                  { key: field.key },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-size-sm align-middle",
                                                      class: field.icon
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticStyle: {} },
                                                      [
                                                        _vm._v(
                                                          _vm._s(" ") +
                                                            _vm._s(
                                                              field.description
                                                            ) +
                                                            _vm._s(
                                                              _vm.getValue(
                                                                row,
                                                                field.key
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("template", { slot: "columns" }, [
                                      _c("th", [_vm._v("Seleccionar")]),
                                      _c("th")
                                    ])
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "text-center center-data " },
                                  [
                                    _vm._v(
                                      "\n                La cuenta seleccionada no tiene sucursales disponibles\n              "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                { staticClass: "col-12 text-right" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v("Cerrar")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }