<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col mt-2 align-self-center">
          <div>
           <h3 h3 class="mb-0">
              Encuestas
           </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body border-0">
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="tableData">
          <template slot="columns">
            <th>Encuesta</th>
            <th>Promedio</th>

          </template>

          <template slot-scope="{row}">
            <th class="text-center align-self-center" scope="row">
              <survey-chart :data="row"></survey-chart>
            </th>
            <td style=" text-align: center; vertical-align: middle;">
              <h4 v-if=" row.average">
                {{row.average}}
              </h4>
              <h4 v-else>
                0.0
              </h4>
            </td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>
<script>
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import SurveyChart from '../../components/SurveyChart.vue';
import { getSurveyData } from '../../utils/api';


export default {
  name: "transactions-table",
  components: {
    SurveyChart
  },
  props: {
    idBranch: Number,
  },
  data() {
    return {
      tableData: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let loader = this.$loading.show({loader: 'dots'})
      getSurveyData(this.idBranch)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.tableData = response.data.result
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  },
  mounted() {}
};
</script>
