var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-xl-12" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              !this.showLinkInfo
                ? _c("div", { staticClass: "col-lg-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card bg-secondary shadow border-0 ",
                        staticStyle: { "margin-bottom": "250px" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "m-3",
                            staticStyle: { color: "#9C1C00" },
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v("CoDI")]
                        ),
                        _c(
                          "b-container",
                          [
                            _c("enterprise-branch-selector", {
                              attrs: {
                                errorEnterprise: _vm.validateField(
                                  "idEnterprise"
                                ),
                                errorBranch: _vm.validateField("idBranch")
                              },
                              on: {
                                "branch-selected": _vm.branchSelected,
                                "enterprise-selected": _vm.enterpriseSelected
                              }
                            }),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        "input-classes":
                                          "form-control-alternative",
                                        label: "Ingresa el monto*:",
                                        error: _vm.validateField("amount"),
                                        validation: "number"
                                      },
                                      model: {
                                        value: _vm.data.amount,
                                        callback: function($$v) {
                                          _vm.$set(_vm.data, "amount", $$v)
                                        },
                                        expression: "data.amount"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        "input-classes":
                                          "form-control-alternative",
                                        label: "Vigencia del código(Minutos)*:",
                                        error: _vm.validateField("minutes"),
                                        validation: "number"
                                      },
                                      model: {
                                        value: _vm.data.minutes,
                                        callback: function($$v) {
                                          _vm.$set(_vm.data, "minutes", $$v)
                                        },
                                        expression: "data.minutes"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        "input-classes":
                                          "form-control-alternative",
                                        label: "Número telefónico*:",
                                        error: _vm.validateField("phoneNumber"),
                                        maxlength: 10,
                                        validation: "number"
                                      },
                                      on: { input: _vm.validate },
                                      model: {
                                        value: _vm.data.phoneNumber,
                                        callback: function($$v) {
                                          _vm.$set(_vm.data, "phoneNumber", $$v)
                                        },
                                        expression: "data.phoneNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        "input-classes":
                                          "form-control-alternative",
                                        label: "Referencia:",
                                        error: _vm.validateField("reference")
                                      },
                                      on: { input: _vm.validate },
                                      model: {
                                        value: _vm.data.reference,
                                        callback: function($$v) {
                                          _vm.$set(_vm.data, "reference", $$v)
                                        },
                                        expression: "data.reference"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "base-button",
                          {
                            staticClass: "my-4 ml-3 mr-3",
                            attrs: { type: "primary" },
                            on: { click: _vm.validateAmounts }
                          },
                          [_vm._v("Solicitar cobro")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              this.showLinkInfo
                ? _c("div", { staticClass: "col-lg-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card bg-secondary shadow border-0 ",
                        staticStyle: { "margin-bottom": "250px" }
                      },
                      [
                        _c("span", { staticClass: "m-3" }, [
                          _vm._v("La liga de pago fue generada exitosamente")
                        ]),
                        _c(
                          "b-container",
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "7" } },
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        "input-classes":
                                          "form-control-alternative",
                                        label: "CoDi:",
                                        disabled: "true"
                                      },
                                      model: {
                                        value: _vm.dataUrl.link,
                                        callback: function($$v) {
                                          _vm.$set(_vm.dataUrl, "link", $$v)
                                        },
                                        expression: "dataUrl.link"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "base-button",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:copy",
                                            value: this.dataUrl.link,
                                            expression: "this.dataUrl.link",
                                            arg: "copy"
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.copy,
                                            expression: "copy",
                                            arg: "success"
                                          }
                                        ],
                                        staticClass: "my-4 ml-3 mr-3",
                                        staticStyle: { width: "70%" },
                                        attrs: { type: "primary" }
                                      },
                                      [_vm._v(" Copiar")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "7" } },
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        "input-classes":
                                          "form-control-alternative",
                                        label: "Email:",
                                        maxlength: 64,
                                        error: _vm.validateField("mail")
                                      },
                                      model: {
                                        value: _vm.dataUrl.mail,
                                        callback: function($$v) {
                                          _vm.$set(_vm.dataUrl, "mail", $$v)
                                        },
                                        expression: "dataUrl.mail"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "base-button",
                                      {
                                        staticClass: "my-4 ml-3 mr-3",
                                        staticStyle: { width: "70%" },
                                        attrs: { type: "primary" },
                                        on: { click: _vm.sendEmail }
                                      },
                                      [_vm._v("Compartir por correo de Zuma")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "base-button",
                          {
                            staticClass: "my-4 ml-3 mr-3",
                            attrs: { type: "primary" },
                            on: { click: _vm.resetData }
                          },
                          [_vm._v("Solicitar nuevo cobro")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }