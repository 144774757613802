<template>
  <modal :bigModal="true" :show="showCreateAccount" :showClose="true" v-on:close="$emit('close')">
    <div slot="header">
      <div class="container-fluid">
        <div class="row">Nueva cuenta</div>
        <div v-if="itemId !== 0">
          <span>
            <strong>{{  }}</strong>
          </span>
        </div>
      </div>
    </div>
    <card shadow type="secondary">
      <b-tabs pills card no-nav-style v-model="tabIndex" no-key-nav class="local-card">
            <!-- General data -->
            <b-tab title-item-class="disabledTab">
              <div class="col-xl-11">
                <h3 class="mb-3">Datos generales</h3>
              </div>
              <b-container style="width:100%" >
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de la cuenta: *"
                      v-model="item.name"
                      :error = "validateField('name')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Clave interna:"
                      v-model="item.internalKey"
                      :error = "validateField('internalKey')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Nombre de contacto: *"
                      v-model="item.contactName"
                      :error = "validateField('contactName')"
                      :capitalize='true'
                      :maxlength="128"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número Telefonico: *"
                      v-model="item.contactPhoneNumber"
                      :error = "validateField('contactPhoneNumber')"
                      :maxlength="16"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Extención:"
                      v-model="item.contactPhoneExt"
                      :error = "validateField('contactPhoneExt')"
                      :maxlength="8"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Celular: *"
                      v-model="item.contactMobileNumber"
                      :error = "validateField('contactMobileNumber')"
                      :capitalize='true'
                      :maxlength="16"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Email: *"
                      v-model="item.contactMail"
                      :error = "validateField('contactMail')"
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>
                <partner-selector
                  v-on:partner-selected='partnerSelected'
                ></partner-selector>
              </b-container>
            </b-tab>

            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Datos de ubicación</h3>
              </div>
              <b-container>
                <b-row class="mt-4">
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Código postal: * "
                      v-model="item.zipcode"
                      :error = "validateField('zipcode')"
                      :capitalize='true'
                      :maxlength="6"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Calle: * "
                      v-model="item.street"
                      :error = "validateField('street')"
                      :capitalize='true'
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                </b-row>
                <b-row >
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número ext: * "
                      v-model="item.extNumber"
                      :error = "validateField('extNumber')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Número int: "
                      v-model="item.intNumber"
                      :error = "validateField('intNumber')"
                      :capitalize='true'
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Colonia: *"
                      v-model="item.suburb"
                      :error = "validateField('suburb')"
                      :capitalize='true'
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Municipio: *"
                      v-model="item.county"
                      :error = "validateField('county')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Ciudad: * "
                      v-model="item.city"
                      :error = "validateField('city')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Estado: * "
                      v-model="item.state"
                      :error = "validateField('state')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Tipo Cliente (Marketing): * "
                      v-model="item.country"
                      :error = "validateField('country')"
                      :capitalize='true'
                      :maxlength="64"
                    ></base-input>
                  </b-col>
                </b-row>
              </b-container>

            </b-tab>
            
            <b-tab>
              <div class="col-xl-11">
                <h3 class="mb-3">Datos de la cuenta</h3>
              </div>
              <b-container>
                <!-- <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo de ventas por día:"
                      v-model="item.maxByDay"
                      :error = "validateField('maxByDay')"
                      validation="number"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Máximo de ventas por mes:"
                      v-model="item.maxByMonth"
                      :error = "validateField('maxByMonth')"
                      validation="number"
                    ></base-input>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <h3>Encuesta</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Texto"
                      v-model="item.surveyQuestion"
                      :error = "validateField('surveyQuestion')"
                      :maxlength="256"
                    ></base-input>
                  </b-col>
                  <b-col>
                    <base-input
                      input-classes="form-control-alternative"
                      label="Código"
                      v-model="item.surveyCode"
                      :error = "validateField('surveyCode')"
                      :maxlength="16"
                    ></base-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
        </b-tabs>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goPreviousPage" v-if="tabIndex > 0" >Atras</base-button>
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goNextPage" >{{textButton}}</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import flatpickr from "flatpickr";
import Spanish from "flatpickr/dist/l10n/es.js";
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { assingAccounts, getItemInfoByType, saveAccounts } from '../../utils/api';
import { validateMail } from '../../utils/utilMethods';
import { isEmptyOrNull } from '../../utils/generalMethods';
import PartnerSelector from '../../components/PartnerSelector.vue';

export default {
  components: { PartnerSelector },
  name: "new-account-modal",
  mixins: {axiosResponseMixin},
  props: {
    type: String,
    showCreateAccount: {
      type: Boolean
    },
    itemId: {},
    clean: Boolean
  },
  data() {
    return {
      item: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: 'N',
        internalKey: '',
        maxByDay: '0',
        maxByMonth: '0',
        maxByDayNoSwipped: '0',
        maxByMonthNoSwipped: '0',
        // status: 'A',
        surveyCode: '',
        surveyQuestion: '',
        zipcode: '',
        pin: '',
        pinRequired: 'N',
        actionsList: [],
        userPartnerName: '',
        userPartnerId: ''
      },
      emptyItem: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: 'N',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        maxByDayNoSwipped: '',
        maxByMonthNoSwipped: '',
        // status: "A",
        surveyCode: '',
        surveyQuestion: '',
        zipcode: '',
        pin: '',
        pinRequired: 'N',
        actionsList: [],
        userPartnerName: '',
        userPartnerId: ''
      },
      textButton: 'Siguiente',
      tabIndex: 0,
      numberTabs: 2,
      showErrorMessages: false,
    };
  },
  methods: {
    partnerSelected(item){
      this.item.userPartnerName = item.userPartnerName
      this.item.userPartnerId = item.userPartnerId
    },
    handleError(error) {
      this.$emit('error', error)
    },
     goPreviousPage(){
      this.tabIndex --
    },
    goNextPage() {
      this.showErrorMessages = true                
      if (!this.canContinue()) {
        this.showErrorMessages = true
        return
      } else{
        this.showErrorMessages = false
      }
      if (this.tabIndex === this.numberTabs ) {
          this.saveAccount()
      } else {
          this.tabIndex++;
          this.textButton = this.tabIndex === this.numberTabs ? "Continuar" : "Siguiente"
      }
    },
    checkIfHasPartner(data){
      const idAccount = data.result.idAccount
      if(isEmptyOrNull(this.item.userPartnerId)){
          this.$toasted.show('Cuenta creada correctamente')
          this.$emit('account-created')
      }else{
        let loader = this.$loading.show({loader: 'dots'})
        assingAccounts({idUserPartner: this.item.userPartnerId, idAccounts: [idAccount]})
        .then(response => {
          loader.hide()
          if (response.data.statusCode !== 200) {
            processErrorResponse(response.data, this.$toasted )
          }
          this.$toasted.show('Cuenta creada correctamente')
          this.$emit('account-created')
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
          this.$toasted.show('Cuenta creada correctamente')
          this.$emit('account-created')
        })
      }
      
    },
    saveAccount() {
      this.showErrorMessages = true
      if (this.canContinue()){
        let loader = this.$loading.show({loader: 'dots'})
        this.item.maxByDay = Number(this.item.maxByDay)
        this.item.maxByMonth = Number(this.item.maxByMonth)
        this.item.maxByDayNoSwipped = Number(this.item.maxByDayNoSwipped)
        this.item.maxByMonthNoSwipped = Number(this.item.maxByMonthNoSwipped)
        saveAccounts(this.item)
        .then(response => {
          loader.hide()
          if(response.data.statusCode === 200) {
            this.checkIfHasPartner(response.data)
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      }
    },
    canContinue() {
      var keys = Object.keys(this.item)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    validateField(key) {
      if (this.showErrorMessages) {
        if (this.tabIndex === 0) {
          switch (key) {
            case 'name':
              return this.item.name === '' ? 'Ingresa el nombre de la cuenta' : ''
            case 'contactName':
              return this.item.contactName === '' ? 'Ingresa el nombre de contacto' : ''
            case 'contactPhoneNumber':
              return this.item.contactPhoneNumber === '' 
              || this.item.contactPhoneNumber.length < 10 ? 'El número telefónico no es valido' : ''
            case 'contactMobileNumber':
              return this.item.contactMobileNumber === ''
              || this.item.contactMobileNumber.length < 10  ? 'El número de celular no es valido' : ''
            case 'contactMail':
              return !validateMail(this.item.contactMail) ? 'El correo electrónico no es valido' : ''
            case 'internalKey':
              return isEmptyOrNull(this.item.internalKey ) ? 'Es necesario ingresar la clave interna' : ''
          }
        }else if (this.tabIndex === 1) {
          switch (key) {
            case 'street':
              return this.item.street === '' ? 'Ingresa la calle' : ''
            case 'extNumber':
              return this.item.extNumber === '' ? 'Ingresa el número del domicilio' : ''
            case 'city':
              return this.item.city === '' ? 'Ingresa la ciudad' : ''
            case 'state':
              return this.item.state === '' ? 'Ingresa el estado' : ''
            case 'country':
              return this.item.country === '' ? 'Ingresa el tipo de cliente' : ''
            case 'zipcode':
              return isEmptyOrNull(this.item.zipcode) ? 'Ingresa el código postal' : ''
          }
        }else {
          switch (key) {
            case 'maxByDay':
              return this.item.maxByDay === '' ? 'Ingresa la cantidad maxima por día' : ''
            case 'maxByMonth':
              return this.item.maxByMonth === '' ? 'Ingresa la cantidad maxima por mes' : ''
            case 'businessName':
              return this.item.businessName === '' ? 'Ingresa el nombre del negocio' : ''
            case 'surveyQuestion':
              return this.item.surveyQuestion === '' ? 'Ingresa la pregunta de la encuesta' : ''
            case 'surveyCode':
              return this.item.surveyCode === '' ? 'Ingresa el código de la encuesta' : ''
            
          }
        }
        return '';
      }
    },
    cleanData() {
      this.item = Object.assign({}, this.emptyItem)
      this.showErrorMessages = false
      this.tabIndex = 0
    },
  },
  watch: {
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    },
    itemId: {
      handler(newValue) {
        if (newValue) {
          getItemInfoByType(this.type, newValue)
          .then(response => {
            if (response.data.statusCode === 200) {
              this.item = response.data.result
            }
          })
          .catch(error => {
            this.$emit('error', error);
          })
        }
      }
    }
  }
};
</script>

<style >
.disabledTab {
  pointer-events: none;
}

.local-card .card-header {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}

.body {
  font-size: 14px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.t {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.editor-w {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.w-2 {
  float: left;
  width: 100%;
}

.editor {
  padding: 20px 60px;
}

.pure-form select{
  min-height: 2.75em;
}

/* .big-modal-dialog {
  max-width: 90% ;
} */
</style>