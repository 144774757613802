const typeAccess = {
    ACCOUNT: 'Cuenta',
    ENTERPRISE: 'Empresa',
    BRANCH: 'Sucursal',
    ACCOUNT_E: 'Account',
    ENTERPRISE_E: 'Enterprise',
    BRANCHE_E: 'Branch'
}

export default Object.freeze(typeAccess)

export const getTypeAccessEnglish = (value) => {
    switch (value) {
        case typeAccess.ACCOUNT:
            return typeAccess.ACCOUNT_E;
        case typeAccess.ENTERPRISE:
            return typeAccess.ENTERPRISE_E;
        case typeAccess.BRANCH:
            return typeAccess.BRANCHE_E
    }
    return ''
}

export const getTypeAccessSpanish = (value) => {
    switch (value) {
        case typeAccess.ACCOUNT_E:
            return typeAccess.ACCOUNT;
        case typeAccess.ENTERPRISE_E:
            return typeAccess.ENTERPRISE;
        case typeAccess.BRANCHE_E:
            return typeAccess.BRANCH
    }
    return ''
}