<template>
  <modal :bigModal="true" :show="showEnterpriseDetails" :showClose="true" v-on:close="$emit('close')">
    <div slot="header">
      <div class="container-fluid">
        <div class="row">Detalles de la empresa</div>
      </div>
    </div>
    
    <card shadow type="body">
      <div class="bg-white border-0">
        <b-tabs
          pills
          v-model="tabIndex"
          content-class="mt-3"
          no-key-nav
        >
          <b-tab title-item-class="flex-fill text-center " title="Cuenta">
            <span slot="title">Generales</span>
            <b-container style="width:100%" >
              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Nombre de la empresa: *"
                    v-model="item.name"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Nombre de contacto: *"
                    v-model="item.contactName"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Número Telefonico: *"
                    v-model="item.contactPhoneNumber"
                    
                    :maxlength="10"
                    validation="number"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Extención:"
                    v-model="item.contactPhoneExt"
                    
                    :maxlength="10"
                    validation="number"
                  ></base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Celular: *"
                    v-model="item.contactMobileNumber"
                    
                    :capitalize='true'
                    :maxlength="10"
                    validation="number"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Email: *"
                    v-model="item.contactMail"
                    
                    :maxlength="64"
                  ></base-input>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title-item-class="flex-fill text-center ">
            <span slot="title">Dirección</span>
            <b-container>
              <b-row class="mt-4">
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Calle: * "
                    v-model="item.street"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Número ext: * "
                    v-model="item.extNumber"
                    
                    :capitalize='true'
                    :maxlength="10"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Número int: "
                    v-model="item.intNumber"
                    
                    :capitalize='true'
                    :maxlength="10"
                  ></base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Colonia: *"
                    v-model="item.suburb"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Municipio: *"
                    v-model="item.county"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Ciudad: * "
                    v-model="item.city"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Estado: * "
                    v-model="item.state"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Tipo Cliente (Marketing): * "
                    v-model="item.country"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title-item-class="flex-fill text-center " title="Administrador">
            <span slot="title">Empresa</span>
              <b-container>
              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Nombre del negocio:"
                    v-model="item.businessName"
                    
                    :capitalize='true'
                    :maxlength="40"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Clave interna:"
                    v-model="item.internalKey"
                    
                    :capitalize='true'
                    :maxlength="16"
                  ></base-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Máximo de ventas por día:"
                    v-model="item.maxByDay"
                    
                    validation="number"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Máximo de ventas por mes:"
                    v-model="item.maxByMonth"
                    
                    validation="number"
                  ></base-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Número de cuenta CLABE:"
                    v-model="item.bankTransferNumber"
                    
                    :maxlength="18"
                  ></base-input>
                </b-col>
                <b-col>
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="RFC:"
                    v-model="item.tin"
                    
                    :maxlength="13"
                  ></base-input>
                </b-col>
              </b-row>
              <b-row>

                <b-col>
                  <slot name="helpBlock">
                    <div class="h4">
                        Giro de la empresa:
                    </div>
                  </slot>
                  <slot name="helpBlock">
                    <div>
                        {{businessActivityName}}
                    </div>
                  </slot>
                </b-col>                  
              </b-row>
              <b-row v-show="item.strFiscalRegimen === 'Otros'">
                <b-col class="mt-3">
                  <base-input
                    disabled
                    input-classes="form-control-alternative"
                    label="Especifique el Giro:"
                    v-model="item.idBusinessActivity"
                    
                  ></base-input>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="$emit('close')">Cerrar</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { getBusinessActivities, getEnterpriseInfo } from '../../utils/api';

export default {
  name: "enterprise-details-modal",
  components: {
  },
  mixins: {axiosResponseMixin},
  props: {
    idEnterprise: Number,
    showEnterpriseDetails: {
      type: Boolean
    },
    itemId: {},
    clean: {}
  },
  data() {
    return {
      item: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        bankData: '',
        businessName: '',
        idBusinessActivity: '',
        tin: '',
        bankTransferNumber: ''
      },
      emptyItem: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        internalKey: '',
        inheritable: 'Y',
        maxByDay: '',
        maxByMonth: '',
        bankData: '',
        businessName: '',
        idBusinessActivity: '',
        tin: '',
        bankTransferNumber: '',
      },
      businessActivityName: '',
      tabIndex: 0,
      tabNumber: 2,
    };
  },
  methods: {
    handleError(error) {
      this.$emit('error', error)
    },
    getEnterpriseDetails(){
      getEnterpriseInfo(this.idEnterprise)
      .then(response=>{
        if (response.data.statusCode === 200) {
          this.item = response.data.result
          this.getBusinessActivity()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
    getBusinessActivity() {
      getBusinessActivities()
      .then(response => {
        if (response.data.statusCode === 200){
          response.data.result.list.forEach(element => {
            if(element.idBusinessActivities === this.item.idBusinessActivity){
              this.businessActivityName = element.name
            }
          });
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    }
  },
  watch: {
    idEnterprise: {
      handler(newValue){
        if (newValue > 0){
          this.getEnterpriseDetails()
        }else{
          this.item = this.emptyItem
          this.tabIndex = 0
        }
      }
    }
  }
};
</script>
<style >
.local-card .card-header {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}
</style>