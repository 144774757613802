<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <div class="row justify-content-center">
            <div v-if="!this.showLinkInfo" class="col-lg-6">
              <div class="card bg-secondary shadow border-0 " style="margin-bottom: 250px">
                <span slot="title" class="m-3" style="color: #9C1C00">ZumaLink</span>
                  <b-container>
                    <enterprise-branch-selector
                      v-on:branch-selected="branchSelected"
                      v-on:enterprise-selected="enterpriseSelected"
                      :errorEnterprise="validateField('idEnterprise')"
                      :errorBranch="validateField('idBranch')"
                    />
                    <b-row>
                      <b-col>
                        <label class="form-control-label">
                          Selecciona tipo de pago.
                        </label>
                        <div class="mt-1" style="background: white" >
                          <v-select 
                            label="label"
                            :value="promotionSelected"
                            :reduce="label => label.cardPromotionCode"
                            v-model="data.promotion"
                            placeholder = 'Seleccione una opción'
                            :options="promotions"
                            ></v-select>
                        </div>
                        <slot name="helpBlock">
                          <div class="text-danger invalid-feedback" style="display: block;" >
                              {{validateField('promotion')}}
                          </div>
                        </slot>
                      </b-col>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Vigencia del código(Días)*:"
                          v-model="data.expire"
                          :error = "validateField('expire')"
                          validation="number"
                        ></base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Ingresa el monto*:"
                          v-model="data.amount"
                          :error = "validateField('amount')"
                          validation="number"
                        ></base-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Referencia:"
                          v-model="data.reference"
                          :error = "validateField('reference')"
                          :maxlength="40"
                          @input="validate"
                        ></base-input>
                      </b-col>
                    </b-row>
                  </b-container>
                  <base-button type="primary" class="my-4 ml-3 mr-3"  @click="validateAmounts" >Generar liga de pago</base-button>
              </div>
            </div>

             <div  v-if="this.showLinkInfo" class="col-lg-6">
              <div class="card bg-secondary shadow border-0 " style="margin-bottom: 250px">
                    <span class="m-3">La liga de pago fue generada exitosamente </span>
                   <b-container>
                    <b-row align-v="center">
                      <b-col cols="7">
                        <base-input
                          input-classes="form-control-alternative"
                          label="ZumaLink:"
                          v-model="dataUrl.link"
                          disabled = "true"
                        ></base-input>
                      </b-col>
                      <b-col cols="5" >
                        <base-button  
                          v-clipboard:copy="this.dataUrl.link" 
                          v-clipboard:success="copy" 
                          type="primary" 
                          class="my-4 ml-3 mr-3"  
                          style="width: 70%"> Copiar</base-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="7" >
                        <base-input
                          input-classes="form-control-alternative"
                          label="Email:"
                          v-model="dataUrl.mail"
                          :maxlength="64"
                          :error = "validateField('mail')"
                        ></base-input>
                      </b-col>
                      <b-col cols="5" >
                        <base-button type="primary" class="my-4 ml-3 mr-3" style="width: 70%"  @click="sendEmail" >Compartir por correo de Zuma</base-button>
                      </b-col>
                    </b-row>
                  </b-container>
                <base-button type="primary" class="my-4 ml-3 mr-3"  @click="resetData" >Generar una nueva liga</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EnterpriseBranchSelector from '../components/EnterpriseBranchSelector.vue';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { generateZumaLink, getProductCode, getProductCodeEnterp, sendLinkByEmail, validateAmount } from '../utils/api';
import { isEmptyOrNull, validateMail } from '../utils/utilMethods';
 export default {
  components: { EnterpriseBranchSelector },
      name: 'link-generator', 
      data() {
        return {
          showErrorMessages: false,
          showLinkInfo: false,
          promotions: [{label: '', cardPromotionCode: null}],
          promotionSelected: {label: '', cardPromotionCode: null},
          data: {
              amount: '',
              reference: '',
              idBranch: '',
              idEnterprise: '',
              productCode: "",
              promotion: "",
              expire: 2,
              paymentChannel: "ZUMA_ZPOS",
            
          },
          dataUrl: {
              link: '',
              mail: '',
              idBranch: '',
                 
          },
          productCodeBackup: '',
          isDevelop: false
        }
      },
      methods: {
        resetData() {
          this.showErrorMessages = false;
          this.showLinkInfo = false
          this.data = {
              amount: '',
              reference: '',
              productCode: this.productCodeBackup,
              promotion: "",
              expire: 2,
              paymentChannel: "ZUMA_ZPOS",
            
          }
          this.dataUrl = {
              link: '',
              mail: ''
          }
          this.selectDefaulPromotion()
        },
        validateAmounts(){
          this.showErrorMessages = true
          if (this.canContinue()) {
            let dto = {}
            dto.amount = Number(this.data.amount)
            dto.promotion = this.data.promotion
            dto.productCode = this.data.productCode 
            dto.idBranch = this.data.idBranch
            let loader = this.$loading.show({loader: 'dots'})
            validateAmount(dto)
            .then(response=>{
              loader.hide()
              if (response.data.statusCode === 200) {
                this.generateLink()
              }else{
                processErrorResponse(response.data, this.$toasted)
              }
            })
            .catch(error => {
              loader.hide()
              this.$emit('error', error)
            })
          }
        },
        generateLink() {
             let loader = this.$loading.show({
              loader: 'dots'
            });
            this.data.amount = Number(this.data.amount)
            generateZumaLink(this.data)
              .then((response) => {
                loader.hide()
                if (response.data.statusCode === 200) {                  
                  this.showErrorMessages = false
                  this.showLinkInfo = true
                  this.dataUrl.link = response.data.result.code
                }else {
                  processErrorResponse(response.data, this.$toasted)
                }
              })
              .catch(error => {
                loader.hide()
                this.$emit('error', error)
              })
        },
        canContinue() {
          let keys = Object.keys(this.data)
          for (let i = 0; i < keys.length; i++) {
            let canContinue = this.validateField(keys[i]);
            if (canContinue !== '')
              return false;
          }
          return true;
        },
        sendEmail() {
          this.showErrorMessages = true
          if (this.validateField('mail') === '') {            
            let loader = this.$loading.show({
              loader: 'dots'
            });
            this.dataUrl.idBranch = this.data.idBranch
            sendLinkByEmail(this.dataUrl)
            .then(response=>{
              loader.hide()
              if (response.data.statusCode === 200) {
                this.$toasted.show("El email fue enviado exitosamente");
              }else{                
                processErrorResponse(response.data, this.$toasted)
              }
            })
            .catch(error => {
              loader.hide()
              this.$emit('error', error)
            })
          }
        },
        validate() {
          if(this.data.reference.length > 40) {
            this.data.reference = this.data.reference.substring(0, 40)
          }          
        },
        validateField(key) {
          if (this.showErrorMessages) {
            switch (key) {
              case 'amount': {
                let amount = this.data.amount;
                if (amount === '') {
                  return 'Ingresa un monto para continuar'
                }else{
                  return ''
                } 
              }
              case 'promotion':
                return isEmptyOrNull(this.data.promotion) ? 'Es necesario seleccionar una promoción' : ''
              case 'expire': {
                let expire = this.data.expire;
                if (expire === '') {
                  return 'Ingresa los días de valides del código'
                }else{
                  let expire = parseInt(this.data.expire);
                  if (expire <= 0 || expire > 2) {
                    return 'Los días de valides deben ser mayor a 0 y menor a 2'
                  }else{
                    this.data.expire = Number(this.data.expire)
                    return ''
                  }
                } 
              }
              case 'idBranch':
                return isEmptyOrNull(this.data.idBranch) ? 'Es necesario seleccionar una sucursal' : ''
              case 'idEnterprise':
                return isEmptyOrNull(this.data.idEnterprise) ? 'Es necesario seleccionar una empresa' : ''
              case 'reference':
                return ''
              case 'mail':
                return this.dataUrl.mail === '' 
                        || !validateMail(this.dataUrl.mail) ? 'El correo electrónico no es valido' : ''
              default:
                return '';
            }
          }       
        },
        copy() {
          this.$toasted.show("En link fue copiado al porta papeles");
        },
        branchSelected(value){
          this.data.idBranch = value
        },
        enterpriseSelected(value){
          this.data.idEnterprise = value
          this.getProduct();
        },
        getProduct(){
          let idEnterprise = Number(this.data.idEnterprise)
          getProductCodeEnterp(idEnterprise)
          .then(response=>{
            if (response.data.statusCode === 200) {
              this.data.productCode = response.data.result.productCode
              this.productCodeBackup = response.data.result.productCode
              this.promotions = response.data.result.promotions
              this.createLabelSelectorPromotion()
              this.selectDefaulPromotion()
            }else{
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            this.$emit('error', error)
          })

        },
        selectDefaulPromotion(){
          if (this.promotions.length > 0){
            this.promotionSelected = this.promotions.find(function (item) { return item.cardPromotionCode === '1' });
            this.data.promotion = this.promotionSelected.cardPromotionCode
          }
        },
        createLabelSelectorPromotion() {
          this.promotions.map(function (x){
            if (x.cardPromotionCode === '1'){
              return x.label = 'Pago único';
            }else{
              return x.label = x.cardPromotionCode + ' MSI';
            }
          });
        },
      },
      mounted(){
      }
  }
</script>
<style lang="stylus">

</style>