var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xl-3 col-lg-6" },
      [
        _c("stats-card", {
          staticClass: "mb-4 mb-xl-0",
          attrs: {
            title: "Sucursales",
            type: "text-blue",
            "sub-title": _vm._f("currency")(_vm.branches, "", 0),
            icon: "ni ni-shop"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xl-3 col-lg-6" },
      [
        _c("stats-card", {
          staticClass: "mb-4 mb-xl-0",
          attrs: {
            title: _vm.salesTitle,
            type: "text-red",
            "sub-title": _vm._f("currency")(_vm.salesData.sum, "$", 2),
            icon: "ni ni-money-coins"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }