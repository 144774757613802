<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <survey-table
          :idBranch="idBranch">
          </survey-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import SurveyTable from './Tables/SurveyTable.vue';

export default {
  mixins: [handleErrorMixin],
  components: {
    SurveyTable
  },
  data() {
    return {
      idBranch: Number(this.$route.params.id)
    };
  }
};
</script>
<style>
.disabledTab {
  pointer-events: none;
}
</style>
