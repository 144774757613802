<template>
  <div class="card shadow" style="height: 400px;">
    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  tbody-classes="list"
                  :data="items">
        <template slot="columns">
          <th v-if="isMultipleSelection">Seleccionar</th>
          <th>{{getType()}}</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row" v-if="isMultipleSelection">
            <base-checkbox v-on:input="checkBoxSelected(row, $event)"></base-checkbox>
          </th>
          <td @click="rowSelected(row)" :class="!isMultipleSelection ? 'mycursor': ''">
            <div >
              <i class="ni ni-circle-08 icon-size-sm align-middle"></i>
              <span v-if="row.name" :class="detailClass">{{" "}}{{row.name}}</span>
              <span v-else-if="row.fullname" :class="detailClass">{{" "}}{{row.fullname}}</span>
              <span v-else :class="detailClass">{{" "}}{{desconocido}}</span>
            </div>

             <div >
              <i class="ni ni-email-83 icon-size-sm align-middle"></i>
              <span v-if="row.contactMail" :class="detailClass">{{" "}}{{row.contactMail}}</span>
              <span v-else-if="row.email" :class="detailClass">{{" "}}{{row.email}}</span>
              <span v-else :class="detailClass">{{" "}}{{desconocido}}</span>
            </div>

             <div v-if="row.contactEnterprise" >
              <i class="ni ni-building icon-size-sm align-middle"></i>
              <span :class="detailClass">{{" "}}{{row.contactEnterprise}}</span>
            </div>
             <div v-if="row.partnerName" >
              <i class="ni ni-badge icon-size-sm align-middle"></i>
              <span :class="detailClass">{{" "}}{{row.partnerName}}</span>
            </div>
            <!-- <div>
              <label>Vend: </label> &nbsp;<strong>{{'No asignado'}}</strong>
            </div> -->
          </td>
        </template>

      </base-table>
    </div>
  </div>
</template>
<script>

import _ from 'lodash';
import { searchItemsByType } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import enums from '../../enums/enums';
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";

  export default {
    name: 'search-account-table',
    mixins: [infoCSSClasesMixin],
    props: {
      type: String,
      showAssignItem: Boolean,
      isMultipleSelection: Boolean,
      searchTerm: {
        type: String,
        description:
          'The search term for user, this gona search on the email field (username)',
      },
      clean: {
        type: Boolean,
        description:
          'Indicates this component to clean itself'
      }
    },
    data() {
      return {
        show: true,
        items: [] 
      }
    },
    watch: {
      showAssignItem: {
        handler(newValue){
          if (newValue){
            this.searchItems()
          }
        }
      },
      searchTerm: {
        handler: _.debounce(function(newVal){
          if(newVal.length > 1){
            this.searchItems()
          }
        }, 500),
        deep: true
      },
      
      clean(newVal){
        if(newVal){
          this.items = [];
          this.$emit('cleaned');
        }
      }
    },
    mounted() {
    },
    methods:{
      searchItems() {
        searchItemsByType(this.type, {searchTerm: this.searchTerm, offset: 0, limit: 1000000})
            .then(response => {
              if (response.data.statusCode === 200) {
                this.items = response.data.result.list
              }else{
                processErrorResponse(response.data, this.$toasted)
              }
            })
            .catch(error => {
              
              this.$emit('error', error)
            })
      },
      getType(){
        switch (this.type) {
          case enums.P_ACCOUNT:
            return "Vendedor"
          case enums.C_ACCOUNT:
            return "Cuenta"
          case enums.DEVICE:
            return "Sucursal"
          case enums.EXTERNAL:
            return "Sucursal" 
          default:
            return "";
        }
      },
      userTypeDescription(row){
        const findType = this.userTypes.find( (type) => row.role === type.key );
        if(findType){
          return findType.value;
        }
        return "";
      },
      checkBoxSelected(row){
        let item = {}
        item.id = row.idAccount
        item.fullName = row.name
        this.$emit('item-selected', {data: item});
      },
      rowSelected(row){
        if(this.isMultipleSelection) {
          return
        }
        let item = {}
        item.fullName = row.name
        switch (this.type) {
          case enums.P_ACCOUNT:
            item.id = row.idUser
            item.fullName = row.fullname
            break
          case enums.ACCOUNT:
            item.id = row.idAccount
            break
          case enums.BRANCH:
            item.id = row.idEnterprise
            break
          case enums.ENTERPRISE:
            item.id = row.idAccount
            break
          case enums.C_ACCOUNT:
            item.id = row.idAccount
            break
          case enums.DEVICE:
          case enums.EXTERNAL:
             item.id = row.idBranch 
            break
          default:
            return "";
        }
        this.$emit('item-selected', {data: item});
      }
    }
  }
</script>
<style>
.mycursor {
  cursor: pointer;
}
</style>
