<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">{{title}}</h3>
        </div>
        <div v-if="showSearch" class="col-xl-3" style="{background-color: #0A0;}">
          <base-input-no-info addon-left-icon="ni ni-calendar-grid-58">
            <flat-pickr
              slot-scope="{focus, blur}"
              @on-open="focus"
              @on-close="blur"
              :config="{allowInput: true, mode: 'range'}"
              class="form-control datepicker"
              v-model="dateRange"
              @keyup.enter="searchCompanies"
            ></flat-pickr>
          </base-input-no-info>
        </div>
        <div v-if="showSearch" class="col-xl-3 text-right" style="{background-color: #0A0;}">
          <base-input-no-info
            placeholder="Búsqueda"
            @keyup.enter="searchCompanies"
            v-model="searchText"
          ></base-input-no-info>
        </div>
        <div
          v-if="showSearch"
          class="text-center align-self-center"
          style="{background-color: #00A; width: 80px;}"
        >
          <download-button
            endpoint="/user/searchCompanies/download"
            :payload="companiesDownloadPayload"
            reportName="Empresas"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th class="text-center">ID</th>
          <th>Negocio</th>
          <th class="text-center">Fecha alta</th>
          <th class="md-screen-hide text-center">Vendedor</th>
          <th
            class="text-right"
            v-for="n in monthsToDisplay"
            :key="n"
          >{{monthNames[monthsToDisplay - n]}}</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td class="text-center">{{row.usuario_IdUsuario}}</td>
          <td>
            <router-link v-if="row.webUser_id " :to="companyTransactionLink(row)">
              <div class="h4">
                <i v-if="row.level === 1" class="ni ni-building"></i>
                {{row.usuario_NombreNegocio}}
              </div>
            </router-link>
            <div
              class="ligth-text"
            >{{row.usuario_ApellidoPaterno}} {{row.usuario_ApellidoMaterno}} {{row.usuario_Nombre}}</div>
            <div>{{row.usuario_Correo}}</div>
            <div>{{row.usuario_Celular}}</div>
          </td>
          <td>{{ row.webUser_createdAt | formatDate}}</td>
          <td
            class="md-screen-hide text-center"
          >{{row.vendedor_nbNombre}} {{row.vendedor_apPaterno}}</td>
          <td
            class="text-right"
            v-for="n in monthsToDisplay"
            :key="n"
          >{{ (row[monthNames[monthsToDisplay - n]] || "0.00") | currency }}</td>
          <td class="text-right">
            &nbsp;&nbsp;
            <router-link v-if="row.webUser_id" :to="branchesLink(row.webUser_id)">
              <i v-tooltip.top-center="tooltipCompanyDetailMsg" class="ni ni-shop icon-size"></i>
            </router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent': ''"
    >
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import genericJoin from "../../mixins/joinArraysHelper";
import monthSalesMixin from "../../mixins/monthSalesMixin";
import DownloadButton from "../Buttons/DownloadButton";
import paginationMixin from "../../mixins/paginationMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "companies-table",
  mixins: [monthSalesMixin, paginationMixin],
  components: {
    flatPickr,
    DownloadButton
  },
  props: {
    type: {
      type: String
    },
    title: String,
    mitDataSaved: Number,
    webUserCreated: Number,
    refreshTable: Boolean,
    mobileUserId: {
      type: Number,
      description:
        "Used when usersType === branches this is the id of the company we want branches for"
    },
    showSearch: {
      default: true
    }
  },
  data() {
    return {
      tableData: [],
      searchText: "",
      branchToDelete: {},
      tooltipCompanyDetailMsg: "Ver negocio",
      tooltipTransactionMsg: "Transacciones sucursal",
      searched: false,
      dateRange: "",
      dates: {}
    };
  },
  computed: {
    /**
     * Dont have pagination because we want and excel with all the information in the file
     */
    companiesDownloadPayload() {
      return {
        txEmail: this.searchText,
        dates: this.dates
      };
    }
  },
  watch: {
    mitDataSaved: {
      handler(newValue) {
        const objIndex = this.tableData.findIndex(
          tableData => tableData.usuario_IdUsuario === newValue
        );

        if (objIndex > -1) {
          const newRow = Object.assign(this.tableData[objIndex], {
            configuracionesLectorUsuario_IdConfiguraciones: 1
          });
          this.$set(this.tableData, objIndex, newRow); // https://vuejs.org/2016/02/06/common-gotchas/
        }
      },
      deep: true
    },
    webUserCreated: {
      handler(newValue) {
        const objIndex = this.tableData.findIndex(
          tableData => tableData.usuario_IdUsuario === newValue
        );

        if (objIndex > -1) {
          const newRow = Object.assign(this.tableData[objIndex], {
            webUser_id: 1
          });
          this.$set(this.tableData, objIndex, newRow); // https://vuejs.org/2016/02/06/common-gotchas/
        }
      },
      deep: true
    },
    refreshTable: {
      handler(newValue) {
        if (newValue) {
          this.searchCompanies();
        }
      }
    },
    mobileUserId: {
      handler(newValue) {
        if (newValue > 0) {
          this.searchCompanies();
        }
      }
    },
    dateRange(newVal) {
      if (newVal.includes(" a ")) {
        this.dates.start = newVal.split(" a ")[0];
        this.dates.end = newVal.split(" a ")[1];

        if (this.dates.start && this.dates.end) {
          this.searchCompanies();
        }
      }
    }
  },
  methods: {
    branchesLink(row) {
      return `/company/${row}/branches`;
    },
    selectBranchLink(row) {
      return `/branch/${row.sucursales_idBranch}`;
    },
    deleteBranch(row) {
      this.axios
        .delete(`/branch/${row.sucursales_idBranch}`)
        .then(() => {
          this.searchCompanies();
          this.$toasted.show(
            `Sucursal ${this.branchToDelete.usuario_NombreNegocio} eliminada`
          );
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.clearDeleteData();
        });
    },
    searchCompanies() {
      let bodyRequest = {
        txEmail: this.searchText,
        dates: this.dates,
        pagination: this.pagination
      };
      this.axios
        .post("/user/searchCompanies", bodyRequest)
        .then(response => {
          const BRANCH_DELETED = 2;
          this.tableData = response.data.users.filter(
            e => e.sucursales_status !== BRANCH_DELETED
          );
          this.pagination = response.data.pagination;
          this.totalElements = parseInt(response.data.total);
          this.$emit("loaded-users");
          let companiesId = this.tableData.map(x => x.IdBranch);
          return this.axios.post("/sales/yearreport", { companiesId });
        })
        .then(results => {
          for (let r of results.data.sales) {
            r.company = parseInt(r.company);
          }

          const approvedSales = results.data.sales.filter(
            r => r.estatusVenta === "approved"
          );

          let joinedData = genericJoin(
            "IdBranch",
            "company",
            this.tableData,
            approvedSales
          );

          this.tableData = joinedData;
          this.$emit("loaded-sales");
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {});
    },
    companyTransactionLink(row) {
      return `/company/${row.IdBranch}/transaction-detail`;
    },
    loadData() {
      this.searchCompanies();
    }
  },
  mounted() {
    this.initializeMonthData();
    this.loadData();
  }
};
</script>
<style lang="scss">
@media (max-width: 1400px) {
  .md-screen-hide {
    display: none;
  }
}
.ligth-text {
  color: #828f7f;
}
.parentBranch {
  background-color: cornsilk;
}
.icon-size {
  font-size: 1rem;
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
