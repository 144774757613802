const products = {
    RESTAURANTE: 'ZPOS_RESTAURANTE',
    QPOS: 'ZUMA_QPOS',
    RETAIL: 'ZPOS_RETAIL',
    LINK_MIT: 'ZUMA_LINK_MIT',
    LINK_PRAGA: 'ZUMA_LINK_PRAGA',
    CODI: 'ZUMA_CODI',
    RETAIL_BZPAY: 'ZPOS_RETAIL_BZPAY',
    RESTAURANT_BZPAY : 'ZPOS_RESTAURANT_BZPAY',
    LINK_BZPAY : 'ZUMA_LINK_BZPAY',
    ZPOS_RETAIL_AFIRME: 'ZPOS_RETAIL_AFIRME',
    ZPOS_RESTAURANT_AFIRME: 'ZPOS_RESTAURANT_AFIRME',
    ZUMA_LINK_AFIRME: 'ZUMA_LINK_AFIRME',
}

export default Object.freeze(products)