const products = {
    RESTAURANTE: 'RESTAURANTE',
    QPOS: 'QPOS',
    RETAIL: 'RETAIL',
    LINK_MIT: 'ZUMA LINK MIT',
    LINK_PRAGA: 'ZUMA LINK PRAGA',
    CODI: 'CODI',
    RETAIL_BZPAY: 'ZPOS_RETAIL_BZPAY',
    RESTAURANT_BZPAY : 'ZPOS_RESTAURANT_BZPAY',
    LINK_BZPAY : 'ZUMA_LINK_BZPAY'
}

export default Object.freeze(products)