<template>
  <modal
  :show="showUserInfo"
  
  v-on:close="showUserInfo = false"
  >
    <card shadow type="secondary">
      <template>
        <div>
          <span>
            <h2>{{user.nbNombre}} {{user.apPaterno}} {{user.apMaterno}} <span class="heading-small text-muted"> {{userTypes[user.role]}}</span></h2> 
          </span>
        </div>
        
        <div class="mt-4" v-if="user.createdAt">
          <i class="ni ni-calendar-grid-58 icon-size-sm align-middle"></i> 
          &nbsp;<label class="heading-small text-muted">Creado: </label> {{user.createdAt | formatDateTime}}  
        </div>
        <div v-if="user.loggedInAt">
          <i class="ni ni-calendar-grid-58 icon-size-sm align-middle"></i> 
          &nbsp;<label class="heading-small text-muted">Último acceso: </label> {{user.loggedInAt | formatDateTime}}
        </div>       
        <div>
          <i class="ni ni-calendar-grid-58 icon-size-sm align-middle"></i> 
          &nbsp;<label class="heading-small text-muted">Fecha Nacimiento:</label> {{user.fhNacimiento}}
        </div>

        <hr class="my-4" />
        <h6 class="heading-small text-muted mb-4">Contacto</h6>
        <div v-if="user.txEmail">
          <i class="ni ni-email-83 icon-size-sm align-middle"></i>
          <span> {{user.txEmail}}</span>
        </div>

        <div v-if="user.nuCelular">
          <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
          <span> {{user.nuCelular}}</span>
        </div>
        
        <div v-if="user.nuTelefono">
          <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
          <span> {{user.nuTelefono}}</span>
        </div>

      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
              <div class="col-12 text-right">
                  <base-button 
                    @click.prevent="close()" 
                    class="btn btn-primary text-white">
                      Cerrar
                  </base-button>
              </div>
          </div>
      </div>
    </card>
  </modal>
</template>
<script>

  export default {
    name: "user-info-modal",
    props: {
      userId: {
        description: 'User name to show',
      }
    },
    data() {
      return {
        user: {
          role: 'SALER',
        },
        defaultUser: {
          role: 'SALER',
        },
        userTypes: {
          'SALER': 'Vendedor',
          'ADMIN': 'Administrador',
          'SUPER_ADMIN': 'Super Administrador'
        },
        title: 'Información Usuario',  
        showUserInfo: false,
      }
    },
    methods: {
      cleanData(){
        this.user = this.defaultUser;
      },
      close(){
        this.cleanData();
        this.showUserInfo = false;
        this.$emit('close');
      }
    },
    watch: {
      clean:{
        handler(newValue) {
          if (newValue){
            this.cleanData();
            this.$emit('cleaned');
          }
        }
      },
      userId: {
        handler(newValue){
          if(newValue && newValue>0) {
            //load user
            this.axios.get(`/user/${newValue}`)
            .then(result => {
              this.user = result.data;
              this.showUserInfo = true;
            })
            .catch(error => {
              this.$emit('error', error);
            })
           
          }else {
            this.title = 'Informacion Usuario';
          }
        }
      }
    },
  }
</script>