<template>
  <div>
    <assign-user-modal
      :showAssignClient="showAssignClient"
      :salerSelected="salerSelected"
      v-on:close="showAssignClient=false"
      v-on:error="handleError"
    ></assign-user-modal>
    <new-user-modal
      :showCreateUser="showCreateUser"
      v-on:close="closeUserModal"
      v-on:error="handleError"
      v-on:user-created="updateNewUser"
      :userTypes="userTypes"
      :isCellPhoneRequired="this.activeTab === 'Vendedores'"
      :userId="userId"
      :clean="clean"
      v-on:cleaned="clean = false"
      v-on:user-updated="userUpdated"
    ></new-user-modal>

    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <card shadow>
            <tabs
              fill
              class="flex-column flex-md-row"
              :value="activeTab"
              v-on:new-active-tab="changeTab"
            >
              <tab-pane title="Vendedores">
                <span slot="title">Vendedores</span>
                <div class="col-xl-12">
                  <card shadow type="secondary">
                    <template>
                      <users-table
                        title="Vendedores"
                        v-on:show-clients="showClients"
                        v-on:assign-client="assignClients"
                        v-on:new-user="showCreateUser=true"
                        v-on:update-table-completed="newSaler = {}"
                        :newUser="newSaler"
                        v-on:error="handleError"
                        :salers="true"
                        v-on:update-user="prepareUpdateUser"
                      />
                    </template>
                  </card>
                </div>
              </tab-pane>
              <tab-pane title="Administradores">
                <span slot="title">Administradores</span>
                <div class="col-xl-12">
                  <card shadow type="secondary">
                    <template>
                      <users-table
                        title="Administradores"
                        v-on:show-clients="showClients"
                        v-on:assign-client="assignClients"
                        v-on:new-user="showCreateUser=true"
                        v-on:update-table-completed="newUser = {}"
                        :newUser="newUser"
                        v-on:error="handleError"
                        :admins="true"
                        v-on:update-user="prepareUpdateUser"
                      />
                    </template>
                  </card>
                </div>
              </tab-pane>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UsersTable from "./Tables/UsersTable";
import AssignUserModal from "./Modals/AssignUserModal";
import NewUserModal from "./Modals/NewUserModal";
import handleErrorMixin from "../mixins/handleErrorMixin";

export default {
  components: {
    UsersTable,
    AssignUserModal,
    NewUserModal
  },
  mixins: [handleErrorMixin],
  data() {
    return {
      showAssignClient: false,
      showCreateUser: false,
      salerSelected: 0,
      activeTab: "Vendedores",
      userTypes: [],
      newUser: {}, //Required for update UI
      newSaler: {},
      userId: 0,
      clean: false
    };
  },
  methods: {
    showClients() {
      return;
    },
    assignClients(row) {
      this.showAssignClient = true;
      this.salerSelected = row.id;
    },
    changeTab(event) {
      this.activeTab = event.title;
      this.updateUserType(event.title);
    },
    updateUserType(newVal) {
      this.userTypes = [];

      if (newVal === "Vendedores") {
        this.userTypes = [{ key: "SALER", value: "Vendedor" }];
      } else if (newVal === "Administradores") {
        this.userTypes = [{ key: "ADMIN", value: "Administrador" }];
        
        if (this.$store.getters.jwtRole === "SUPER_ADMIN") {
          this.userTypes.push({
            key: "SUPER_ADMIN",
            value: "Super Administrador"
          });
        }
      }
    },
    updateNewUser($event) {
      if ($event.role === "SALER") {
        this.newSaler = $event;
      } else {
        this.newUser = $event;
      }

      this.showCreateUser = false;
    },
    prepareUpdateUser(row) {
      this.userId = row.id;
      this.showCreateUser = true;
    },
    closeUserModal() {
      this.clean = true;
      this.showCreateUser = false;
      this.userId = 0;
    },
    userUpdated($event) {
      if ($event.role === "SALER") {
        this.newSaler = $event;
      } else {
        this.newUser = $event;
      }
      this.userId = 0;
      this.showCreateUser = false;
    }
  },
  mounted() {}
};
</script>
<style>
</style>
