<template>
  <modal :show="showCreateUser" :showClose="true" v-on:close="$emit('close')">
    <div slot="header">
      <div class="container-fluid">
        <div class="row" v-if="userId === 0">{{title}}</div>
        <div class="row" v-if="userId !== 0">
          <span>
            <strong>{{ user.nbNombre }} {{ user.apPaterno }}</strong>
          </span>
        </div>
        <div class="row" v-if="userId !== 0">
          <span v-if="user.createdAt">
            <label>Creado el:</label>
            {{user.createdAt | formatDateTime}}
          </span>
          <span v-if="user.loggedInAt">
            <label>Último acceso</label>
            {{user.loggedInAt | formatDateTime}}
          </span>
        </div>
      </div>
    </div>
    <card shadow type="secondary">
      <template>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6">
              <base-select label="Tipo Usuario" :options="userTypes" v-model="user.role" required />
            </div>
            <div class="col-lg-6">
              <base-input
                alternative
                label="Nombre"
                input-classes="form-control-alternative"
                v-model="user.nbNombre"
                :capitalize='true'
                :maxlength="50"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                alternative
                label="Paterno"
                input-classes="form-control-alternative"
                v-model="user.apPaterno"
                :capitalize='true'
                :maxlength="50"
                required
              />
            </div>
            <div class="col-lg-6">
              <base-input
                alternative
                label="Materno"
                input-classes="form-control-alternative"
                v-model="user.apMaterno"
                :maxlength="50"
                :capitalize='true'
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">
                  Fecha Nacimiento
                  <span>*</span>
                </label>
                <base-input-no-info addon-left-icon="ni ni-calendar-grid-58">
                  <flat-pickr
                    slot-scope="{focus, blur}"
                    @on-open="focus"
                    @on-close="blur"
                    :config="{allowInput: true, dateFormat: 'd/m/Y'}"
                    class="form-control datepicker"
                    v-model="user.fhNacimiento"
                    required
                  ></flat-pickr>
                </base-input-no-info>
                 <slot name="helpBlock">
                      <div class="text-danger invalid-feedback" style="display: block;"  v-if="validDate !== '' && user.fhNacimiento !== undefined">
                          {{ validDate }}
                      </div>
                  </slot>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <!-- Address -->
        <h6 class="heading-small text-muted mb-4">Contacto</h6>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-8">
              <base-input
                alternative
                label="Correo Electrónico"
                input-classes="form-control-alternative"
                v-model="user.txEmail"
                required
                :maxlength="100"
                :error="validEmail"
              />
            </div>
          </div>
          <div class="row" v-if="userId === 0">
            <div class="col-md-6">
              <base-password
                alternative
                label="Contraseña"
                placeholder
                input-classes="form-control-alternative"
                v-model="user.password"
                required
                :minlength="4"
                :error="validPassword"
              />
            </div>
            <div class="col-md-6">
              <base-password
                alternative
                label="Confirmar Contraseña"
                placeholder
                input-classes="form-control-alternative"
                v-model="user.passwordConfirm"
                required
                :minlength="4"
                :error="validPasswordConfirm"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <base-input
                alternative
                label="Celular"
                input-classes="form-control-alternative"
                v-model="user.nuCelular "
                :required="isCellPhoneRequired"
                :maxlength="10"
                validation="number"
                :error="validCellPhone"
              />
            </div>
            <div class="col-md-6">
              <base-input
                alternative
                label="Teléfono"
                input-classes="form-control-alternative"
                v-model="user.nuTelefono"
                :maxlength="10"
                validation="number"
                :error="validPhone"
              />
            </div>
          </div>
        </div>
      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button
              :disabled="$v.user.$invalid"
              @click.prevent="saveUser()"
              class="btn btn-primary text-white"
            >Guardar</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
import { isStrong } from "../../utils/utilMethods";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { isOlder } from "../../utils/customValidators";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "new-user-modal",
  props: {
    userTypes: {
      type: Array
    },
    showCreateUser: {
      type: Boolean
    },
    isCellPhoneRequired: {
      type: Boolean
    },
    userId: {},
    clean: {}
  },
  data() {
    return {
      user: {
        role: "SALER"
      },
      defaultUser: {
        role: "SALER"
      },
      newUser: {},
      title: "Nuevo usuario"
    };
  },
  components: {
    flatPickr
  },
  methods: {
    saveUser() {
      let that = this;

      if (this.userId === 0) {
        this.axios
          .post("/user/adminuser", this.user)
          .then(result => {
            this.$toasted.show("Usuario creado");
            that.user = Object.assign({}, this.defaultUser);
            this.newUser = result.data; // Trigger update of user table
            this.$emit("user-created", this.newUser);
          })
          .catch(error => {
            this.$emit("error", error);
          })
          .finally(() => {});
      } else {
        this.axios
          .put("/user/adminuser", { user: this.user })
          .then(result => {
            this.$toasted.show("Usuario actualizado");
            that.user = Object.assign({}, this.defaultUser);
            this.newUser = result.data;
            // Trigger update of user table
            this.$emit("user-updated", this.newUser);
          })
          .catch(error => {
            this.$emit("error", error);
          })
          .finally(() => {});
      }
    },
    cleanData() {
      this.user = this.defaultUser;
    }
  },
  watch: {
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    },
    userId: {
      handler(newValue) {
        if (newValue) {
          //load user
          this.title = "Actualizar usuario";
          this.axios
            .get(`/user/${newValue}`)
            .then(result => {
              this.user = result.data;
            })
            .catch(error => {
              this.$emit("error", error);
            });
        } else {
          this.title = "Nuevo usuario";
        }
      }
    }
  },
  validations() {
    if (this.userId === 0) {
      return {
        user: {
          nbNombre: { required },
          apPaterno: { required },
          txEmail: { required, email },
          password: { required, minLength: minLength(8), isStrong },
          passwordConfirm: { sameAsPassword: sameAs("password") },
          nuCelular: { minLength: minLength(10) },
          nuTelefono: { minLength: minLength(10) },
          fhNacimiento: {required, isOlder}
        }
      };
    } else {
      return {
        user: {
          nbNombre: { required },
          apPaterno: { required },
          txEmail: { required, email },
          nuCelular: { minLength: minLength(10) },
          nuTelefono: { minLength: minLength(10) },
          fhNacimiento: {required, isOlder}

        }
      };
    }
  },
  computed: {
    validName() {
      if (!this.$v.user.nbNombre.required) {
        return "Campo Obligatorio";
      }
      return "";
    },
    validSurname() {
      if (!this.$v.user.apPaterno.required) {
        return "Campo Obligatorio";
      }
      return "";
    },
    validEmail() {
      if (!this.$v.user.txEmail.email) {
        return "Proporcione un correo válido";
      }
      return "";
    },
    validPassword() {
      if (!this.$v.user.password.isStrong) {
        return "Al menos 8 caracteres, 1 mayúscula y 1 número";
      }
      return "";
    },
    validPasswordConfirm() {
      if (!this.$v.user.passwordConfirm.sameAsPassword) {
        return "Las contraseñas no coinciden";
      }
      return "";
    },
    validCellPhone() {
      if (!this.$v.user.nuCelular.minLength) {
        return "Introduzca los 10 dígitos del celular";
      }
      return "";
    },
    validPhone() {
      if (!this.$v.user.nuTelefono.minLength) {
        return "Introduzca los 10 dígitos del teléfono";
      }
      return "";
    },
    validDate() {
      if (!this.$v.user.fhNacimiento.isOlder) {
        return "Es necesario ser mayor de edad";
      }
      return "";
    }
  }
};
</script>