export const NUMBER_OF_MONTHS_TO_DISPLAY = 4;

export const monthTableMomentFormat = {
  enero: 0,
  febrero: 1,
  marzo: 2,
  abril: 3,
  mayo: 4,
  junio: 5,
  julio: 6,
  agosto: 7,
  septiembre: 8,
  octubre: 9,
  noviembre: 10,
  diciembre: 11
};

export const monthNames = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre"
];

export const monthNamesEnglish = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const monthNamesWithId = [
  {id: 1,month: "january"},
  {id: 2,month: "february"},
  {id: 3,month: "march"},
  {id: 4,month: "april"},
  {id: 5,month: "may"},
  {id: 6,month: "june"},
  {id: 7,month: "july"},
  {id: 8,month: "august"},
  {id: 9,month: "september"},
  {id: 10,month: "october"},
  {id: 11,month: "november"},
  {id: 12,month: "december"},
];


export let getLastMonths = monthNumberBaseZero => {
  const FIRST_MONTH = 0;
  const LAST_MONTH = 11;
  let monthIndex = monthNumberBaseZero;
  let lastPeriods = [];

  for (let i = 1; i <= NUMBER_OF_MONTHS_TO_DISPLAY; i++) {
    lastPeriods.push(monthNames[monthIndex]);
    monthIndex -= 1;

    if (monthIndex < FIRST_MONTH) {
      monthIndex = LAST_MONTH;
    }
  }

  return lastPeriods;
};

export let getLastMonthsEnglish = monthNumberBaseZero => {
  const FIRST_MONTH = 0;
  const LAST_MONTH = 11;
  let monthIndex = monthNumberBaseZero;
  let lastPeriods = [];

  for (let i = 1; i <= NUMBER_OF_MONTHS_TO_DISPLAY; i++) {
    lastPeriods.push(monthNamesEnglish[monthIndex]);
    monthIndex -= 1;

    if (monthIndex < FIRST_MONTH) {
      monthIndex = LAST_MONTH;
    }
  }

  return lastPeriods;
};


export let getLastMonthsWhithId = monthNumberBaseZero => {
  const FIRST_MONTH = 0;
  const LAST_MONTH = 11;
  let monthIndex = monthNumberBaseZero;
  let lastPeriods = [];

  for (let i = 1; i <= NUMBER_OF_MONTHS_TO_DISPLAY; i++) {
    const uppercase = monthNames[monthIndex].charAt(0).toUpperCase()
    const lowercase = monthNames[monthIndex].slice(1, monthNames[monthIndex].length).toLowerCase();
    let month = uppercase + lowercase;
    lastPeriods.push({id: monthIndex + 1, month: month});
    monthIndex -= 1;

    if (monthIndex < FIRST_MONTH) {
      monthIndex = LAST_MONTH;
    }
  }

  return lastPeriods;
};


export let getMonths = () => {
  let months = monthNames;
  for (let index = 0; index < months.length; index++) {
    const uppercase = months[index].charAt(0).toUpperCase()
    const lowercase = months[index].slice(1, months[index].length).toLowerCase();
    months[index] = uppercase + lowercase;
  }
  return months; 
};