<template>
  <modal :bigModal="true" :show="showCreateDevice" :showClose="true" v-on:close="$emit('close')">
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="type"
      :isMultipleSelection="false"
      v-on:close="closeAssignItem"
      v-on:selectedItems="itemsSelected"
      v-on:error="handleError"
    ></assign-item-modal>
    <div slot="header">
      <div class="container-fluid">
        <div class="row" >{{title}}</div>
      </div>
    </div>
    <card shadow type="secondary">
      <template>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col">
              <base-input
                :disabled="idDevice > 0 || userRoll !== 'UsersAdmins'"
                alternative
                label="Nombre del dispositivo"
                input-classes="form-control-alternative"
                v-model="device.deviceName"
                :isUserName='true'
                :maxlength="64"
                required
              :error="validateField('deviceName')"/>
            </div>
            <div class="col">
              <base-input
                :disabled="userRoll !== 'UsersAdmins'"
                alternative
                label="Nombre corto"
                input-classes="form-control-alternative"
                v-model="device.sequence"
                :capitalize='true'
                :maxlength="8"
                required
              :error="validateField('sequence')"/>
            </div>
          </div>
        </div>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col">
              <base-input
                :disabled="userRoll !== 'UsersAdmins'"
                alternative
                label="Descripción del dispositivo"
                input-classes="form-control-alternative"
                v-model="device.deviceDescription"
                :capitalize='true'
                :maxlength="128"
                required
              :error="validateField('deviceDescription')"/>
            </div>
          </div>
        </div>
        <form>
          <div class="pl-lg-4">
            <!-- <div class="row">
              <div class="col">
                <base-input
                  :disabled="userRoll !== 'UsersAdmins'"
                  alternative
                  label="Correo Electrónico"
                  input-classes="form-control-alternative"
                  v-model="device.email"
                  required
                  :maxlength=64"
                  :error="validateField('email')"
                />
              </div>
            </div> -->

            <div class="row" v-if="idDevice <= 0">
              <div class="col-md-6">
                <base-password
                  label="Contraseña"
                  placeholder
                  v-model="device.password"
                  :error="validateField('password')"
                />
              </div>
              <div class="col-md-6" >
                <base-password
                  label="Confirmar Contraseña"
                  placeholder
                  v-model="device.passwordConfirm"
                  :error="validateField('passwordConfirm')"
                />
              </div>
            </div>

            <div class="row mt-4"  v-if="idDevice <= 0">
              <div class="col">
                <label class="form-control-label">
                  Asignar dispositivo a la sucursal 
                </label>
                <input
                  alternative
                  @click="showAssignItem = true" 
                  style="cursor: pointer; background-color: white" 
                  class="form-control" 
                  v-model="device.branchName"
                  readonly="true"
                  required>
                <slot name="helpBlock">
                  <div class="text-danger invalid-feedback" style="display: block;" >
                      {{validateField('idBranch')}}
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="saveDevice" >{{userRoll === 'UsersAdmins' ? 'Guardar' : 'Cerrar'}}</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import { getDeviceInfo, saveDevice, updatedDevice } from '../../utils/api';
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import AssignItemModal from './AssignItemModal.vue';
import { isEmptyOrNull } from '../../utils/utilMethods';
import enums from '../../enums/enums';
import { getFocus, validatePassword } from '../../utils/generalMethods';

export default {
  name: "new-device-modal",
  components: {
    AssignItemModal
  },
  mixins: {axiosResponseMixin},
  props: {
    showCreateDevice: {
      type: Boolean
    },
    isCellPhoneRequired: {
      type: Boolean
    },
    idDevice: {},
    clean: {},
    branch: {}
  },
  data() {
    return {
      searchTerm: '',
      type: enums.DEVICE,
      showErrorMessages: false,
      showAssignItem: false,
      device: {
        deviceName: '',
        deviceDescription: '',
        email: 'zum4development@zuma.com',
        idBranch: '',
        password: '',
        passwordConfirm: '',
        sequence: ''
      },
      deviceDefault: {
        deviceName: '',
        deviceDescription: '',
        email: 'zum4development@zuma.com',
        idBranch: '',
        password: '',
        passwordConfirm: '',
        sequence: ''
    },
      selectedItems:{},
      title: "Nuevo dispositivo",
    };
  },
  computed: {
    userRoll() {
      return  this.$store.getters.jwtRole
    }
  },
  methods: {
    handleError(error) {
      this.$emit('error', error)
    },
    itemsSelected(event) {
      getFocus()
      this.selectedItems = event;
      if (this.selectedItems.length > 0){
        this.device.branchName = this.selectedItems[0].fullName
        this.device.idBranch = this.selectedItems[0].id
      }
      this.showAssignItem = false
    },
    saveDevice() {
      if (this.userRoll !== 'UsersAdmins'){
        this.$emit('close')
        return 
      }
      this.showErrorMessages = true
      
      if (this.canContinue()){
        let loader = this.$loading.show({loader: 'dots'});
        if (this.idDevice === 0) {
          saveDevice(this.device)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.$toasted.show("Dispositivo creado correctamente");
              this.$emit('device-created')
            }else {
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        } else{
          updatedDevice(this.device)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.$toasted.show("Dispositivo actualizado correctamente");
              this.$emit('device-updated', this.device)
            }else {
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        }
      }
      
    },
    canContinue() {
      var keys = Object.keys(this.device)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
     validateField(key) {
      if (this.showErrorMessages) {
        switch (key) {
          case 'deviceName': 
            return isEmptyOrNull(this.device.deviceName) ? 'Es necesario ingresar el nombre' : ''
          case 'deviceDescription': 
            return isEmptyOrNull(this.device.deviceDescription) ? 'Es necesario ingresar la descripción del dispositivo' : ''
          // case 'email': 
          //   return isEmptyOrNull(this.device.email) || !validateMail(this.device.email)  ? 'El correo electrónico no es valido' : ''
          case 'idBranch':
            if (this.idDevice === undefined || this.idDevice === 0 ) {
              return isEmptyOrNull(this.device.idBranch) ? 'Es necesario seleccionar una sucursal' : ''
            }
            return ''
          case 'passwordConfirm':
              return this.device.passwordConfirm !== this.device.password ? 'las contraseñas no coinciden' : ''
          case 'password':
            if (this.idDevice === undefined || this.idDevice === 0 )
              return !validatePassword(this.device.password) ? 'Al menos 8 caracteres, 1 mayúscula y 1 número' : ''
            return ''
          case 'sequence':
            return isEmptyOrNull(this.device.sequence) ? 'Es necesario ingresar el identificador' : ''
          default:
            return '';
        }
      }            
    },
    cleanData() {
      this.device = Object.assign({}, this.deviceDefault) 
      this.showErrorMessages = false
    },
    closeAssignItem(){
      this.showAssignItem=false 
      getFocus()
    }
  },
  watch: {
    branch: {
      handler(newValue){
        if (newValue !== null && newValue !== undefined){
          this.device.branchName = newValue.name
          this.device.idBranch = newValue.idBranch
        }

      }
    },
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    },
    idDevice: {
      handler(newValue) {
        if (newValue) {
          let loader = this.$loading.show({loader: 'dots'});
          this.title = "Actualizar dispositivo";
          getDeviceInfo(newValue)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.device = response.data.result
              if (isEmptyOrNull(this.device.email)){
                this.device.email = 'zum4development@zuma.com'
              }
            }else{
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        } else {
          this.title = "Nuevo dispositivo";
        }
      }
    }
  }
};
</script>