<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <update-user-password-modal
      :selectedUserId="selectedUserId"
      :subtitle="subtitle"
      :showChangePassword="showChangePassword"
      v-on:close="closeChangePassword"
    ></update-user-password-modal>
    <branch-data-info :selectedBranchId="selectedBrach" v-on:close="selectedBrach=0"></branch-data-info>
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row">
        <div class="col mt-2">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">{{title}}</h3>
        </div>
        <div v-if="showSearch" class="col-xl-3 text-right" style="{background-color: #0A0;}">
          <base-input placeholder="Búsqueda" v-model="searchText"></base-input>
        </div>
        <div
          v-if="showSearch"
          class="text-center align-self-center"
          style="{background-color: #00A; width: 80px;}"
        >
          <download-button
            :endpoint="downloadEndpoint"
            :payload="companiesDownloadPayload"
            reportName="Empresa"
          />
        </div>
      </div>
    </div>
    <modal :show="showConfirmToggle" :showClose="true" v-on:close="clearDeleteData">
      <div slot="header">{{enabled ? 'Deshabilitar': 'Habilitar'}} sucursal</div>
      <form @submit.prevent="toggleBranch(branchToDelete)" ref="DeleteBranchForm">
        <div class="container-fluid">
          <div class="row mt-1">
            <div
              class="col-xl-12"
            >¿Está seguro que desea {{enabled ? 'deshabilitar': 'habilitar'}} la sucursal {{companyName}}?</div>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-4 text-right"></div>
            <div class="col-4 text-right">
              <a @click="clearDeleteData">Cancelar</a>
            </div>
            <div class="col-4 text-right">
              <base-button
                type="primary"
                class="my-4 error"
                nativeType="submit"
              >{{enabled ? 'Deshabilitar': 'Habilitar'}}</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <!-- Confirmation to unlink branch -->
    <modal
      :show="showUnlinkBranchConfirmation"
      :showClose="true"
      v-on:close="clearUnlinkBranchConfirmation"
    >
      <div slot="header">Desasignar sucursal</div>
      <form @submit.prevent="unlinkBranch(branchToUnlink)" ref="UnlinkBranchForm">
        <div class="container-fluid">
          <div class="row mt-1">
            <div class="col-xl-12">¿Está seguro que desea desasignarla como sucursal?</div>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-4 text-right"></div>
            <div class="col-4 text-right">
              <a @click="clearUnlinkBranchConfirmation">Cancelar</a>
            </div>
            <div class="col-4 text-right">
              <base-button type="primary" class="my-4 error" nativeType="submit">Aceptar</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
        tbody-classes="list"
        :data="filteredData"
      >
        <template slot="columns">
          <th>Sucursal</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td v-bind:class="{ parentBranch: row.sucursales_level === 1}">
            <span
              v-if="row.sucursales_status === 2"
              class="badge badge-pill badge-danger"
            >Deshabilitado</span>
            <router-link v-if="row.webUser_id " :to="branchTransactionLink(row)">
              <div class="h4">
                <i v-if="row.sucursales_mitStatus === 'CREATED'" class="ni ni-building"></i>
                {{row.usuario_IdUsuario}} {{row.usuario_NombreNegocio}}
              </div>
            </router-link>
            <div
              class="light-blue"
            >{{row.usuario_ApellidoPaterno}} {{row.usuario_ApellidoMaterno}} {{row.usuario_Nombre}}</div>
            <div>{{row.usuario_Correo}}</div>
            <div>{{row.usuario_Celular}}</div>
          </td>
          <td
            v-bind:class="{ parentBranch: row.sucursales_level === 1 }"
            v-for="n in monthsToDisplay"
            :key="n"
          >{{ (row[monthNames[monthsToDisplay - n]] || "0.00") | currency }}</td>
          <td class="text-right" v-bind:class="{ parentBranch: row.sucursales_level === 1 }">
            <router-link
              v-if="row.webUser_id  && userRole === 'SUPER_ADMIN'"
              :to="selectBranchLink(row)"
            >
              <i v-tooltip.top-center="tooltipDetailMsg" class="fas fa-desktop icon-size"></i>
            </router-link>&nbsp;
            <a v-if="row.webUser_id " href="#" v-on:click="showBranchDataInfo(row)">
              <i v-tooltip.top-center="tooltipInfoDetailMsg" class="fas fa-info-circle icon-size"></i>
              &nbsp;
            </a>
            <a v-if="row.webUser_id" href="#" @click="prepareConfirmTogle(row)">
              <i
                v-if="row.sucursales_status === 2"
                v-tooltip.top-center="tooltipEnnableMsg"
                class="fas fa-check icon-size"
              ></i>
              <i
                v-else
                v-tooltip.top-center="tooltipDisabbleMsg"
                class="fas fa-exclamation-triangle icon-size"
              ></i>
              &nbsp;
            </a>
            <a
              href="#"
              v-if="!row.configuracionesLectorUsuario_IdConfiguraciones && row.sucursales_level === 2"
              @click.prevent="prepareToUnlinkBranch(row)"
            >
              <i v-tooltip.top-center="tooltipUnlinkMsg" class="fas fa-unlink icon-size"></i>
              &nbsp;
            </a>
            <a
              href="#"
              v-if="!row.configuracionesLectorUsuario_IdConfiguraciones && userRole === 'SUPER_ADMIN'"
              @click.prevent="prepareChangePassword(row)"
            >
              <i v-tooltip.top-center="tooltipChangePassword" class="fas fa-unlock-alt icon-size"></i>
            </a>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import genericJoin from "../../mixins/joinArraysHelper";
import monthSalesMixin from "../../mixins/monthSalesMixin";
import paginationMixin from "../../mixins/paginationMixin";
import DownloadButton from "../Buttons/DownloadButton";
import UpdateUserPasswordModal from "../Modals/UpdateUserPasswordModal";
import BranchDataInfo from "../Modals/BranchDataInfo";

export default {
  name: "branches-table",
  mixins: [monthSalesMixin, paginationMixin],
  components: {
    DownloadButton,
    UpdateUserPasswordModal,
    BranchDataInfo
  },
  props: {
    type: {
      type: String
    },
    title: String,
    mitDataSaved: Number,
    webUserCreated: Number,
    refreshTable: Boolean,
    mobileUserId: {
      type: Number,
      description:
        "Used when usersType === branches this is the id of the company we want branches for"
    },
    showSearch: {
      default: true
    }
  },
  data() {
    return {
      mainBranchId: 0,
      tableData: [],
      searchText: "",
      showConfirmToggle: false,
      showUnlinkBranchConfirmation: false,
      branchToDelete: {},
      branchToUnlink: {},
      tooltipDisabbleMsg: "Deshabilitar la sucursal",
      tooltipEnnableMsg: "Habilitar la sucursal",
      tooltipDetailMsg: "Ver detalle sucursal",
      tooltipInfoDetailMsg: "Información de la sucursal",
      tooltipTransactionMsg: "Transacciones sucursal",
      tooltipUnlinkMsg: "Desasignar como sucursal",
      tooltipChangePassword: "Cambiar contraseña",
      searched: false,
      selectedUserId: 0,
      subtitle: "",
      showChangePassword: false,
      enabled: true,
      selectedBrach: 0
    };
  },
  computed: {
    companyName() {
      return this.branchToDelete.usuario_NombreNegocio
        ? this.branchToDelete.usuario_NombreNegocio.trim()
        : "";
    },
    filteredData() {
      return this.tableData.filter(e => {
        if (this.searchText) {
          return (
            e.usuario_NombreNegocio.includes(this.searchText) ||
            e.usuario_ApellidoPaterno.includes(this.searchText) ||
            e.usuario_ApellidoMaterno.includes(this.searchText) ||
            e.usuario_Nombre.includes(this.searchText)
          );
        } else {
          return true;
        }
      });
    },
    companiesDownloadPayload() {
      return {
        txEmail: this.searchText
      };
    },
    downloadEndpoint() {
      return `/sales/yearreport/${this.mainBranchId}/download`;
    },
    userRole() {
      return this.$store.state.user.userInfo.role;
    }
  },
  watch: {
    mitDataSaved: {
      handler(newValue) {
        const objIndex = this.tableData.findIndex(
          tableData => tableData.usuario_IdUsuario === newValue
        );

        if (objIndex > -1) {
          const newRow = Object.assign(this.tableData[objIndex], {
            configuracionesLectorUsuario_IdConfiguraciones: 1
          });
          this.$set(this.tableData, objIndex, newRow); // https://vuejs.org/2016/02/06/common-gotchas/
        }
      },
      deep: true
    },
    webUserCreated: {
      handler(newValue) {
        const objIndex = this.tableData.findIndex(
          tableData => tableData.usuario_IdUsuario === newValue
        );

        if (objIndex > -1) {
          const newRow = Object.assign(this.tableData[objIndex], {
            webUser_id: 1
          });
          this.$set(this.tableData, objIndex, newRow); // https://vuejs.org/2016/02/06/common-gotchas/
        }
      },
      deep: true
    },
    refreshTable: {
      handler(newValue) {
        if (newValue) {
          this.loadData();
        }
      }
    },
    mobileUserId: {
      handler(newValue) {
        if (newValue > 0) {
          this.loadData();
        }
      }
    }
  },
  methods: {
    loadData() {
      if (this.mobileUserId > 0) {
        const endpoint = `/mobileUser/${this.mobileUserId}/branches`;

        this.axios
          .get(endpoint)
          .then(result => {
            function compare(a, b) {
              if (a.sucursales_status < b.sucursales_status) {
                return -1;
              }
              if (a.sucursales_status > b.sucursales_status) {
                return 1;
              }
              return 0;
            }

            this.tableData = result.data.sort(compare);

            let mainBranch = this.tableData.filter(
              e => e.sucursales_level === 1
            );

            this.mainBranchId = mainBranch.map(x => x.sucursales_idBranch)[0];
            this.$emit("loaded-branches");

            let endpoint = `/sales/yearreport/${this.mainBranchId}`;

            return this.axios.post(endpoint);
          })
          .then(result => {
            let joinedData;

            for (let r of result.data.sales) {
              r.idBranch = parseInt(r.idBranch);
            }

            const approvedSales = result.data.sales.filter(
              r => r.estatusVenta === "approved"
            );

            if (this.tableData.length == 1) {
              joinedData = genericJoin(
                "IdBranch",
                "company",
                this.tableData,
                approvedSales
              );
            } else {
              joinedData = genericJoin(
                "sucursales_idBranch",
                "idBranch",
                this.tableData,
                result.data.sales
              );
            }

            this.tableData = joinedData;
          })
          .catch(error => {
            this.$emit("error", error);
          });
      }
    },
    selectBranchLink(row) {
      return `/branch/${row.sucursales_idBranch}`;
    },
    showBranchDataInfo(row) {
      this.selectedBrach = row.sucursales_idBranch;
    },
    branchTransactionLink(row) {
      return `/branch/${row.sucursales_idBranch}/transaction-detail`;
    },
    toggleBranch(row) {
      this.axios
        .put(`/branch/${row.sucursales_idBranch}/toggle`)
        .then(result => {
          this.loadData();
          this.$toasted.show(
            `Sucursal ${this.branchToDelete.usuario_NombreNegocio} ${result.data.message}`
          );
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.clearDeleteData();
        });
    },
    unlinkBranch(branch) {
      this.axios
        .delete(`/branch/unlink/${branch.sucursales_idBranch}`)
        .then(result => {
          this.loadData();
          this.$toasted.show(result.data);
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.clearUnlinkBranchConfirmation();
        });
    },
    prepareConfirmTogle(row) {
      if (row.sucursales_status === 2) {
        this.enabled = false;
      } else {
        this.enabled = true;
      }
      this.branchToDelete = row;
      this.showConfirmToggle = true;
    },
    clearDeleteData() {
      this.branchToDelete = {};
      this.showConfirmToggle = false;
    },
    clearUnlinkBranchConfirmation() {
      this.branchToUnlink = {};
      this.showUnlinkBranchConfirmation = false;
    },
    prepareChangePassword(row) {
      this.selectedUserId = row.webUser_id;
      this.subtitle = `${row.usuario_IdUsuario} ${row.usuario_NombreNegocio}`;
      this.showChangePassword = true;
    },
    prepareToUnlinkBranch(row) {
      this.branchToUnlink = row;
      this.showUnlinkBranchConfirmation = true;
    },
    closeChangePassword() {
      this.selectedUserId = 0;
      this.showChangePassword = false;
    }
  },
  mounted() {
    this.initializeMonthData();
    this.loadData();
  }
};
</script>
<style lang="scss">
@media (max-width: 1400px) {
  .md-screen-hide {
    display: none;
  }
}
.ligth-text {
  color: #828f7f;
}

.parentBranch {
  background-color: cornsilk;
}
.deleted {
  background-color: darksalmon;
}
.icon-size {
  font-size: 1rem;
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
