/**
 * Little helper to bubble up error to DashboadLayout.vue
 * There is where is handled the toasts for errors
 * 
 * When bubbled up the event must be send completly and not only
 * the message. DashboardLayout is responsible of handle all that logic
 */

export default {
  methods:{
    handleError(error) {
      this.$emit('error', error);
    }
  }
}