var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        { attrs: { show: _vm.showAppUser }, on: { close: _vm.closeAppUser } },
        [
          _c(
            "card",
            { attrs: { shadow: "", type: "secondary" } },
            [
              [
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Cuenta:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.ticket.accountName) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Empresa:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.ticket.enterpriseName) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Sucursal:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.ticket.branchName) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Dispositivo:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.ticket.deviceName) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Fecha de alta:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("formatDateTime")(_vm.ticket.createdOn)) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Tipo :\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.ticket.type) + "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Título:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.ticket.description1) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Descripción:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.ticket.description2) +
                      "\n        "
                  )
                ]),
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v("\n          Estatus:\n        ")
                ]),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.ticket.status === "I" ? "Revisado" : "Pendiente"
                      ) +
                      "\n        "
                  )
                ])
              ],
              _c(
                "div",
                {
                  staticClass: "bg-white border-0",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-right" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "btn btn-primary text-white",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.closeAppUser()
                              }
                            }
                          },
                          [_vm._v("\n                Salir\n            ")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }