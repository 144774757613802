<template>
  <div>
    <assign-branch-modal
      :showAssignModal="showAssignModal"
      :webUserToCreate="webUserToCreate"
      v-on:close="showAssignModal=false"
      v-on:assigned-branch="refreshTables"
      v-on:error="handleError"
    ></assign-branch-modal>
    <modal :show="showMITData" :showClose="true" v-on:close="showMITData=false">
      <div slot="header">Información de coneccion MIT</div>
      <form @submit.prevent="saveMITData" ref="MITDataForm">
        <div class="container-fluid">
          <div class="row mt-1">
            <div class="col-xl-6">
              <base-input
                label="Usuario MIT"
                input-classes="form-control-alternative"
                v-model="mitData.User"
              />
            </div>
            <div class="col-xl-6">
              <base-input
                label="Password MIT"
                input-classes="form-control-alternative"
                v-model="mitData.Password"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <base-input
                label="Id Empresa"
                input-classes="form-control-alternative"
                v-model="mitData.Company"
              />
            </div>
            <div class="col-xl-6">
              <base-input
                label="Id Sucursal"
                input-classes="form-control-alternative"
                v-model="mitData.Branch"
              />
            </div>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-12 text-right">
              <base-button type="primary" class="my-4" nativeType="submit">Guardar</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>

    <modal :show="showCreateWebUser" :showClose="true" v-on:close="showCreateWebUser=false">
      <div slot="header">Permisos usuario</div>
      <form @submit.prevent="createWebUser" ref="MITDataForm">
        <div class="container-fluid">
          <div class="row mt-1">
            <p>
              Esta accion hará que el negocio relacionado al usuario se asigne como negocio de primer nivel y permitirá
              que otros negocios puedan ser asignados como sucursal al mismo
            </p>
            <p>¿Desea continuar?</p>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-6">
              <a @click.prevent="showCreateWebUser=false">Cancelar</a>
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" class="my-4" nativeType="submit">Guardar</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <card shadow>
            <tabs
              fill
              class="flex-column flex-md-row"
              :value="activeTab"
              v-on:new-active-tab="updateState"
            >
              <tab-pane title="Empresas">
                <span slot="title">Empresas</span>
                <div class="col-xl-12">
                  <card shadow type="secondary">
                    <template>
                      <companies-table
                        title="Empresas"
                        v-on:show-mit-data="prepareMITDataPopup"
                        v-on:show-web-user="prepatecreateWebUser"
                        :mitDataSaved="mitDataSaved"
                        :webUserCreated="webUserCreated"
                        v-on:assign-to-company="prepareAssignToCompany"
                        v-on:loaded-users="refreshCompanyTable=false"
                        :refreshTable="refreshCompanyTable"
                        usersType="top-level"
                        v-on:error="handleError"
                      />
                    </template>
                  </card>
                </div>
              </tab-pane>
              <tab-pane title="Nuevos Clientes">
                <span slot="title">Nuevos Clientes</span>
                <div class="col-xl-12">
                  <card shadow type="secondary">
                    <template>
                      <clients-table
                        title="Clientes registrados"
                        v-on:show-mit-data="prepareMITDataPopup"
                        v-on:show-web-user="prepatecreateWebUser"
                        :mitDataSaved="mitDataSaved"
                        :webUserCreated="webUserCreated"
                        v-on:assign-to-company="prepareAssignToCompany"
                        v-on:loaded-users="refreshNewUserTable=false"
                        :refreshTable="refreshNewUserTable"
                        usersType="unassigned"
                        v-on:error="handleError"
                      />
                    </template>
                  </card>
                </div>
              </tab-pane>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientsTable from "./Tables/ClientsTable";
import CompaniesTable from "./Tables/CompaniesTable";
import AssignBranchModal from "./Modals/AssignBranchModal";
import handleErrorMixin from "../mixins/handleErrorMixin";
import { SAVE_BRANCH_TAB_STATE } from "../store/actions/branch";

export default {
  components: {
    CompaniesTable,
    ClientsTable,
    AssignBranchModal
  },
  mixins: [handleErrorMixin],
  data() {
    return {
      showMITData: false,
      showCreateWebUser: false,
      showAssignModal: false,
      mitData: {
        IdUsuario: 0,
        User: "",
        Password: "",
        Company: "",
        Branch: ""
      },
      defaultMitData: {
        IdUsuario: 0,
        User: "",
        Password: "",
        Company: "",
        Branch: ""
      },
      mitDataSaved: 0,
      webUserToCreate: 0,
      webUserCreated: 0,
      refreshNewUserTable: false,
      refreshCompanyTable: false,
      activeTab: ""
    };
  },
  computed: {},
  methods: {
    saveMITData() {
      this.axios
        .post("/userconfiguration", this.mitData)
        .then(() => {
          this.mitDataSaved = this.mitData.IdUsuario;
          this.$toasted.show("Informacion actualizada");
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.showMITData = false;
        });
    },
    prepareMITDataPopup(event) {
      this.mitData = Object.assign({}, this.defaultMitData);
      this.mitData.IdUsuario = event.usuario_IdUsuario;
      this.showMITData = true;
    },
    prepareAssignToCompany(event) {
      this.webUserToCreate = event.usuario_IdUsuario;
      this.showAssignModal = true;
    },
    createWebUser() {
      let info = { mobileUserId: this.webUserToCreate };
      this.axios
        .post("/user/createwebuser", info)
        .then(result => {
          this.webUserCreated = result.data.mobileUserId;
          this.$toasted.show("Informacion actualizada");
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.refreshTables();
          this.showCreateWebUser = false;
        });
    },
    prepatecreateWebUser(event) {
      this.webUserToCreate = event.usuario_IdUsuario;
      this.showCreateWebUser = true;
    },
    refreshTables() {
      this.refreshNewUserTable = true;
      this.refreshCompanyTable = true;
    },
    handleError($event) {
      this.$emit("error", $event);
    },
    updateState($event) {
      this.$store.commit(SAVE_BRANCH_TAB_STATE, {
        activeTab: $event._props.title
      });
    }
  },
  mounted() {
    if (!this.$store.state.branch.clientComponentState.activeTab) {
      this.$store.commit(SAVE_BRANCH_TAB_STATE, { activeTab: "Empresas" });
    }
    this.activeTab = this.$store.state.branch.clientComponentState.activeTab;
  }
};
</script>
<style>
</style>
