<template>
  <modal :show="showChangePassword" :showClose="true" v-on:close="closeChangePassword">
    <div slot="header">
      Actualizar Contraseña
      <br v-if="subtitle" />
      <strong v-if="subtitle">{{subtitle}}</strong>
    </div>
    <card shadow type="secondary">
      <template>
        <form>
          <div class="pl-lg-3">
            <div class="row">
              <div class="col-md-6">
                <base-password
                  alternative
                  label="Contraseña"
                  placeholder
                  input-classes="form-control-alternative"
                  v-model="user.password"
                  required
                  :error="validationMsg($v.user.password)"
                />
              </div>
              <div class="col-md-6">
                <base-password
                  alternative
                  label="Confirmar Contraseña"
                  placeholder
                  input-classes="form-control-alternative"
                  v-model="user.passwordConfirm"
                  required
                  :error="validationMsg($v.user.passwordConfirm)"
                />
              </div>
            </div>
          </div>
        </form>
      </template>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button
              :disabled="$v.user.$invalid"
              @click.prevent="updatePassword()"
              class="btn btn-primary text-white"
            >Guardar</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
import { required, sameAs, maxLength } from "vuelidate/lib/validators";
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import validationMessagesMixin from "../../mixins/validationMessagesMixin";
import { changeUserPassword } from '../../utils/api';
import { isStrong } from "../../utils/utilMethods";

export default {
  name: "update-user-password-modal",
  mixins: [validationMessagesMixin],
  props: {
    selectedUserId: {
      type: Number,
      description: "The id of the user to update password"
    },
    showChangePassword: {
      type: Boolean,
      description: "When to show or hide this modal window"
    },
    dashboardUser: {
      type: Boolean,
      description:
        "When the change password is in table of webusers and mobileusers or only mobile users",
      default: true
    },
    subtitle: {
      type: String,
      description: "The full name of the user to display it on the modal window"
    }
  },

  data() {
    return {
      user: {}
    };
  },

  methods: {
    updatePassword() {
      let requestPayload = {
        newPassword: this.user.password,
        idUser: this.selectedUserId
      };
      let loader = this.$loading.show({loader: 'dots'})
      changeUserPassword(requestPayload)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show('Contraseña actualizada')
          this.closeChangePassword()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      }).catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
      // let endpoint = "/user/changePassword";
      // if (!this.dashboardUser) {
      //   endpoint = "/mobileuser/changePassword";
      // }

      // this.axios
      //   .put(endpoint, requestPayload)
      //   .then(() => {
      //     this.$toasted.show("Contraseña actualizada");
      //     this.closeChangePassword();
      //   })
      //   .catch(error => {
      //     this.$emit("error", error);
      //   });
    },
    closeChangePassword() {
      this.user = {};
      this.$emit("close");
    }
  },

  validations: {
    user: {
      password: { required, maxLength: maxLength(20), isStrong },
      passwordConfirm: { sameAsPassword: sameAs("password") }
    }
  }
};
</script>