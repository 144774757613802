export default {
  data() {
    return{
      pagination: {
        page: 1,
        size: 30,
      },
      defaultPagination: {
        page: 1,
        size: 30,
      },
      totalElements: 0,
    }
  },
  methods:{
    updatePagination(page) {
      this.pagination.page = page;
      this.loadData();
    },
  }
}