<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col-xl-11">
          <h3 class="mb-0">Ventas por Comisionista</h3>
        </div>
        <div class="text-center align-self-center" style="{background-color: #00A; width: 80px;}">
          <download-button
            endpoint="/sales/salers/download"
            :payload="salersPayload"
            reportName="Vendedores"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>Vendedor</th>
          <th
            class="text-right"
            v-for="n in monthsToDisplay"
            :key="n"
          >{{monthNames[monthsToDisplay - n]}}</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td>
            <div class="h4">
              <router-link
                :to="sellerSalesLink(row, period)"
              >{{row.saler_nbNombre}} {{row.saler_apPaterno}} {{row.saler_apMaterno}}</router-link>
            </div>
            <div>{{row.saler_txEmail}}</div>
            <div>{{row.saler_nuCelular}}</div>
          </td>

          <td
            class="text-right"
            v-for="n in monthsToDisplay"
            :key="n"
          >{{ (row[monthNames[monthsToDisplay - n]] || "0.00") | currency}}</td>
          <td>
            <router-link :to="sellerSalesLink(row, period)">Detalle</router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent': ''"
    >
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import periodSearchMixin from "../../mixins/periodSearchMixin";
import monthSalesMixin from "../../mixins/monthSalesMixin";
import paginationMixin from "../../mixins/paginationMixin";
import DownloadButton from "../Buttons/DownloadButton";

export default {
  name: "salers-resume-table",
  mixins: [periodSearchMixin, monthSalesMixin, paginationMixin],
  components: {
    DownloadButton
  },
  props: {
    type: {
      type: String
    },
    title: String,
    selectedUser: Number,
    refreshTable: Boolean
  },
  data() {
    return {
      tableData: []
    };
  },
  computed: {
    salersPayload() {
      return {
        period: this.period
      };
    }
  },
  methods: {
    updatePagination(page) {
      this.pagination.page = page;
      this.fetchData();
    },
    fetchData() {
      this.error = this.tableData = null;
      this.loading = true;
      this.axios
        .post("/sales/salers", {
          period: this.period,
          pagination: this.pagination
        })
        .then(result => {
          this.tableData = result.data.salers;
          this.pagination = result.data.pagination;
          this.totalElements = parseInt(result.data.total);
          this.$emit("period-changed", this.period);
        })
        .catch(error => {
          this.$emit("error", error);
        });
    },
    sellerSalesLink(row, period) {
      return `/salers2/${row.salerId}/${period.month}/${period.year}`;
    }
  },
  mounted() {
    this.initializeMonthData();
    this.initializeYearArray();
    this.initializePeriod();
    this.fetchData();
  }
};
</script>