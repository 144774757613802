<template>
  <div>
    <modal
        :show="showAppUser"
        v-on:close="closeAppUser"
      >
      <!--div slot="header">Información General</div-->
      <card shadow type="secondary">
        <template>
          <div>
            <span>
              <h2>{{mobileUser.nombreNegocio}}</h2>
            </span>
          </div>
          <div class="ligth-text">
            <i class="ni ni-single-02 icon-size-sm align-middle"></i>{{mobileUser.nombre}} {{mobileUser.apellidoPaterno}} {{mobileUser.apellidoMaterno}}
          </div>
          <div v-if="mobileUser.correo">
            <i class="ni ni-email-83 icon-size-sm align-middle"></i> {{mobileUser.correo}}
          </div>
          <div v-if="mobileUser.celular">
            <i class="ni ni-mobile-button icon-size-sm align-middle"></i> {{mobileUser.celular}}
          </div>
          <div v-if="mobileUser.serial">
            <i class="ni ni-books icon-size-sm align-middle"></i> {{mobileUser.serial}}
          </div>
          <div v-if="mobileUser.numeroTarjeta">
            <i class="ni ni-credit-card icon-size-sm align-middle"></i> {{mobileUser.numeroTarjeta}}
          </div>
        </template>
        <div slot="footer" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-12 text-right">
              <base-button
                @click.prevent="closeAppUser()"
                class="btn btn-primary text-white">
                  Salir
              </base-button>
            </div>
            </div>
        </div>
      </card>
    </modal>
  </div>
</template>
<script>

export default {
  name: 'app-user-info-modal',
  props: ['idUser'],
  data() {
    return {
      showAppUser: false,
      mobileUser: {

      },
      /** Names taken from the legacy database */
      defaultAppUser: {
        idUsuario: undefined,
        correo: '',
        pin: '',
        confirmPin: '',
        contrasena: '',
        confirmContrasena: '',
        nombreNegocio: '',
        numeroTarjeta: '',
        estado: 0,
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        celular: '',
        documentacion: false,
        serial: '',
      }, 
    }
  },
  methods: {
    closeAppUser() {
      this.clearData();
      this.showAppUser=false;
      this.$emit('close');
    },
    clearData() {
      this.mobileUser = Object.assign({}, this.defaultAppUser);
    },
  },
  watch: {
    idUser(newVal){
      if(newVal && newVal > 0){
        this.axios.get(`/mobileUser/${newVal}`)
        .then((result) => {
          this.mobileUser = result.data;
          this.showAppUser = true;
        })
        .catch((error) => {
          this.$emit('error', error);
        })
      }
    }
  },
}
</script>