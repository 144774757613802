<template>
  <modal :bigModal="true" :show="showDetails" :showClose="true" v-on:close="$emit('close')">
    <div slot="header">
      <div class="container-fluid">
        <div class="row">Detalles de la sucursal</div>
      </div>
    </div>
    <card shadow type="secondary">
      <b-tabs
        pills
        v-model="tabIndex"
        content-class="mt-3"
        no-key-nav
      >
        <b-tab title-item-class="flex-fill text-center " title="Generales">
          <span slot="title">Generales</span>
          <b-container style="width:100%" >
            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Nombre de la sucursal: *"
                  v-model="item.name"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Nombre de contacto: *"
                  v-model="item.contactName"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Número Telefonico: *"
                  v-model="item.contactPhoneNumber"
                  disabled
                  :maxlength="10"
                  validation="number"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Extención:"
                  v-model="item.contactPhoneExt"
                  disabled
                  :maxlength="10"
                  validation="number"
                ></base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Celular: *"
                  v-model="item.contactMobileNumber"
                  disabled
                  :capitalize='true'
                  :maxlength="10"
                  validation="number"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Email: *"
                  v-model="item.contactMail"
                  disabled
                  :maxlength="64"
                ></base-input>
              </b-col>
            </b-row>
          </b-container>
            </b-tab>
        <b-tab title-item-class="flex-fill text-center " title='Direccion'>
          <span slot="title">Dirección</span>
          <b-container>
            <b-row class="mt-4">
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Calle: * "
                  v-model="item.street"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Número ext: * "
                  v-model="item.extNumber"
                  disabled
                  :capitalize='true'
                  :maxlength="10"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Número int: "
                  v-model="item.intNumber"
                  disabled
                  :capitalize='true'
                  :maxlength="10"
                ></base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Colonia: *"
                  v-model="item.suburb"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Municipio: *"
                  v-model="item.county"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Ciudad: * "
                  v-model="item.city"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Estado: * "
                  v-model="item.state"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Tipo Cliente (Marketing): * "
                  v-model="item.country"
                  disabled
                  :capitalize='true'
                  :maxlength="40"
                ></base-input>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab title-item-class="flex-fill text-center " title="Cuenta">
          <span slot="title">Cuenta</span>
          <b-container>
            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Clave interna:"
                  v-model="item.internalKey"
                  disabled
                  :capitalize='true'
                  :maxlength="16"
                ></base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Máximo de ventas por día:"
                  v-model="item.maxByDay"
                  disabled
                  validation="number"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  input-classes="form-control-alternative"
                  label="Máximo de ventas por mes:"
                  v-model="item.maxByMonth"
                  disabled
                  validation="number"
                ></base-input>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="$emit('close')" >Cerrar</base-button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { getBranchInfo } from '../../utils/api';

export default {
  name: "branch-details-modal",
  mixins: {axiosResponseMixin},
  props: {
    idBranch: Number,
    showDetails: {
      type: Boolean
    },
    clean: {}
  },
  data() {
    return {
      item: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      emptyItem: {
        idEnterprise: this.idBranch,
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone

      },
      tabIndex: 0,
      numberTabs: 2,
    };
  },
  methods: {
    handleError(error) {
      this.$emit('error', error)
    },
    cleanData() {
      this.item = this.emptyItem
      this.tabIndex = 0
    },
    getBranchDetails(){
      let loader = this.$loading.show({loader: 'dots'})
      getBranchInfo(this.idBranch)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          this.item = response.data.result
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  },
  watch: {
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    },
    idBranch: {
      handler(newValue) {
        if (newValue > 0) {
          this.getBranchDetails()
        }else{
          this.cleanData()
        }
      }
    }
  },
  mounted() {
    if (isNaN(this.item.idEnterprise))
      this.item.idEnterprise = undefined
  }
};
</script>
<style >

/* .big-modal-dialog {
  max-width: 90% ;
} */
</style>