<template>
  <modal
    :show="showAssignClient"
    :showClose=true
    v-on:close="closeModal">
    <div slot="header">Asignar cliente</div>
    <form @submit.prevent="assignClients" ref="AssignClientForm">
      <div class="container-fluid ">
        <div class="row mt-1 ">
          <div class="col-xl-12">
            <base-input
                label="Cliente"
                input-classes="form-control-alternative"
                v-model="client.email"
              />
          </div>
        </div>
          <div class="row mt-1 ">
          <div class="col-xl-12">
            <search-client-table
                title="Usuarios registrados"
                :searchTerm="client.email"
                v-on:client-selected="selectClient"
                :clean="clean"
                v-on:cleaned="clean=false"
            />
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4" nativeType="submit">Guardar</base-button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
  import SearchClientTable from '../Tables/SearchClientTable';
  export default {
    name: 'assign-user-modal',
    components: {
      SearchClientTable
    },
    props: {
      showAssignClient: {
        type: Boolean,
        default: false
      },
      salerSelected: {
        type: Number,
        description: 'Id of the saler to assign clients to'
      }
    },
    data() {
      return {
        client: {
          email: '',
        },
        selectedClients:new Set(),
        clean: false,
      }
    },
    methods:{
      selectClient(event) {
        if(event.selected){
          this.selectedClients = new Set(this.selectedClients.add(event.data.id));
        }else{
          this.selectedClients = new Set(this.selectedClients.delete(event.data.id));
        }
      },
      closeModal(){
        this.client.email= '';
        this.clean = true;
        this.$emit('close');
      },
      assignClients() {
         this.axios.put(`/user/${this.salerSelected}/assignclients`, {clients: Array.from(this.selectedClients)})
          .then(() => {
            this.$toasted.show('Clientes asignados');
          })
          .catch(error => {
            this.$emit('error', error);
          })
          .finally (() => {
            this.closeModal();
          }); 
      },
    },
  }
</script>