<template>
  <div class="card">
    <ticket-info-modal :idTicket="idTicket" v-on:close="idTicket = 0" v-on:error="handleError"></ticket-info-modal>
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col mt-2 align-self-center">
          <div>
           <h3 h3 class="mb-0">
              Tickets
           </h3>
          </div>
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <v-select 
            placeholder = 'Todos'
            :options="['Revisados', 'Pendientes']"
            @input = "changeTableData"

          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <v-select 
            placeholder = 'Todos'
            :options="['Rollos', 'Reportes', 'Comentarios', 'Limite de ventas']"
            @input = "changeTableDataTypes"

          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center"  style="{background-color: #0A0;}">
          <base-input
            style="margin-bottom: 0px"
            placeholder="Búsqueda"
            v-model="paginationDTO.searchTerm"
            @keyup.enter="loadData"
          >
          </base-input>
        </div>
      </div>
    </div>

    <div class="card-body border-0">
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="tableData">
          <template slot="columns">
            <th>Tipo</th>
            <th>Empresa</th>
            <th ref="prev">Título/Descripción</th>
            <th>F. Alta</th>
            <th>Estatus</th>
          </template>

          <template slot-scope="{row}">
            <th class="text-left" style="cursor: pointer" @click.prevent="ticketClicket(row)" >
              {{getType(row)}}
            </th>
            <td  class="text-left" style="cursor: pointer" @click.prevent="ticketClicket(row)" 
            >
              {{row.enterpriseName}}
            </td>
            <td class="text-left" style="cursor: pointer" @click.prevent="ticketClicket(row)" >
              <div >
                {{row.description1}}
              </div>
              <div >
                {{row.description2}}
              </div>
            </td>
            <td class="text-left">
              {{row.createdOn}}
            </td>
            <td>
              <span style="cursor: pointer" @click.prevent="check(row)" v-if="row.status === 'A'" class="badge badge-pill badge-danger">Pendiente</span>
              <span style="cursor: pointer" @click.prevent="check(row)" v-if="row.status === 'I'" class="badge badge-pill badge-success">Revisado</span>
            </td>
          </template>
        </base-table>
        <div class="card-footer d-flex justify-content-end">
          <base-pagination
            :total="totalElements"
            :perPage="pagination.size"
            :value="pagination.page"
            @input="updatePagination"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TicketInfoModal from '../Modals/TicketInfoModal.vue';
import { getTicketsList, toggleTickets } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import BasePagination from '../../components/BasePagination.vue';
import paginationMixin from "../../mixins/paginationMixin";
import "vue-select/dist/vue-select.css";
import enumTicketsType from '../../enums/enumTicketsType';
import { paginationLimit } from '../../utils/catalog';

export default {
  mixins: [paginationMixin],
  components: { TicketInfoModal, BasePagination },
  name: "transactions-table",
  props: {
    idAccount: Number,
  },
  data() {
    return {
      idTicket: 0,
      totalElements: 0,
      tableData: [
      ],
      paginationDTO:{
        offset : 0,
        limit : paginationLimit,
        start : "",
        end : "",
        status : 'ALL',
        orderBy : "DESC",
        type : null,
        searchTerm : ""
      }
    };
  },
  methods: {
    updatePagination(page) {
      if (this.pagination.page !== page){
        if (page === 1){
          this.paginationDTO.offset = 0
        }else{
          let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
          this.paginationDTO.offset = offset
        }
        this.loadData();
      }
    },
    changeTableDataTypes(e) {
      switch (e) {
        case 'Rollos':
            this.paginationDTO.type = 'Rolls'
          break;
        case 'Reportes':
            this.paginationDTO.type = 'Report'
          break;
        case 'Comentarios':
            this.paginationDTO.type = 'Comments'
          break;
        case 'Limite de ventas':
            this.paginationDTO.type = 'MaxAmounts'
          break;
        default:
            this.paginationDTO.type = null
          break;
      }
      this.loadData()
    },
    changeTableData(e) {
      switch (e) {
        case 'Pendientes':
            this.paginationDTO.status = 'A'
          break;
        case 'Revisados':
            this.paginationDTO.status = 'I'
          break;
        default:
            this.paginationDTO.status = 'ALL'
          break;
      }
      this.loadData()
    },
    loadData() {
      let loader = this.$loading.show({loader: 'dots'})
      getTicketsList(this.paginationDTO)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.totalElements = response.data.result.count
          this.tableData = response.data.result.list
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    check(row) {
      let dto = {
        idTicket: row.idTicket,
        status: row.status === 'A' ? 'I' : 'A'
      }
      let loader = this.$loading.show({loader: 'dots'})
      toggleTickets(dto)
      .then(response=>{
        loader.hide()
        if (response.data.statusCode === 200) {
          if (this.paginationDTO.status === 'ALL'){
              row.status = row.status === 'A' ? 'I' : 'A'
            }else{
              let index = this.tableData.findIndex(e => e.idBranch === row.idBranch)
              if ( index >= 0 )  
                this.tableData.splice(index,1) 
            }
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    ticketClicket(row){
      this.idTicket = row.idTicket
    },
    handleError(value){
      this.idTicket = 0
      this.$emit('error', value)
    },
    getType(row){
      switch(row.type){
        case enumTicketsType.REPORT:
          return 'Reporte'
        case enumTicketsType.COMMENTS:
          return 'Comentario'
        case enumTicketsType.ROLLS:
          return 'Petición de rollitos'
        case enumTicketsType.MAXAMOUNTS:
          return 'Cambio de montos'
        case enumTicketsType.UPDATE_APP:
          return 'Actualizar versión'
      }
    }
  },
  mounted() {
    this.idTicket = 0
    this.pagination.size = 10
    this.loadData();

  }
};
</script>