import { NUMBER_OF_MONTHS_TO_DISPLAY, getLastMonths, getLastMonthsEnglish, getLastMonthsWhithId } from "./monthDays";
import moment from "moment";

export default {
  data() {
    return {
      defultSalesData: {
        abril: "0.00",
        agosto: "0.00",
        diciembre: "0.00",
        enero: "0.00",
        estatusVenta: "approved",
        febrero: "0.00",
        julio: "0.00",
        junio: "0.00",
        marzo: "0.00",
        mayo: "0.00",
        noviembre: "0.00",
        octubre: "0.00",
        septiembre: "0.00"
      },
      monthNames: [],
      currentMonth: 0,
      monthsToDisplay: NUMBER_OF_MONTHS_TO_DISPLAY
    };
  },
  methods: {
    initializeMonthData() {
      this.currentMonth = moment().month();
      this.monthNames = getLastMonths(this.currentMonth);
      this.monthNamesEnglish = getLastMonthsEnglish(this.currentMonth);
      this.monthNamesWithId = getLastMonthsWhithId(this.currentMonth);
    }
  }
};
