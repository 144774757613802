<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
            <salers-resume-table
                title="Vendedores"
                v-on:loaded-users="refreshSalersTable=false"
                :refreshTable="refreshSalersTable"
                v-on:error="handleError"
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import SalersResumeTable from './Tables/SalersResumeTable';
  import handleErrorMixin from '../mixins/handleErrorMixin';
  export default {
    mixins: [handleErrorMixin],
    components: {
      SalersResumeTable,
    },
    data() {
      return {
        refreshSalersTable: false,
      };
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    },
  };
</script>
<style>
</style>
