<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="card bg-secondary shadow border-0 " style="margin-bottom: 250px">
                <span slot="title" class="m-3" style="color: #9C1C00">Estado de cuenta</span>
                  <b-container>
                     <enterprise-branch-selector
                      v-on:branch-selected="branchSelected"
                      v-on:enterprise-selected="enterpriseSelect"
                      :errorEnterprise="validateField('idEnterprise')"
                      :errorBranch="validateField('idBranch')"
                    />
                    <b-row>
                      <b-col>
                        <label class="form-control-label">
                          Selecciona un periodo
                        </label>
                        <div class="mt-3 " style="background: white" >
                          <v-select 
                            label="label"
                            :value="periodSelected"
                            :reduce="label => label"
                            v-model="periodSelected.data"
                            placeholder = 'Seleccione una opción'
                            :options="periods"
                            @input = "periodChange"
                          ></v-select>
                        </div>
                        <slot name="helpBlock">
                          <div class="text-danger invalid-feedback" style="display: block;" >
                              {{validateField('date')}}
                          </div>
                        </slot>
                      </b-col>
                    </b-row>
                  </b-container>
                  <base-button type="primary" class="my-4 ml-3 mr-3"  @click="generateStatement" >Generar estado de cuenta</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "vue-select/dist/vue-select.css";
  import axiosResponseMixin, { processErrorResponse }  from "../mixins/axiosResponseMixin";
  import monthSalesMixin from '../mixins/monthSalesMixin';
  import EnterpriseBranchSelector from '../components/EnterpriseBranchSelector.vue';
import { isEmptyOrNull } from '../utils/generalMethods';

  export default {
      components: { EnterpriseBranchSelector },
      mixins: [axiosResponseMixin, monthSalesMixin],
      name: 'link-generator', 
      data() {
        return {
          idBranch: '',
          idEnterprise: '',
          data: {
            year: '',
            month: '',
            idBranch: 0
          },
          periods: [],
          showErrorMessages: false,
          periodSelected: {}
        }
      },
      mounted() {
        this.mobileUserId = this.$store.getters.jwtData.userId;
        this.initializeMonthData()
        this.getMounts()
      },
      methods: {
        branchSelected(value){
          this.data.idBranch = value
        },
        enterpriseSelect(value){
          this.idEnterprise = value
        },
        getMounts(){
          this.periods = []
          var currentDate = new Date();
          for (let index = 0; index < 4; index++) {
            var date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15 );
            date.setMonth(date.getMonth() - index)
            var month = date.toLocaleString('es', { month: 'long' });
            month = month.charAt(0).toUpperCase() + month.slice(1)
            this.periods.push({
              month: date.getMonth() + 1,
              label: `${month} ${date.getFullYear()}`,
              year: date.getFullYear()
            })
          }
        },
        periodChange(){
          this.data.year = this.periodSelected.data.year
          this.data.month = this.periodSelected.data.month
        },
        resetData() {
          this.data = {
            year: '',
            month: ''
          }
        },
        generateStatement() {
          this.showErrorMessages = true
          if (this.canContinue()) {
            let loader = this.$loading.show({
              loader: 'dots'
            });
            this.axios.post(`/reports/accountstatement/branch`,
            this.data,
            {
                responseType: 'arraybuffer'
            }
            )
            .then(response => {
              loader.hide()
              const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                //Build a URL from the file
              if (file.size < 500 ){
                var responseDataArray = new Uint8Array(response.data);
                var enc = new TextDecoder("utf-8");
                var data = JSON.parse(enc.decode(responseDataArray))
                processErrorResponse(data, this.$toasted)
              }else{
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
              }
              
              
                              
            })
            .catch(error => {
              loader.hide()
              var enc = new TextDecoder("utf-8");
              var arr = new Uint8Array(error.response.data);
              processErrorResponse(JSON.parse(enc.decode(arr)), this.$toasted)
            });
          }
        },
        canContinue() {
          var keys = Object.keys(this.data)
          for (let i = 0; i < keys.length; i++) {
            if (this.validateField(keys[i]) !== '')
              return false;
          }
          return true;
        },
        validateField(key) {
          if (this.showErrorMessages) {
            switch (key) {
              case 'idBranch': 
                return this.isEmptyOrNull(this.data.idBranch) ? 'Es necesario seleccionar una sucursal' : ''
              case 'idEnterprise': 
                return this.isEmptyOrNull(this.idEnterprise) ? 'Es necesario seleccionar una empresa' : ''
              case 'date': 
                return isEmptyOrNull(this.periodSelected.data) ? 'Es necesario seleccionar un periodo' : ''
              default:
                return '';
            }
          }            
        },
        isEmptyOrNull(data) {
          return data === '' || data === null
        }
      }
  }
</script>
<style>
.vs__clear {
  visibility: hidden;
}
</style>