const brands = {
    AMEX: "AMEX",
    DISCOVER: "DISCOVER",
    MASTER: "MASTER",
    MASTERCARD: "MASTERCARD",
    VISA: "VISA",
    NOTDEFINED: "NOTDEFINED",
    AMEX_IMAGE: "img/brand/amex.svg",
    MASTER_IMAGE: "img/brand/mastercard.svg",
    VISA_IMAGE: "img/brand/visa.svg",
    DISCOVER_IMAGE: "img/brand/discover.svg",
    NOTDEFINED_IMAGE: "img/brand/default-card.svg"
}

export default Object.freeze(brands)