var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showAssignClient, showClose: true },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Asignar cliente")
      ]),
      _c(
        "form",
        {
          ref: "AssignClientForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.assignClients($event)
            }
          }
        },
        [
          _c("div", { staticClass: "container-fluid " }, [
            _c("div", { staticClass: "row mt-1 " }, [
              _c(
                "div",
                { staticClass: "col-xl-12" },
                [
                  _c("base-input", {
                    attrs: {
                      label: "Cliente",
                      "input-classes": "form-control-alternative"
                    },
                    model: {
                      value: _vm.client.email,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "email", $$v)
                      },
                      expression: "client.email"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row mt-1 " }, [
              _c(
                "div",
                { staticClass: "col-xl-12" },
                [
                  _c("search-client-table", {
                    attrs: {
                      title: "Usuarios registrados",
                      searchTerm: _vm.client.email,
                      clean: _vm.clean
                    },
                    on: {
                      "client-selected": _vm.selectClient,
                      cleaned: function($event) {
                        _vm.clean = false
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                { staticClass: "col-12 text-right" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4",
                      attrs: { type: "primary", nativeType: "submit" }
                    },
                    [_vm._v("Guardar")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }