<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row">
        <div class="col mt-2 align-self-center">
          <h3 class="mb-0">
            {{getTitle()}}
          </h3>
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <v-select 
            placeholder = 'Todos'
            :options="['Habilitados', 'Desabilitados']"
            @input = "changeTableData"

          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center"  style="{background-color: #0A0;}">
          <base-input
            style="margin-bottom: 0px"
            placeholder="Búsqueda"
            v-model="paginationDTO.searchTerm"
            @keyup.enter="loadData"
          >
          </base-input>
        </div>
         <div v-if="userRol === 'UsersAdmins'" class="col-md-2 mt-2">
          <div class="text-center">
            <a @click="$emit('new-item')" class="btn btn-primary text-white">Agregar cuenta</a>
          </div>
        </div>
        <div class="mt-2 mr-3 text-center align-self-center">
          <download-button
            endpoint="/accounts/download"
            :payload="downloadPayload"
            reportName="Reporte_cuentas"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>ID</th>
          <th>Cuenta</th>
          <th>F. Alta</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
          <th>Vendedor</th>
          <th>Estatus</th>
          <th v-if="userRol === 'UsersAdmins'"></th>
        </template>

        <template slot-scope="{row}">
          <td>
            <div class="h4">
                {{row.data.idAccount}}
            </div>
            
          </td>
          <td>
            <div 
              @click="goToDetailsItem(row)" 
              style="cursor: pointer; background-color: white" 
              class="h4">
                {{row.data.name}}
            </div>
            <div >
              {{row.data.contactPhone}}
              <br>
              {{row.data.contactMail}}
            </div>
          </td>
          <td>
            {{row.data.createdDate | formatDateWithoutTimeZone}}
          </td>
          <td
            class="text-left"
            v-for="n in monthsToDisplay" :key="n">
            {{ (row.totals[monthNamesEnglish[monthsToDisplay - n]] || "0.00") | currency}}
          </td>
          <td>
            {{row.data.partnerName}}
          </td>
          <td>
            <span v-if="row.data.status === 'I'" class="badge badge-pill badge-danger">Deshabilitado</span>
            <span v-if="row.data.status === 'A'" class="badge badge-pill badge-success">Habilitado</span>
          </td>
          <td v-if="userRol === 'UsersAdmins'">
            <router-link
              :to="selectedAccount(row)"
            >
              <i v-tooltip.top-center="'Ver detalles de la cuenta'" class="fas fa-desktop icon-size"></i>
            </router-link>
            &nbsp;
            <!-- <router-link
              :to="goProductsAccount(row)"
            >
              <i v-tooltip.top-center="'Productos'" class="fas fa-cube icon-size"></i>
            </router-link> -->
            &nbsp;
            <a>
              <i
                @click.prevent="disableItem(row)"
                v-tooltip.top-center="row.data.status === 'A' ? 'Deshabilitar' : 'Habilitar'"
                class="fas fa-exclamation-triangle icon-size"
              ></i>
            </a>
          </td>
        </template>

      </base-table>
      <div class="card-footer d-flex justify-content-end">
        <base-pagination
          :total="totalElements"
          :perPage="pagination.size"
          :value="pagination.page"
          @input="updatePagination"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import "vue-select/dist/vue-select.css";
import monthSalesMixin from '../../mixins/monthSalesMixin';
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import enums from '../../enums/enums';
import enumRoutes from '../../enums/enumRoutes';
import { disableAccount, getAccounts } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import DownloadButton from "../Buttons/DownloadButton";
import { paginationLimit } from '../../utils/catalog';

  export default {
    name: 'generic-data-table',
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin],
    components: {DownloadButton},
    props: {
      ownerId: Number,
      type: String,
      updateTable:Boolean,
      refreshTable: Boolean,
      showSearch: {
        default: true,
      },

    },
    data() {
      return {
        mainBranchId: 0,
        tableData: [],
        mobileUserId: 0,
        title: '',
        tooltipTransactionMsg: 'Transacciones sucursal',
        searched: false,
        idParentBranch: 0,
        totalElements: 0,
        paginationDTO: {
          searchTerm: "",
          offset: 0,
          limit: paginationLimit,
          status: 'ALL'
        },
        downloadPayload: {
          offset: 0,
          limit: 0,
          status: 'ALL',
          orderBy: "DESC",
          searchTerm: ""
        }
      }
    },
    computed: {
      userRol() {
        return  this.$store.getters.jwtRole
      }
    },
    watch: {
      updateTable: {
        handler(newValue){
          if(newValue) {
            this.loadData()
            this.$emit('table-loaded')
          }
        }
      },
      refreshTable: {
        handler(newValue){
          if(newValue){
            this.loadData();
          }
        }
      },
      mobileUserId: {
        handler(newValue){
          if(newValue > 0){
            this.loadData();
          }
        }
      },
    },
    methods: {
      getTitle() {
        switch (this.type) {
          case enums.ACCOUNT:
            return "Cuentas"
          case enums.ENTERPRISE:
            return "Empresas"
          case enums.BRANCH:
            return "Sucursales"
          case enums.DEVICE:
            return "Dispositivos"
        }
        return ''
      },
      updatePagination(page) {
        if (this.pagination.page !== page){
          if (page === 1){
            this.paginationDTO.offset = 0
          }else{
            let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
            this.paginationDTO.offset = offset
          }
          this.pagination.page = page;
          this.loadData();
        }
      },
      loadData() {
        this.downloadPayload.searchTerm = this.paginationDTO.searchTerm
        let load = this.$loading.show({loader: 'dots'})
        getAccounts(this.paginationDTO)
        .then(response => {
          load.hide()
          if (response.data.statusCode === 200){
            this.totalElements = response.data.result.count
            this.tableData = response.data.result.list
            this.downloadPayload.limit = response.data.result.count
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          load.hide()
          this.$emit('error', error)
        })
      },
      changeTableData(e) {
        switch (e) {
          case 'Habilitados':
              this.paginationDTO.status = 'A'
            break;
          case 'Desabilitados':
              this.paginationDTO.status = 'I'
            break;
          default:
              this.paginationDTO.status = 'ALL'
            break;
        }
        this.downloadPayload.status = this.paginationDTO.status
        this.loadData()
      },
      disableItem(row) {
        let loader = this.$loading.show({loader: 'dots'})
        disableAccount(row.data.idAccount)
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show("Operacion exitosa")
            if (this.paginationDTO.status === 'ALL'){
              row.data.status = row.data.status === 'A' ? 'I' : 'A'
            }else{
              let index = this.tableData.findIndex(e => e.data.idAccount === row.data.idAccount)
              if ( index >= 0 )  
                this.tableData.splice(index,1) 
            }
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      },
      prepareUpdateItem(row){
        this.$emit("update-item", row);
      },
      goToDetailsItem(row) {
        this.$router.push({
          path: `${enumRoutes.ENTERPRISE}${row.data.idAccount}`
        });
      },
      goProductsAccount(row){
         return `/products/${row.data.idAccount}`;
      },
      selectedAccount(row) {
        return `/account-detail/${row.data.idAccount}`;
      }
    },
    mounted() {
      this.pagination.size = this.paginationDTO.limit
      this.initializeMonthData();
      this.loadData();
    }
  }
</script>
<style lang="scss">

   @media (max-width: 1400px) {
    .md-screen-hide{
      display: none;
    }
  }
  .ligth-text {
    color: #828f7f;
  }

  .parentBranch{
    background-color: cornsilk;
  }
  .deleted{
    background-color: darksalmon;
  }
  .icon-size {
    font-size: 1rem;
  }
  .tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
