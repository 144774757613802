<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-xl-3 mt-2 align-self-center">
          <div>
           <h3 h3 class="mb-0">
              Transacciones
           </h3>
          </div>
        </div>
        <div class="col-xl-2 mt-2 align-self-center">
          <v-select 
            placeholder = 'Todas'
            :options="['Todas', 'Aprobadas', 'Canceladas']"
            @input = "changeTableData"
          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center">
          <base-input-no-info addon-left-icon="ni ni-calendar-grid-58">
            <flat-pickr

              @on-close="startSearch"
              :config="{allowInput: true, mode: 'range'}"
              class="form-control datepicker"
              v-model="dateRange"
            ></flat-pickr>
          </base-input-no-info>
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center"  style="{background-color: #0A0;}">
          <base-input
            style="margin-bottom: 0px"
            placeholder="Búsqueda: (No. transacción, No. tarjeta)"
            v-model="paginationDTO.searchTerm"
            @keyup.enter="fetchData"
          >
          </base-input>
        </div>
        <div class="col  mt-2  text-center align-self-center">
          <download-button
            endpoint="/reports/transactions/download"
            :payload="downloadPayload"
            reportName="Reporte_transacciones"
          />
        </div>
      </div>
    </div>

    <div class="card-body border-0">
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="tableData">
          <template slot="columns">
            <th v-if="idBranch <= 0">Sucursal</th>
            <th v-if="idBranch > 0">Dispositivos</th>
            <th>Num. Op.</th>
            <th>Referencia</th>
            <th>Tipo Pago</th>
            <th>Monto</th>
            <th>Estado</th>
            <th>Fecha</th>

          </template>

          <template slot-scope="{row}">
            <th scope="row" v-if="idBranch <= 0">
              {{row.branchName}}
            </th>
            <th scope="row" v-if="idBranch > 0">
              {{row.username}}
            </th>
            <td>{{row.idPayment}}</td>
            <td>{{row.reference}}</td>
            <td>{{ row.paymentType | typePayment }} {{ row.cardType | typeCard }}</td>
            <td class="text-left">
              <div v-if="row.tip">
                monto:&nbsp;&nbsp;&nbsp;&nbsp;{{row.amount | currency}}
              </div>
              <div v-if="row.tip">
                propina:&nbsp;&nbsp;{{row.tip | currency}}
              </div>
              <div>
                Importe:&nbsp;&nbsp;{{row.amount + row.tip | currency}}
              </div>
            </td>
            <td>
              <span v-if="row.paymentStatus === 'cancelled'" class="badge badge-pill badge-danger">Cancelada</span>
              <span v-if="row.paymentStatus === 'approved'" class="badge badge-pill badge-success">Aprobada</span>
            </td>
            <th scope="row">
              {{row.creationDate | formatDate}} {{row.creationDate | formatOnlyTime}}
            </th>
          </template>
        </base-table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end dark">
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
flatpickr.localize(Spanish);
import "vue-select/dist/vue-select.css";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import periodSearchMixin from "../../mixins/periodSearchMixin";
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import flatpickr from "flatpickr";
import flatPickr from "vue-flatpickr-component";
import { getTransactions } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { Spanish } from "flatpickr/dist/l10n/es.js";
import DownloadButton from "../Buttons/DownloadButton";
import { paginationLimit } from '../../utils/catalog';

export default {
  name: "transactions-table",
  mixins: [periodSearchMixin, paginationMixin, infoCSSClasesMixin],
  components: {
    flatPickr,
    DownloadButton
  },
  props: {
    idSession: Number,
    idBranch: Number,
    idDevice: Number
  },
  data() {
    return {
      tableData: [],
      error: "",
      dateRange: "",
      totalElements: 0,
      paginationDTO: {
        idBranch: this.idBranch,
        idUserDevice: this.idDevice,
        idSession: this.idSession,
        offset: 0,
        limit: paginationLimit,
      },
      downloadPayload: {
        idBranch: this.idBranch,
        idUserDevice: this.idDevice,
        offset: 0,
        limit: 0,
        status: null,
        orderBy: "DESC",
        idSession: -1,
        searchTerm: ""
      }
    };
  },
  created() {
    this.pagination.size = this.paginationDTO.limit
    this.fetchBranchData();
    this.fetchData();
  },
  methods: {
    startSearch(dates){
      if (dates.length === 2){
        let dateStart = new Date(dates[0])
        let dateEnd = new Date(dates[1])
        dateEnd.setHours(23,59,59,59)
        this.paginationDTO.start = moment(dateStart).format('YYYY-MM-DD HH:mm:ssZZ')
        this.paginationDTO.end =  moment(dateEnd).format('YYYY-MM-DD HH:mm:ssZZ')
        this.downloadPayload.start = this.paginationDTO.start
        this.downloadPayload.end = this.paginationDTO.end
        if (this.paginationDTO.start && this.paginationDTO.end) {
          this.fetchData();
        }
      }else if (dates.length === 0){
        this.paginationDTO.start = ""
        this.paginationDTO.end =  ""
        this.downloadPayload.start = ""
        this.downloadPayload.end = ""
        this.fetchData();
      }
    },
    changeTableData(e) {
      switch (e) {
        case 'Aprobadas':
            this.paginationDTO.status = 'approved'
          break;
        case 'Canceladas':
            this.paginationDTO.status = 'cancelled'
          break;
        default:
            this.paginationDTO.status = null
          break;
      }
      this.downloadPayload.status = this.paginationDTO.status
      this.fetchData()
    },
    updatePagination(page) {
      if (this.pagination.page !== page){
        if (page === 1){
          this.paginationDTO.offset = 0
        }else{
          let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
          this.paginationDTO.offset = offset
        }
        this.pagination.page = page;
        this.fetchData();
      }
    },
    fetchData() {
      let loader = this.$loading.show({loader: 'dots'})
      getTransactions(this.paginationDTO)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.totalElements = response.data.result.count
          this.tableData = response.data.result.list
          this.downloadPayload.limit = response.data.result.count
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    fetchBranchData() {
     
    },
    formateDate: function(value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm:ssZZ')
    }
  },
  filters: {
    typePayment: function(type) {
      switch (type) {
        case "CASH":
          return "Efectivo";
        case "CARD":
          return "T.";
      }
    },
    typeCard: function(type) {
      switch (type) {
        case "Credit":
          return "Crédito";
        case "Debit":
          return "Débito";
      }
    }
  },
mounted() {}
};
</script>