<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <app-user-modal
      :showAppUser="showAppUser"
      v-on:close="showAppUser = false; idUser = 0"
      v-on:error="handleError"
      v-on:user-created="insertClientIntoTable"
      v-on:user-updated="updateClientIntoTable"
      :idUser="idUser"
    ></app-user-modal>
    <modal
      :show="showUserDeleteConfirmation"
      :showClose="true"
      v-on:close="clearUserDeleteConfirmation"
    >
      <div slot="header">Eliminar usuario</div>
      <form @submit.prevent="deleteUser(userToDelete)" ref="DeleteUserForm">
        <div class="container-fluid">
          <div class="row mt-1">
            <div
              class="col-xl-12"
            >¿Está seguro que desea eliminar de forma permanente el usuario {{userToDelete.usuario_ApellidoPaterno}} {{userToDelete.usuario_ApellidoMaterno}} {{userToDelete.usuario_Nombre}}, sus ventas, dispositivos y configuraciones?</div>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-4 text-right"></div>
            <div class="col-4 text-right">
              <a @click="clearUserDeleteConfirmation">Cancelar</a>
            </div>
            <div class="col-4 text-right">
              <base-button type="primary" class="my-4 error" nativeType="submit">Eliminar</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <modal :show="showConfirmDelete" :showClose="true" v-on:close="clearDeleteData">
      <div slot="header">Eliminar sucursal</div>
      <form @submit.prevent="deleteBranch(branchToDelete)" ref="DeleteBranchForm">
        <div class="container-fluid">
          <div class="row mt-1">
            <div
              class="col-xl-12"
            >Está seguro que desea eliminar la sucursal {{branchToDelete.usuario_NombreNegocio}}</div>
          </div>
        </div>
        <div slot="footer">
          <div class="row align-items-center">
            <div class="col-4 text-right"></div>
            <div class="col-4 text-right">
              <a @click="clearDeleteData">Cancelar</a>
            </div>
            <div class="col-4 text-right">
              <base-button type="primary" class="my-4 error" nativeType="submit">Eliminar</base-button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <app-user-info-modal :idUser="idUserInfo" v-on:close="idUserInfo = 0"></app-user-info-modal>

    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">{{title}}</h3>
        </div>
        <div v-if="showSearch" class="col-xl-3 text-right" style="{background-color: #0A0;}">
          <base-input-no-info
            placeholder="Búsqueda"
            @keyup.enter="searchUsers"
            v-model="searchText"
          ></base-input-no-info>
        </div>
        <div
          v-if="showSearch"
          class="text-center align-self-center"
          style="{background-color: #00A; width: 80px;}"
        >
          <download-button endpoint="/user/download" :payload="usersPayload" reportName="Usuarios" />
        </div>
        <div class="text-right">
          <a @click="newAppUser" class="btn btn-primary text-white">Nuevo</a>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th class="text-center" width="10%">ID</th>
          <th>Negocio</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td class="text-center">{{row.usuario_IdUsuario}}</td>
          <td>
            <div class="h4">
              <a
                @click.prevent="prepareShowUser(row)"
                style="cursor: pointer;"
              >{{row.usuario_NombreNegocio}}</a>
            </div>
            <div
              class="ligth-text"
            >{{row.usuario_ApellidoPaterno}} {{row.usuario_ApellidoMaterno}} {{row.usuario_Nombre}}</div>
            <div>{{row.usuario_Correo}}</div>
            <div>{{row.usuario_Celular}}</div>
          </td>
          <td class="text-right" v-bind:class="{ parentBranch: row.sucursales_level === 1 }">
            <router-link
              v-if="row.webUser_id && usersType ==='branches'"
              :to="selectBranchLink(row)"
            >
              <i v-tooltip.top-center="tooltipDetailMsg" class="ni ni-shop icon-size"></i>
            </router-link>
            <a v-if="row.webUser_id && usersType ==='branches'" @click="prepareConfirmDelete(row)">
              <i v-tooltip.top-center="tooltipDeleteMsg" class="ni ni-fat-remove icon-size"></i>
            </a>
            <a v-if="!row.webUser_id" @click.prevent="$emit('show-web-user', row)">
              <i v-tooltip.top-center="tooltipPrincipalMsg" class="fas fa-home icon-size"></i>
              &nbsp;
            </a>
            <a v-if="!row.webUser_id" @click.prevent="$emit('assign-to-company', row)">
              <i v-tooltip.top-center="tooltipBranchMsg" class="fas fa-network-wired icon-size"></i>
              &nbsp;
            </a>
            <a v-if="!row.webUser_id" @click.prevent="prepareUpdateUser(row)">
              <i v-tooltip.top-center="tooltipUpdateUser" class="fas fa-edit icon-size"></i>
              &nbsp;
            </a>
            <a v-if="!row.webUser_id" @click.prevent="prepareDeleteUser(row)">
              <i v-tooltip.top-center="tooltipDeleteUser" class="far fa-trash-alt icon-size"></i>
              &nbsp;
            </a>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent': ''"
    >
      <base-pagination
        :total="totalElements"
        :perPage="pagination.size"
        :value="pagination.page"
        @input="updatePagination"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import DownloadButton from "../Buttons/DownloadButton";
import AppUserModal from "../Modals/AppUserModal";
import AppUserInfoModal from "../Modals/AppUserInfoModal";
import handleErrorMixin from "../../mixins/handleErrorMixin";
import paginationMixin from "../../mixins/paginationMixin";

export default {
  name: "clients-table",
  components: {
    DownloadButton,
    AppUserModal,
    AppUserInfoModal
  },
  mixins: [handleErrorMixin, paginationMixin],
  props: {
    type: {
      type: String
    },
    title: String,
    mitDataSaved: Number,
    webUserCreated: Number,
    refreshTable: Boolean,
    usersType: {
      type: String,
      default: "unassigned",
      required: true,
      description: "which type of users the endpoint is retrieving",
      validator: function(value) {
        // The value must match one of these strings
        return ["unassigned", "branches"].indexOf(value) !== -1;
      }
    },
    mobileUserId: {
      type: Number,
      description:
        "Used when usersType === branches this is the id of the company we want branches for"
    },
    showSearch: {
      default: true
    }
  },
  data() {
    return {
      tableData: [],
      searchText: "",
      showConfirmDelete: false,
      showUserDeleteConfirmation: false,
      branchToDelete: {},
      userToDelete: {},
      tooltipDeleteMsg: "Eliminar la sucursal",
      tooltipDetailMsg: "Ver detalle sucursal",
      tooltipCompanyDetailMsg: "Ver negocio",
      tooltipPrincipalMsg: "Hacer negocio principal",
      tooltipBranchMsg: "Asignar como sucursal",
      tooltipUpdateUser: "Actualizar usuario",
      tooltipDeleteUser: "Eliminar usuario",

      showAppUser: false,

      idUser: 0,
      idUserInfo: 0
    };
  },
  computed: {
    usersPayload() {
      return {
        period: this.period
      };
    },
    userRole() {
      return this.$store.state.user.userInfo.role;
    }
  },
  watch: {
    mitDataSaved: {
      handler(newValue) {
        const objIndex = this.tableData.findIndex(
          tableData => tableData.usuario_IdUsuario === newValue
        );
        if (objIndex > -1) {
          const newRow = Object.assign(this.tableData[objIndex], {
            configuracionesLectorUsuario_IdConfiguraciones: 1
          });
          this.$set(this.tableData, objIndex, newRow); // https://vuejs.org/2016/02/06/common-gotchas/
        }
      },
      deep: true
    },
    webUserCreated: {
      handler(newValue) {
        const objIndex = this.tableData.findIndex(
          tableData => tableData.usuario_IdUsuario === newValue
        );
        if (objIndex > -1) {
          const newRow = Object.assign(this.tableData[objIndex], {
            webUser_id: 1
          });
          this.$set(this.tableData, objIndex, newRow); // https://vuejs.org/2016/02/06/common-gotchas/
        }
      },
      deep: true
    },
    refreshTable: {
      handler(newValue) {
        if (newValue) {
          this.loadData();
        }
      }
    },
    mobileUserId: {
      handler(newValue) {
        if (newValue > 0) {
          this.loadData();
        }
      }
    }
  },
  methods: {
    resolveEndpoint() {
      let endpoint = "";
      switch (this.usersType) {
        case "unassigned":
          endpoint = "/mobileUser/unnasigned";
          break;
        case "branches":
          endpoint = `/mobileUser/${this.mobileUserId}/branches`;
          break;
        default:
          throw new Error(`Undefined option ${this.usersType}`);
      }
      return endpoint;
    },
    loadData() {
      let bodyRequest = {
        searchTerm: this.searchText,
        pagination: this.pagination
      };

      const endpoint = this.resolveEndpoint();
      if (
        this.usersType !== "branches" ||
        (this.usersType === "branches" && this.mobileUserId > 0)
      ) {
        this.axios
          .post(endpoint, bodyRequest)
          .then(response => {
            const BRANCH_DELETED = 2;
            this.tableData = response.data.users.filter(
              e => e.sucursales_status !== BRANCH_DELETED
            );
            this.pagination = response.data.pagination;
            this.totalElements = parseInt(response.data.total);
            this.$emit("loaded-users");
          })
          .catch(error => {
            this.$emit("error", error);
          });
      }
    },
    branchesLink(row) {
      return `/company/${row}/branches`;
    },
    selectBranchLink(row) {
      return `/branch/${row.sucursales_idBranch}`;
    },
    deleteBranch(row) {
      this.axios
        .delete(`/branch/${row.sucursales_idBranch}`)
        .then(() => {
          this.loadData();
          this.$toasted.show(
            `Sucursal ${this.branchToDelete.usuario_NombreNegocio} eliminada`
          );
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.clearDeleteData();
        });
    },
    deleteUser(user) {
      this.axios
        .delete(`/mobileuser/${user.usuario_IdUsuario}`)
        .then(() => {
          this.loadData();
          this.$toasted.show(`El usuario ha sido eliminado`);
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.clearUserDeleteConfirmation();
        });
    },
    prepareConfirmDelete(row) {
      this.branchToDelete = row;
      this.showConfirmDelete = true;
    },
    clearDeleteData() {
      this.branchToDelete = {};
      this.showConfirmDelete = false;
    },
    clearUserDeleteConfirmation() {
      this.userToDelete = {};
      this.showUserDeleteConfirmation = false;
    },
    searchUsers() {
      let bodyRequest = {
        searchTerm: this.searchText,
        pagination: this.pagination
      };
      this.axios
        .post("/mobileUser/unnasigned", bodyRequest)
        .then(response => {
          const BRANCH_DELETED = 2;
          this.tableData = response.data.users.filter(
            e => e.sucursales_status !== BRANCH_DELETED
          );
          this.pagination = response.data.pagination;
          this.totalElements = parseInt(response.data.total);
          this.$emit("loaded-users");
          //this.tableData = response.data;
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {});
    },
    newAppUser() {
      this.showAppUser = true;
      this.$emit("new-app-user");
    },
    insertClientIntoTable(client) {
      let addedClient = this.transformResponse(client);

      this.tableData.unshift(addedClient);
      this.$toasted.show("Cliente agregado");
    },
    updateClientIntoTable(client) {
      let updatedClient = this.transformResponse(client);

      const index = this.tableData.findIndex(
        client => client.usuario_IdUsuario === updatedClient.usuario_IdUsuario
      );

      this.$set(this.tableData, index, updatedClient);
      this.$toasted.show("Cliente actualizado");
    },
    prepareUpdateUser(row) {
      this.idUser = row.usuario_IdUsuario;
      this.showAppUser = true;
    },
    prepareDeleteUser(row) {
      this.userToDelete = row;
      this.showUserDeleteConfirmation = true;
    },
    prepareShowUser(row) {
      this.idUserInfo = row.usuario_IdUsuario;
    },
    transformResponse(client) {
      return {
        usuario_ApellidoMaterno: client.apellidoMaterno,
        usuario_ApellidoPaterno: client.apellidoPaterno,
        usuario_Celular: client.celular,
        usuario_Correo: client.correo,
        usuario_Estado: client.estado,
        usuario_IdUsuario: client.idUsuario,
        usuario_Nombre: client.nombre,
        usuario_NombreNegocio: client.nombreNegocio,
        usuario_NumeroTarjeta: client.numeroTarjeta
      };
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style lang="scss">
.parentBranch {
  background-color: cornsilk;
}
.icon-size {
  font-size: 1rem;
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
