var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-xl-3 mt-2 text-right align-self-center" },
          [
            _c("base-input-no-info", {
              attrs: { "addon-left-icon": "ni ni-calendar-grid-58" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var focus = ref.focus
                    var blur = ref.blur
                    return _c("flat-pickr", {
                      staticClass: "form-control datepicker",
                      attrs: { config: { allowInput: true, mode: "range" } },
                      on: { "on-open": focus, "on-close": blur },
                      model: {
                        value: _vm.dateRange,
                        callback: function($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange"
                      }
                    })
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "card-body border-0" }, [
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              attrs: { "thead-classes": "thead-light", data: _vm.tableData },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.idBranch > 0
                        ? _c(
                            "th",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                "background-color": "white"
                              },
                              attrs: { scope: "row" },
                              on: {
                                click: function($event) {
                                  return _vm.goTransactions(row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.username) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            "background-color": "white"
                          },
                          on: {
                            click: function($event) {
                              return _vm.goTransactions(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.totalOperations))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-left",
                          staticStyle: {
                            cursor: "pointer",
                            "background-color": "white"
                          },
                          on: {
                            click: function($event) {
                              return _vm.goTransactions(row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("currency")(row.totalAmountWithTip))
                          )
                        ]
                      ),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("formatDate")(row.creationDate)) +
                            " " +
                            _vm._s(_vm._f("formatTime")(row.creationDate)) +
                            "              \n          "
                        )
                      ]),
                      _c(
                        "th",
                        [
                          _c("a", [
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top-center",
                                  value: "Detalles",
                                  expression: "'Detalles'",
                                  modifiers: { "top-center": true }
                                }
                              ],
                              staticClass: "fas fa-money-check icon-size",
                              staticStyle: {
                                cursor: "pointer",
                                "background-color": "white"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.goTransactions(row)
                                }
                              }
                            })
                          ]),
                          _vm._v("\n             \n            "),
                          _c(
                            "router-link",
                            { attrs: { to: _vm.selectedAccount(row) } },
                            [
                              _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top-center",
                                    value: "Totales",
                                    expression: "'Totales'",
                                    modifiers: { "top-center": true }
                                  }
                                ],
                                staticClass: "fas fa-dollar-sign icon-size"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "columns" }, [
                _vm.idBranch > 0 ? _c("th", [_vm._v("Dispositivo")]) : _vm._e(),
                _c("th", [_vm._v("Operaciones")]),
                _c("th", [_vm._v("totales")]),
                _c("th", [_vm._v("Fecha de cerrado")]),
                _c("th")
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "card-footer d-flex justify-content-end dark" },
      [
        _c("base-pagination", {
          attrs: {
            total: _vm.totalElements,
            perPage: _vm.pagination.size,
            value: _vm.pagination.page
          },
          on: { input: _vm.updatePagination }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col mt-2 align-self-center" }, [
      _c("div", [
        _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
          _vm._v("\n            Sesiones\n         ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }